import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const QUERY_CACHE_KEY = 'investor-reporting';

async function fetchInvestorReport({ queryKey }) {
  try {
    const [, id] = queryKey;
    const {
      data: { data },
    } = await api.get(`investor-reporting/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

async function updateInvestorReporting(data) {
  try {
    const { data: response } = await api.post(
      `investor-reporting/update-report`,
      data,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function createInvestorReporting(data) {
  try {
    const { data: response } = await api.post(
      `investor-reporting/create-report`,
      data,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

function useFetchInvestorReportAPI(id) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEY, id],
    queryFn: fetchInvestorReport,
    enabled: !!id,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
}

function useFetchInvestorReportsAPI(filters = {}) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEY, { filters }],
    queryFn: async () => {
      const { data } = await api.get(`investor-reporting`, {
        params: { ...filters },
      });

      return data.data;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

function useCreateInvestorReportingAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createInvestorReporting,
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      }),
  });
}

function useDeleteInvestorReportingAPI({
  onDeleteSuccess,
  onDeleteFailure,
} = {}) {
  const queryClient = useQueryClient();
  const { error } = useNotification();
  return useMutation({
    mutationFn: async (reportingId) => {
      const { data: response } = await api.delete(
        `investor-reporting/delete/${reportingId}`,
      );
      return response;
    },
    onError: () => {
      error('Error when trying to delete Investor Reporting');
      onDeleteFailure?.();
    },
    onSuccess: async () => {
      onDeleteSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      });
    },
  });
}

function useUpdateInvestorReportingAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateInvestorReporting,
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_CACHE_KEY],
      }),
  });
}

export function useInvestorReporting(params) {
  return {
    fetchInvestorReports: useFetchInvestorReportsAPI,
    fetchInvestorReport: useFetchInvestorReportAPI,
    createInvestorReport: useCreateInvestorReportingAPI(params),
    updateInvestorReport: useUpdateInvestorReportingAPI(params),
    deleteInvestorReport: useDeleteInvestorReportingAPI(params),
  };
}
