import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import Table from 'components/Table';
import Error from '../Common/Error';
import {
  SubmittedCashBalance,
  SubmittedShareholding,
} from './InvestmentTransferForm';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import useStyles from './styles';
import { numberToCurrencyString } from 'further-ui/utils';
import { sumBy } from 'lodash';
import { numberToDisplayString } from 'utils/numbers';
import { useHistory } from 'react-router';

type SummaryProps = {
  recipientId?: string;
  sourceInvestorId: string;
  shareholdings: SubmittedShareholding[];
  cashBalances: SubmittedCashBalance[];
  onSubmit: () => void;
  isCreatingInvestmentTransfer: boolean;
};

const columns = [
  {
    label: 'Tranche',
    render: (shareholding: SubmittedShareholding) =>
      shareholding.fund?.fundName,
  },
  {
    label: 'Company',
    render: (shareholding: SubmittedShareholding) =>
      `${shareholding.company?.tradingName} ${shareholding.company?.shareClass}`,
  },
  {
    label: 'Initial share price',
    render: (shareholding: SubmittedShareholding) =>
      numberToCurrencyString(shareholding.company?.initialPrice),
  },
  {
    label: 'Initial subscription',
    render: (shareholding: SubmittedShareholding) =>
      numberToCurrencyString(shareholding.subscriptionAmount),
  },
  {
    label: 'Current share price',
    render: (shareholding: SubmittedShareholding) =>
      numberToCurrencyString(shareholding.currentSharePrice),
  },
  {
    label: 'Current share value',
    render: (shareholding: SubmittedShareholding) =>
      numberToCurrencyString(shareholding.currentShareValue),
  },
  {
    label: 'Shares to transfer',
    render: (shareholding: SubmittedShareholding) =>
      numberToDisplayString(shareholding.sharesToTransfer),
  },
  {
    label: 'Value to transfer',
    render: (shareholding: SubmittedShareholding) =>
      numberToCurrencyString(
        shareholding.sharesToTransfer *
          (shareholding.company?.initialPrice ?? 0),
      ),
  },
];

const Summary: React.FC<SummaryProps> = ({
  recipientId,
  sourceInvestorId,
  shareholdings,
  cashBalances,
  onSubmit,
  isCreatingInvestmentTransfer,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const { fetch: recipient } = useInvestor(recipientId);
  const { fetch: sourceInvestor } = useInvestor(sourceInvestorId);
  if (!recipientId) {
    return <Error message="You have to select recipient." />;
  }

  if (recipient.isLoading || sourceInvestor.isLoading) {
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <>
      <Grid item>
        <Typography variant="h1" color="textSecondary">
          Transfer summary
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" color="textSecondary">
          Please check and confirm the below transfer(s)
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          <strong>Please note:</strong> When transfers are made: (i) past and
          future fees; (ii) exits; and (iii) cash held for fees, will each move
          to the transferee on a pro rata basis, calculated as the initial value
          of the transferring shares as a percentage of the transferor
          subscription’s initial investable capital.
        </Typography>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <Typography
          variant="h6"
          className={classes.tableHeader}
          component="div"
        >
          {`Transfer(s) from ${sourceInvestor.data?.fullName} (${sourceInvestor.data?.email}) to ${recipient.data?.fullName} (${recipient.data?.email})`}
        </Typography>
        <Table
          columns={columns}
          tablebody={shareholdings}
          hideEmptyMessage
          TableFooter={
            <tfoot>
              {cashBalances.map((cashBalance) => (
                <tr
                  key={cashBalance.firm?._id}
                  className={classes.summaryCashBalanceRow}
                >
                  <td colSpan={7} align="right">
                    Transferring cash balance
                  </td>
                  <td>
                    {numberToCurrencyString(cashBalance.balanceToTransfer)}
                    {cashBalances.length > 1
                      ? ` (${cashBalance.firm?.firmName})`
                      : null}
                  </td>
                </tr>
              ))}
              <tr className={classes.tableFooter}>
                <td colSpan={7}>
                  <Typography variant="h6" component="div" align="right">
                    Total value to transfer
                  </Typography>
                </td>
                <td>
                  <Typography variant="h6" component="div">
                    {numberToCurrencyString(
                      sumBy(cashBalances, 'balanceToTransfer') +
                        sumBy(
                          shareholdings,
                          (shareholding) =>
                            shareholding.sharesToTransfer *
                            (shareholding.company?.initialPrice ?? 0),
                        ),
                    )}
                  </Typography>
                </td>
              </tr>
            </tfoot>
          }
        />
      </Grid>
      <Grid item>
        <Box
          gridGap={theme.spacing(4)}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={isCreatingInvestmentTransfer}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default Summary;
