import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import GridContainer from 'components/GridContainer';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { api } from 'lib/httpClient';
import { useGetRole } from 'hooks/ui/useGetRole';
import { dateToLabel } from 'further-ui/utils';
import { InvestorType } from 'further-types/investor';
import useInvestorCertification from 'hooks/data/investor/useInvestorCertification';

const useStyles = makeStyles(() => ({
  value: {
    color: '#656565',
  },
  boldValue: {
    color: '#656565',
    fontWeight: 'bold',
  },
  label: {
    color: '#656565',
    fontSize: 12,
    height: '100%',
  },
  minHeight: {
    minHeight: 50,
  },
}));

const CoolOffStatus = ({ id }) => {
  const classes = useStyles();
  const [coolOffs, setCoolOffs] = useState([]);
  const { isSuperAdmin } = useGetRole();
  const { certification } = useInvestorCertification(id);

  useEffect(() => {
    const getCoolOffs = async () => {
      try {
        const response = await api.get(`investment-cool-off/${id}`);
        if (!isEmpty(response.data.data)) {
          setCoolOffs(response.data.data);
        }
      } catch (error) {}
    };
    getCoolOffs();
  }, [id]);

  const professionalClientCertification = (certification.data ?? []).find(
    ({ forceExpired, investorType }) =>
      !forceExpired && investorType === InvestorType.ProfessionalClient,
  );

  return (
    <Grid item xs={12}>
      <GridContainer alignItems="flex-start">
        <Grid item xs={3} className={classes.minHeight}>
          <span className={classes.label}>24-hour cool off</span>
        </Grid>
        <Grid item xs={9} className={classes.minHeight}>
          {!coolOffs.length && <span className={classes.value}>N/A</span>}
          {coolOffs.map((coolOff) => (
            <div key={coolOff._id}>
              {isSuperAdmin && coolOff.firmId?.firmName && (
                <span className={classes.boldValue}>
                  {coolOff.firmId.firmName} -{' '}
                </span>
              )}
              {coolOff && !!professionalClientCertification ? (
                <>
                  <span className={classes.boldValue}>
                    Exempt as a professional client{' '}
                  </span>
                  <span className={classes.value}>
                    {dateToLabel(
                      professionalClientCertification.certificationPerformedAt,
                      null,
                      true,
                    )}
                  </span>
                </>
              ) : (
                <>
                  {coolOff && coolOff.isElapsed && (
                    <>
                      <span className={classes.boldValue}>Complete - </span>
                      <span className={classes.value}>
                        {dateToLabel(coolOff.elapsesAfter, null, true)}
                      </span>
                    </>
                  )}
                  {coolOff && !coolOff.isElapsed && (
                    <>
                      <span className={classes.boldValue}>Pending </span>
                      <span className={classes.value}>
                        (until {dateToLabel(coolOff.elapsesAfter, null, true)})
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </Grid>
      </GridContainer>
    </Grid>
  );
};
export default CoolOffStatus;
