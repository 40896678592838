import { FC, useMemo, useState } from 'react';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useInvestments } from 'hooks/data/investment/useInvestments';
import { uniq } from 'lodash';
import { Menu, MenuItem, Button, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useDownload } from 'hooks/ui/useDownload';

type Props = { investorId: string; investorName: string };
const useStyles = makeStyles(() => ({
  button: {
    height: 26,
  },
}));

const DownloadTransferButton: FC<Props> = ({ investorId, investorName }) => {
  const classes = useStyles();
  const { firmId } = useGetRole();
  const investments = useInvestments({ params: { investorId, firmId } });
  const { firms } = useFirm({ params: { firmId } });
  const download = useDownload({
    filename: `Transfer Form for completion - The Late ${investorName}.xlsx`,
    errorMessage: 'The tranfer form could not be downloaded',
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const firmIdsWithInvestments = useMemo(() => {
    if (!investments?.data?.result) return [];
    return uniq(
      investments?.data?.result?.map((investment) => investment.firmId),
    );
  }, [investments.data]);

  const firmsWithInvestments = useMemo(() => {
    if (!firms?.data?.result) return [];
    return firms?.data?.result?.filter((firm) =>
      firmIdsWithInvestments.includes(firm._id),
    );
  }, [firmIdsWithInvestments, firms.data]);

  const downloadTransferForm = async (firm: { _id: string }) => {
    download.mutate(
      `investor/download-transfer-form/${firm._id}/${investorId}`,
    );
  };

  if (!firmsWithInvestments?.length) return null;

  if (firmId && firmsWithInvestments.length === 1) {
    return (
      <Button
        size="small"
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => {
          downloadTransferForm(firmsWithInvestments[0]);
        }}
      >
        Download transfer form
      </Button>
    );
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        size="small"
        variant="outlined"
        color="primary"
        className={classes.button}
        endIcon={<ArrowDropDown />}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        Download transfer form
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {firmsWithInvestments?.map((firm) => (
          <MenuItem
            onClick={() => {
              downloadTransferForm(firm);
            }}
            key={firm.firmName}
          >
            {firm.firmName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DownloadTransferButton;
