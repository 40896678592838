import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchTrancheEisAllotments({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/tranche-eis-allotments?${queryParams}`
    : `dashboard/tranche-eis-allotments`;

  const { data } = await api.get(url);

  return data.data;
}

function useTrancheEisAllotments({ firmId }): UseQueryResult<{
  awaitingEisWizard: number;
  totalEisWizard: number;
}> {
  return useQuery({
    queryKey: ['tranche-eis-allotments', firmId],
    queryFn: fetchTrancheEisAllotments,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useTrancheEisAllotments;
