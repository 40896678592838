import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  blockContent: {
    display: 'block',
    marginTop: 10,
  },
  buttonAsLink: {
    color: '#56B26C',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontStyle: 'italic',
    background: 'none',
    border: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
}));

export const BottomTextContent = ({
  handleDownload,
  disableButton = false,
}: {
  handleDownload: () => void;
  disableButton: boolean;
}) => {
  const classes = useStyles();

  return (
    <i>
      If you don't send an exit update now, you can do it later by navigating to
      the Exits section and clicking the mail icon next to the relevant company.
      <span className={classes.blockContent}>
        <button
          disabled={disableButton}
          className={classes.buttonAsLink}
          onClick={() => {
            handleDownload();
          }}
        >
          Click here
        </button>{' '}
        to download a statement of this exit to send to your custodian.{' '}
      </span>
    </i>
  );
};
