import React from 'react';

import clsx from 'clsx';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import Header from '../Header';
import Sidebar from '../Sidebar';

const MainContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  const isDrawer = useMediaQuery(
    theme.breakpoints.down(theme.sidebar.drawerBreakpoint),
  );

  const classes = useStyles();

  const sidebarClasses = isDrawer
    ? 'Cmt-sidebar-fixed Cmt-drawerLayout'
    : 'Cmt-sidebar-fixed ';

  return (
    <Box
      className={clsx(classes.appRoot, 'Cmt-fixedHeaderLayout', sidebarClasses)}
    >
      <div className={classes.appInnerRoot}>
        <div className={classes.appMainContainer}>
          <Sidebar />
          <div className={classes.appMain}>
            <Header />
            {children}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default MainContainer;
