import { useEffect } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router';
import clsx from 'clsx';
import { makeStyles, Grid } from '@material-ui/core';
import { DASHBOARD_ALL_FIRMS, DASHBOARD_MY_FIRM } from 'constants/routes';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import CompanyCategoryPieChart from 'components/DashboardWidgets/CompanyCategoryPieChart';
import EisAllotments from 'components/DashboardWidgets/EisAllotments';
import InvestmentsInflowSummary from 'components/DashboardWidgets/InvestmentsInflowSummary';
import TrancheCountsByStatus from 'components/DashboardWidgets/TrancheCountsByStatus';
import OpenTranchesTable from 'components/DashboardWidgets/OpenTranchesTable';
import { useGetRole } from 'hooks/ui/useGetRole';
import LifetimePerformanceSummary from 'components/DashboardWidgets/LifetimePerformanceSummary';
import EntitySummary from 'components/DashboardWidgets/EntitySummary';
import TopEntitiesTables from 'components/DashboardWidgets/TopEntitiesTables';
import ClosedTrancheDeployments from 'components/DashboardWidgets/ClosedTrancheDeployments';
import useLocalStorage from 'hooks/ui/useLocalStorage';

const useStyles = makeStyles((theme: any) => ({
  scrollableTable: {
    maxHeight: '400px',
    overflow: 'auto',
    marginBottom: '24px',
    paddingBottom: '0 !important',
  },
  heading: {
    margin: '1rem 0',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
    alignItems: 'stretch',
  },
  childDiv100: {
    '&> div': {
      height: '100%',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  link: {
    fontSize: '14px',
    color: theme.palette.text.rootColor,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  linkContainer: {
    flex: '1',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  viewAll: {
    color: theme.palette.text.rootColor,
    textTransform: 'inherit',
    fontWeight: 700,
    letterSpacing: '.5px',
    backgroundColor: 'transparent !important',
    padding: '0',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  compactCardHeader: {
    padding: '20px 30px 14px 25px',
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const FirmDashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const { firmId } = useParams<{ firmId: string }>();
  const { firmId: usersFirmId, isSuperAdmin } = useGetRole();
  const isMyFirmPage = useRouteMatch(DASHBOARD_MY_FIRM)?.isExact;
  const isAllFirmsDashboard = useRouteMatch(DASHBOARD_ALL_FIRMS)?.isExact;

  const loggedInUsersFirmName = useLocalStorage<{
    user?: { firmId?: { firmName?: string } };
  }>('user', {
    defaultData: { user: { firmId: { firmName: '' } } },
    validate: (data) => !!data?.user?.firmId?.firmName,
  }).getData()?.user?.firmId?.firmName;

  const dashboardFirmName = localStorage.getItem('dashboardFirmName');

  const firmName = loggedInUsersFirmName || dashboardFirmName;

  const firmIdToQuery = isMyFirmPage ? usersFirmId : firmId;
  const adminUserOnMyFirmsPage = isMyFirmPage && isSuperAdmin;

  const params: { firmId?: string } = {};
  if (!isAllFirmsDashboard && !adminUserOnMyFirmsPage) {
    params.firmId = firmIdToQuery;
  }

  useEffect(() => {
    if (isAllFirmsDashboard && !isSuperAdmin) {
      history.push(DASHBOARD_MY_FIRM);
    }
  }, [isAllFirmsDashboard, isSuperAdmin]);

  return (
    <GridContainer spacing={5}>
      <Grid item xs={12}>
        <div className={classes.heading}>
          <h3 className={classes.masth}>
            {isAllFirmsDashboard
              ? 'Master dashboard'
              : `${firmName ? `${firmName} master` : 'Master'} dashboard`}
          </h3>
        </div>
      </Grid>

      <Grid item md={6} xs={12} xl={7} className={classes.flexGrid}>
        <InvestmentsInflowSummary
          parentClasses={classes}
          firmId={firmIdToQuery}
        />
      </Grid>

      <Grid
        item
        md={6}
        xs={12}
        xl={5}
        className={clsx(classes.flexGrid, classes.childDiv100)}
      >
        <LifetimePerformanceSummary
          parentClasses={classes}
          firmId={firmIdToQuery}
          firmName={firmName}
        />
      </Grid>

      <EntitySummary parentClasses={classes} firmId={firmIdToQuery} />

      <Grid item md={8} xs={12} className={classes.flexGrid}>
        <EisAllotments classes={classes} firmId={firmIdToQuery} />
        <TrancheCountsByStatus firmId={firmIdToQuery} classes={classes} />
      </Grid>

      <Grid item md={4} xs={12}>
        <CmtCard className={classes.flexGrow}>
          <CmtCardContent style={{ marginTop: '1rem' }}>
            <CompanyCategoryPieChart
              title="Fund portfolio by sector"
              subtitle="(Ordered by current value)"
              firmId={firmIdToQuery}
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>

      <Grid item xs={12}>
        <TopEntitiesTables firmId={firmIdToQuery} />
      </Grid>

      <Grid item xs={12}>
        <OpenTranchesTable firmId={firmIdToQuery} classes={classes} />
      </Grid>

      <ClosedTrancheDeployments firmId={firmIdToQuery} classes={classes} />
    </GridContainer>
  );
};

export default FirmDashboard;
