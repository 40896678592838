import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import useInvestmentTransfer, {
  CashBalance,
  ShareHolding,
} from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import CashBalancesList from './CashBalancesList';
import InvestmentsList from './InvestmentsList';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab';
import useStyles from './styles';
import { groupBy, keyBy, mapValues } from 'lodash';

type TransferSummary = {
  balancesToTransfer: Record<string, number>;
  sharesToTransfer: Record<string, Record<string, number>>;
  general?: never;
};

export type SubmittedShareholding = Partial<ShareHolding> & {
  sharesToTransfer: number;
};

export type SubmittedCashBalance = Partial<CashBalance> & {
  balanceToTransfer: number;
};

type InvestmentTransferFormProps = {
  onSubmit: (
    shareholdings: SubmittedShareholding[],
    cashBalances: SubmittedCashBalance[],
  ) => void;
  sourceInvestorId: string;
  firmId: string;
  defaultValues: {
    shareholdings: SubmittedShareholding[];
    cashBalances: SubmittedCashBalance[];
  } | null;
};

const mapDefaultValues = (
  initialData: InvestmentTransferFormProps['defaultValues'],
) => {
  if (!initialData) return undefined;

  return {
    balancesToTransfer: mapValues(
      keyBy(initialData.cashBalances, (balance) => balance.firm._id),
      'balanceToTransfer',
    ),
    sharesToTransfer: mapValues(
      groupBy(
        initialData.shareholdings,
        (shareholding) => shareholding.investment.id,
      ),
      (shareholdings) =>
        mapValues(
          keyBy(shareholdings, ({ id }) => id),
          'sharesToTransfer',
        ),
    ),
  };
};

const InvestmentTransferForm: React.FC<InvestmentTransferFormProps> = ({
  onSubmit,
  defaultValues,
  sourceInvestorId,
  firmId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { getInvestorDetails } = useInvestmentTransfer();
  const { data: investorDetails } = getInvestorDetails(
    sourceInvestorId,
    firmId,
  );

  const schema = z
    .object({
      sharesToTransfer: z
        .record(z.string(), z.record(z.string(), z.coerce.number().gte(0)))
        .superRefine((investments, context) => {
          Object.keys(investments).forEach((investmentId) => {
            Object.keys(investments[investmentId]).forEach((shareholdingId) => {
              const shareholding = investorDetails?.investments
                .find((investment) => investment.investmentId === investmentId)
                ?.shareholdings.find(({ id }) => id === shareholdingId);

              if (
                investments[investmentId][shareholdingId] >
                (shareholding?.noOfShare ?? 0)
              ) {
                context.addIssue({
                  path: [investmentId, shareholdingId],
                  code: z.ZodIssueCode.custom,
                  message:
                    'Please ensure the number of shares to transfer is equal to or less than the number of available shares.',
                });
              }
            });
          });
        })
        .optional(),
      balancesToTransfer: z
        .record(z.string(), z.coerce.number().gte(0))
        .superRefine((cashBalances, context) => {
          Object.keys(cashBalances).forEach((firmId) => {
            const cashBalance =
              investorDetails?.cashBalances.find(
                (cashBalance) => cashBalance.firm._id === firmId,
              )?.cashBalance ?? 0;

            if (
              cashBalances[firmId] > cashBalance &&
              cashBalances[firmId] !== 0
            ) {
              context.addIssue({
                path: [firmId],
                code: z.ZodIssueCode.custom,
                message:
                  'Please ensure the cash to transfer is equal to or less than the available cash.',
              });
            }
          });
        }),
    })
    .superRefine((data, context) => {
      const hasSharesToTransfer = !!Object.values(
        data.sharesToTransfer ?? {},
      ).find((shareholdingData) =>
        Object.values(shareholdingData).find(
          (sharesToTransfer) => sharesToTransfer,
        ),
      );
      const hasCashBalancesToTransfer = !!Object.values(
        data.balancesToTransfer,
      ).find((cashBalane) => cashBalane);

      if (!hasCashBalancesToTransfer && !hasSharesToTransfer) {
        context.addIssue({
          path: ['general'],
          code: z.ZodIssueCode.custom,
          message: 'You have to transfer at least one share or cash balance',
        });
      }
    });

  const processTransferData = (data: TransferSummary) => {
    const investmentsIds = Object.keys(data.sharesToTransfer ?? {});
    const submittedShareholdings = investmentsIds.flatMap((investmentId) => {
      return Object.keys(data.sharesToTransfer[investmentId])
        .filter(
          (shareholdingId) =>
            data.sharesToTransfer[investmentId][shareholdingId] > 0,
        )
        .map((shareholdingId) => {
          const investment = investorDetails?.investments?.find(
            (investment) => investment.investmentId === investmentId,
          );
          return {
            sharesToTransfer:
              data.sharesToTransfer[investmentId][shareholdingId],
            ...(investment?.shareholdings.find(
              (shareholding) => shareholding.id === shareholdingId,
            ) ?? {}),
          };
        });
    });
    const submittedBalances = Object.keys(data.balancesToTransfer)
      .filter((firmId) => data.balancesToTransfer[firmId] > 0)
      .map((firmId) => {
        const cashBalance = investorDetails?.cashBalances.find(
          (cashBalance) => cashBalance.firm._id === firmId,
        );
        return {
          balanceToTransfer: data.balancesToTransfer[firmId],
          ...(cashBalance ?? {}),
        };
      });

    onSubmit(submittedShareholdings, submittedBalances);
  };

  const formMethods = useForm<TransferSummary>({
    values: mapDefaultValues(defaultValues),
    shouldFocusError: true,
    resolver: zodResolver(schema),
  });

  return (
    <Grid container item spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          color="textSecondary"
          className={classes.heading}
        >
          2. Select the shares and cash to transfer to the recipient
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.note}
        >
          <strong>Please note:</strong> Upon making a transfer, the transferor’s
          past and future fees, and a record of historic exit proceeds, will be
          transferred to the transferee’s account pro rata to ensure (i)
          performance is represented correctly on the transferee’s portal and
          (ii) future performance fees are collected as appropriate.
        </Typography>
      </Grid>
      {investorDetails ? (
        <Grid item className={classes.fullWidth} xs>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(processTransferData)}>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(8)}
              >
                <InvestmentsList investments={investorDetails.investments} />
                <CashBalancesList cashBalances={investorDetails.cashBalances} />
                {formMethods.formState.errors?.general ? (
                  <Alert severity="error">
                    {formMethods.formState.errors.general.message}
                  </Alert>
                ) : null}
                <Box
                  gridGap={theme.spacing(4)}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Go to transfer summary
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default InvestmentTransferForm;
