import Grid, { GridProps } from '@material-ui/core/Grid';
import { PropsWithChildren } from 'react';

const GridContainer: React.FC<PropsWithChildren<GridProps>> = ({
  children,
  ...rest
}) => {
  const hasGridSizeProps = ['xs', 'sm', 'md', 'lg', 'xl'].some((size) =>
    rest.hasOwnProperty(size),
  );
  return (
    <Grid container spacing={6} {...rest} item={hasGridSizeProps}>
      {children}
    </Grid>
  );
};

export default GridContainer;
