import React from 'react';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';

const AppChipInput = ({
  value,
  handleDeleteChip,
  fullWidth,
  size,
  variant,
  label,
  disabled,
}) => {
  return (
    <ChipInput
      label={label}
      value={value}
      onDelete={(chip, index) => handleDeleteChip(chip, index)}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
    />
  );
};
ChipInput.prototype = {
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

ChipInput.defaultProps = {
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
};
export default AppChipInput;
