import Table from 'components/Table';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import useTopInvestors from 'hooks/data/dashboards/useTopInvestors';
import { Link } from 'react-router-dom';
import { createInvestorViewRoute, INVESTOR } from 'constants/routes';

import topInvestorTableStyles from './styles';
import { Button } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

const TopInvestorTable = ({ firmId }) => {
  const { data: investors, isFetching } = useTopInvestors({
    params: { firmId },
  });

  const classes = topInvestorTableStyles();
  const columns = [
    {
      label: 'Name',
      render: (investor) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          investor.fullName
        ),
      sort: false,
    },
    {
      label: 'Subscription amount',
      render: (investor) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(investor.totalInvestmentAmount)
        ),
      sort: false,
    },
    {
      label: 'Total portfolio value',
      render: (investor) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(investor.totalPortfolioValue)
        ),
      sort: false,
    },
    {
      label: 'Most recent subscription',
      render: (investor) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          dateToLabel(investor.mostRecentSubscription)
        ),
      sort: false,
    },
    {
      label: '',
      render: (investor) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          <Link to={createInvestorViewRoute(investor._id)}>
            <Button
              title="View"
              color="primary"
              className={classes.actionButton}
            >
              <Visibility />
            </Button>
          </Link>
        ),
      sort: false,
    },
  ];

  return (
    <>
      <Link className={classes.link} to={INVESTOR}>
        View all
      </Link>
      <Table
        columns={columns}
        tablebody={
          investors?.length
            ? investors
            : // Adding empty objects to show skeletons
              Array.from({ length: 10 }, () => ({}))
        }
        pagination={false}
        emptyMessage="No top investors found"
        variant="nohover"
      />
    </>
  );
};

export default TopInvestorTable;
