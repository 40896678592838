import React from 'react';
import FullPageSuccess from 'components/FullPageSuccess';
import { INVESTOR_REPORTING } from 'constants/routes';
import { useGetInvestorsInReport } from 'hooks/data/investorReporting/useGetInvestorsInReport';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { useDownload } from 'hooks/ui/useDownload';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: '2rem',
    marginBottom: '0.4rem',
    fontStyle: 'italic',
  },
  downloadText: {
    fontSize: 15,
    textAlign: 'center',
    color: theme.palette.text.green,
    textDecoration: 'underline',
    fontStyle: 'italic',
    cursor: 'pointer',
    marginTop: '1rem',
  },
}));

const SuccessPage = () => {
  // eslint-disable-next-line no-restricted-globals
  const { id: reportingId } = useParams();
  const { isLoading, data } = useGetInvestorsInReport({
    params: { reportingId, countOnly: true },
  });
  const fetchPdfSample = useDownload({
    mimeType: 'application/pdf',
    filename: 'sample.pdf',
    errorMessage: 'The sample update is still being created. Please try again.',
  });
  const classes = useStyles();

  const downloadSample = () => {
    if (!fetchPdfSample.isLoading) {
      fetchPdfSample.mutate(
        `investor-reporting/export-sample-pdf/${reportingId}`,
      );
    }
  };

  return !isLoading && data ? (
    <FullPageSuccess
      title="Your update has been successfully processed"
      additionalText={`This update has been created for ${data.investorsCount} investors. Your investors will now be able to view this update in their investor portals.`}
      proceedLink={INVESTOR_REPORTING}
      proceedText="View all submitted updates"
    >
      <>
        <p className={classes.text}>
          The system is currently creating individual PDF updates which will be
          available for download from the Investor Reporting table in 15-30
          minutes.
        </p>

        {fetchPdfSample.isLoading ? (
          <CircularProgress size={24} className={classes.downloadText} />
        ) : (
          <p className={classes.downloadText} onClick={downloadSample}>
            Download a sample update
          </p>
        )}
      </>
    </FullPageSuccess>
  ) : null;
};

export default SuccessPage;
