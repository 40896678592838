import axios from 'axios';

export const updateAdviserFeeAmendments = (requestBody) => {
  return () => {
    return axios
      .put(`adviser/update-fee-amendments/${requestBody?.id}`, requestBody)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteAdviser = (adviserId) => {
  return async () => {
    const response = await axios.delete(`adviser/${adviserId}`);
    if (response.status === 200) {
      return true;
    }
  };
};
