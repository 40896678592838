import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
    width: '100%',
    padding: 12,
  },
  iconButton: {
    marginTop: -5,
    marginBottom: 'auto',
  },
  switchBase: {
    marginRight: 10,
    marginLeft: 10,
  },
  otherNationalityContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 62,
  },
  taxAndBankDetailContainer: {
    marginTop: 20,
    padding: 12,
    width: '100%',
  },
  button: {
    marginLeft: 10,
  },
}));

export default useStyles;
