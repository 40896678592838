import { makeStyles, Typography } from '@material-ui/core';
import { NotesApi } from 'further-types/notes';
import Note from './Note';
import { UseMutationResult } from '@tanstack/react-query';

const useStyles = makeStyles(() => ({
  title: {
    color: '#656565',
  },
}));

interface NoteGroupProps {
  notes: NotesApi.NoteResponse[];
  title: string;
  linkOnLastNote?: boolean;
  deleteNote: UseMutationResult<boolean, Error, string>;
}

const NoteGroup: React.FC<NoteGroupProps> = ({
  notes,
  title,
  linkOnLastNote,
  deleteNote,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      {notes.map((note, i) => (
        <Note
          key={note._id}
          note={note}
          link={i !== notes.length - 1 || linkOnLastNote}
          deleteNote={deleteNote}
        />
      ))}
    </>
  );
};

export default NoteGroup;
