import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { sortBy } from 'lodash';

import { useFunds } from 'hooks/data/fund/useFunds';
import TranchesMultiSelect from '../TrancheMultiSelect';
import getFirmsInTranches from 'helpers/firm/get-firms-in-tranches';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
}));

const MultiFirmTrancheSelector = ({
  handleChangeSelectedTranches,
  trancheFilters = { fundStatus: 0 },
  disabled,
  value,
}) => {
  const classes = useStyles();
  const { tranches } = useFunds({ ...trancheFilters, leanResponse: true });
  const [formState, setFormState] = useState({
    selectedTrancheIds: value || [],
    tranchesSelectAll: false,
    selectedFirmIds: [],
    selectedFirmNames: [],
  });

  const mappedTranche = (tranches.data ?? []).map((x) => ({
    _id: x._id,
    label: `${x.firmId.firmName} ${x.fundName}`,
    firmId: x.firmId,
  }));

  const sortedTranche = sortBy(mappedTranche ?? [], (x) =>
    x.label.toLowerCase(),
  );

  const handleChangeSelectedTranche = useCallback(
    (trancheIds) => {
      // find the selected firms based on the selectedTrancheIds
      const { selectedFirmNames, selectedFirmIds } = getFirmsInTranches(
        trancheIds,
        sortedTranche,
      );

      const newFormState = {
        ...formState,
        selectedTrancheIds: trancheIds,
        selectedFirmNames,
        selectedFirmIds,
      };

      setFormState({ ...newFormState });
      handleChangeSelectedTranches(newFormState);
    },

    [tranches],
  );

  return (
    <>
      <TranchesMultiSelect
        tranches={sortedTranche}
        handleChangeSelectedTranches={handleChangeSelectedTranche}
        disabled={disabled}
        value={formState.selectedTrancheIds}
      />
      {formState.selectedFirmIds.length > 1 && (
        <p className={classes.error}>
          Please note: you have selected tranches from two or more firms (
          {formState.selectedFirmNames.join(', ')}). Please select tranches
          relating to only one firm to continue.
        </p>
      )}
    </>
  );
};

export default MultiFirmTrancheSelector;
