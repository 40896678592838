import { InputAdornment } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import { baseDomain } from 'constants/redirectConstant';
import ColourPicker from './ColourPicker';

const WhitelabellingSettings = ({ control, errors, firm, classes }) => (
  <>
    <FieldRow title="Background (primary) colour" centerTitle>
      <Controller
        name="whiteLabelConfig.bgColor"
        defaultValue={firm.data?.whiteLabelConfig?.bgColor}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <ColourPicker {...rest} ref={ref} />
        )}
      />
    </FieldRow>

    <FieldRow title="Button and text colour" centerTitle>
      <Controller
        name="whiteLabelConfig.primaryColor"
        defaultValue={firm.data?.whiteLabelConfig?.primaryColor}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <ColourPicker {...rest} ref={ref} />
        )}
      />
    </FieldRow>
    <FieldRow title="Button and text hover colour" centerTitle>
      <Controller
        name="whiteLabelConfig.primaryHoverColor"
        defaultValue={firm.data?.whiteLabelConfig?.primaryHoverColor}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <ColourPicker {...rest} ref={ref} />
        )}
      />
    </FieldRow>
    <FieldRow title="Menu icon colour" centerTitle>
      <Controller
        name="whiteLabelConfig.secondaryColor"
        defaultValue={firm.data?.whiteLabelConfig?.secondaryColor}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <ColourPicker {...rest} ref={ref} />
        )}
      />
    </FieldRow>
    <FieldRow
      title="Onboarding side panel text"
      centerTitle
      tooltipText="All investors and advisers will see an informational side panel during your fund's subscription flow. This side panel contains your contact details as well as three optional bullets (with a tick icon next to each bullet). You may use these bullets to provide advice, fund benefits or other brief information if you wish."
    >
      <Controller
        name="contactDetails.keyChecks[0]"
        defaultValue={firm.data?.contactDetails?.keyChecks[0] ?? undefined}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="First bullet point, e.g. Subscription specialists since 2002"
            fullWidth
          />
        )}
      />
    </FieldRow>
    <FieldRow>
      <Controller
        name="contactDetails.keyChecks[1]"
        defaultValue={firm.data?.contactDetails?.keyChecks[1] ?? undefined}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Second bullet point, e.g. Managing over £2bn of investors' capital"
            fullWidth
          />
        )}
      />
    </FieldRow>
    <FieldRow>
      <Controller
        name="contactDetails.keyChecks[2]"
        defaultValue={firm.data?.contactDetails?.keyChecks[2] ?? undefined}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Third bullet point, e.g. Supporting over 200 high growth companies"
            fullWidth
          />
        )}
      />
    </FieldRow>
    <FieldRow title="Contact days and times" centerTitle>
      <Controller
        name="contactDetails.phoneHours"
        defaultValue={firm.data?.contactDetails?.phoneHours}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="When investors can contact you, e.g. Mon-Fri 9:00-17:00"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.contactDetails?.phoneHours?.message}
      </p>
    </FieldRow>
    <FieldRow title="Investor service phone number" centerTitle>
      <Controller
        name="contactDetails.phoneNo"
        defaultValue={firm.data?.contactDetails?.phoneNo}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Enter investor service phone number"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.contactDetails?.phoneNo?.message}
      </p>
    </FieldRow>
    <FieldRow
      title="Verified reply-to email address*"
      centerTitle
      tooltipText="Please ensure this email has been verified by Further’s team before adding it here."
    >
      <Controller
        name="whiteLabelConfig.clientServiceEmail"
        defaultValue={firm.data?.whiteLabelConfig?.clientServiceEmail}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="What should the reply-to address be on system emails?"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.whiteLabelConfig?.clientServiceEmail?.message}
      </p>
    </FieldRow>
    <FieldRow title="Investor service email address*" centerTitle>
      <Controller
        name="contactDetails.email"
        defaultValue={firm.data?.contactDetails?.email ?? ''}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="What email address should investors use to contact you?"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.contactDetails?.email?.message}
      </p>
    </FieldRow>
    <FieldRow title="Subdomain*" centerTitle>
      <Controller
        name="domain"
        defaultValue={firm.data?.domain}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Enter text here"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.{baseDomain}</InputAdornment>
              ),
            }}
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>{errors.domain?.message}</p>
    </FieldRow>
    <FieldRow title="Email footer text">
      <Controller
        name="whiteLabelConfig.emailFooterText"
        defaultValue={firm.data?.whiteLabelConfig?.emailFooterText}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Enter any footer text you need to include here, such as your limited company name, number, address or other legal information."
            fullWidth
            multiline
            minRows={4}
          />
        )}
      />
    </FieldRow>
  </>
);

export default WhitelabellingSettings;
