import { useGetRole } from 'hooks/ui/useGetRole';

export const useGetPermissions = (
  permissionsSet: Array<string>,
): Array<boolean> => {
  const permissionData = useGetAllPermissionsList();
  const { isSuperAdmin } = useGetRole();

  return permissionsSet.map((permissionName) => {
    if (isSuperAdmin) return true;

    return permissionData?.some((permission) => permission === permissionName);
  });
};

export const useGetAllPermissionsList = () => {
  const permissionDataString = localStorage.getItem('permission');
  if (!permissionDataString) return [];

  try {
    return JSON.parse(permissionDataString) as Array<string>;
  } catch {
    return [];
  }
};
