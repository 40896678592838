import { useStyles } from './styles';
import AppNumberInput from 'components/FormElements/AppNumberInput';

const TotalSaleValueCell = ({ cell, setOverride, disableTable }) => {
  const classes = useStyles();

  const defaultValue =
    Number(cell.sharesAllocatedForSale) * Number(cell.sharePrice);
  const isInputChanged = cell?.totalSaleValue !== defaultValue;
  const className = isInputChanged ? classes.inputChanged : '';

  return (
    <AppNumberInput
      disabled={disableTable}
      decimals={2}
      placeholder={0}
      defaultValue={0}
      min={0}
      gbpStartAdornment={true}
      value={cell.totalSaleValue}
      onChange={(newValue) => {
        setOverride(cell.shareholdingId, {
          totalSaleValue: Number(newValue),
        });
      }}
      className={className}
    />
  );
};

export default TotalSaleValueCell;
