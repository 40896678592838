import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { NoteRelation, NotesApi } from 'further-types/notes';

const fetchNotes = async ({ queryKey }) => {
  const [, { params }] = queryKey;
  const {
    data: { data },
  } = await api.get<ApiResponse<NotesApi.NoteResponse[]>>(`notes/`, {
    params,
  });
  return data;
};

export function useNotes({
  params,
}: {
  params: {
    firmId: string | null;
    relationId: string;
    noteRelation: NoteRelation;
  };
}) {
  const queryClient = useQueryClient();
  const handleApiException = useApiExceptionHandler();

  const createNote = useMutation({
    useErrorBoundary: false,
    mutationFn: async (data: NotesApi.NoteDto) => {
      const { data: result } = await api.post<
        ApiResponse<NotesApi.NoteResponse>
      >(`notes/`, data);
      return result.data;
    },
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      }),
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const getNotes = useQuery({
    queryKey: ['notes', { params }],
    queryFn: fetchNotes,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const deleteNote = useMutation({
    useErrorBoundary: false,
    mutationFn: async (id: string) => {
      const { data: response } = await api.delete<ApiResponse<boolean>>(
        `notes/${id}`,
      );

      return response.data;
    },
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: ['notes'],
      }),
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  return {
    createNote,
    getNotes,
    deleteNote,
  };
}
