import React from 'react';
import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { CircularProgress, makeStyles } from '@material-ui/core';
import FieldRow from 'components/FormElements/FieldRow';
import useGetInvestorsLeanResponse from 'hooks/data/investor/useGetInvestorsLeanResponse';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '2rem',
  },
  input: {
    minWidth: '260px',
  },
}));

const InvestorSearch = ({
  onInvestorSelect,
  selectedInvestorId,
  queuedWithdrawals,
  firmId,
}) => {
  const classes = useStyles();
  const investors = useGetInvestorsLeanResponse({ firmId });

  const selectedInvestor = investors.data?.find(
    (inv) => inv._id === selectedInvestorId,
  );

  const investorNotAlreadyQueued = (investor) => {
    return !queuedWithdrawals.some(
      (withdrawal) => withdrawal._id === investor._id,
    );
  };

  return (
    <FieldRow title="Search for an investor" centerTitle>
      {investors.isFetching ? (
        <CircularProgress size={20} />
      ) : (
        <Autocomplete
          options={investors.data?.filter(investorNotAlreadyQueued)}
          id="investorSearch"
          getOptionLabel={(option) => {
            return `${option.fullName} (${option.email})` || '';
          }}
          filterSelectedOptions
          onChange={(_, investor) => {
            if (!investor) return onInvestorSelect();
            onInvestorSelect(investor._id);
          }}
          value={selectedInvestor || null}
          renderInput={(params) => (
            <TextField
              {...params}
              value={'41'}
              className={classes.input}
              variant="outlined"
              placeholder="Search..."
            />
          )}
        />
      )}
    </FieldRow>
  );
};

export default InvestorSearch;
