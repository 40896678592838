import React, { Fragment } from 'react';
import GridContainer from 'components/GridContainer';
import { Button, IconButton, Grid, Divider } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    margin: '20px 0',
  },
  trashIcon: {
    color: '#E8453C',
  },
}));

export default function TrackRecord({
  additionalNote,
  errorText,
  handleAddClick,
  handleCancel,
  handleClickTrackRecord,
  handleInputChange,
  handleRemoveClick,
  id,
  setAdditionalNote,
  setTrackRecordText,
  trackRecord,
  trackRecordText,
  disabled = false,
}) {
  const classes = useStyles();
  return (
    <GridContainer item xs={12} md={11}>
      <FieldRow title="Track record summary">
        <TextField
          name="trackRecordText"
          placeholder="Enter a summary paragraph explaining your track record information, for example the returns period covered, the basis for the data (e.g. initial subscription rebased to 1.00x or £1.00 or £100) and what is considered to form your fund's total return (e.g. typically capital subscription distributions, dividends, and unrealised subscriptions)."
          fullWidth
          onChange={(event) => setTrackRecordText(event.target.value)}
          value={trackRecordText}
          variant="outlined"
          multiline
          minRows={6}
        />
      </FieldRow>
      <FieldRow title="Additional note">
        <TextField
          name="additionalNote"
          placeholder="This additional text box sits below the track record performance graph and can be used to provide specific context on the graph itself. Leave this box blank if you do not wish to use this field."
          fullWidth
          onChange={(event) => setAdditionalNote(event.target.value)}
          value={additionalNote}
          variant="outlined"
          multiline
          minRows={4}
        />
      </FieldRow>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
        {trackRecord.map((x, i) => {
          return (
            <Fragment key={i}>
              <GridContainer>
                <FieldRow
                  title="Year (or, if using custom axis label, order)"
                  centerTitle
                >
                  <TextField
                    name="year"
                    placeholder="e.g. 2016"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.year}
                  />
                </FieldRow>
                <FieldRow title="Custom X axis label" centerTitle>
                  <TextField
                    name="xAxisLabel"
                    placeholder="Enter axis label"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.xAxisLabel}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Label sub-text" centerTitle>
                  <TextField
                    name="subLabel"
                    placeholder="Enter subtext"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.subLabel}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Unrealised return*" centerTitle>
                  <TextField
                    required
                    name="unrealisedReturn"
                    placeholder="e.g. 100"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.unrealisedReturn}
                    error={errorText[i]?.unrealisedReturn}
                    helperText={errorText[i]?.unrealisedReturn}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Realised return*" centerTitle>
                  <TextField
                    required
                    name="realisedReturn"
                    placeholder="e.g. 100"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.realisedReturn}
                    error={errorText[i]?.realisedReturn}
                    helperText={errorText[i]?.realisedReturn}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Tax benefit" centerTitle>
                  <TextField
                    name="taxBenefit"
                    placeholder="e.g. 30"
                    fullWidth
                    onChange={(e) => handleInputChange(e, i)}
                    value={x.taxBenefit}
                    variant="outlined"
                  />
                </FieldRow>
                <Grid item xs={12}>
                  {trackRecord.length && (
                    <div className={classes.buttonContainer}>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveClick(i)}
                      >
                        <Delete className={classes.trashIcon} />
                      </IconButton>
                    </div>
                  )}
                </Grid>
              </GridContainer>
              <Divider className={classes.divider} />
            </Fragment>
          );
        })}
      </Grid>
      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button onClick={handleAddClick} variant="text" color="primary">
            Add year
          </Button>
        </div>
      </FieldRow>
      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            disabled={disabled}
            color="primary"
            onClick={handleClickTrackRecord}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </FieldRow>
    </GridContainer>
  );
}
