import React from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import SidebarInner from './SidebarInner';
import SidebarHeader from './SidebarHeader';
import DrawerContent from './DrawerContent';
import SidebarContent from './DrawerContent/SidebarContent';

const Sidebar: React.FC = () => {
  const theme = useTheme();

  const hasDrawer = useMediaQuery(
    theme.breakpoints.down(theme.sidebar.drawerBreakpoint),
  );

  if (hasDrawer) {
    return (
      <DrawerContent>
        <SidebarHeader />
        <SidebarInner />
      </DrawerContent>
    );
  } else {
    return (
      <SidebarContent>
        <SidebarHeader />
        <SidebarInner />
      </SidebarContent>
    );
  }
};

export default Sidebar;
