import createBreakpoints, {
  Breakpoint,
} from '@material-ui/core/styles/createBreakpoints';
import { red } from '@material-ui/core/colors';
import { createTheme, Direction } from '@material-ui/core/styles';

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebar?: {
      width?: number;
      drawerBreakpoint?: Breakpoint;
    };
  }

  interface ThemeOptions {
    sidebar?: {
      width?: number;
      drawerBreakpoint?: Breakpoint;
    };
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    sidebar?: {
      bgColor?: string;
      textColor?: string;
      textDarkColor?: string;
      textActiveColor?: string;
      navHoverBgColor?: string;
      navActiveBgColor?: string;
      borderColor?: string;
    };
    borderColor?: {
      main?: string;
      dark?: string;
      white?: string;
      rootColor?: string;
    };
  }

  interface PaletteOptions {
    sidebar?: {
      bgColor?: string;
      textColor?: string;
      textDarkColor?: string;
      textActiveColor?: string;
      navHoverBgColor?: string;
      navActiveBgColor?: string;
      borderColor?: string;
    };
    borderColor?: {
      main?: string;
      dark?: string;
      white?: string;
      rootColor?: string;
    };
  }

  interface CommonColors {
    dark?: string;
    brandColor?: string;
    rootColor?: string;
  }
  interface TypeBackground {
    rootColor?: string;
    header?: string;
    danger?: string;
  }
  interface TypeText {
    white?: string;
    red?: string;
    green?: string;
    rootColor?: string;
    error?: string;
    tableText?: string;
    txtLight?: string;
  }
}

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr' as Direction,
  sidebar: {
    width: 304,
    drawerBreakpoint: 'md',
  },
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
      brandColor: '#56b26c',
    },
    primary: {
      50: '#e6f4e9',
      100: '#c3e3c9',
      200: '#9cd0a7',
      300: '#74bf84',
      400: '#56b26c',
      500: '#37a553',
      600: '#2f9649',
      700: '#25853e',
      800: '#1c7434',
      900: '#085521',
    },
    secondary: red,
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#56B26C',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F4F1EF',
      rootColor: '#56B26C',
      header: '#FBFBFA',
      danger: '#D71C1C',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
      red: '#ee0432',
      green: '#56B26C',
      rootColor: '#56B26C',
      error: '#E00930',
      tableText: '#212121',
      txtLight: 'rgba(0, 0, 0, 0.60)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
      white: '#fff',
      rootColor: '#56B26C',
    },
    error: {
      main: '#E8453C',
    },
  },
  typography: {
    fontFamily: 'MaisonNeue',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 20,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 22,
        },
      },
      h2: {
        fontSize: 18,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 20,
        },
      },
      h3: {
        fontSize: 18,
        fontWeight: 'bold',
        [breakpoints.up('md')]: {
          fontSize: 18,
        },
      },
      h4: {
        fontSize: 18,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 14,
        fontWeight: 400,
      },
      h6: {
        fontSize: 14,
        fontWeight: 'bold',
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.1,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
        minHeight: 37,
        padding: '1px 12px 0 !important',
      },
      input: {
        padding: '0 !important',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#56B26C',
      },
    },
    MuiInputBase: {
      input: {
        letterSpacing: '0',
        fontSize: 14,
      },
    },
    MuiFormControlLabel: {
      label: {
        letterSpacing: '0',
        fontSize: 14,
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#56B26C',
        },
        '&$active': {
          color: '#56B26C',
        },
      },
      active: {},
      completed: {},
    },
    MuiButton: {
      root: {
        fontWeight: 600,
        letterSpacing: 0,
        fontSize: 15,
        borderRadius: 3,
        textTransform: 'none',
        boxShadow: 'none',
      },
      containedPrimary: {
        backgroundColor: '#56b26c',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiAccordion: {
      root: {
        margin: 0,
        boxShadow: 'none',
        border: '1px solid rgba(224,224,224,1)',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: '15px 0 12px !important',
      },
    },
    MuiSelect: {
      icon: {
        color: '#56b26c',
      },
    },
  },
});

export default defaultTheme;
