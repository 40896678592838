import downloadFile from 'utils/downloadFile';
import axios from 'axios';

export const exportToCustodian = (uploadShareHoldingId, notification) => {
  return async () => {
    try {
      const response = await axios.get(
        `upload-shareholding/${uploadShareHoldingId}/export-to-custodian`,
        {
          responseType: 'blob',
        },
      );
      if (response.status === 200) {
        downloadFile(response.data, `Upload-shareholding-${Date.now()}.xlsx`);
        return Promise.resolve(response);
      }
    } catch (error) {
      notification.error(error.response.data.responseMsg);
      return false;
    }
  };
};

export const downloadEIS1Certificate = (params, notification) => {
  return async () => {
    try {
      const response = await axios.get(`upload-shareholding/${params?.id}`, {
        params,
        responseType: 'blob',
      });
      if (response.status === 200) {
        downloadFile(response.data, `EIS1-Data-${Date.now()}.xlsx`);
        return Promise.resolve(response);
      }
    } catch (error) {
      notification.error(error.response.data.responseMsg);
      return false;
    }
  };
};

export const getShareholdingUploads = (params) => {
  return () => {
    return axios
      .get(`upload-shareholding/${params?.id}`, { params })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
