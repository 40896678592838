import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';

async function fetchExits({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await api.get(`exit/list`, { params });
    return data;
  } catch (error) {
    throw error;
  }
}

export default function useExits({ params }) {
  const notification = useNotification();

  const list = useQuery({
    queryKey: ['exits', { params }],
    queryFn: fetchExits,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    const errorMessage = list.error?.response?.data?.responseMsg;

    if (errorMessage) {
      notification.error(errorMessage);
    }
  }, [list.error?.response?.data?.responseMsg, notification]);

  return list;
}
