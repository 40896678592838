import { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: '50px',
    display: 'inline-block',
    padding: '0.25rem 1rem',
    fontWeight: 'bold',
  },
  variantDisabled: {
    color: 'rgba(150, 150, 150, 1)',
    backgroundColor: 'rgba(178, 178, 178, 0.1)',
  },
  variantSuccess: {
    color: theme.palette.success.main,
    backgroundColor: 'rgba(86, 178, 108, 0.1)',
  },
  variantError: {
    color: theme.palette.error.main,
    backgroundColor: 'rgba(2332, 69, 60, 0.1)',
  },
  small: {
    fontSize: '0.8rem',
    padding: '0.2rem 0.5rem',
  },
}));

type Props = {
  variant?: 'disabled' | 'success' | 'error';
  children: ReactNode;
  small?: boolean;
};

const Chip: FC<Props> = ({ children, variant, small }) => {
  const classes = useStyles();

  let classname = `${classes.chip}`;

  if (small) {
    classname += ` ${classes.small}`;
  }

  if (variant === 'disabled') {
    classname += ` ${classes.variantDisabled}`;
  } else if (variant === 'success') {
    classname += ` ${classes.variantSuccess}`;
  } else if (variant === 'error') {
    classname += ` ${classes.variantError}`;
  }

  return <span className={classname}>{children}</span>;
};

export default Chip;
