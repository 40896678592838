import React, { useState } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';
import AlertDialog from 'components/AlertDialog';
import { Disclosure, useDisclosure } from 'further-ui/hooks';
import { numberToCurrencyString } from 'further-ui/utils';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineOutlined';
import moment, { Moment } from 'moment';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useInvestment } from 'hooks/data/investment/useInvestments';
import { Alert } from '@material-ui/lab';
import { InfoOutlined } from '@material-ui/icons';
import { type as feeConst } from 'constants/typeConstant';

import useStyles from './styles';
import { useNotification } from 'hooks/ui/useNotification';

type Props = {
  investmentId: string;
  freezeDisclosure: Disclosure;
  hasFrozenFundsMoved: boolean;
  frozenOn?: string;
  availableFunds: number;
  fees:
    | {
        date: string;
        isFrozen?: boolean;
        custodian?: boolean;
        viaExitId?: unknown;
        feeAccounting?: unknown;
      }[]
    | null;
  recentShareholdingDate: Date | null;
};

const ReversedButton = withStyles((theme: any) => ({
  root: {
    color: theme.palette.text.white,
    borderColor: theme.palette.borderColor.white,
    fontWeight: 700,
    '&:hover': {
      borderColor: theme.palette.borderColor.white,
    },
  },
}))(Button);

const InvestmentsFreeze: React.FC<Props> = ({
  frozenOn,
  investmentId,
  freezeDisclosure,
  fees,
  hasFrozenFundsMoved,
  availableFunds,
  recentShareholdingDate,
}) => {
  const classes = useStyles();
  const unfreezeDisclosure = useDisclosure();
  const moveFundsDisclosure = useDisclosure();
  const { error } = useNotification();
  const [freezeStartDate, setFreezeStartDate] = useState<Moment | null>(
    moment(),
  );

  const recentCustodianExportedFeeDate = fees
    ?.filter((fee) => fee.custodian)
    .map((fee) => new Date(fee.date))
    .sort((date1: Date, date2: Date) => date2.getTime() - date1.getTime())[0];

  const earliestPossibleFreezeTime = Math.max(
    ...[
      recentCustodianExportedFeeDate?.getTime() ?? 0,
      recentShareholdingDate?.getTime() ?? 0,
    ],
  );
  const earliestPossibleFreezeDate = earliestPossibleFreezeTime
    ? new Date(earliestPossibleFreezeTime)
    : undefined;

  const {
    freeze: {
      error: freezeError,
      mutate: freezeInvestment,
      isLoading: isFreezing,
    },
    moveFrozenFunds: {
      error: moveFrozenFundsError,
      mutate: moveFrozenFunds,
      isLoading: isMovingFrozenFunds,
    },
  } = useInvestment(investmentId);

  const canBeUnfrozen = !fees?.find(
    (fee) =>
      fee.isFrozen &&
      ((fee.feeAccounting === feeConst.feeAccounting.chargedToInvestor &&
        fee.viaExitId) ||
        fee.custodian),
  );

  const isFrozen = frozenOn && new Date() >= new Date(frozenOn);

  const handleFreeze = () => {
    if (isFreezing || isFrozen) return;

    freezeInvestment(
      {
        freezeStartDate: moment
          .utc(freezeStartDate, 'DD/MM/YYYY', true)
          .startOf('day')
          .toDate(),
      },
      {
        onSuccess: (result) => {
          if (!result.data.success) {
            error('Freezing subscription failed.');
            return;
          }
          freezeDisclosure.onClose();
        },
      },
    );
  };

  const handleUnfreeze = () => {
    if (isFreezing || !canBeUnfrozen || !isFrozen || hasFrozenFundsMoved)
      return;

    freezeInvestment(
      { freezeStartDate: null },
      {
        onSuccess: (result) => {
          if (!result.data.success) {
            error('Freezing subscription failed.');
            return;
          }
          unfreezeDisclosure.onClose();
        },
      },
    );
  };

  const handleMoveFrozenFunds = () => {
    if (!isFrozen || hasFrozenFundsMoved) return;

    moveFrozenFunds(null, {
      onSuccess: () => {
        moveFundsDisclosure.onClose();
      },
    });
  };

  return (
    <>
      {isFrozen ? (
        <div className={classes.freezeLabel}>
          <span>
            This subscription was frozen on{' '}
            {moment(frozenOn).format('DD/MM/YYYY')}
          </span>
          <ReversedButton
            size="small"
            variant="outlined"
            color="primary"
            onClick={unfreezeDisclosure.onOpen}
            disabled={!canBeUnfrozen || hasFrozenFundsMoved}
          >
            Unfreeze subscription
          </ReversedButton>
          {!canBeUnfrozen ? (
            <Tooltip
              title="This exit is unable to be unfrozen due to one or multiple exits or custodian exports occurring after the freeze date."
              placement="top"
            >
              <InfoOutlined />
            </Tooltip>
          ) : null}
          <ReversedButton
            size="small"
            variant="outlined"
            color="primary"
            onClick={moveFundsDisclosure.onOpen}
            disabled={hasFrozenFundsMoved}
          >
            Move frozen funds
          </ReversedButton>
        </div>
      ) : null}
      <AlertDialog
        icon={<QuestionMarkIcon className={classes.dialogIcon} />}
        open={freezeDisclosure.isOpen}
        onClose={freezeDisclosure.onClose}
        onConfirm={handleFreeze}
        title="Are you sure?"
        content={
          <div className={classes.dialogContent}>
            <p className={classes.dialogContentText}>
              If frozen, fees will accrue pro rata to deployed capital and this
              subscription will be excluded from future allocations.
            </p>
            <p className={classes.dialogContentText}>
              Any allocations you add before the freeze date could make freeze
              fee calculations incorrect. If this occurs, please unfreeze and
              freeze the investor again
            </p>
            <div className={classes.freezeDate}>
              <label htmlFor="freezeStartDate">Freeze start date:</label>
              <AppDatePicker
                placeholder="Freeze start date"
                id="freezeStartDate"
                name="freezeStartDate"
                value={freezeStartDate}
                minDate={earliestPossibleFreezeDate}
                onChange={(date: Moment | null) => {
                  setFreezeStartDate(date);
                }}
                disableFuture={true}
              />
            </div>
            {freezeError ? (
              <Alert severity="error" className="mt-5">
                Error when trying to freeze subscription. Please try again.
              </Alert>
            ) : null}
          </div>
        }
        confirmBtnProps={{ disabled: isFreezing }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Freeze subscription',
        }}
        cancelBtnProps={undefined}
        confirmBtnComponent={undefined}
      />
      <AlertDialog
        icon={<QuestionMarkIcon className={classes.dialogIcon} />}
        open={unfreezeDisclosure.isOpen}
        onClose={unfreezeDisclosure.onClose}
        onConfirm={handleUnfreeze}
        title="Are you sure?"
        content={
          <div className={classes.dialogContent}>
            <p className={classes.moveFundsDialogContentText}>
              When unfrozen, all freeze-related fee reductions will be undone.
              If this subscription missed deal allocations while frozen, it may
              not be possible to fully deploy the subscription’s capital.
            </p>
            {freezeError ? (
              <Alert severity="error" className="mt-5">
                Error when trying to unfreeze subscription. Please try again.
              </Alert>
            ) : null}
          </div>
        }
        confirmBtnProps={{ disabled: isFreezing }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Unfreeze subscription',
        }}
        cancelBtnProps={undefined}
        confirmBtnComponent={undefined}
      />
      <AlertDialog
        open={moveFundsDisclosure.isOpen}
        onClose={moveFundsDisclosure.onClose}
        onConfirm={handleMoveFrozenFunds}
        title="Are you sure?"
        content={
          <div className={classes.dialogContent}>
            <p className={classes.moveFundsDialogContentText}>
              Doing this will clear {numberToCurrencyString(availableFunds)}{' '}
              from the subscription and transfer it to the investor's cash
              balance. This cannot be undone.
            </p>
            {moveFrozenFundsError ? (
              <Alert severity="error" className="mt-5">
                Error when trying to move frozen funds. Please try again.
              </Alert>
            ) : null}
          </div>
        }
        confirmBtnProps={{ disabled: isMovingFrozenFunds }}
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Transfer funds',
        }}
        icon={undefined}
        cancelBtnProps={undefined}
        confirmBtnComponent={undefined}
      />
    </>
  );
};

export default InvestmentsFreeze;
