import React from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';

import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '../AppTextInput';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    padding: '3px',
  },
}));

export const MuiDateRangePicker = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const format = props.format ?? 'DD/MM/YYYY';

  const toggle = () => setOpen(!open);

  const getDisplayDateRange = (dateRange) => {
    const startDate = dateRange?.startDate
      ? moment(dateRange.startDate).format(format)
      : undefined;

    const endDate = dateRange?.endDate
      ? moment(dateRange.endDate).format(format)
      : undefined;

    return startDate || endDate ? `${startDate} - ${endDate}` : '';
  };

  return (
    <>
      <TextField
        {...props.TextFieldProps}
        value={getDisplayDateRange(props.dateRange)}
        onClick={toggle}
        InputProps={{
          ...props.TextFieldProps?.InputProps,
          readOnly: true,
          endAdornment: (
            <>
              <IconButton className={classes.iconButton}>
                <DateRangeIcon />
              </IconButton>
              {getDisplayDateRange(props.dateRange) && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    props.onDateRangeChange({
                      startDate: null,
                      endDate: null,
                    });
                    // JB: this is a bad hack I know - no time to refactor
                    // TextField to set onClick only on the appropriate part
                    window.setTimeout(() => {
                      setOpen(false);
                    }, 1);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          ),
        }}
      />
      <Modal
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => {
                if (moment(range.startDate).isSame(range.endDate)) {
                  range.startDate = moment(range.startDate)
                    .startOf('day')
                    .toDate();
                  range.endDate = moment(range.endDate).endOf('day').toDate();
                } else if (!range.label) {
                  range.endDate = moment(range.endDate).endOf('day').toDate();
                }
                props.onDateRangeChange(range);
                toggle();
              }}
              initialDateRange={props.dateRange}
              definedRanges={props.definedRanges}
              wrapperClassName="date-range-picker-wrapper"
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};
