import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { deleteCompany } from '@redux/actions/Company';
import { FirmSelector } from 'components/FirmSelector';
import { COMPANY_ADD } from 'constants/routes';
import { Link } from 'react-router-dom';
import { useDisclosure } from 'further-ui/hooks';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { usePagination } from 'hooks/ui/usePagination';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDebounce } from 'usehooks-ts';
import { useNotification } from 'hooks/ui/useNotification';
import useFilters from 'hooks/ui/useFilters';
import {
  generateCollapsedTableColumns,
  generateMainTableColumns,
} from './tableConfig';
import useGroupedCompanies from 'hooks/data/company/useGroupedCompanies';
import useExportCompanies from 'hooks/data/company/useExportCompanies';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
  socialIcons: {
    maxWidth: 24,
  },
  investmentTypeWidth: {
    width: 110,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    paddingBottom: 8,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  actionButton: {
    marginRight: 8,
  },
  childRowContainer: {
    marginBottom: '1rem',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '4px',
  },

  cmTableDarkHeading: {
    '& th': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Companies', isActive: true },
];

const Company = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deleteDisclosure = useDisclosure();
  const { error, success } = useNotification();
  const { firmId, isSuperAdmin } = useGetRole();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions([
      'create:companies',
      'delete:companies',
      'edit:companies',
    ]);

  const pagination = usePagination({
    id: 'companies-table',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters('companies-table', {
    defaultFilters: {
      firmId,
      companyName: null,
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const debouncedCompanyName = useDebounce(filters.companyName, 150);
  localStorage.removeItem('duplicate-company-id');

  const { mutateAsync: exportCompanies, isLoading: isExportingCompanies } =
    useExportCompanies();

  const {
    data: groupedCompanies,
    isFetching: isFetchingGroupedCompanies,
    refetch: refetchGroupedCompanies,
  } = useGroupedCompanies({
    firmId: filters.firmId,
    page: pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    companyName: debouncedCompanyName,
  });

  const columns = generateMainTableColumns({
    classes,
    isSuperAdmin,
  });

  const collapsedTableColumns = generateCollapsedTableColumns({
    classes,
    updatePermission,
    deletePermission,
    history,
    deleteDisclosure,
  });

  const handleConfirmDelete = async () => {
    const companiesList = groupedCompanies?.result;
    const record = deleteDisclosure.actionProps;

    try {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteCompany(record._id),
      );
      if (response.status === 200) {
        success(response.data.responseMsg);
        if (companiesList.length === 1) {
          pagination.pageBack();
        } else {
          refetchGroupedCompanies();
        }
      }
    } catch (err) {
      error(err.response.data.responseMsg);
      refetchGroupedCompanies();
    }
    deleteDisclosure.onClose();
  };

  const exportExcelSheet = async () => {
    try {
      await exportCompanies({
        ...filters,
        type: pagination.order,
      });
    } catch (e) {
      error(e.response?.data?.responseMsg || 'Failed to export companies');
    }
  };

  return (
    <PageContainer heading="Companies" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                {isSuperAdmin && (
                  <Grid item xs={12} md={4}>
                    <FirmSelector
                      firmId={filters.firmId}
                      allowDeselection
                      placeholder="Search by firm"
                      deselectionLabel="All firms"
                      onChange={(firmId) => {
                        handleChangeFilters({ firmId });
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <TextField
                    type="search"
                    name="companyName"
                    placeholder="Search by company name"
                    fullWidth
                    value={filters.companyName}
                    onChange={(event) => {
                      handleChangeFilters({ companyName: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={isSuperAdmin ? 4 : 8}>
                  <div className={classes.buttonContainer}>
                    {createPermission && (
                      <Link to={COMPANY_ADD}>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.actionButton}
                        >
                          Add Company
                        </Button>
                      </Link>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={exportExcelSheet}
                      disabled={
                        !groupedCompanies?.result?.length ||
                        isExportingCompanies
                      }
                    >
                      {isExportingCompanies ? (
                        <CircularProgress
                          style={{
                            color: 'white',
                            marginRight: 10,
                          }}
                          size={18}
                        />
                      ) : null}
                      Export
                    </Button>
                  </div>
                </Grid>
              </GridContainer>
              <div className={classes.tableMt}>
                {
                  <Table
                    columns={columns}
                    onRequestSort={pagination.handleRequestSort}
                    order={pagination.order}
                    orderBy={pagination.orderBy}
                    tablebody={groupedCompanies?.result ?? []}
                    onPageChange={pagination.handleChangePage}
                    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                    page={pagination.page}
                    rowsPerPage={pagination.rowsPerPage}
                    count={groupedCompanies?.total}
                    pagination={true}
                    loading={isFetchingGroupedCompanies}
                    variant="nohover"
                    collapsedContent={(row) => {
                      return (
                        <div
                          className={`${classes.childRowContainer} ${classes.cmTableDarkHeading}`}
                        >
                          <Table
                            columns={collapsedTableColumns}
                            tablebody={row.investees || []}
                          />
                        </div>
                      );
                    }}
                  />
                }
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this company?`}
          content={
            <p>
              This cannot be undone and all information relating to the company
              will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete company',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default Company;
