import { Button, CircularProgress, Grid } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import Table from 'components/Table';
import {
  numberToCurrencyString,
  dateToLabel,
  renderMultiple,
} from 'further-ui/utils';
import { usePagination } from 'hooks/ui/usePagination';
import { numberToDisplayString } from 'utils/numbers';
import { InvesteeShareHoldingDetails } from 'further-types/companies';
import { useNotification } from 'hooks/ui/useNotification';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import ShareholdingLabel from 'components/ShareholdingLabel';
import useCompanyShareholdings from 'hooks/data/company/useCompaniesShareholdings';
import useExportCompanyShareholdings from 'hooks/data/company/useExportCompanyShareholdings';

type Props = {
  companyId: string;
  tradingName: string;
  classes: Record<string, any>;
};
const Shareholdings = ({ companyId, tradingName, classes }: Props) => {
  const { error } = useNotification();
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 300);

  const columns = [
    {
      label: 'Investor',
      key: 'investor.fullName',
      sort: true,
      className: classes.mediumColumn,
      render: (elm: InvesteeShareHoldingDetails) => (
        <ShareholdingLabel
          label={elm?.fullName || ''}
          shareholding={elm}
          classes={classes}
        />
      ),
    },
    {
      label: 'Investment date',
      key: 'allocationDate',
      sort: true,
      render: (elm: InvesteeShareHoldingDetails) =>
        dateToLabel(elm.allocationDate),
    },
    {
      label: 'Shares purchased',
      key: 'sharesPurchased',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToDisplayString(elm.sharesPurchased),
    },
    {
      label: 'Capital allocated',
      key: 'capitalAllocated',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToCurrencyString(elm.subscriptionAmount),
    },
    {
      label: 'Shares held',
      key: 'sharesHeld',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToDisplayString(elm.sharesCurrentlyHeld),
    },
    {
      label: 'Hold value',
      key: 'holdValue',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToCurrencyString(elm.holdValue),
    },
    {
      label: 'Shares sold',
      key: 'sharesSold',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToDisplayString(elm.sharesSold),
    },
    {
      label: 'Sale value',
      key: 'saleValue',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToCurrencyString(elm.saleValue),
    },
    {
      label: 'MOIC',
      key: 'moic',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        renderMultiple(elm.multipleOnInvestedCapital),
    },
    {
      label: 'Tranche',
      key: 'fundName',
      sort: false,
      className: classes.wideColumn,
      render: (elm: InvesteeShareHoldingDetails) => (
        <div className={classes.wideColumn}>{elm?.fundName}</div>
      ),
    },
    {
      label: 'Subscription ID',
      key: 'externalSubscriptionId',
      sort: false,
      className: classes.wideColumn,
      render: (elm: InvesteeShareHoldingDetails) => (
        <div className={classes.wideColumn}>{elm?.externalInvestmentId}</div>
      ),
    },
    {
      label: 'Initial share price',
      key: 'sharePrice',
      sort: false,
      render: (elm: InvesteeShareHoldingDetails) =>
        numberToCurrencyString(elm.initialSharePrice, { unlimitedDp: true }),
    },
  ];

  const pagination = usePagination({
    id: `companyShareholdings`,
    order: 'asc',
    orderBy: 'investor.fullName',
  });

  const { exportCompanyShareholdings, isExportingCompanyShareholdings } =
    useExportCompanyShareholdings({
      companyId,
      tradingName,
      order: pagination.order,
      orderBy: pagination.orderBy,
    });

  const {
    data: companyShareholdings,
    isFetching: isFetchingCompanyShareholdings,
  } = useCompanyShareholdings({
    companyId,
    page: pagination.page,
    pageSize: pagination.rowsPerPage,
    order: pagination.order,
    orderBy: pagination.orderBy,
    search: debouncedSearchTerm,
  });

  const exportExcelSheet = async () => {
    try {
      await exportCompanyShareholdings({
        tradingName,
      });
    } catch (e) {
      error(
        e.response?.data?.responseMsg ||
          'Failed to export company shareholdings',
      );
    }
  };

  useEffect(() => {
    pagination.toFirstPage();
  }, [debouncedSearchTerm]);

  return (
    <Grid item xs={12} className={classes.shareholdingsContainer}>
      <CardHeader title="Shareholdings" />
      <GridContainer className={classes.gridMargin}>
        <Grid item xs={12} md={5}>
          <TextField
            type="search"
            name="search"
            placeholder="Search by investor or subscription ID"
            fullWidth
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </Grid>
        <Grid item className={classes.exportShareholdingsBtn}>
          <Button
            onClick={() => exportExcelSheet()}
            variant="contained"
            color="primary"
            disabled={
              !companyShareholdings?.total || isExportingCompanyShareholdings
            }
          >
            {isExportingCompanyShareholdings ? (
              <CircularProgress
                style={{
                  color: 'white',
                  marginRight: 10,
                }}
                size={18}
              />
            ) : null}
            Export
          </Button>
        </Grid>
      </GridContainer>
      <Table
        columns={columns}
        tablebody={companyShareholdings?.result ?? []}
        pagination={true}
        count={companyShareholdings?.total ?? 0}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        onRequestSort={pagination.handleRequestSort}
        order={pagination.order}
        orderBy={pagination.orderBy}
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        emptyMessage="No shareholdings found"
        loading={isFetchingCompanyShareholdings}
      />
    </Grid>
  );
};

export default Shareholdings;
