import { FC } from 'react';
import { startCase } from 'lodash';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import { Box } from '@material-ui/core';
import { CollectionHistoryEventType } from 'further-types/collection-history';

type Filters = {
  collection?: string;
  eventType?: CollectionHistoryEventType;
  docId?: string;
};

type Props = {
  filters: Filters;
  handleChangeFilters: (filters: Filters) => void;
  collectionNames: string[];
  eventTypeToLabelMap: Record<CollectionHistoryEventType, string>;
};

const ChangeLogFilters: FC<Props> = ({
  filters,
  handleChangeFilters,
  collectionNames,
  eventTypeToLabelMap,
}) => (
  <Box display="flex" gridGap="1rem" marginBottom={4}>
    <Box style={{ flex: 1 }}>
      <Autocomplete
        // @ts-expect-error Autocomplete component is not TS yet
        disableClearable
        options={collectionNames}
        getOptionLabel={(option) => startCase(option)}
        filterSelectedOptions
        value={filters.collection}
        onChange={(_event, collection) => handleChangeFilters({ collection })}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select a data type"
          />
        )}
      />
    </Box>
    <Box style={{ flex: 1 }}>
      <TextField
        variant="outlined"
        placeholder="Search by ID"
        onChange={(e) => handleChangeFilters({ docId: e.target.value })}
        value={filters.docId}
      />
    </Box>
    <Box style={{ flex: 1 }}>
      <Autocomplete
        // @ts-expect-error Autocomplete component is not TS yet
        options={Object.values(CollectionHistoryEventType)}
        getOptionLabel={(option: CollectionHistoryEventType) =>
          eventTypeToLabelMap[option]
        }
        filterSelectedOptions
        value={filters.eventType}
        onChange={(_event, eventType: CollectionHistoryEventType) =>
          handleChangeFilters({ eventType })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select an event type"
          />
        )}
      />
    </Box>
  </Box>
);

export default ChangeLogFilters;
