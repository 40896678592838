import { cloneElement, isValidElement } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useLogout from 'hooks/ui/useLogout';

const useStyles = makeStyles((theme) => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
  },
  activeClass: {
    color: '#56B26C !important',
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: theme.palette.sidebar.textColor,
    '&:hover, &:focus': {
      color: theme.palette.text.rootColor,
      backgroundColor: theme.palette.sidebar.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.text.rootColor,
      },
    },
    '&.active': {
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: theme.palette.sidebar.textActiveColor,
        },
      },
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    paddingTop: '5px',
    fontWeight: 600,
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
  },
  cursor: {
    cursor: 'pointer',
  },
}));

type Props = {
  name: string;
  icon: React.ReactNode;
  link?: string;
  isLogout?: boolean;
  onClick?: () => void;
};

const NavMenuItem: React.FC<Props> = ({
  name,
  icon,
  link = '',
  isLogout,
  onClick,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { handleLogout } = useLogout();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        //@ts-expect-error
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }
    return null;
  };

  let activeFlag = false;
  if (
    history.location.pathname === link ||
    `${history.location.pathname}${history.location.search}` === link
  ) {
    activeFlag = true;
  }

  return (
    <List
      component="div"
      className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}
    >
      {isLogout ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          onClick={handleLogout}
          className={clsx(
            classes.navMenuLink,
            classes.cursor,
            'Cmt-nav-menu-link',
          )}
        >
          {renderIcon()}
          <span className={clsx(classes.navText)}>{name}</span>
        </div>
      ) : link ? (
        <NavLink
          className={clsx(
            classes.navMenuLink,
            activeFlag ? classes.activeClass : '',
            'Cmt-nav-menu-link',
          )}
          to={link}
          onClick={onClick}
        >
          {renderIcon()}
          <span className={clsx(classes.navText)}>{name}</span>
        </NavLink>
      ) : null}
    </List>
  );
};

export default NavMenuItem;
