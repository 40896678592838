import GridContainer from 'components/GridContainer';
import { Button, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import { dateToLabel } from 'further-ui/utils';

type InvestmentError = {
  investorName: string;
};

type Props = {
  investmentsWithBalanceErrors: InvestmentError[];
  investmentsWithDateErrors: InvestmentError[];
  isFee: boolean;
  dateEntered: Date;
  onGoBack: () => void;
  isBulk: boolean;
};

type InsufficientBalanceErrorProps = {
  investments: InvestmentError[];
  isFee: boolean;
};
const InsufficientBalanceError: React.FC<InsufficientBalanceErrorProps> = ({
  investments,
  isFee,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" className={classes.root}>
        {isFee
          ? 'Investors have insufficient funds available'
          : 'Investors are unable to be given this discount'}
      </Typography>
      <Typography variant="body1" className={classes.root}>
        The following investors{' '}
        {isFee
          ? 'do not have sufficient cash available in their subscription for this additional fee to be charged.'
          : 'have been charged total fees that are less than the proposed discount.'}{' '}
        To continue, please go back and either reduce the{' '}
        {isFee ? 'fee' : 'discount'} amount or exclude these investors from the
        fee.
      </Typography>
      {investments?.map((investmentError, index) => (
        <Typography className={classes.marginTop} variant="body1" key={index}>
          {investmentError?.investorName}
        </Typography>
      ))}
    </>
  );
};

type IncorrectFeeDateErrorProps = {
  investments: InvestmentError[];
  dateEntered: Date;
};
const IncorrectFeeDateError: React.FC<IncorrectFeeDateErrorProps> = ({
  investments,
  dateEntered,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" className={classes.root}>
        Invalid fee date entered
      </Typography>
      <Typography variant="body1" className={classes.root}>
        The date entered ({dateToLabel(dateEntered)}) is not valid for the
        following investors because it is before their initial subscription
        date. To continue, please amend the fee date or exclude these investors
        from the fee.
      </Typography>
      {investments?.map((investmentError, index) => (
        <Typography className={classes.marginTop} variant="body1" key={index}>
          {investmentError?.investorName}
        </Typography>
      ))}
    </>
  );
};

const FeesAndDiscountError: React.FC<Props> = ({
  investmentsWithBalanceErrors,
  investmentsWithDateErrors,
  isFee,
  dateEntered,
  onGoBack,
  isBulk,
}) => {
  const classes = useStyles();
  return (
    <>
      {investmentsWithBalanceErrors?.length > 0 ? (
        <InsufficientBalanceError
          investments={investmentsWithBalanceErrors}
          isFee={isFee || isBulk}
        />
      ) : investmentsWithDateErrors.length > 0 ? (
        <IncorrectFeeDateError
          investments={investmentsWithDateErrors}
          dateEntered={dateEntered}
        />
      ) : null}
      <GridContainer>
        <Grid item xs={12}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonLeftMargin}
              color="primary"
              variant="contained"
              onClick={onGoBack}
            >
              Go back
            </Button>
          </div>
        </Grid>
      </GridContainer>
    </>
  );
};

export default FeesAndDiscountError;
