import { Control, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import useCommonStyles from '../commonStyles';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

type Props = {
  control: Control<any>;
  errors: any;
};

const AddNewBankAccountForm = ({ control, errors }: Props) => {
  const commonClasses = useCommonStyles();

  return (
    <GridContainer>
      <Typography style={{ padding: '0.75rem', fontSize: 14 }}>
        Please enter your bank details. A minimum of the beneficiary name and
        either (i) account number and sort code or (ii) IBAN are required.
      </Typography>
      <FieldRow title="Beneficiary name*" centerTitle>
        <Controller
          name={`beneficiaryName`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter beneficiary name"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>
          {errors.beneficiaryName?.message}
        </p>
      </FieldRow>

      <FieldRow title="Beneficiary address" centerTitle>
        <Controller
          name={`beneficiaryAddress`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter beneficiary address"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>
          {errors.beneficiaryAddress?.message}
        </p>
      </FieldRow>

      <FieldRow title="Bank name" centerTitle>
        <Controller
          name={`bankName`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank name"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>{errors.bankName?.message}</p>
      </FieldRow>

      <FieldRow title="Bank address" centerTitle>
        <Controller
          name={`bankAddress`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank address"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>{errors.bankAddress?.message}</p>
      </FieldRow>

      <FieldRow title="UK account number" centerTitle>
        <Controller
          name={`bankAccountNumber`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumberFormat
              {...rest}
              inputRef={ref}
              placeholder="Enter bank account number"
              format="########"
              customInput={TextField}
              type="text"
            />
          )}
        />
        <p className={commonClasses.errorText}>
          {errors.bankAccountNumber?.message}
        </p>
      </FieldRow>

      <FieldRow title="UK sort code" centerTitle>
        <Controller
          name={`bankSortCode`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <NumberFormat
              {...rest}
              inputRef={ref}
              placeholder="Enter bank sort code"
              format="##-##-##"
              customInput={TextField}
              type="text"
            />
          )}
        />
        <p className={commonClasses.errorText}>
          {errors.bankSortCode?.message}
        </p>
      </FieldRow>

      <FieldRow title="IBAN" centerTitle>
        <Controller
          name={`iban`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank account IBAN"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>{errors.iban?.message}</p>
      </FieldRow>

      <FieldRow title="SWIFT/BIC" centerTitle>
        <Controller
          name={`swift`}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              {...rest}
              inputRef={ref}
              placeholder="Enter bank account SWIFT/BIC"
              fullWidth
            />
          )}
        />
        <p className={commonClasses.errorText}>{errors.swift?.message}</p>
      </FieldRow>

      <Grid item xs={12}>
        <p className={commonClasses.errorText}>
          {errors.ibanOrAccountNumberAndSortCodeMissing?.message}
        </p>
      </Grid>
    </GridContainer>
  );
};

export default AddNewBankAccountForm;
