import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useDispatch } from 'react-redux';
import { userInformation } from '@redux/actions/Auth';

async function fetchUser({ dispatch }) {
  try {
    const response = await api.get('/user/me');

    if (response.status === 200) {
      localStorage.setItem('user', JSON.stringify(response?.data?.result));
      await dispatch(userInformation(response?.data?.result));
    }

    return response.data.result.user;
  } catch (error) {
    throw error;
  }
}

export default function useCurrentUser() {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['user'],
    queryFn: () => fetchUser({ dispatch }),
    retry: 1,
    enabled: !!localStorage.getItem('token'),
  });
}
