import { useQuery, useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchFees({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const { data } = await api.post('fee/list', params);

    return data.data;
  } catch (error) {
    throw error;
  }
}

const exportFees = async (params, exportType) => {
  const response = await api.post(
    'fee/export',
    {
      ...params,
      exportType,
    },
    {
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    throw Error('Unexpected export response.');
  }
  return response.data;
};

export function useFees({ params }) {
  const { mutateAsync, isLoading: isExportLoading } = useMutation({
    mutationFn: (exportType) => exportFees(params, exportType),
  });

  return {
    fees: useQuery({
      queryKey: ['fees', { params }],
      queryFn: fetchFees,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }),
    exportFees: mutateAsync,
    isExportLoading,
  };
}
