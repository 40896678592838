import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { isEqual } from 'lodash';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { makeStyles } from '@material-ui/core';
import FundInformationStep from './FundInformationStep';
import CompanyInformationStep from './CompanyInformationStep';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { INVESTOR_REPORTING_VIEW } from 'constants/routes';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useReportingFundInfo } from 'hooks/data/investorReporting/useReportingFundInfo';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { useReportingInvestee } from 'hooks/data/investorReporting/useReportingInvestee';

const useStyles = makeStyles(() => ({
  firmDropDown: {
    padding: '10px 0',
  },
}));

const STEPS = {
  FUND_UPDATE: 0,
  COMPANY_UPDATE: 1,
};

const InvestorReportingForm = ({ onExitForm }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(STEPS.FUND_UPDATE);

  const handleNavigation = (step) =>
    setActiveStep((currentStep) => {
      const newActiveStep = currentStep + step;
      return Object.values(STEPS).includes(newActiveStep)
        ? newActiveStep
        : currentStep;
    });

  const formContext = useInvestorReportingFormContext();
  const {
    setFundInformation,
    investorReport,
    firmId,
    setFirmId,
    managerOverview,
    setManagerOverview,
    showFirmDropdown,
    submitDate,
    companyInformation,
    setCompanyInformation,
    processFundInformation,
    allActiveFundIds: contextAllActiveFundIds,
    setAllActiveFundIds,
  } = formContext;

  const investorReportId = investorReport?._id;

  const isViewPage = useRouteMatch(INVESTOR_REPORTING_VIEW)?.isExact;
  const { firms } = useFirm({ params: {} });

  const { data: closedFundsData, isFetching: isFetchingClosedFunds } =
    useReportingFundInfo(
      !investorReportId ? firmId : null,
      submitDate?.format('DD/MM/YYYY'),
    );

  const allActiveFundIds = investorReportId
    ? investorReport.fundIds
    : closedFundsData?.closedFunds?.map(({ _id }) => _id) ?? [];

  useEffect(() => {
    if (!isEqual(allActiveFundIds, contextAllActiveFundIds)) {
      setAllActiveFundIds(allActiveFundIds);
    }
  }, [contextAllActiveFundIds, allActiveFundIds]);

  const { data: companyInformationData, isFetching: isFetchingInvestees } =
    useReportingInvestee({
      firmId,
      submitDate: submitDate.format('DD/MM/YYYY'),
      enabled: Boolean(
        !investorReportId &&
          !companyInformation.length &&
          !!firmId & !!submitDate,
      ),
    });

  useEffect(() => {
    if (
      !companyInformationData?.result?.length ||
      investorReportId ||
      companyInformation.length
    )
      return;

    setCompanyInformation(
      companyInformationData.result.map((item) => ({
        ...item,
        additionalMetrics: [],
        attachments: [],
        mediaType: 'VIDEO',
        media: [{}],
        updateSummary: '',
        additionalTitle: '',
        additionalValue: '',
        updatedSharePrice: '',
        copyFromCompanies: [],
      })),
    );
  }, [companyInformationData]);

  useEffect(() => {
    if (!closedFundsData) return;

    const filteredClosedFunds = closedFundsData.closedFunds
      ?.filter((item) => !item.tag.isSyndicate)
      .map((item) => ({
        ...item,
        title: '',
        shortDescription: '',
        provideCommentary: false,
        commentary: '',
        role: '',
        copyFund: true,
      }));
    setFundInformation(filteredClosedFunds);
  }, [closedFundsData]);

  const hasActiveFundsToReportOn =
    investorReport?.fundIds?.length || allActiveFundIds.length;

  const onNextStep = (data) => {
    setManagerOverview({
      ...(managerOverview ?? {}),
      ...data.managerOverview,
    });
    setFundInformation(processFundInformation(data.fundInformation));
    handleNavigation(1);
  };

  return activeStep === STEPS.FUND_UPDATE ? (
    <>
      {showFirmDropdown && (
        <GridContainer className={classes.firmDropDown}>
          <Grid item md={6} xs={12}>
            <AppSelectBox
              required
              data={firms?.data?.result}
              disabled={isViewPage}
              valueKey="_id"
              labelKey="firmName"
              label="Firm"
              value={firmId}
              variant="outlined"
              onChange={(event) => setFirmId(event.target.value)}
            />
          </Grid>
        </GridContainer>
      )}
      <FundInformationStep
        firm={firms?.data?.result?.find(({ _id }) => _id === firmId)}
        isFetching={isFetchingClosedFunds || isFetchingInvestees}
        isViewPage={isViewPage}
        hasActiveFundsToReportOn={hasActiveFundsToReportOn}
        onNextStep={onNextStep}
        onExitForm={onExitForm}
      />
    </>
  ) : (
    <CompanyInformationStep
      handleStepBack={() => handleNavigation(-1)}
      isViewPage={isViewPage}
    />
  );
};

export default InvestorReportingForm;
