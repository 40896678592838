import axios from 'axios';

export const getInvestmentList = (value) => {
  return () => {
    return axios
      .post(`investment/get-investees`, value)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
