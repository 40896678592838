import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CardHeader from 'components/CardHeader';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { createShareSplitAddRoute } from 'constants/routes';
import { useShareSplits } from 'hooks/data/company/useShareSplits';

const ShareSplitRows = ({ companyId, classes }) => {
  const history = useHistory();

  const { shareSplits } = useShareSplits({ params: { companyId } });

  const columns = [
    {
      label: 'Date',
      key: 'date',
      sort: false,
      render: (elm) => dateToLabel(elm.date),
    },
    {
      key: 'shareSplitFactor',
      label: 'Share split factor',
      sort: false,
    },
    {
      key: 'sharePricePreSplit',
      label: 'Original share price',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.sharePricePreSplit, { unlimitedDp: true }),
    },
    {
      key: 'sharePricePostSplit',
      label: 'New share price',
      sort: false,
      render: (elm) =>
        numberToCurrencyString(elm.sharePricePostSplit, { unlimitedDp: true }),
    },
  ];

  return (
    <Grid item xs={12}>
      <CardHeader title="Share split record" />
      <Table columns={columns} tablebody={shareSplits?.data ?? []} />
      <Grid item xs={12} lg={12} className={classes.addRevalutionBtn}>
        <Button
          onClick={() => history.push(createShareSplitAddRoute(companyId))}
          variant="contained"
          color="primary"
        >
          Split shares
        </Button>
      </Grid>
    </Grid>
  );
};

export default ShareSplitRows;
