import axios from 'axios';

/**
 * Create spreadsheet by tranches and date
 * @param {string[]} selectedTrancheIds
 * @param {string} interestPaymentsDate
 * @returns
 */
export const createSpreadsheetByTranchesAndDate = (
  selectedTrancheIds,
  interestPaymentsDate,
) => {
  return axios.post(`interest-payment/spreadsheet/create`, {
    selectedTrancheIds,
    interestPaymentsDate,
  });
};

/**
 * Upload spreadsheet
 * @param {FormData} formData
 * @returns
 */
export const uploadSpreadsheet = (formData) => {
  return axios.post(`interest-payment/spreadsheet/upload`, formData);
};

/**
 * List interest payment uploads
 * @param {number} currentPage
 * @param {number} pageSize
 * @param {string} sortDirection
 * @param {string} sortField
 * @returns
 */
export const list = (
  currentPage,
  pageSize,
  sortDirection,
  sortField,
  uploadDateRange,
  paymentDateRange,
) => {
  return axios.get('interest-payment', {
    params: {
      currentPage,
      pageSize,
      sortDirection,
      sortField,
      paymentStartDate: paymentDateRange.startDate,
      paymentEndDate: paymentDateRange.endDate,
      uploadStartDate: uploadDateRange.startDate,
      uploadEndDate: uploadDateRange.endDate,
    },
  });
};

/**
 * Get interest payment spreadsheet by upload id
 * @param {number} uploadId
 * @returns
 */
export const spreadsheetDownloadByUploadId = (uploadId) => {
  return axios.post(`interest-payment/spreadsheet/download`, {
    uploadId,
  });
};

/**
 * Delete interest payment upload by id
 * @param {number} uploadId
 * @returns
 */
export const deleteUploadById = (uploadId) => {
  return axios.delete(`interest-payment/${uploadId}`);
};
