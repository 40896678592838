import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/delegated-access';
import { api } from 'lib/httpClient';

async function fetchDelegatedAccessGiven({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(
      `delegated-access/list-access-given/${params.investorId}`,
    );

    return data.data;
  } catch (error) {
    throw error;
  }
}

type Params = {
  investorId?: string;
};

export function useListDelegatedAccessGiven(params: Params) {
  return useQuery<ApiResponse<Api.ListDelegatedAccessGivenByFirmResponse>>({
    queryKey: ['delegated-access-given', params],
    queryFn: fetchDelegatedAccessGiven,
    enabled: !!params.investorId,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}
