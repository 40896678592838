import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import AppDatePicker from 'components/FormElements/AppDatePicker';

type Props = {
  control: Control<any>;
  error?: FieldError;
};

const FormDateInput: FC<Props> = ({ control, error }) => {
  return (
    <Controller
      name="datePaid"
      control={control}
      render={({ field: { value, onChange } }) => (
        <AppDatePicker
          value={value ?? null}
          onChange={(e) => onChange(e?.toDate() ?? null)}
          error={!!error?.message}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default FormDateInput;
