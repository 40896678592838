import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  Button,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import { round } from 'lodash';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CardHeader from 'components/CardHeader';
import InvestmentDetails, {
  EditableInvestmentDetails,
} from './InvestmentDetails';
import CurrentShareholdings from './CurrentShareholdings';
import ExitedShareholdings from './ExitedShareholdings';
import CoreFees from './CoreFees';
import FutureFees from './FutureFees';
import PaymentsReconciliationTable from './PaymentsReconciliationTable';
import Dividend from './Dividend';
import InterestPayments from './InterestPayments';
import InvestmentBalanceAndValue from './InvestmentBalanceAndValue';
import { useDisclosure } from 'further-ui/hooks';
import { useInvestment } from 'hooks/data/investment/useInvestments';
import FeesRecalculationWarningModal from './FeesRecalculationWarningModal';
import UpdatedCommitmentErrorModal from './UpdatedCommitmentErrorModal';
import NegativeBalanceErrorModal from './NegativeBalanceErrorModal';
import { usePayments } from 'hooks/data/investment/usePayments';
import { createAddInvestmentTransferRoute } from 'constants/routes';
import useInvestmentDetails from 'hooks/data/investment/useInvestmentDetails';
import { Tooltip } from 'components/Tooltip';
import Notes from 'components/Notes';
import { NoteRelation } from 'further-types/notes';
import { Api } from 'further-types/investment';
import { OverallPerformanceTabs } from './OverallPerformanceTabs';

const useStyles = makeStyles(() => ({
  gridMargin: {
    margin: '1rem 0',
  },
  gridMarginLarge: {
    margin: '2rem 0',
  },
  divider: {
    marginBottom: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  noMargin: {
    margin: 0,
  },
  paymentsReconciliationLoader: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabTitle: {
    fontSize: 14,
    marginBottom: 0,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Subscriptions', link: '/investment' },
  { label: 'Update Subscription', link: '/', isActive: true },
];

export type Shareholding = Api.GetInvestmentShareholdingsResponse[number] & {
  isTransferred?: boolean;
};

export type Exits = Api.GetInvestmentExitsResponse['exits'];
export type InvestmentBalance =
  Api.GetInvestmentDetailsResponse['investmentBalance'];

const UpdateInvestment = ({ history }) => {
  // misc hooks
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  // disclosures
  const feesRecalculationWarningDisclosure = useDisclosure();
  const updatedCommitmentErrorDisclosure = useDisclosure();
  const negativeBalanceErrorDisclosure = useDisclosure();
  // state hooks
  const [editableDetails, setEditableDetails] =
    useState<EditableInvestmentDetails>({});
  const [initialTotalCommitment, setInitialTotalCommitment] = useState(0);
  //data hooks
  const { fetch, update } = useInvestment(
    id,
    negativeBalanceErrorDisclosure.onOpen,
  );
  const fetchPayments = usePayments(id);
  const { getFees, getExits, getShareholdings } = useInvestmentDetails();
  const { data: fees } = getFees(id);
  const { data: exitResponse } = getExits(id);
  const { data: currentShareholdings } = getShareholdings(id);

  const exits: Exits = exitResponse?.exits ?? [];

  const combinedShareholdings = useMemo(() => {
    return (
      currentShareholdings?.reduce((combined, shareholding) => {
        return [
          ...combined,
          ...(shareholding.sharesCurrentlyHeld ? [shareholding] : []),
          ...shareholding.transferredShareholdings
            .filter(({ sharesCurrentlyHeld }) => !!sharesCurrentlyHeld)
            .map((transferredShareholding) => ({
              ...transferredShareholding,
              isTransferred: true,
            })),
        ];
      }, []) ?? []
    );
  }, [currentShareholdings]);

  const allowLeaveFeesUnchangedOption =
    editableDetails.totalCommitment >=
    fetch.data?.investmentBalance.deploymentSummary.cashFeesOnInitialInvestment;

  useEffect(
    function fetchInvestment() {
      if (fetch.data) {
        setEditableDetails({
          adviser: fetch.data.adviser,
          adviserFee: fetch.data.adviserFee ?? 0,
          totalCommitment: fetch.data.totalCommitment ?? 0,
          externalInvestmentId: fetch.data.externalInvestmentId ?? '',
        });

        setInitialTotalCommitment(fetch.data.totalCommitment);
      }
    },
    [id, fetch.data],
  );

  const investmentDetails = fetch.data;
  const { investmentBalance, interest, dividends } = investmentDetails || {};
  const { deploymentSummary } = investmentBalance || {};

  const handleInvestmentUpdate = async ({
    recalculateFees,
  }: {
    recalculateFees?: boolean;
  }) => {
    feesRecalculationWarningDisclosure.onClose();

    const values: Api.UpdateInvestmentRequest = {
      adviserId: editableDetails.adviser?._id || null,
      externalInvestmentId: editableDetails.externalInvestmentId ?? '',
      adviserFee: editableDetails.adviserFee ?? 0,
    };

    if (initialTotalCommitment !== editableDetails.totalCommitment) {
      values.totalCommitment = editableDetails.totalCommitment;
      values.recalculateFees = recalculateFees;
    }

    update.mutate(values);
  };

  const handleSave = async () => {
    const hasTotalCommitmentError =
      round(editableDetails.totalCommitment + editableDetails.adviserFee, 2) <
      deploymentSummary.fundsReceived;

    if (hasTotalCommitmentError) {
      updatedCommitmentErrorDisclosure.onOpen();
      return;
    }

    if (editableDetails.totalCommitment !== initialTotalCommitment) {
      feesRecalculationWarningDisclosure.onOpen();
    } else {
      void handleInvestmentUpdate({});
    }
  };

  if (fetch.isLoading) return <CircularProgress />;
  if (!fetch.data || !fetch.isSuccess) return <div>Subscription not found</div>;

  const isTransferDisabled = !!deploymentSummary?.heldByFundAwaitingInvestment;
  const transferDisabledTooltipText =
    "To transfer any holdings from this subscription, please first freeze the subscription and withdraw all this subscription's uninvested capital.";

  const onTransfer = () =>
    isTransferDisabled
      ? null
      : history.push(
          createAddInvestmentTransferRoute(fetch.data?.investor._id ?? ''),
        );

  return (
    <>
      <PageContainer heading="Update Subscription" breadcrumbs={breadcrumbs}>
        <FeesRecalculationWarningModal
          disclosure={feesRecalculationWarningDisclosure}
          onConfirm={handleInvestmentUpdate}
          allowLeaveFeesUnchangedOption={allowLeaveFeesUnchangedOption}
        />
        <UpdatedCommitmentErrorModal
          disclosure={updatedCommitmentErrorDisclosure}
        />
        <NegativeBalanceErrorModal
          disclosure={negativeBalanceErrorDisclosure}
        />
        <CmtCard>
          <CmtCardContent>
            <InvestmentDetails
              shareholdings={currentShareholdings ?? []}
              investmentDetails={investmentDetails}
              paymentExpectations={fetchPayments.data ?? []}
              editableDetails={editableDetails}
              setEditableDetails={setEditableDetails}
              fees={fees?.fees ?? []}
            />

            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={update.isLoading}
              >
                Update
              </Button>
              {update.isLoading && (
                <CircularProgress style={{ marginLeft: '1rem' }} size={15} />
              )}
            </div>

            <Divider className={classes.divider} />
            <GridContainer>
              <Grid item xs={12}>
                <OverallPerformanceTabs
                  investmentBalance={investmentBalance}
                  combinedShareholdings={combinedShareholdings}
                  exits={exits}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.divider}>
                  <Grid item xs={12} sm>
                    <CardHeader
                      title="Current shareholdings"
                      className={classes.noMargin}
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Tooltip
                      title={
                        isTransferDisabled ? transferDisabledTooltipText : ''
                      }
                    >
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={onTransfer}
                          disabled={isTransferDisabled}
                        >
                          Transfer holdings
                        </Button>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>

                <CurrentShareholdings
                  combinedShareholdings={combinedShareholdings ?? []}
                  transferDate={fetch.data?.transferDate}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridMargin}>
                <CardHeader title="Exited shareholdings" />
                <ExitedShareholdings exitedShareholdings={exits} />
              </Grid>

              <Grid item xs={12}>
                <CardHeader title="Dividends" />
                <Dividend
                  dividends={dividends}
                  firmName={investmentDetails.firm.firmName}
                  fundName={investmentDetails.fund.fundName}
                />
              </Grid>

              <Grid item xs={12}>
                <InterestPayments interestPayments={interest} />
              </Grid>

              <Grid item xs={12}>
                <CardHeader title="Initial and recurring fees" />
                <CoreFees fees={fees?.fees ?? []} />
              </Grid>
              <Grid item xs={12}>
                <FutureFees fees={fees?.futureFees ?? []} />
              </Grid>

              <Grid item xs={12}>
                <InvestmentBalanceAndValue
                  investmentBalance={investmentBalance}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <CardHeader title="Funds received" />
                {!fetchPayments.isLoading ? (
                  <PaymentsReconciliationTable
                    investmentId={investmentDetails._id}
                    firmId={investmentDetails.firm._id}
                    payments={fetchPayments.data ?? []}
                  />
                ) : (
                  <div className={classes.paymentsReconciliationLoader}>
                    <CircularProgress size={24} />
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Notes relationId={id} noteRelation={NoteRelation.Investment} />
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </>
  );
};

export default UpdateInvestment;
