import { FC } from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { usePagination } from 'hooks/ui/usePagination';
import useOutstandingPayments from 'hooks/data/paymentExpectation/useOutstandingPayments';
import useFilters from 'hooks/ui/useFilters';
import PaymentExpectationRow from './PaymentExpectationRow';
import BulkPaymentAssignmentFilters from './Filters';
import GridContainer from 'components/GridContainer';

const useStyles = makeStyles((theme) => ({
  noPaymentsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7em 0',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
  },
  noPaymentsText: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Bulk payment assignment', isActive: true },
];

const BulkPaymentAssignment: FC = () => {
  const classes = useStyles();
  const pagination = usePagination({
    id: 'bulk-payment-assignment',
  });
  const { filters, handleChangeFilters } = useFilters(
    'bulk-payment-assignment',
    {},
  );
  const { data, isLoading } = useOutstandingPayments({
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    ...filters,
  });

  return (
    <PageContainer heading="Bulk payment assignment" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <BulkPaymentAssignmentFilters
                filters={filters}
                onFiltersChange={handleChangeFilters}
              />
              {isLoading && <CircularProgress />}
              {(data?.payments ?? []).map((payment) => (
                <PaymentExpectationRow key={payment._id} payment={payment} />
              ))}
              {!isLoading && data?.payments?.length === 0 && (
                <div className={classes.noPaymentsContainer}>
                  <Typography className={classes.noPaymentsText}>
                    No outstanding payments found
                  </Typography>
                </div>
              )}
              <TablePagination
                color="primary"
                component="div"
                count={data?.total ?? 0}
                page={pagination.page === 0 ? 0 : pagination.page - 1}
                onPageChange={pagination.handleChangePage}
                rowsPerPage={pagination.rowsPerPage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
              />
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default BulkPaymentAssignment;
