import { FC } from 'react';
import { AttachFile, Comment } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

type Props = {
  notes?: string;
  attachment?: string;
  attachmentSignedGetUrl?: string;
};

const NotesAndAttachmentColumn: FC<Props> = ({
  notes,
  attachment,
  attachmentSignedGetUrl,
}) => {
  const classes = useStyles();

  if (!notes && !attachment) {
    return <span>-</span>;
  }

  return (
    <div className={classes.flex}>
      {notes && (
        <Tooltip title={notes}>
          <IconButton color="primary">
            <Comment style={{ fontSize: 22 }} />
          </IconButton>
        </Tooltip>
      )}
      {attachmentSignedGetUrl && (
        <a
          href={attachmentSignedGetUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <IconButton color="primary">
            <AttachFile style={{ fontSize: 22 }} />
          </IconButton>
        </a>
      )}
    </div>
  );
};

export default NotesAndAttachmentColumn;
