import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { Button, Typography, makeStyles } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import Autocomplete from 'components/FormElements/AppAutocomplete';

import { ORGANISATION } from 'constants/routes';
import FieldRow from 'components/FormElements/FieldRow';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useOrganisations } from 'hooks/data/organisation/useOrganisations';
import { FirmSelector } from 'components/FirmSelector';
import {
  useFetchOrganisation,
  useMutateOrganisation,
} from 'hooks/data/organisation/useOrganisation';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  accOwnerTitle: {
    color: '#656565',
    margin: '1rem 0 0.5rem 0',
    fontSize: 20,
  },
  accOwnerSubtitle: {
    color: '#656565',
    fontSize: 14,
  },
}));

const AddUpdateOrganisation = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const { firmId, isSuperAdmin } = useGetRole();
  const [editPermission] = useGetPermissions(['edit:organisations']);

  const inputDisabled = !editPermission && id;

  const parentOrganisations = useOrganisations({
    params: { isParent: true, firmId },
  });
  const { create, update } = useMutateOrganisation(
    () => {
      history.push(ORGANISATION);
    },
    { id },
  );
  const fetch = useFetchOrganisation({ id });

  const [organisationData, setOrganisationData] = useState({
    adviserDropdown: '',
    parentOrganisation: {
      parentId: null,
      name: null,
    },
    name: '',
    fcaNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    firmId: firmId || null,
    type: 'parent',
  });
  const [errorText, setErrorText] = useState({});
  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Organisation', link: '/organisation' },
    {
      label: id ? 'Update Organisation' : 'Add Organisation',
      link: '/',
      isActive: true,
    },
  ];
  const record = [
    { key: 1, name: 'Adviser organisation' },
    { key: 2, name: 'Adviser parent organisation' },
  ];

  const validate = () => {
    const errors = {};
    let isError = false;
    if (!id && !organisationData?.firmId) {
      errors.firmId = 'Please select firm.';
      isError = true;
    }
    if (!organisationData?.adviserDropdown) {
      errors.adviserDropdown = 'Please select organisation type.';
      isError = true;
    }
    if (!organisationData?.name) {
      errors.name = 'Please enter organisation name.';
      isError = true;
    }
    if (!organisationData?.fcaNumber) {
      errors.fcaNumber = 'Please enter FCA registration number.';
      isError = true;
    } else if (
      !new RegExp(/^[a-zA-Z0-9]+$/i).test(organisationData?.fcaNumber)
    ) {
      errors.fcaNumber = 'An FCA number can only contain letters or numbers.';
      isError = true;
    }
    if (!id && !organisationData?.email) {
      errors.email = 'Please enter user email.';
      isError = true;
    } else if (
      !id &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
        organisationData?.email,
      )
    ) {
      errors.email = 'Invalid email.';
      isError = true;
    }
    if (!id && !organisationData?.firstName) {
      errors.firstName = 'Please enter account owner first name.';
      isError = true;
    }
    if (!id && !organisationData?.lastName) {
      errors.lastName = 'Please enter account owner surname.';
      isError = true;
    }
    setErrorText(errors);

    return {
      errors,
      isError,
    };
  };

  const onSubmit = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      let { parentOrganisation, ...rest } = organisationData;
      let value = { ...rest };
      if (organisationData?.type === 'child') {
        value.parentId = parentOrganisation?.parentId || null;
      }
      if (!id) {
        create.mutate(value);
      } else {
        value.id = id;
        update.mutate(value);
      }
    }
  };

  useEffect(() => {
    if (fetch.data?.organisation && parentOrganisations.isFetched) {
      let { name, parentId, fcaNumber, type } = fetch.data?.organisation || {};
      let value = {
        name,
        parentId,
        fcaNumber,
        adviserDropdown: type === 'child' ? 1 : 2,
        type,
      };
      if (type === 'child') {
        let findExistingOrganisation =
          parentOrganisations.data?.organisation?.find(
            (org) => parentId?._id === org?._id,
          );
        value = {
          ...value,
          parentOrganisation: {
            name: findExistingOrganisation?.name,
            parentId: findExistingOrganisation?._id,
          },
        };
      }
      setOrganisationData(value);
    }
  }, [parentOrganisations.isFetched, fetch.data]);

  return (
    <React.Fragment>
      <PageContainer
        heading={
          id
            ? 'Organisation: Update organisation'
            : 'Organisation: Create organisation'
        }
        breadcrumbs={breadcrumbs}
      >
        <CmtCard>
          <CmtCardContent>
            <GridContainer item md={10}>
              {!id && isSuperAdmin && (
                <FieldRow title="Firm*" centerTitle>
                  <FirmSelector
                    firmId={organisationData?.firmId}
                    onChange={(firmId) =>
                      setOrganisationData({
                        ...organisationData,
                        firmId,
                      })
                    }
                    error={!!errorText?.firmId}
                    helperText={errorText?.firmId}
                    disabled={inputDisabled}
                    required
                  />
                </FieldRow>
              )}

              <FieldRow title="Organisation type*" centerTitle>
                <AppSelectBox
                  required
                  data={record}
                  valueKey="key"
                  name="adviserDropdown"
                  placeholder="Select organisation type"
                  value={organisationData?.adviserDropdown}
                  labelKey="name"
                  variant="outlined"
                  error={!!errorText?.adviserDropdown}
                  helperText={errorText?.adviserDropdown}
                  onChange={(event) => {
                    if (event.target.value === 1) {
                      parentOrganisations.refetch();
                    }
                    setOrganisationData({
                      ...organisationData,
                      adviserDropdown: event.target.value,
                      type: event.target.value === 1 ? 'child' : 'parent',
                    });
                  }}
                  disabled={inputDisabled}
                />
              </FieldRow>
              {(typeof organisationData?.adviserDropdown === 'string' ||
                organisationData?.adviserDropdown === 1) && (
                <FieldRow
                  title="Select parent organisation"
                  informationText="(if applicable)"
                  centerTitle
                >
                  <Autocomplete
                    id="parentId"
                    options={parentOrganisations.data?.organisation ?? []}
                    getOptionLabel={(option) => option.name ?? ''}
                    filterSelectedOptions
                    value={
                      organisationData?.parentOrganisation?.parentId
                        ? organisationData?.parentOrganisation
                        : null
                    }
                    onChange={(event, newValue) => {
                      setOrganisationData({
                        ...organisationData,
                        parentOrganisation: {
                          name: newValue?.name,
                          parentId: newValue?._id,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select parent organisation"
                      />
                    )}
                    disabled={inputDisabled}
                  />
                </FieldRow>
              )}
              <FieldRow
                title={
                  typeof organisationData?.adviserDropdown === 'string' ||
                  organisationData?.adviserDropdown === 1
                    ? 'Organisation name*'
                    : 'Parent organisation name*'
                }
                centerTitle
              >
                <TextField
                  required
                  name="name"
                  fullWidth
                  placeholder={
                    typeof organisationData?.adviserDropdown === 'string' ||
                    organisationData?.adviserDropdown === 1
                      ? 'Enter organisation name'
                      : 'Enter parent organisation name'
                  }
                  value={organisationData?.name}
                  error={!!errorText?.name}
                  helperText={errorText?.name}
                  onChange={(event) =>
                    setOrganisationData({
                      ...organisationData,
                      name: event.target.value,
                    })
                  }
                  disabled={inputDisabled}
                />
              </FieldRow>
              <FieldRow title="FCA firm reference number*" centerTitle>
                <TextField
                  required
                  name="fcaNumber"
                  placeholder="Enter FCA firm reference number"
                  fullWidth
                  value={organisationData?.fcaNumber}
                  error={!!errorText?.fcaNumber}
                  helperText={errorText?.fcaNumber}
                  onChange={(event) =>
                    setOrganisationData({
                      ...organisationData,
                      fcaNumber: event.target.value.toUpperCase(),
                    })
                  }
                  disabled={inputDisabled}
                />
              </FieldRow>
              {!id && (
                <>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h3" className={classes.accOwnerTitle}>
                      Account owner details
                    </Typography>
                    <p className={classes.accOwnerSubtitle}>
                      Additional users can be added on the organisation page
                      after the organisation has been created
                    </p>
                  </Grid>
                  <FieldRow title="Account owner email address*" centerTitle>
                    <TextField
                      required
                      name="email"
                      placeholder="Enter email"
                      fullWidth
                      value={organisationData?.email}
                      error={!!errorText?.email}
                      helperText={errorText?.email}
                      onChange={(event) =>
                        setOrganisationData({
                          ...organisationData,
                          email: event.target.value,
                        })
                      }
                      disabled={inputDisabled}
                    />
                  </FieldRow>
                  <FieldRow title="Account owner first name*" centerTitle>
                    <TextField
                      required
                      name="firstName"
                      placeholder="Enter first name"
                      fullWidth
                      value={organisationData?.firstName}
                      error={!!errorText?.firstName}
                      helperText={errorText?.firstName}
                      onChange={(event) =>
                        setOrganisationData({
                          ...organisationData,
                          firstName: event.target.value,
                        })
                      }
                      disabled={inputDisabled}
                    />
                  </FieldRow>
                  <FieldRow title="Account owner surname*" centerTitle>
                    <TextField
                      required
                      name="lastName"
                      placeholder="Enter surname"
                      fullWidth
                      value={organisationData?.lastName}
                      error={!!errorText?.lastName}
                      helperText={errorText?.lastName}
                      onChange={(event) =>
                        setOrganisationData({
                          ...organisationData,
                          lastName: event.target.value,
                        })
                      }
                      disabled={inputDisabled}
                    />
                  </FieldRow>
                </>
              )}
              <Grid item md={12} xs={12}>
                <div className={classes.buttonContainer}>
                  <Link to={ORGANISATION}>
                    <Button color="primary" variant="outlined">
                      Cancel
                    </Button>
                  </Link>
                  {!inputDisabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.marginLeftBtn}
                      onClick={onSubmit}
                      disabled={
                        inputDisabled || create.isLoading || update.isLoading
                      }
                    >
                      {id ? 'Update organisation' : 'Add organisation'}
                    </Button>
                  )}
                </div>
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </React.Fragment>
  );
};

export default AddUpdateOrganisation;
