import React, { useState, useCallback, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import {
  ALLOCATIONS_UPLOAD,
  UPLOAD_SHEET,
  createDeploymentUpdateAddRoute,
} from 'constants/routes';
import { NavLink } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { InfoOutlined } from '@material-ui/icons';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CardHeader from 'components/CardHeader';
import { Button, makeStyles, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { Tooltip } from 'components/Tooltip';
import CreateSheetForm from './CreateSheetForm';
import FullPageSuccess from 'components/FullPageSuccess';
import { numberToCurrencyString } from 'further-ui/utils';
import {
  uploadExcelFile,
  uploadFinalExcelFile,
} from '@redux/actions/Investment';
import ErrorsTable from './ErrorsTable';
import { createShareSplitAddRoute } from 'constants/routes';
import { exportToCustodian } from '@redux/actions/UploadShareHolding';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  createSheetForm: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    '& > .MuiGrid-item': {
      padding: '12px 0',
    },
  },
  note: {
    fontSize: 16,
    color: '#656565',
    marginBottom: theme.spacing(6),
  },
  textLink: {
    textDecoration: 'underline',
    color: theme.palette.text.rootColor,
  },
  uploadButton: {
    width: '135px',
    textTransform: 'none',
  },
  errorBackButton: {
    float: 'right',
    width: '135px',
    marginTop: '20px',
  },
  reviewLabel: {
    fontSize: '12px',
    color: '#656565',
  },
  reviewValue: {
    fontWeight: 'bold',
    color: '#656565',
    fontSize: '14px',
  },
  reviewConfirmButton: {
    width: '135px',
    float: 'right',
  },
  shareSplitWarning: {
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    marginBottom: theme.spacing(6),
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: '#b0b0b0',
    textUnderlineOffset: '2px',
    color: '#656565',

    '& svg': {
      marginLeft: 6,
    },
  },
  blockcontant: {
    display: 'block',
    marginTop: 10,
  },
}));

const AdduploadSheet = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();
  const handleApiRequest = useApiRequestHandler();

  const [flag, setFlag] = useState({
    uploadFlag: true,
    fillFlag: false,
    reviewFlag: false,
    successFlag: false,
    shareAllocationUploadId: '',
    currentSharePrice: 0,
    totalShareAllocate: 0,
    totalSubscriptionAmount: 0,
    totalTransactionFee: 0,
    investorsCount: 0,
    fullyDeployedTranches: [],
  });
  const [uploadErrors, setUploadErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleSubmitUpload = async (event) => {
    let formData = new FormData();
    formData.append('uploadExcelFile', event.target.files[0]);
    if (event.target.files && event.target.files.length) {
      await handleApiRequest(async () => {
        let response = await dispatch(uploadExcelFile(formData));
        if (response.status === 200) {
          if (response.data.data.error && response.data.data.error.length) {
            setUploadErrors(response.data.data.error);
          } else {
            let {
              shareUpload,
              currentSharePrice,
              totalShareAllocate,
              totalSubscriptionAmount,
              investorsCount,
              fullyDeployedTranches,
              totalTransactionFee,
            } = response.data.data;
            setFlag({
              ...flag,
              fillFlag: true,
              reviewFlag: true,
              shareAllocationUploadId: shareUpload._id,
              currentSharePrice: currentSharePrice,
              totalShareAllocate: totalShareAllocate,
              totalSubscriptionAmount: totalSubscriptionAmount,
              investorsCount,
              fullyDeployedTranches,
              totalTransactionFee,
            });
            setUploadErrors([]);
          }
        }
      });
    }
  };
  const uploadFinalSheet = async () => {
    setIsLoading(true);
    await handleApiRequest(async () => {
      let response = await dispatch(
        uploadFinalExcelFile(flag.shareAllocationUploadId),
      );
      // This is an escape hatch to invalidate the allocations cache
      // because this mutation still goes through redux, but the
      // fetch query goes through react-query
      queryClient.invalidateQueries({
        queryKey: ['allocations'],
      });
      if (response.status === 200) {
        setFlag({
          ...flag,
          fillFlag: false,
          reviewFlag: false,
          successFlag: true,
          selectedCompanyId: response?.data?.data?.companyId,
          currentSharePrice: 0,
          totalShareAllocate: 0,
          totalSubscriptionAmount: 0,
        });
      }
    });
    setIsLoading(false);
  };
  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  const createFormSubmitCallback = useCallback((formState) => {
    setFlag({
      ...flag,
      fillFlag: true,
      uploadFlag: false,
      selectedCompanyId: formState.selectedCompanyId,
    });
  }, []);
  const createFormSkipCallback = useCallback(() => {
    setFlag({ ...flag, fillFlag: true, uploadFlag: false });
  }, []);

  const downloadCustodianExport = (event) => {
    event.preventDefault();
    dispatch(exportToCustodian(flag?.shareAllocationUploadId, notification));
  };

  const bottomTextContent = () => {
    return (
      <i>
        If you don't send a deployment update now, you can do it later by
        navigating to the Allocations section and clicking the mail icon next to
        the relevant company.
        <span className={classes.blockcontant}>
          <NavLink
            style={{ color: '#56B26C' }}
            to={'#'}
            onClick={downloadCustodianExport}
          >
            Click here{' '}
          </NavLink>
          to download a custodian transfer statement relating to this
          allocation.
        </span>
      </i>
    );
  };
  return (
    <PageContainer heading="Allocations: Upload new shareholdings">
      {flag.successFlag ? (
        <FullPageSuccess
          title="Your shares have been allocated successfully"
          additionalText="Your investors will now be able to view this shareholding in their investor portals. Would you like to send a deployment update?"
          proceedText="Send deployment update"
          proceedLink={createDeploymentUpdateAddRoute(
            flag.shareAllocationUploadId,
            flag.selectedCompanyId?._id || flag.selectedCompanyId,
          )}
          bottomText={bottomTextContent()}
          secondaryText="Do this later"
          secondaryLink={UPLOAD_SHEET}
        />
      ) : (
        <CmtCard>
          <CmtCardContent>
            {uploadErrors.length > 0 && (
              <>
                <ErrorsTable errors={uploadErrors} />
                <Button
                  className={`${classes.errorBackButton}`}
                  component="label"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setUploadErrors([]);
                  }}
                >
                  Go back
                </Button>
              </>
            )}
            {uploadErrors.length === 0 && (
              <>
                <CardHeader title="1. Create an upload sheet" />
                <Grid item md={9} className={classes.createSheetForm}>
                  <Suspense fallback="Loading form...">
                    <CreateSheetForm
                      callback={createFormSubmitCallback}
                      skipCallback={createFormSkipCallback}
                      hideButtons={flag.fillFlag}
                    />
                  </Suspense>
                </Grid>
                <Grid className="mt-4 mb-8" item md={9}>
                  {flag.fillFlag && (
                    <>
                      <Divider className="mb-8 mt-8" />
                      <CardHeader
                        className="mt-4"
                        title="2. Upload your allocation sheet"
                      />
                      <div className={classes.note}>
                        Please check your download folder for your allocation
                        sheet. Please fill in the share quantities and upload
                        the sheet here. Form didn't download?{' '}
                        <a
                          className={classes.textLink}
                          href={ALLOCATIONS_UPLOAD}
                          title="Try again"
                          onClick={(e) => {
                            e.preventDefault();
                            setFlag({
                              ...flag,
                              fillFlag: false,
                              uploadFlag: true,
                            });
                          }}
                        >
                          Click here to try again.
                        </a>
                      </div>
                      <Tooltip
                        interactive
                        leaveDelay={250}
                        title={
                          <span>
                            If there has been a share split since the company's
                            last share price update, please process this in the
                            share split function for this company{' '}
                            {flag.selectedCompanyId ? (
                              <NavLink
                                style={{ color: '#56B26C' }}
                                target="_blank"
                                to={createShareSplitAddRoute(
                                  flag.selectedCompanyId,
                                )}
                              >
                                here
                              </NavLink>
                            ) : (
                              ''
                            )}{' '}
                            before uploading the new allocation.
                          </span>
                        }
                      >
                        <span className={classes.shareSplitWarning}>
                          Has a share split taken place for this company?
                          <InfoOutlined style={{ fontSize: 18 }} />
                        </span>
                      </Tooltip>
                      <Grid item md={12}>
                        <Button
                          variant="outlined"
                          component="label"
                          color="primary"
                          className={classes.uploadButton}
                        >
                          Upload
                          <input
                            disabled={flag.reviewFlag}
                            name="uploadExcelFile"
                            type="file"
                            hidden
                            onChange={handleSubmitUpload}
                            onClick={handleClick}
                          />
                        </Button>
                      </Grid>
                    </>
                  )}
                  {flag.reviewFlag && (
                    <>
                      <Divider className="mb-8 mt-8" />
                      <CardHeader title="3. Review and confirm the shareholding you're adding" />
                      {[
                        {
                          label: 'Current share price',
                          value: numberToCurrencyString(
                            flag.currentSharePrice,
                            {
                              unlimitedDp: true,
                            },
                          ),
                        },
                        {
                          label: 'Total shares allocated in this upload',
                          value: flag.totalShareAllocate?.toFixed(0),
                        },
                        {
                          label: 'Total subscription amount',
                          value: numberToCurrencyString(
                            flag.totalSubscriptionAmount,
                          ),
                        },
                        {
                          label: 'Number of investors',
                          value: flag.investorsCount,
                        },
                        {
                          label: 'Fully deployed tranches',
                          value:
                            flag.fullyDeployedTranches.length &&
                            flag.fullyDeployedTranches
                              .map(
                                (trancheIdName) => trancheIdName.split('::')[1],
                              )
                              .join(', '),
                        },
                        {
                          label: 'Total transaction fees (net)',
                          value: numberToCurrencyString(
                            flag.totalTransactionFee,
                          ),
                        },
                      ].map((item, index) => (
                        <GridContainer
                          className={index > 0 ? 'mt-2' : ''}
                          key={item.label}
                        >
                          <Grid item md={4} className={classes.reviewLabel}>
                            {item.label}
                          </Grid>
                          <Grid item md={8} className={classes.reviewValue}>
                            {item.value}
                          </Grid>
                        </GridContainer>
                      ))}
                      <GridContainer className="mt-2">
                        <Grid item md={12}>
                          <Button
                            className={classes.reviewConfirmButton}
                            variant="contained"
                            color="primary"
                            onClick={() => uploadFinalSheet()}
                            disabled={isLoading}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </GridContainer>
                    </>
                  )}
                </Grid>
              </>
            )}
          </CmtCardContent>
        </CmtCard>
      )}
    </PageContainer>
  );
};
export default AdduploadSheet;
