import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { Visibility, VisibilityOff } from '@material-ui/icons';

const AppPasswordInput = ({
  name,
  id,
  value,
  onChange,
  helperText,
  error,
  size,
  required,
  variant,
  className,
  label,
  labelWidth,
}) => {
  const [show, setShow] = useState(false);
  return (
    <FormControl
      size={size}
      required={required}
      fullWidth
      className={className}
      variant={variant}
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        name={name}
        id={id}
        type={show ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error || helperText !== ''}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
      />
      {error && (
        <FormHelperText error id="username-error">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AppPasswordInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  labelWidth: PropTypes.number,
};

AppPasswordInput.defaultProps = {
  type: 'password',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  variant: 'outlined',
};

export default AppPasswordInput;
