import React, { useState } from 'react';
import moment from 'moment';
import { IconButton, Grid, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Dialog from 'components/ConfirmDialog';
import { deleteDividend } from '@redux/actions/Company';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import { useDispatch } from 'react-redux';
import CardHeader from 'components/CardHeader';
import FieldRow from 'components/FormElements/FieldRow';
import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import { useNotification } from 'hooks/ui/useNotification';

const DividendRows = ({
  dividends,
  setDividends,
  errorTextDividends,
  classes,
}) => {
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [recordStagedForDeletion, setRecordStagedForDeletion] = useState();
  const dispatch = useDispatch();
  const { error, success } = useNotification();

  const handleInputDividendChange = (e, index, isDate) => {
    if (isDate) {
      const list = [...dividends];
      list[index]['date'] = e;
      setDividends(list);
    } else {
      const { name, value } = e;
      const list = [...dividends];
      list[index][name] = value;
      setDividends(list);
    }
  };

  const handleAddDividend = () => {
    setDividends([
      ...dividends,
      {
        amount: '',
        date: null,
      },
    ]);
  };

  const handleDelete = (rec, i) => {
    if (rec._id) {
      setRecordStagedForDeletion(rec._id);
      setIsConfirmDeleteDialogOpen(!isConfirmDeleteDialogOpen);
    } else {
      // support the ability to remove dividends from the list that haven't been saved yet
      dividends.splice(i, 1);
      setDividends([...dividends]);
    }
  };

  const handleConfirmDelete = () => {
    if (recordStagedForDeletion) {
      dispatch(deleteDividend(recordStagedForDeletion))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            const list = dividends.filter(
              (dividend) => dividend._id !== recordStagedForDeletion,
            );
            setDividends(list);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    }
    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <GridContainer className={classes.cmxc}>
      <CardHeader title="Dividend record" />
      {dividends &&
        dividends.map((dividend, i) => {
          return (
            <GridContainer key={i} className={classes.rowWrapper}>
              <FieldRow title={`Dividend per share ${i + 1}`} centerTitle>
                <GridContainer alignItems="center">
                  <Grid item md={5} xs={12}>
                    <NumberFormat
                      decimalScale={2}
                      onValueChange={({ value }) => {
                        handleInputDividendChange(
                          {
                            name: 'amount',
                            value,
                          },
                          i,
                        );
                      }}
                      required
                      name="amount"
                      placeholder="Enter amount"
                      disabled={dividend._id ? true : false}
                      value={dividend.amount}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      prefix="£"
                      customInput={AppTextInput}
                      error={errorTextDividends[i]?.amount}
                      helperText={errorTextDividends[i]?.amount}
                    />
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <KeyboardDatePicker
                      required
                      placeholder="Select date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="date"
                      value={dividend.date}
                      onChange={(e) => handleInputDividendChange(e, i, true)}
                      error={errorTextDividends[i]?.date}
                      helperText={errorTextDividends[i]?.date}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      maxDate={moment().format('YYYY-MM-DD')}
                      disabled={dividend._id ? true : false}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    {dividends.length && (
                      <IconButton
                        size="small"
                        className={classes.deleteButton}
                        onClick={() => handleDelete(dividend, i)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </GridContainer>
              </FieldRow>
            </GridContainer>
          );
        })}
      <Grid item xs={12} lg={12} className={classes.addRevalutionBtn}>
        <Button
          className={classes.marginLeftBtn}
          onClick={handleAddDividend}
          variant="text"
          color="primary"
        >
          Add dividend
        </Button>
      </Grid>

      <Dialog
        open={isConfirmDeleteDialogOpen}
        title={`Confirm delete`}
        content={'Are you sure you wish to delete this dividend?'}
        onClose={() => setIsConfirmDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </GridContainer>
  );
};

export default DividendRows;
