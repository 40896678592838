import { makeStyles } from '@material-ui/core/styles';

const paddingClasses = {};
const marginClasses = {};
for (let count = 1; count <= 8; count++) {
  paddingClasses['.pt-' + count] = { paddingTop: count * 4 + 'px !important' };
  paddingClasses['.pb-' + count] = {
    paddingBottom: count * 4 + 'px !important',
  };
  paddingClasses['.pl-' + count] = { paddingLeft: count * 4 + 'px !important' };
  paddingClasses['.pr-' + count] = {
    paddingRight: count * 4 + 'px !important',
  };
  marginClasses[`.ml-${count}`] = { marginLeft: `${count * 4}px !important` };
  marginClasses[`.mr-${count}`] = { marginRight: `${count * 4}px !important` };
  marginClasses[`.mt-${count}`] = { marginTop: `${count * 4}px !important` };
  marginClasses[`.mb-${count}`] = { marginBottom: `${count * 4}px !important` };
}

const globalStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    '.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable)':
      {
        minHeight: '100px',
      },
    '.ck.ck-editor__editable_inline': {
      border: '1px solid #c4c4c4 !important',
      borderTop: 'none !important',
    },
    '.ck .ck-placeholder:before, .ck.ck-placeholder:before': {
      color: '#bababa !important',
    },
    '.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)': {
      border: `1px solid #56b26c !important`,
    },
    '.ck-content.text-component p + p': {
      marginTop: theme.spacing(1),
    },
    '.ck-content.text-component p': {
      margin: 0,
    },
    '.ck-content p': {
      marginBottom: theme.spacing(1),
    },
    a: {
      textDecoration: 'none',
      '&:hover,&:focus': {
        textDecoration: 'none',
      },
      '&.disable-link': {
        pointerEvents: 'none',
        cursor: 'default',
        [theme.breakpoints.down('xs')]: {
          pointerEvents: 'inherit',
          cursor: 'pointer',
        },
      },
    },
    img: {
      maxWidth: '100%',
    },
    'ql-tooltip': {
      zIndex: '999',
    },
    '.pointer': {
      cursor: 'pointer',
    },
    '.rounded': {
      borderRadius: '50%',
    },
    '.Cmt-table-responsive': {
      minHeight: '.01%',
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 15,
        overflowY: 'hidden',
        border: `1px solid ${theme.palette.borderColor.main}`,
        '& > table': {
          marginBottom: 0,
          '& > thead > tr > th': {
            paddingTop: 8,
          },
          '& > thead > tr > th, > tbody > tr > th, > tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td':
            {
              whiteSpace: 'nowrap',
            },
        },
      },
    },

    '.Cmt-header': {
      '& img': {
        verticalAlign: 'middle',
      },
    },

    '.MuiFormHelperText-contained': {
      marginLeft: '0 !important',
    },

    '.MuiChip-label': {
      paddingTop: 1,
    },

    '.MuiButton-textPrimary': {
      color: '#56b26c!important',
    },

    '.date-range-picker-wrapper .MuiListItem-gutters': {
      padding: '6px 24px',
    },

    '.date-range-picker-wrapper .MuiList-padding': {
      paddingTop: 20,
    },

    '.MuiInputAdornment-positionEnd p': {
      bottom: '-22px',
      position: 'absolute',
      margin: 0,
    },

    '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      maxWidth: '75%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.MuiButton-containedPrimary': {
      backgroundColor: '#56b26c!important',
      padding: '6px 16px 3px!important',
      lineHeight: '29px!important',
      '&:hover': {
        backgroundColor: '#56b26c!important',
        opacity: '0.9',
      },
    },
    '.MuiButton-outlinedPrimary': {
      color: '#56b26c',
      border: '1px solid #56b26c',
      '&:hover': {
        borderColor: '#56b26c',
        backgroundColor: 'rgba(86, 178, 108, 0.06)',
      },
    },
    '.MuiRadio-colorPrimary.Mui-checked': {
      color: '#56b26c!important',
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: '#56b26c!important',
    },
    '.MuiPaginationItem-outlinedPrimary.Mui-selected': {
      color: '#56b26c!important',
      border: '1px solid rgb(86 178 108)!important',
      backgroundColor: 'rgb(86 178 108 / 6%)!important',
      paddingTop: '4px!important',
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#56b26c!important',
    },
    '.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#56b26c!important',
    },
    '.MuiPickersDatePickerRoot-toolbar': {
      alignItems: 'center',
    },
    '.MuiPickersBasePicker-pickerView': {
      maxWidth: 'none',
      minHeight: 'auto',
    },
    '.MuiPickersCalendar-transitionContainer': {
      minHeight: '200px',
    },
    '.MuiPickersToolbar-toolbar': {
      backgroundColor: '#56b26c',
    },
    'MuiAccordionSummary-content': {
      margin: '20px 0 !important',
    },
    '.MuiPickersDay-daySelected': {
      backgroundColor: '#56b26c',
      '&:hover': {
        backgroundColor: '#56b26c',
        opacity: '0.9',
      },
    },
    '.MuiDialogActions-root': {
      justifyContent: 'space-between',
    },
    '.MuiButton-root': {
      padding: '10px 16px 6px!important',
      lineHeight: '22px!important',
    },
    '.MuiCheckbox-colorPrimary': {
      paddingTop: '6px!important',
    },

    '.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
      paddingTop: '10.5px!important',
      paddingBottom: '10.5px!important',
    },

    '@media screen and (max-width: 1279.98px)': {
      '.Cmt-fixedHeaderLayout .Cmt-header': {
        background: '#f4f1ef',
        boxShadow: 'none',
      },
      '.Cmt-fixedHeaderLayout .Cmt-header-fixed': {
        background: '#fff',
      },
      '.Cmt-fixedHeaderLayout .Cmt-header > div': {
        justifyContent: 'center',
      },
      '.Cmt-toggle-menu': {
        position: 'absolute!important',
        left: '15px',
        top: '9px',
        '& svg': {
          color: '#000',
        },
      },
    },

    '@media screen and (max-width: 575.98px)': {
      bscBlock: {
        '& hoverContent': {
          transform: 'none !important',
        },
      },
    },
    '@media screen and (max-width: 359.98px)': {
      '.MuiGrid-spacing-xs-6 > .MuiGrid-item': {
        padding: '5px!important',
      },
      '.makeStyles-root-70': {
        padding: '24px 13px!important',
      },
      '.MuiOutlinedInput-inputMarginDense': {
        paddingTop: '18.5px!important',
        paddingBottom: '18.5px!important',
      },
      '.bt-cls': {
        margin: '10px 0!important',
      },
    },

    '@media screen and (min-width: 992.98px)': {
      '.MuiDialog-paperWidthSm': {
        minWidth: '400px',
      },
    },

    '.dropzone': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(10, 5),
      border: `2px dashed ${theme.palette.borderColor.main}`,
      borderRadius: 2,
      backgroundColor: theme.palette.background.default,
      outline: 'none',
      transition: 'border .24s ease-in-out',
      marginTop: '10px !important',
    },
    // notistack
    '.notistack-CollapseWrapper, .notistack-CollapseWrapper .notistack-Snackbar, .notistack-CollapseWrapper .notistack-Snackbar .notistack-MuiContent':
      {
        minWidth: 'auto',
      },
    '.text-component': {
      margin: 0,
      '& p': {
        margin: 0,
      },
      '& p + p': {
        marginTop: '1rem',
      },
    },
    ...paddingClasses,
    ...marginClasses,
  },
}));

export default globalStyles;
