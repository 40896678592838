import React from 'react';
import FieldRow from 'components/FormElements/FieldRow';
import GridContainer from 'components/GridContainer';
import CompanyCard from '../CompanyCard';

const Summary = ({ data, company }) => {
  return (
    <GridContainer item md={11}>
      <FieldRow title={'Subject line'} centerTitle>
        {data?.subject || '-'}
      </FieldRow>
      <FieldRow title="Email title text" centerTitle>
        {data?.title}
      </FieldRow>
      <FieldRow title="Email top paragraph" centerTitle>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.emailTopParagraph,
          }}
        />
      </FieldRow>
      <CompanyCard company={company} />
      <FieldRow title="Email bottom paragraph" centerTitle>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.emailBottomParagraph,
          }}
        />
      </FieldRow>
    </GridContainer>
  );
};

export default Summary;
