import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchTopAdvisers({ queryKey }) {
  const [, params] = queryKey;

  const queryParams = qs.stringify(params);

  const url = params.firmId
    ? `dashboard/top-advisers/?${queryParams}`
    : `dashboard/top-advisers`;

  const { data } = await api.get(url);

  return data.data;
}

function useTopAdvisers({ params }) {
  return useQuery({
    queryKey: ['dashboard-top-advisers', params],
    queryFn: fetchTopAdvisers,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useTopAdvisers;
