import { Button, Grid } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { AppDatePicker, AppSelectBox, FieldRow } from 'components/FormElements';
import CkEditor from 'components/CkEditor';
import { useInvestorBulletin } from 'hooks/data/investorBulletin/useInvestorBulletin';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useFirm } from 'hooks/data/firm/useFirm';
import { endOfDay } from 'date-fns';

const removeHTML = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || '';
};

const useStyles = makeStyles(() => ({
  introCopy: {
    color: '#656565',
    marginBottom: 20,
    marginTop: -10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 12,
    width: '100%',
    gap: 16,
  },
  characterCount: {
    fontSize: 12,
    marginTop: 4,
  },
  error: {
    color: '#E8453C',
  },
}));

const InvestorBulletin = () => {
  const classes = useStyles();

  const characterLimit = 125;
  const [expiryDate, setExpiryDate] = useState(null);
  const [text, setText] = useState('');
  const [characterCount, setCharacterCount] = useState(0);

  const { isSuperAdmin, firmId } = useGetRole();

  const [selectedFirm, setSelectedFirm] = useState(firmId);

  const { updateBulletin, bulletin } = useInvestorBulletin(selectedFirm);
  const disabled = updateBulletin.isLoading || bulletin.isLoading;

  const { firms } = useFirm({ params: {} });

  const { data } = bulletin;

  useEffect(() => {
    setText(data?.text || '');
    setExpiryDate(data?.expiryDate || null);
  }, [data]);

  const onClearAndSubmit = () => {
    setText('');
    setExpiryDate(null);
    onSubmit({ text: '', expiryDate: null });
  };

  const onSubmit = ({ text, expiryDate }) => {
    if (characterCount > characterLimit) {
      return;
    }
    const date = expiryDate ? endOfDay(new Date(expiryDate)) : null;

    updateBulletin.mutate({
      text,
      expiryDate: date,
      firmId: selectedFirm,
    });
  };

  return (
    <PageContainer heading={'Investor communications: Manage bulletin'}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <CardHeader title="Manage the investor portal bulletin" />
              <p className={classes.introCopy}>
                If a bulletin is added below, it shows on the investor portal
                until the expiry date. To remove it, please click “Clear and
                save”.
              </p>
              <GridContainer item md={11}>
                {isSuperAdmin && (
                  <FieldRow title="Firm">
                    <AppSelectBox
                      required
                      data={firms?.data?.result}
                      valueKey="_id"
                      labelKey="firmName"
                      value={selectedFirm}
                      variant="outlined"
                      onChange={(event) => setSelectedFirm(event.target.value)}
                    />
                  </FieldRow>
                )}
                {selectedFirm && (
                  <>
                    <FieldRow title="Bulletin text">
                      <CkEditor
                        placeholder={`Max ${characterLimit} characters. This text will display in a banner at the top of your investor portal.`}
                        onChange={(_, editor) => {
                          const data = editor.getData();
                          setCharacterCount(removeHTML(data).length);
                          setText(data);
                        }}
                        value={text}
                        disabled={false}
                        onBlur={(_, editor) => {
                          const data = editor.getData();
                          setText(data);
                        }}
                      />
                      <p
                        className={`${classes.characterCount} ${
                          characterCount > characterLimit && classes.error
                        }`}
                      >
                        Character count: {characterCount}{' '}
                        {characterCount > characterLimit &&
                          `(Character count must be ${characterLimit} or less)`}
                      </p>
                    </FieldRow>
                    <FieldRow title="Expiry date" centerTitle>
                      <AppDatePicker
                        placeholder="Select date"
                        name="exitDate"
                        value={expiryDate}
                        //@ts-ignore
                        onChange={(date) =>
                          setExpiryDate(
                            date ? date.toDate().toISOString() : null,
                          )
                        }
                        disableFuture={false}
                        clearable
                      />
                    </FieldRow>
                    <div className={classes.buttonContainer}>
                      <Button
                        onClick={onClearAndSubmit}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                      >
                        Clear and save
                      </Button>
                      <Button
                        onClick={() => onSubmit({ text, expiryDate })}
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                )}
              </GridContainer>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default InvestorBulletin;
