import { Divider, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { numberToCurrencyString, renderMultiple } from 'further-ui/utils';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import { Tooltip } from 'components/Tooltip';
import { INVESTMENT, ALL_FUNDS } from 'constants/routes';
import useAvailableTags from 'hooks/data/dashboards/useAvailableTags';
import useLifetimePerformanceSummary from 'hooks/data/dashboards/useLifetimePerfomanceSummary';
import { Skeleton } from '@material-ui/lab';
import AppSelectBox from 'components/FormElements/AppSelectBox';

const useStyles = makeStyles(() => ({
  boxContainer: {
    height: '100%',
    '@media (min-width: 600px)': {
      display: 'grid',
      gap: '20px',
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginBottom: '0',
    },
  },
  box: {
    background: '#fafafa',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '0 0 calc(50% - 10px)',
    '&:not(:last-of-type)': {
      marginBottom: '20px',
    },
    '@media (min-width: 600px)': {
      margin: '0 !important',
    },
  },
  boxTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  boxSubtitle: {
    fontSize: '14px',
    opacity: 0.6,
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    cursor: 'pointer',
    fontSize: '13px',
    margin: '-2px 0 0 5px',
  },
}));

const LifetimePerformanceSummary = ({ parentClasses, firmName, firmId }) => {
  const classes = useStyles();

  const [selectedFundType, setSelectedFundType] = useState([]);
  const [allFundsSelected, setAllFundsSelected] = useState(true);

  const availableTags = useAvailableTags({ firmId });
  const labels = availableTags.data ?? [];

  const { data, isFetching } = useLifetimePerformanceSummary({
    firmId,
    tagIds: labels
      .filter((label) => selectedFundType.includes(label.key))
      .map((label) => label.tagId),
  });

  useEffect(() => {
    setSelectedFundType(labels.map((label) => label.key));
  }, [labels]);

  const getFundsSelected = (fundTypeDropdownValue) => {
    if (fundTypeDropdownValue.includes('all')) {
      if (!allFundsSelected) {
        setAllFundsSelected(true);
        setSelectedFundType(labels.map((label) => label.key));
      } else {
        setAllFundsSelected(false);
        setSelectedFundType([]);
      }
    } else {
      setSelectedFundType(fundTypeDropdownValue);
      setAllFundsSelected(fundTypeDropdownValue.length === labels.length);
    }
  };

  return (
    <CmtCard flex>
      {/* @ts-ignore */}
      <CmtCardHeader
        title="Lifetime performance"
        subTitle={`As of ${new Date().toLocaleDateString('en-GB')}`}
      >
        <div style={{ width: '180px' }}>
          <AppSelectBox
            multiple
            fullWidth
            valueKey="key"
            labelKey="name"
            label="Fund type"
            variant="outlined"
            data={labels ?? [{ name: 'All funds', key: 'all' }]}
            value={selectedFundType}
            isCheckedAll={allFundsSelected}
            onChange={(event: any) => {
              getFundsSelected(event.target.value);
            }}
            renderValue={(selected: any) => {
              // avoids rendered value clash with label before data is fetched
              if (labels.length === 0) return '';
              if (allFundsSelected) {
                return 'All funds';
              } else if (selected.length === 1) {
                return labels.find((label) => label.key === selected[0])?.name;
              } else if (selected.length > 1) {
                return `${
                  labels.find((label) => label.key === selected[0])?.name
                } +${selected.length - 1}`;
              }
            }}
          />
        </div>
      </CmtCardHeader>

      <Divider className={parentClasses.divider} />
      <CmtCardContent>
        <div className={classes.boxContainer}>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                numberToCurrencyString(data?.totalInvestmentAmount || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>Total capital raised</div>
            <div className={parentClasses.linkContainer}>
              <Link to={INVESTMENT} className={parentClasses.link}>
                View all subscriptions
              </Link>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                numberToCurrencyString(data?.capitalAwaitingDeployment || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>
              Capital awaiting deployment
            </div>
            <div className={parentClasses.linkContainer}>
              <Link
                to={`${ALL_FUNDS}?fundStatus=closed-in-deployment`}
                className={parentClasses.link}
              >
                View undeployed tranches
              </Link>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                numberToCurrencyString(data?.lifetimeAssetTotalValue || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>
              Lifetime asset total value
              <Tooltip
                title={`This is the sum of the realised and unrealised returns across all ${
                  firmName ? `${firmName} ' ` : ''
                }subscriptions.`}
              >
                <InfoOutlined className={classes.tooltipIcon} />
              </Tooltip>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                renderMultiple(data?.TVPI || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>TVPI</div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                numberToCurrencyString(data?.currentValueAum || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>
              Current value AUM{' '}
              <Tooltip
                title={`This is your current unrealised AUM, calculated as current investable cash plus the live value of existing holdings.`}
              >
                <InfoOutlined className={classes.tooltipIcon} />
              </Tooltip>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                renderMultiple(data?.RVPI || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>RVPI</div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                numberToCurrencyString(data?.totalNetRealisedValue || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>
              Total net realised value
              <Tooltip
                title={
                  'This is the total value returned to investors from all exits, dividends, residual capital and interest across all subscriptions.'
                }
              >
                <InfoOutlined className={classes.tooltipIcon} />
              </Tooltip>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.boxTitle}>
              {isFetching ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                renderMultiple(data?.DPI || 0)
              )}
            </div>
            <div className={classes.boxSubtitle}>DPI</div>
          </div>
        </div>
      </CmtCardContent>
    </CmtCard>
  );
};

export default LifetimePerformanceSummary;
