import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  titleRoot: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}));

const ComingSoon = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <Box className={classes.root}>
      <Typography
        component="div"
        variant="h1"
        className={clsx(classes.titleRoot, 'title')}
      >
        {params.get('title')}
      </Typography>

      <Box fontSize={{ xs: 16, sm: 24 }} mt={4} color="grey.500">
        Coming Soon
      </Box>
    </Box>
  );
};

export default ComingSoon;
