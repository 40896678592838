import React, { isValidElement } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { CmtSubTitle, CmtTitle } from '../index';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  headRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 5,
  },
  headerContent: {
    flex: 1,
  },
  tooltipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: -2,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

/**
 * @return {null} or CmtContentHead Component
 */
function CmtContentHead({
  icon,
  avatar,
  title,
  titleProps,
  subTitle,
  subTitleProps,
  titleStyle,
  subTitleStyle,
  tooltipText,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.headRoot, 'Cmt-content-head')} {...restProps}>
      {avatar && isValidElement(avatar) ? (
        <div className={clsx(classes.avatar, 'Cmt-avatar')}>{avatar}</div>
      ) : (
        icon && <div className={clsx(classes.avatar, 'Cmt-avatar')}>{icon}</div>
      )}
      <div className={clsx(classes.headerContent, 'Cmt-header-content')}>
        <div className={classes.label}>
          {title && (
            <CmtTitle
              className={clsx(titleStyle, 'Cmt-title')}
              content={title}
              {...titleProps}
            />
          )}
          {tooltipText && (
            <Tooltip leaveDelay={250} interactive arrow title={tooltipText}>
              <InfoOutlined className={classes.tooltipIcon} />
            </Tooltip>
          )}
        </div>
        {subTitle && (
          <CmtSubTitle
            className={clsx(subTitleStyle, 'Cmt-sub-title')}
            content={subTitle}
            {...subTitleProps}
          />
        )}
      </div>
    </div>
  );
}

CmtContentHead.propTypes = {
  icon: PropTypes.element,
  avatar: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleProps: PropTypes.object,
  subTitleProps: PropTypes.object,
  titleStyle: PropTypes.string,
  subTitleStyle: PropTypes.string,
};

CmtContentHead.defaultProps = {
  icon: null,
  avatar: null,
  title: null,
  subTitle: null,
  titleStyle: null,
  subTitleStyle: null,
  titleProps: { variant: 'h4', component: 'div' },
  subTitleProps: { variant: 'subtitle2', component: 'p', gutterBottom: false },
};

export default React.memo(CmtContentHead);
