import { IconButton, Menu } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { uniqueId } from 'lodash';
import { useState } from 'react';

type Props = {
  children: React.ReactNode;
};

const Actions: React.FC<Props> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuId = uniqueId('actions-');
  const buttonId = uniqueId('actions-button-');

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id={buttonId}
        aria-controls={anchorEl ? menuId : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        onClick={handleClose}
        id={menuId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
};

export default Actions;
