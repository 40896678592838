import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import { WithdrawalStatus } from 'further-types/withdrawal';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { StatusToLabelMap } from 'constants/withdrawalProperties';
import ExportButton from './ExportButton';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Button } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import { useDisclosure } from 'further-ui/hooks';
import { pluralize } from 'utils/pluralize';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '1.5rem',
  },
  exportBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  custodianExport: {
    marginLeft: '10px',
  },
}));

type Props = {
  filters: any;
  handleChangeFilters: any;

  resultCount: number;
  hasExportError: boolean;
  isExportLoading: boolean;
  onExport: (sendToCustodian?: boolean) => void;
};

const FiltersAndActions: React.FC<Props> = ({
  filters,
  handleChangeFilters,
  resultCount,
  hasExportError,
  isExportLoading,
  onExport,
}) => {
  const { isSuperAdmin: downloadOnly } = useGetRole();
  const classes = useStyles();
  const exportDisclosure = useDisclosure();
  const disableCustodianExportButton =
    resultCount === 0 ||
    [WithdrawalStatus.Rejected, WithdrawalStatus.ExportedToCustodian].includes(
      filters.status,
    );

  const handleCustodianExport = () => {
    exportDisclosure.onClose();
    onExport(true);
  };

  return (
    <>
      <GridContainer className={classes.root}>
        <Grid item md={downloadOnly ? 5 : 4} xs={12}>
          <MuiDateRangePicker
            dateRange={{
              startDate: filters.startDate,
              endDate: filters.endDate,
            }}
            onDateRangeChange={(dates) => {
              handleChangeFilters({
                ...dates,
              });
            }}
            TextFieldProps={{ placeholder: 'Select date range' }}
          />
        </Grid>
        <Grid item md={downloadOnly ? 3 : 2} xs={12}>
          <AppSelectBox
            data={Object.values(WithdrawalStatus).map((status) => ({
              key: status,
              label: StatusToLabelMap[status],
            }))}
            valueKey="key"
            labelKey="label"
            value={filters.status}
            variant="outlined"
            onChange={(event) => {
              const { value } = event.target;
              handleChangeFilters({ status: value });
            }}
          />
        </Grid>
        <Grid
          item
          md={downloadOnly ? 4 : 6}
          xs={12}
          className={classes.exportBtn}
        >
          <ExportButton
            downloadOnly
            disabled={resultCount === 0}
            isLoading={isExportLoading}
            onExport={() => onExport(false)}
          />
          {!downloadOnly && (
            <Button
              disabled={disableCustodianExportButton}
              color="primary"
              variant="contained"
              onClick={exportDisclosure.onOpen}
              className={classes.custodianExport}
            >
              Accept all and export to custodian
            </Button>
          )}
        </Grid>
      </GridContainer>
      <AlertDialog
        open={exportDisclosure.isOpen}
        title={`Are you sure you wish to export ${pluralize(
          'this',
          resultCount,
          'these',
        )} ${resultCount} ${pluralize(
          'withdrawal',
          resultCount,
        )} to your custodian?`}
        content={
          <p>
            This cannot be undone. Upon exporting these withdrawal requests,
            they will be marked as transferred in the Further system. Exported
            files must be shared with your custodian for actioning.
          </p>
        }
        onClose={exportDisclosure.onClose}
        confirmBtnComponent={
          <ExportButton
            disabled={!resultCount}
            onExport={handleCustodianExport}
            hasError={hasExportError}
            isLoading={isExportLoading}
          />
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: false,
        }}
        cancelBtnProps={{
          color: 'primary',
          style: { minWidth: '120px' },
        }}
      />
    </>
  );
};

export default FiltersAndActions;
