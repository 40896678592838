import { FC, useMemo, useState } from 'react';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useInvestments } from 'hooks/data/investment/useInvestments';
import { uniq } from 'lodash';
import { useLaunchPortalAsDelegate } from 'hooks/data/delegatedAccess/useLaunchPortalAsDelegate';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

type Props = { investorId: string };

const LaunchPortalButton: FC<Props> = ({ investorId }) => {
  const { firmId } = useGetRole();
  const investments = useInvestments({ params: { investorId, firmId } });
  const { firms } = useFirm({ params: { firmId } });
  const launchPortalAsDelegate = useLaunchPortalAsDelegate({ investorId });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const firmIdsWithInvestments = useMemo(() => {
    if (!investments?.data?.result) return [];
    return uniq(
      investments?.data?.result?.map((investment) => investment.firmId),
    );
  }, [investments.data]);

  const firmsWithInvestments = useMemo(() => {
    if (!firms?.data?.result) return [];
    return firms?.data?.result?.filter((firm) =>
      firmIdsWithInvestments.includes(firm._id),
    );
  }, [firmIdsWithInvestments, firms.data]);

  if (!firmsWithInvestments?.length) return null;

  if (firmId && firmsWithInvestments.length === 1) {
    return (
      <Button
        color="primary"
        variant="contained"
        size="small"
        style={{ marginLeft: '10px' }}
        onClick={() =>
          launchPortalAsDelegate.mutate({
            firmId: firmsWithInvestments[0]._id,
            firmDomain: firmsWithInvestments[0].domain,
          })
        }
      >
        View investor portal
      </Button>
    );
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        size="small"
        style={{ marginLeft: '10px' }}
        endIcon={<ArrowDropDown />}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        View investor portal
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {firmsWithInvestments?.map((firm) => (
          <MenuItem
            key={firm._id}
            onClick={() =>
              launchPortalAsDelegate.mutate({
                firmId: firm._id,
                firmDomain: firm.domain,
              })
            }
          >
            {firm.firmName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LaunchPortalButton;
