import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';
import CkEditor from 'components/CkEditor';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ConfirmationEmailCustomisation({
  confirmationEmailCustomisation,
  handleCancel,
  handleClickConfirmationEmailCustomisation,
  id,
  handleInputChangeEmailCustomisation,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer item md={11}>
      <FieldRow title={`Customise "What to expect now"`} centerTitle>
        <CkEditor
          maxlength={220}
          onBlur={(_, editor) => {
            handleInputChangeEmailCustomisation(
              'whatToExpectNow',
              editor.getData(),
            );
          }}
          value={confirmationEmailCustomisation.whatToExpectNow}
        />
      </FieldRow>
      <FieldRow
        title="Add additional attachment"
        informationText="If you wish to add a PDF file to the confirmation email, please attach it here. Please note that the Offering document is automatically attached to this email."
      >
        <DropzoneComponent
          accept={{
            'application/pdf': ['.pdf'],
          }}
          onDropFile={(files) =>
            handleInputChangeEmailCustomisation('additionalAttachment', files)
          }
          files={
            confirmationEmailCustomisation?.additionalAttachment
              ? [
                  {
                    preview:
                      confirmationEmailCustomisation?.additionalAttachmentPreview,
                    fileName:
                      confirmationEmailCustomisation?.additionalAttachment,
                  },
                ]
              : []
          }
          onRemove={() =>
            handleInputChangeEmailCustomisation('additionalAttachmentRemove')
          }
        />
      </FieldRow>

      <FieldRow>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickConfirmationEmailCustomisation}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </FieldRow>
    </GridContainer>
  );
}
