import React from 'react';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles } from '@material-ui/core';
import { useGetRole } from 'hooks/ui/useGetRole';
import SingleFirmEmailToggle from './SingleFirmEmailToggle';
import MultiFirmEmailToggle from './MultiFirmEmailToggle';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
    marginBottom: 18,
  },
  label: {
    color: '#656565',
    fontSize: 12,
  },
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
}));

type Props = {
  investorDetail: {
    _id: string;
    requiresHardCopyReports?: boolean;
    firmSpecificSettings?: Array<{
      firmId: string;
      firmName: string;
      disableAllEmails: boolean;
    }>;
  };
};

const InvestorCommunication: React.FC<Props> = ({ investorDetail }) => {
  const classes = useStyles();
  const { isSuperAdmin, firmId } = useGetRole();

  return (
    <>
      <Typography variant="h2" className={classes.heading}>
        Investor communications
      </Typography>
      <Grid item xs={12} md={10}>
        <GridContainer>
          <Grid item xs={4}>
            <span className={classes.label}>System emails</span>
          </Grid>
          <Grid item xs={8}>
            <span className={classes.value}>
              {isSuperAdmin ? (
                <MultiFirmEmailToggle
                  investorId={investorDetail._id}
                  firmSpecificSettings={investorDetail.firmSpecificSettings}
                />
              ) : (
                <SingleFirmEmailToggle
                  investorId={investorDetail._id}
                  firmId={firmId}
                  emailsCurrentlyDisabled={
                    investorDetail.firmSpecificSettings?.[0]?.disableAllEmails
                  }
                />
              )}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Requires hard copy reports</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.requiresHardCopyReports ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
    </>
  );
};

export default InvestorCommunication;
