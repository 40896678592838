import React from 'react';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { numberToCurrencyString } from 'further-ui/utils';
import { Divider } from '@material-ui/core';
import Table from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';

export const InvestmentsByAdviserTable = ({ rows, classes }) => {
  const pagination = usePagination();

  const columns = [
    {
      label: 'Adviser',
      key: 'adviserName',
    },
    {
      label: 'Organisation name',
      key: 'organisationName',
    },
    {
      label: 'Subscriptions (#)',
      key: 'sumInvestments',
    },
    {
      label: 'Subscriptions (£)',
      key: 'sumInvestmentAmount',
      render: (elm) =>
        numberToCurrencyString(elm?.sumInvestmentAmount, {
          hidePenniesIfInteger: true,
        }),
    },
  ];

  return (
    <CmtCard>
      <CmtCardHeader title="Subscriptions by adviser"></CmtCardHeader>
      <Divider className={classes.divider} />
      <CmtCardContent className={classes.scrollableTable}>
        <Table
          columns={columns}
          tablebody={rows.sort(pagination.genericSorter)}
          pagination={false}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          emptyMessage="No advised subscriptions yet"
          variant="nohover"
        />
      </CmtCardContent>
    </CmtCard>
  );
};
