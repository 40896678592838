import { type } from 'constants/typeConstant';

export const getFeeAccountingOptions = ({
  isChargedToInvestorDisable,
  isAccruedDisable,
} = {}) => {
  return [
    {
      label: 'Charged to investor',
      value: type.feeAccounting.chargedToInvestor,
      disabled: isChargedToInvestorDisable,
    },
    {
      label: 'Accrued',
      value: type.feeAccounting.accrued,
      disabled: isAccruedDisable,
    },
  ];
};
