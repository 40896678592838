import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';

type Request = {
  gridData: unknown[];
  gridDropdownsData: unknown;
};

async function validateInvestorsInvestments(data: Request) {
  const { data: response } = await api.post(
    `investor/upload-investor-investment/validate`,
    data,
  );

  return response;
}

export function useUploadedInvestorValidation() {
  const notification = useNotification();
  const mutation = useMutation({
    mutationFn: validateInvestorsInvestments,
  });

  useEffect(() => {
    // @ts-ignore
    if (mutation?.error?.response?.data?.responseMsg) {
      // @ts-ignore
      notification.error(
        'There was an error while uploading the preview. Please try again.',
      );
    }
  }, [mutation.error]);

  return mutation;
}
