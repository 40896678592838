import { FC } from 'react';
import { makeStyles, IconButton, Button } from '@material-ui/core';
import { Email, Delete } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import {
  useListOrganisationUsers,
  useOrganisationUser,
} from 'hooks/data/organisation/useOrganisationUsers';
import useOrganisationPlatformInvites from 'hooks/data/organisation/useOrganisationPlatformInvites';
import Table from 'components/Table';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { NavLink } from 'react-router-dom';
import { createOrganisationAddUserRoute } from 'constants/routes';

type Props = {
  organisationId: string;
};

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    marginBottom: '1.5rem',
  },
  title: {
    color: '#656565',
    margin: '1.5rem 0',
  },
}));

const OrganisationUsers: FC<Props> = ({ organisationId }) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const { success } = useNotification();
  const [createPermission, deletePermission] = useGetPermissions([
    'create:organisation-users',
    'delete:organisation-users',
  ]);
  const deleteDisclosure = useDisclosure();

  const { data } = useListOrganisationUsers({ organisationId });
  const { resendInvite } = useOrganisationPlatformInvites({
    onResendInviteSuccess: () => {
      success('Organisation invitation sent successfully.');
    },
  });
  const { remove } = useOrganisationUser({ organisationId });

  const columns = [
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    {
      label: 'Actions',
      render: (row) => {
        return (
          <div>
            {isSuperAdmin ? (
              <Tooltip
                title={
                  'Resending organisation invites is only possible for firm admin users.'
                }
              >
                <span>
                  <Button disabled>
                    <Email />
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Resend invitation to this user.">
                <IconButton
                  color={'primary'}
                  disabled={resendInvite.isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    resendInvite.mutate(row._id);
                  }}
                >
                  <Email />
                </IconButton>
              </Tooltip>
            )}

            {deletePermission && (
              <IconButton
                title="Delete"
                onClick={() => deleteDisclosure.stageAction({ id: row._id })}
              >
                <Delete htmlColor="#E8453C" />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <h2 className={classes.title}>Organisation users</h2>
        {createPermission && (
          <NavLink to={createOrganisationAddUserRoute(organisationId)}>
            <Button color="primary" variant="outlined">
              Add user
            </Button>
          </NavLink>
        )}
      </div>
      <Table
        tablebody={data ?? []}
        columns={columns}
        emptyMessage="No organisation users found"
      />
      <AlertDialog
        open={deleteDisclosure.isOpen}
        title="Delete organisation user"
        content="Are you sure you want to delete this user?"
        onClose={deleteDisclosure.onClose}
        onConfirm={() => {
          remove.mutate(deleteDisclosure.actionProps.id);
          deleteDisclosure.onClose();
        }}
        btnLabels={{ confirm: 'Delete user', cancel: 'Cancel' }}
      />
    </div>
  );
};

export default OrganisationUsers;
