import { useState } from 'react';
import {
  Button,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { createInvestorEditRoute } from 'constants/routes';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { AccountType } from 'further-types/investor';
import { useInvestor } from 'hooks/data/investor/useInvestor';
import downloadFile from 'utils/downloadFile';
import { useGetRole } from 'hooks/ui/useGetRole';
import LaunchPortalButton from './LaunchPortalButton';
import { ArrowDropDown } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useStyles from './styles';
import IndividualDetails from './IndividualDetails';
import LegalEntityDetails from './LegalEntityDetails';

function InvestorDetails({ investorDetail, id }) {
  const classes = useStyles();
  const [canEdit] = useGetPermissions(['edit:investor']);
  const { isFirmManager } = useGetRole();

  const onExportInvestorStatement = (data, valuationDate) => {
    const dateSuffix = valuationDate
      ? format(new Date(valuationDate), 'dd MM yyyy')
      : format(new Date(), 'dd MM yyyy');

    downloadFile(
      data,
      `${investorDetail?.fullName} - ${dateSuffix}.${
        isFirmManager ? 'xlsx' : 'zip'
      }`,
    );
  };

  const { exportInvestorStatement, isExportInvestorStatementLoading } =
    useInvestor(id, {
      onExportInvestorStatement,
    });

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="h2" className={classes.heading}>
          Investor details
        </Typography>
        <div>
          {canEdit && investorDetail?.isEditAllow && (
            <Link to={createInvestorEditRoute(id)}>
              <Button size="small" variant="contained" color="primary">
                Edit investor
              </Button>
            </Link>
          )}
          {investorDetail?.investmentsCount ? (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                size="small"
                style={{ marginLeft: '10px' }}
                className={classes.download}
                endIcon={<ArrowDropDown />}
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                disabled={isExportInvestorStatementLoading}
              >
                {isExportInvestorStatementLoading ? (
                  <CircularProgress
                    className={classes.exportSpinner}
                    size={18}
                  />
                ) : null}
                Download investor statement
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    exportInvestorStatement(undefined);
                    setMenuAnchorEl(null);
                  }}
                  key="current-valuation"
                >
                  Download current valuation
                </MenuItem>
                <MenuItem
                  onClick={() => setIsDatePickerOpen(true)}
                  key="earlier-valuation"
                >
                  Download earlier valuation
                </MenuItem>
              </Menu>
              <KeyboardDatePicker
                placeholder="Select date"
                name="date"
                open={isDatePickerOpen}
                className={classes.hiddenDatePickerInput}
                onChange={(date) => {
                  exportInvestorStatement(date);
                }}
                onClose={() => {
                  setIsDatePickerOpen(false);
                  setMenuAnchorEl(null);
                }}
                value={undefined}
                autoOk={false}
                disableFuture
                okLabel="Download"
              />
            </>
          ) : null}
          <LaunchPortalButton investorId={id} />
        </div>
      </div>
      {investorDetail.accountType === AccountType.LegalEntity ? (
        <LegalEntityDetails investorDetail={investorDetail} />
      ) : (
        <IndividualDetails investorDetail={investorDetail} />
      )}
    </>
  );
}

export default InvestorDetails;
