import { useHistory } from 'react-router';
import { createInvestorViewRoute } from 'constants/routes';
import useInvestmentTransfer from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import FullPageSuccess from 'components/FullPageSuccess';

type SuccessMessageProps = {
  transferId: string;
  sourceInvestorId: string;
};

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  transferId,
  sourceInvestorId,
}) => {
  const history = useHistory();

  const { exportInvestmentTransfer } = useInvestmentTransfer();

  const handleDownloadTransfer = async () => {
    await exportInvestmentTransfer(transferId);
  };

  return (
    <FullPageSuccess
      title="Congratulations, you have successfully made your transfer"
      additionalText="You can now download a spreadsheet detailing the update to send to your custodian if you wish."
      proceedText="Download custodian transfer statement"
      secondaryText="Return to transferor investor page"
      bottomText="If you don't download a custodian transfer statement now, you can do
      it later by navigating to Investors > Transfer Record and clicking
      the download icon next to the relevant transfer."
      proceedCallback={handleDownloadTransfer}
      secondaryCallback={() =>
        history.push(createInvestorViewRoute(sourceInvestorId))
      }
    />
  );
};

export default SuccessMessage;
