import { SET_USER_INFORMATION, RESET_USER } from 'constants/actionTypes';

const INIT_STATE = {
  authUser: null,
  loadUser: false,
  user: null,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFORMATION: {
      return {
        ...state,
        user: action.payload,
        loadUser: true,
      };
    }
    case RESET_USER: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default Auth;
