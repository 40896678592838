import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  colorRed: {
    color: theme.palette.text.red,
  },
  colorGreen: {
    color: theme.palette.text.green,
  },
  switchCls: {
    margin: '-6px auto',
  },
  customAccordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: '11px 0 8px !important',
    },
  },
  expends: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'inherit',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  cmAccHeader: {
    padding: '0 20px',
    background: 'rgba(234,233,232,0.2)',
    color: '#282525',
    minHeight: '48px !important',
  },
  fundsAccHeader: {
    cursor: 'default !important',

    '& .MuiIconButton-edgeEnd': {
      margin: '12px 0 12px 12px',
      padding: 0,
    },
  },
  cmAccDetail: {
    padding: '25px 20px',
  },
  accordionHeader: {
    textAlign: 'center',
    letterSpacing: 0,
    fontWeight: 'bold',
    fontSize: 13,
    color: '#282525',
    display: 'flex',
    alignItems: 'center',
  },
  accordionHeaderFundName: {
    padding: '4px 0',
  },
  accordionToggleLabel: {
    letterSpacing: 0,
    fontWeight: 'bold',
    fontSize: 13,
    cursor: 'default',
  },
  progressIndication: {
    marginLeft: '2rem',
  },
  flexGap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridGap: '1rem',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}));

export default useStyles;
