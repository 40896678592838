import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gridGap: '1rem',
    },
  },
  dialogActionsRoot: {
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    padding: 0,
    color: theme.palette.grey[500],
    marginLeft: 'auto',
  },
}));

/**
 * @deprecated use AlertDialog instead
 */
const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  btnLabels,
  isDisabledConfirm,
  size,
  icon,
  cancelBtnProps,
  confirmBtnProps,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      className={classes.dialogRoot}
      maxWidth={size}
    >
      <DialogTitle
        id="confirm-dialog-title"
        className={classes.dialogTitleRoot}
      >
        {icon ? icon : null}
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          {...cancelBtnProps}
        >
          {btnLabels.cancel}
        </Button>
        {btnLabels.confirm ? (
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            disabled={isDisabledConfirm}
            {...confirmBtnProps}
          >
            {btnLabels.confirm}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  title: 'Confirm Delete',
  btnLabels: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  isDisabledConfirm: false,
};

export default React.memo(ConfirmDialog);
