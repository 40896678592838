import axios from 'axios';
import { ERROR_404 } from 'constants/routes';
import { history } from '@redux/store';

const baseURL = process.env.REACT_APP_API_URL;
const awsURL = process.env.REACT_APP_AWS_URL;

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  (axiosConfig) => {
    const token = localStorage.getItem('token');

    const { url } = axiosConfig;
    // don't add any additional headers if the url being called is not the further API.
    // e.g. it could be a call to the S3 bucket to fetch a file
    if (
      url.startsWith('http') &&
      !url.startsWith(baseURL) &&
      !url.startsWith(awsURL)
    )
      return axiosConfig;

    if (token) {
      axiosConfig.headers['Authorization'] = `Bearer ${token}`;
    }

    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      history.push(ERROR_404);
    }

    if (error?.response?.data?.isLogout) {
      const currentPath = `${history.location.pathname}${history.location.search}`;
      history.replace(`${currentPath}#l`);
    }

    return Promise.reject(error);
  },
);
