import { useState } from 'react';
import { INTEREST_PAYMENTS_ADD } from 'constants/routes';
import { numberToCurrencyString } from 'further-ui/utils';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import {
  Button,
  makeStyles,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Grid,
  Divider,
  TableHead,
  CircularProgress,
} from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import PageContainer from 'components/PageContainer';
import DownloadSheet from './CreateSheetForm';
import FullPageSuccess from 'components/FullPageSuccess';
import { uploadSpreadsheet } from 'services/api/interestPayment';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  downloadForm: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    '& > .MuiGrid-item': {
      padding: '12px 0',
    },
  },
  note: {
    fontSize: 16,
    color: '#656565',
    marginBottom: theme.spacing(6),
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  loadingNote: {
    fontStyle: 'italic',
    marginLeft: '1rem',
    color: '#656565',
  },
  textLink: {
    textDecoration: 'underline',
    color: theme.palette.text.rootColor,
  },
  uploadButton: {
    width: '135px',
    textTransform: 'none',
  },
}));

const STEPS = {
  DOWNLOAD_SHEET: 0,
  UPLOAD_SHEET: 1,
  UPLOAD_SUCCESS: 2,
};

const InterestPaymentCreate = () => {
  const classes = useStyles();
  const { error } = useNotification();

  const [state, setState] = useState({
    activeStep: STEPS.DOWNLOAD_SHEET,
    totalRowsIngested: 0,
    totalAmountIngested: 0,
    errors: [],
  });
  const [isUploading, setIsUploading] = useState(false);

  const handleUploadButtonClick = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  const handleUploadButtonChange = async (event) => {
    if (event.target.files && event.target.files.length) {
      // create a form data object and add the uploaded file
      const formData = new FormData();
      formData.append('uploadSpreadsheet', event.target.files[0]);

      try {
        // upload the file
        setIsUploading(true);
        const response = await uploadSpreadsheet(formData);
        setIsUploading(false);

        if (response.status === 200) {
          // if there are errors for the uploaded rows, add them to the state
          if (response.data.data.errors && response.data.data.errors.length) {
            setState({
              ...state,
              errors: response.data.data.errors,
            });
          } else {
            // if there are no errors, show the success page
            setState({
              ...state,
              activeStep: STEPS.UPLOAD_SUCCESS,
              totalRowsIngested: response.data.data.totalRowsIngested,
              totalAmountIngested: response.data.data.totalAmountIngested,
              errors: [],
            });
          }
        }
      } catch (err) {
        setIsUploading(false);
        error(err.response.data.responseMsg);
        return Promise.resolve(err);
      }
    }
  };

  return (
    <PageContainer heading={'Interest: Upload interest payments'}>
      {state.activeStep === STEPS.UPLOAD_SUCCESS ? (
        <FullPageSuccess
          title="Your interest payments have been successfully added"
          additionalText={`${numberToCurrencyString(
            state.totalAmountIngested,
          )} has been sent to ${
            state.totalRowsIngested
          } investors, and will now be visible within their investor cash balances.`}
          proceedText="Upload more interest payments"
          proceedLink={INTEREST_PAYMENTS_ADD}
          proceedCallback={() => {
            setState({ ...state, activeStep: STEPS.DOWNLOAD_SHEET });
          }}
        />
      ) : (
        <CmtCard>
          <CmtCardContent>
            {state.activeStep in [STEPS.DOWNLOAD_SHEET, STEPS.UPLOAD_SHEET] && (
              <>
                <CardHeader title="1. Create an upload sheet" />
                <Grid item md={9} className={classes.downloadForm}>
                  <DownloadSheet
                    callback={() => {
                      setState({ ...state, activeStep: STEPS.UPLOAD_SHEET });
                    }}
                    skipCallback={
                      state.activeStep === STEPS.DOWNLOAD_SHEET
                        ? () => {
                            setState({
                              ...state,
                              activeStep: STEPS.UPLOAD_SHEET,
                            });
                          }
                        : null
                    }
                  />
                </Grid>
              </>
            )}
            {state.activeStep === STEPS.UPLOAD_SHEET && (
              <Grid className="mt-4 mb-8" item md={9}>
                <Divider className="mb-8" />
                <CardHeader
                  className="mt-4"
                  title="2. Upload your interest sheet"
                />
                <div className={classes.note}>
                  Please check your download folder for your interest sheet.
                  Please fill in the interest amounts and upload the sheet here.
                  Form didn't download?{' '}
                  <a
                    className={classes.textLink}
                    href={INTEREST_PAYMENTS_ADD}
                    title="Try again"
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, activeStep: STEPS.DOWNLOAD_SHEET });
                    }}
                  >
                    Click here to try again.
                  </a>
                </div>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    disabled={isUploading}
                    className={classes.uploadButton}
                  >
                    Upload
                    <input
                      disabled={
                        state.activeStep === STEPS.UPLOAD_SUCCESS || isUploading
                      }
                      name="uploadSpreadsheet"
                      type="file"
                      hidden
                      onChange={handleUploadButtonChange}
                      onClick={handleUploadButtonClick}
                    />
                  </Button>
                  {isUploading && (
                    <div className={classes.loading}>
                      <CircularProgress size={18} />
                      <span className={classes.loadingNote}>
                        This may take a minute or two for large files.
                      </span>
                    </div>
                  )}
                </Grid>
                {state.errors && state.errors.length > 0 && (
                  <Grid item md={12} className="mt-8">
                    <CardHeader className="mt-4" title="Upload error..." />
                    <div className={classes.note}>
                      The following rows contain errors. Please correct them and
                      try again:
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <em>Row number</em>
                          </TableCell>
                          <TableCell>
                            <em>Error description</em>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.errors.map((single) => {
                          return (
                            <TableRow key={single.lineNo}>
                              <TableCell>{single.lineNo}</TableCell>
                              <TableCell>{single.message}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            )}
          </CmtCardContent>
        </CmtCard>
      )}
    </PageContainer>
  );
};

export default InterestPaymentCreate;
