import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Table from 'components/Table';
import { startCase } from 'lodash';
import {
  useCollectionHistoryEvents,
  useGetCollectionNames,
} from 'hooks/data/collectionHistory/useCollectionHistory';
import { usePagination } from 'hooks/ui/usePagination';
import { dateToLabel } from 'further-ui/utils';
import { FC } from 'react';
import { CollectionHistoryEventType } from 'further-types/collection-history';
import useFilters from 'hooks/ui/useFilters';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from '@material-ui/core';
import { MenuOpen, Subject } from '@material-ui/icons';
import { useDisclosure } from 'further-ui/hooks';
import { Tooltip } from 'components/Tooltip';
import Filters from './ChangeLogFilters';
import { Api } from 'further-types/collection-history';

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Change log', isActive: true },
];
const eventTypeToLabelMap: Record<CollectionHistoryEventType, string> = {
  [CollectionHistoryEventType.Edited]: 'Edited',
  [CollectionHistoryEventType.Created]: 'Created',
  [CollectionHistoryEventType.Deleted]: 'Deleted',
  [CollectionHistoryEventType.SoftDeleted]: 'Soft deleted',
  [CollectionHistoryEventType.Restored]: 'Restored',
};

type Filters = {
  collection?: string;
  eventType?: CollectionHistoryEventType;
  docId?: string;
};

const getChangedByLabel = (
  changedBy: Api.CollectionHistoryEventItem['changedBy'],
) => {
  if (!changedBy) return '-';
  const useRoleName = changedBy?.userType === 'adminUser';
  return useRoleName
    ? `${changedBy.email} (${changedBy.role?.name})`
    : `${changedBy.email} (${startCase(changedBy.userType)})`;
};

const ChangeLog: FC = () => {
  const additionalInfoDisclosure = useDisclosure();
  const pagination = usePagination({
    id: 'change-log',
  });
  const { filters, handleChangeFilters } = useFilters<Filters>('change-log', {
    onFiltersChange: pagination.toFirstPage,
  });
  const { data: collectionNames } = useGetCollectionNames();
  const { data: historyEvents, isFetching } = useCollectionHistoryEvents({
    collection: filters.collection,
    eventType: filters.eventType,
    docId: filters.docId,
    page: pagination.page,
    pageSize: pagination.rowsPerPage,
  });

  const columns = [
    {
      label: 'Date',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) =>
        dateToLabel(elm.date, null, true),
    },
    {
      label: 'ID',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) => (
        <>
          <p>{elm.docId.toString()}</p>
          {elm.secondaryId
            ? `(linked with ${elm.secondaryId?.toString()})`
            : ''}
        </>
      ),
    },
    {
      label: 'Event type',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) =>
        eventTypeToLabelMap[elm.eventType],
    },
    {
      ...((filters.eventType === CollectionHistoryEventType.Edited ||
        !filters.eventType) && {
        label: 'Updated fields',
        sort: false,
        render: (elm: Api.CollectionHistoryEventItem) =>
          Object.entries(elm.updatedFields ?? {}).map(([key, value]) => (
            <p key={key}>
              <strong>{startCase(key)}:</strong>{' '}
              {typeof value === 'string' ? value : JSON.stringify(value)}
            </p>
          )),
      }),
    },
    {
      label: 'Changed by',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) =>
        getChangedByLabel(elm.changedBy),
    },
    {
      label: 'Full document',
      sort: false,
      render: (elm: Api.CollectionHistoryEventItem) =>
        elm.eventType === CollectionHistoryEventType.Edited ? (
          <>
            <Tooltip arrow title="Before change">
              <IconButton
                onClick={() =>
                  additionalInfoDisclosure.stageAction({
                    content: elm.fullDocumentBeforeChange,
                  })
                }
              >
                <MenuOpen />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="After change">
              <IconButton
                onClick={() =>
                  additionalInfoDisclosure.stageAction({
                    content: elm.fullDocument,
                  })
                }
              >
                <Subject />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton
            onClick={() =>
              additionalInfoDisclosure.stageAction({
                content: elm.fullDocument,
              })
            }
          >
            <Subject />
          </IconButton>
        ),
    },
  ];

  return (
    <PageContainer heading="Change log" breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent>
          <Filters
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            collectionNames={collectionNames}
            eventTypeToLabelMap={eventTypeToLabelMap}
          />
          <Table
            columns={columns}
            tablebody={historyEvents?.results ?? []}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            pagination={true}
            count={historyEvents?.total ?? 0}
            loading={isFetching}
            variant="nohover"
          />
          <Dialog
            open={additionalInfoDisclosure.isOpen}
            onClose={additionalInfoDisclosure.onClose}
            aria-labelledby="additional-info-dialog"
            maxWidth={'xl'}
          >
            <DialogContent>
              <pre>
                {JSON.stringify(
                  additionalInfoDisclosure.actionProps?.content,
                  null,
                  2,
                )}
              </pre>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={additionalInfoDisclosure.onClose}
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default ChangeLog;
