import { GET_USER_LIST, RESET_USER } from 'constants/actionTypes';

const INIT_STATE = {
  userData: { result: [] },
  loading: true,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_LIST: {
      return { ...state, userData: action.payload, loading: false };
    }
    case RESET_USER: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default User;
