import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';

import AppTextInput from 'components/FormElements/AppTextInput';
import Table from 'components/Table';
import commonColumns from 'helpers/withdrawals/commonColumns';
import NumberFormat from 'react-number-format';
import { FileUploadButton } from 'components/FileUploadButton';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';
import { useFirm } from 'hooks/data/firm/useFirm';
import { calculateWithdrawalFee } from 'further-ui/utils';
import { InfoOutlined } from '@material-ui/icons';
import { useInvestor } from 'hooks/data/investor/useInvestor';

const useStyles = makeStyles(() => ({
  alignTop: {
    verticalAlign: 'top',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  dropzone: {
    minHeight: 0,
  },
}));

const InvestorWithdrawalForm = ({
  investorId,
  addInvestorToWithdrawalQueue,
  queuedWithdrawals,
  firmId,
}) => {
  const [investorDetail, setInvestorDetail] = useState();
  const [amountToWithdraw, setAmountToWithdraw] = useState('');
  const [withdrawalFee, setWithdrawalFee] = useState('');
  const [amountToWithdrawError, setAmountToWithdrawError] = useState('');
  const [withdrawalFeeError, setWithdrawalFeeError] = useState('');
  const [note, setNote] = useState('');
  const [attachment, setAttachment] = useState();
  const [attachmentUploadError, setAttachmentUploadError] = useState();
  const [attachmentUploading, setAttachmentUploading] = useState(false);
  const { uploadFile } = useFileUpload();
  const classes = useStyles();
  const { firm } = useFirm({
    params: { firmId },
  });

  const {
    fetch: { data, isFetching },
  } = useInvestor(investorId);

  useEffect(() => {
    resetForm();
  }, [investorId]);

  useEffect(() => {
    if (data) {
      setInvestorDetail(data);
    }
  }, [data]);

  const resetForm = () => {
    setAmountToWithdraw('');
    setWithdrawalFee('');
    setNote('');
    setAmountToWithdrawError('');
    setWithdrawalFeeError('');
    setInvestorDetail(undefined);
    setAttachment();
  };

  const validate = () => {
    const firmCashBalance = investorDetail.cashBalances?.find(
      (cb) => cb.firm._id === firmId,
    )?.cashBalance;

    if (!firmCashBalance || Number(firmCashBalance) <= 0) {
      setAmountToWithdrawError('Investor has nothing available to withdraw');
      return false;
    }
    if (!amountToWithdraw || Number(amountToWithdraw) <= 0) {
      setAmountToWithdrawError('Amount to withdraw must be greater than 0');
      return false;
    }
    if (Number(amountToWithdraw) > Number(firmCashBalance)) {
      setAmountToWithdrawError(
        'Amount to withdraw must be less than or equal to the available balance',
      );
      return false;
    }
    if (Number(amountToWithdraw) < Number(withdrawalFee)) {
      setWithdrawalFeeError(
        'Amount to withdraw must be greater than the withdrawal fee',
      );
      return false;
    }

    return true;
  };

  const onAttachmentUpload = async (file) => {
    if (!file?.name) return;

    try {
      setAttachmentUploading(true);

      const { filePath, url } = await uploadFile(
        file,
        UploadType.WithdrawalAttachments,
        firmId,
      );

      setAttachment({ key: filePath, signedGetUrl: url });
      setAttachmentUploading(false);
    } catch (err) {
      setAttachmentUploading(false);
      setAttachmentUploadError(
        "Something went wrong - the file couldn't be uploaded",
      );
    }
  };

  const onAttachmentDelete = () => {
    setAttachment();
    setAttachmentUploadError();
  };

  useEffect(() => {
    const { fee: defaultWithdrawalFee } = calculateWithdrawalFee(
      firm.data?.withdrawalFees,
      amountToWithdraw,
    );
    setWithdrawalFee(defaultWithdrawalFee);
  }, [firm.data?.withdrawalFees, amountToWithdraw]);

  const withdrawalTooltip =
    'Any adjusted withdrawal fee follows the same VAT treatment as the default withdrawal fee (set on your My Firm page). Please enter any adjusted fee figure inclusive of VAT.';

  const columns = [
    ...commonColumns(firmId),
    {
      label: 'Gross withdrawal amount',
      key: 'amountToWithdraw',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: () => (
        <NumberFormat
          decimalScale={2}
          onValueChange={(values) => {
            setAmountToWithdraw(values.value);
            setAmountToWithdrawError('');
          }}
          value={amountToWithdraw}
          prefix="£"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          customInput={AppTextInput}
          error={!!amountToWithdrawError}
          helperText={amountToWithdrawError}
        />
      ),
    },
    {
      label: (
        <Box display="flex" alignItems="center" gridGap="4px">
          <span>Withdrawal fee</span>
          <Tooltip title={withdrawalTooltip}>
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      key: 'withdrawalFee',
      sort: false,
      tdClassname: !!withdrawalFeeError && classes.alignTop,
      render: () => (
        <NumberFormat
          decimalScale={2}
          onValueChange={(values) => {
            setWithdrawalFee(values.value);
            setWithdrawalFeeError('');
          }}
          value={withdrawalFee}
          prefix="£"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          customInput={AppTextInput}
          error={!!withdrawalFeeError}
          helperText={withdrawalFeeError}
        />
      ),
    },
    {
      label: 'Notes',
      key: 'note',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: () => (
        <AppTextInput onChange={(e) => setNote(e.target.value)} value={note} />
      ),
    },
    {
      label: 'Attachment',
      key: 'attachment',
      sort: false,
      tdClassname: !!amountToWithdrawError && classes.alignTop,
      render: () => (
        <FileUploadButton
          onUpload={onAttachmentUpload}
          onDelete={onAttachmentDelete}
          uploadError={attachmentUploadError}
          isUploading={attachmentUploading}
        />
      ),
    },
    {
      label: '',
      key: 'actions',
      sort: false,
      render: () => (
        <Button
          color="primary"
          variant="contained"
          className={classes.nowrap}
          onClick={() => {
            if (!validate()) return;
            if (attachmentUploading) return;
            addInvestorToWithdrawalQueue(investorDetail, {
              amountToWithdraw,
              withdrawalFee,
              note,
              attachment,
            });
            resetForm();
          }}
        >
          Add request
        </Button>
      ),
    },
  ];

  return isFetching ? (
    <CircularProgress size={20} />
  ) : (
    <Table
      columns={columns.filter((col) => !col.hideCol)}
      tablebody={investorDetail ? [investorDetail] : []}
      emptyMessage={
        queuedWithdrawals.length > 0
          ? 'Select another investor from the list'
          : 'First select an investor from the list'
      }
      variant="nohover"
    />
  );
};

export default InvestorWithdrawalForm;
