import { FC } from 'react';
import { Link } from 'react-router-dom';
import GridContainer from 'components/GridContainer';
import {
  makeStyles,
  Divider,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import FieldRow from 'components/FormElements/FieldRow';
import { dateToLabel } from 'further-ui/utils';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import {
  createOrganisationEditRoute,
  createOrganisationViewRoute,
} from 'constants/routes';
import { Api } from 'further-types/organisation';
import { useDownloadOrganisationStatement } from 'hooks/data/organisation/useOrganisation';

const OrgTypeToLabelMap = {
  child: 'Organisation',
  parent: 'Parent organisation',
};

const useStyles = makeStyles(() => ({
  divider: {
    margin: '1.5rem 0',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginBottom: '1rem',
  },
}));

type Props = {
  organisation?: Api.Organisation;
};

const OrganisationDetails: FC<Props> = ({ organisation }) => {
  const [editPermission] = useGetPermissions(['edit:organisations']);
  const classes = useStyles();

  const { download: downloadOrganisationStatement, isDownloading } =
    useDownloadOrganisationStatement({
      id: organisation?._id,
    });

  return (
    <>
      <div className={classes.buttonRow}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => downloadOrganisationStatement()}
          disabled={isDownloading}
        >
          {isDownloading ? (
            <CircularProgress
              style={{ color: 'rgba(0, 0, 0, 0.26)', marginRight: 10 }}
              size={18}
            />
          ) : null}
          Download organisation statement
        </Button>

        {editPermission && (
          <Link to={createOrganisationEditRoute(organisation?._id)}>
            <Button variant="outlined" color="primary" size="small">
              Edit organisation details
            </Button>
          </Link>
        )}
      </div>
      <GridContainer>
        <Grid item md={8}>
          <GridContainer>
            <FieldRow title="Organisation name">{organisation?.name}</FieldRow>
            <FieldRow title="Organisation type">
              {OrgTypeToLabelMap[organisation?.type] || 'Organisation'}
            </FieldRow>
            <FieldRow title="FCA firm reference number">
              {organisation?.fcaNumber}
            </FieldRow>
            <FieldRow title="Date created">
              {dateToLabel(organisation?.createdAt)}
            </FieldRow>
            <Grid xs={12} item>
              <Divider className={classes.divider} />
            </Grid>
          </GridContainer>
        </Grid>

        {organisation?.parentId && (
          <>
            <Grid item md={8}>
              <GridContainer>
                <FieldRow title="Parent organisation" centerTitle>
                  <Link
                    to={createOrganisationViewRoute(organisation?.parentId._id)}
                  >
                    <Button color="primary" size="small">
                      {organisation?.parentId?.name}
                    </Button>
                  </Link>
                </FieldRow>
                <Grid xs={12} item>
                  <Divider className={classes.divider} />
                </Grid>
              </GridContainer>
            </Grid>

            <Grid item md={4}></Grid>
          </>
        )}
      </GridContainer>
    </>
  );
};

export default OrganisationDetails;
