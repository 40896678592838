import { Box, Button, TextField } from '@material-ui/core';
import useStyles from './styles';
import { useState } from 'react';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';
import { useGetRole } from 'hooks/ui/useGetRole';

type Props = {
  statusLabel: string;
  approveButtonText: string;
  showApproveButton: boolean;
  approvalNotes?: string;
  showDownloadResultsButton: boolean;
  handleApprove?: (notes: string) => void;
  handleDowonloadResults?: () => void;
  showNotesTooltip: boolean;
  handleSaveNotes?: (notes: string) => void;
};

const KycResult: React.FC<Props> = ({
  approveButtonText,
  handleApprove,
  handleDowonloadResults,
  showApproveButton,
  showDownloadResultsButton,
  statusLabel,
  approvalNotes,
  showNotesTooltip,
  handleSaveNotes,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();

  const [notes, setNotes] = useState(approvalNotes);
  const [showNotesField, setShowNotesField] = useState(showApproveButton);

  const showEditNotesButton =
    isSuperAdmin && showNotesTooltip && !showNotesField;
  const showSaveNotesButton =
    isSuperAdmin && showNotesTooltip && showNotesField;

  return (
    <Box display="flex" gridGap="16px" alignItems="center">
      <span className={classes.value}>
        {statusLabel}{' '}
        {showNotesTooltip && (
          <Tooltip
            placement="top"
            title={`Approval note: ${
              approvalNotes?.length ? approvalNotes : 'none'
            }`}
          >
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        )}
      </span>

      {showDownloadResultsButton && (
        <Button
          className={classes.reviewBtn}
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleDowonloadResults}
        >
          Download results
        </Button>
      )}
      {showEditNotesButton && (
        <Button
          className={classes.reviewBtn}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setShowNotesField(true)}
        >
          Edit note
        </Button>
      )}

      {showNotesField && (
        <div className={classes.notes}>
          <TextField
            value={notes}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ className: classes.notesInput }}
            placeholder="Enter approval note"
            onChange={(e) => setNotes(e.target.value)}
          />
          {showApproveButton && (
            <Button
              className={classes.reviewBtn}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleApprove?.(notes)}
            >
              {approveButtonText}
            </Button>
          )}
          {showSaveNotesButton && (
            <Button
              className={classes.reviewBtn}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleSaveNotes?.(notes)}
            >
              Save note
            </Button>
          )}
        </div>
      )}
    </Box>
  );
};

export default KycResult;
