import React, { Fragment } from 'react';
import GridContainer from 'components/GridContainer';
import { Button, IconButton, Grid, Divider } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  deleteButton: {
    color: '#E8453C',
    margin: '10px 0',
  },
  divider: {
    margin: '10px 0 30px',
  },
}));

export default function PressLink({
  errorPressLink,
  handleAddClickPressLink,
  handleCancel,
  handleClickPressLink,
  handleInputChangePressLink,
  handleRemoveClickPressLink,
  id,
  pressLink,
}) {
  const classes = useStyles();
  return (
    <GridContainer>
      <Grid item xs={12} md={11}>
        {pressLink.map((x, i) => {
          return (
            <Fragment key={i}>
              <GridContainer>
                <FieldRow title="Article title*" centerTitle>
                  <TextField
                    required
                    name="label"
                    placeholder="Enter the article title"
                    fullWidth
                    onChange={(e) => handleInputChangePressLink(e, i)}
                    value={x.label}
                    error={errorPressLink[i]?.label}
                    helperText={errorPressLink[i]?.label}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Link*" centerTitle>
                  <TextField
                    required
                    name="link"
                    placeholder="Enter the URL"
                    fullWidth
                    onChange={(e) => handleInputChangePressLink(e, i)}
                    value={x.link}
                    error={errorPressLink[i]?.link}
                    helperText={errorPressLink[i]?.link}
                    variant="outlined"
                  />
                </FieldRow>
                <FieldRow title="Publication*" centerTitle>
                  <TextField
                    required
                    name="publication"
                    placeholder="Enter the publication name"
                    fullWidth
                    onChange={(e) => handleInputChangePressLink(e, i)}
                    value={x.publication}
                    error={errorPressLink[i]?.publication}
                    helperText={errorPressLink[i]?.publication}
                    variant="outlined"
                  />
                </FieldRow>

                <FieldRow title="Date of article publication*" centerTitle>
                  <KeyboardDatePicker
                    name="date"
                    required
                    value={x.date}
                    onChange={(date) =>
                      handleInputChangePressLink(date, i, true)
                    }
                    error={errorPressLink[i]?.date}
                    helperText={errorPressLink[i]?.date}
                    placeholder="Please select date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FieldRow>
                {pressLink.length && (
                  <div className={classes.buttonContainer}>
                    <IconButton
                      size="small"
                      className={classes.deleteButton}
                      onClick={() => handleRemoveClickPressLink(i)}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </GridContainer>
              <Divider className={classes.divider} />
            </Fragment>
          );
        })}
        <Grid item xs={12}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleAddClickPressLink}
              variant="text"
              color="primary"
            >
              Add article
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.marginLeftBtn}
            color="primary"
            onClick={handleClickPressLink}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
