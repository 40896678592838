import React, { forwardRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  swatch: {
    height: 22,
    width: 22,
    padding: 2,
    borderRadius: '11px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    background: 'transparent',
    cursor: 'pointer',
    border: 0,
  },
  swatchColour: {
    background: ({ colour }) => colour ?? '#000',
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
  swatchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  popover: {
    position: 'absolute',
    marginTop: 10,
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  colourValue: {
    fontSize: 14,
    fontFamily: 'MaisonNeue',
    textTransform: 'uppercase',
    marginLeft: 8,
  },
}));

const ColourPicker = forwardRef(({ value, ...props }, ref) => {
  const [activeColour, setActiveColour] = useState(value);
  const [pickerShown, setPickerShown] = useState(false);
  const classes = useStyles({ colour: activeColour });

  const handleClick = () => {
    setPickerShown(!pickerShown);
  };

  const handleChange = (colour) => {
    setActiveColour(colour.hex);
    props.onChange(colour.hex);
  };

  const handleClose = () => {
    setPickerShown(false);
  };

  return (
    <>
      <div className={classes.swatchContainer}>
        <button className={classes.swatch} onClick={handleClick} type="button">
          <span className={classes.swatchColour} />
        </button>
        <span className={classes.colourValue}>{activeColour}</span>
      </div>
      {pickerShown ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handleClose} />
          <SketchPicker color={activeColour} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
});

export default ColourPicker;
