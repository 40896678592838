import { useDownload } from 'hooks/ui/useDownload';

export default function useDownloadKiFundCertificates() {
  const fileDownload = useDownload({
    filename: 'eis-5-certificates.zip',
  });

  return {
    download: (kiFundCertificateBatchId?: string) => {
      fileDownload.mutate(
        `ki-fund-certificate/${kiFundCertificateBatchId}/certificates`,
      );
    },
    isDownloading: fileDownload.isLoading,
  };
}
