import { useSelector } from 'react-redux';

export const useGetRole = () => {
  const { user } = useSelector(({ auth }) => auth);

  const roleSlug = user?.user?.roleId?.roleSlug;
  const isSuperAdmin = roleSlug === 'super-admin';

  const firmId = user?.user?.firmId?._id;
  const isFirmManager = roleSlug === 'firm-manager' || firmId;

  return {
    isSuperAdmin,
    isFirmManager,
    roleSlug,
    firmId,
  };
};
