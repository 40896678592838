import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appSidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '304px',
    height: '100%',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
    position: 'relative',
    zIndex: 1103,
  },
  appSidebarContent: {
    height: '100%',
    position: 'relative',
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.sidebar.bgColor,
    color: theme.palette.sidebar.textColor,
    overflow: 'hidden',
    boxShadow:
      '0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14)',
    '& > *': {
      position: 'relative',
    },
  },
  overlayRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  '@media screen and (max-width: 575.98px)': {
    cmSidebar: {
      width: '100%',
    },
  },
}));

export default useStyles;
