import { dateToLabel } from 'further-ui/utils';
import Table from 'components/Table';
import { useGetReportsForInvestor } from 'hooks/data/investorReporting/useGetReportsForInvestor';
import { DownloadPdfUpdateButton } from 'components/DownloadPdfUpdateButton';
import { usePagination } from 'hooks/ui/usePagination';

const InvestorUpdates = ({ investorId, investorDetail }) => {
  const pagination = usePagination();
  const columns = [
    {
      label: 'Date',
      key: 'submitDate',
      sort: false,
      render: (elm) => dateToLabel(elm?.submitDate),
    },
    {
      label: 'Firm',
      key: 'firmName',
      sort: false,
      render: (elm) => elm?.firmId?.firmName,
    },
    {
      label: 'Update title',
      key: 'managerOverViewTitle',
      sort: false,
    },
    {
      label: 'Download',
      key: 'download',
      sort: false,
      render: (elm) => (
        <DownloadPdfUpdateButton
          reportingId={elm._id}
          investorId={investorId}
          filename={`${investorDetail.fullName}-${elm.managerOverViewTitle}.pdf`}
        />
      ),
    },
  ];
  const reports = useGetReportsForInvestor({
    params: {
      investorId,
      page: pagination.page,
      perPage: pagination.rowsPerPage,
    },
  });

  return (
    <Table
      pagination={true}
      columns={columns}
      tablebody={reports?.data?.investorReports ?? []}
      variant="nohover"
      emptyMessage="This investor has not been included in any investor updates."
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      page={pagination.page}
      rowsPerPage={pagination.rowsPerPage}
      count={reports?.data?.total ?? 0}
    />
  );
};

export default InvestorUpdates;
