import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const AppNumberInput = ({
  min,
  max,
  decimals = 0,
  disabled,
  name,
  id,
  fullWidth,
  size,
  value,
  defaultValue = 0,
  onChange,
  helperText,
  variant,
  error,
  gbpStartAdornment,
  percentEndAdornment,
  className,
  placeholder = '',
  ...rest
}) => {
  const numberDisplayArgs = [
    'en-GB',
    decimals
      ? {
          maximumFractionDigits: decimals,
        }
      : {},
  ];

  const parseNumber = (value) => {
    let updatedValue;

    if (typeof value === 'string') {
      const strippedValue = value.replace(/[^0-9.]/g, '');
      updatedValue = parseFloat(strippedValue);
    } else {
      updatedValue = parseFloat(value);
    }

    if (!isNaN(updatedValue)) {
      return updatedValue.toFixed(decimals);
    }
  };

  const classes = useStyles();
  const [fieldValue, setFieldValue] = useState(
    parseNumber(value ?? defaultValue ?? ''),
  );
  const [displayValue, setDisplayValue] = useState(fieldValue);

  useEffect(() => {
    const newValue = value ?? defaultValue;
    if (newValue !== fieldValue) {
      setFieldValue(newValue);
      if (newValue === '-') {
        // allow a dash to indicate a blank value
        setDisplayValue(newValue);
      } else {
        setDisplayValue(
          Intl.NumberFormat(...numberDisplayArgs).format(newValue),
        );
      }
    }
  }, [value, defaultValue, setFieldValue]);

  const inputProps = {
    inputProps: {
      step: (10 ** decimals) ** -1,
      min,
      max,
      type: 'text',
      pattern: decimals ? '[0-9]+([,\\.][0-9]+)?' : '[0-9]',
    },
  };

  if (gbpStartAdornment) {
    inputProps.startAdornment = (
      <InputAdornment position="start">£</InputAdornment>
    );
  }
  if (percentEndAdornment) {
    inputProps.endAdornment = (
      <InputAdornment position="start">%</InputAdornment>
    );
  }

  return (
    <TextField
      {...rest}
      className={`${classes.input} ${className}`}
      type={'text'}
      name={name}
      placeholder={placeholder?.toString()}
      id={id ?? name}
      size={size}
      fullWidth={fullWidth}
      value={displayValue}
      variant={variant}
      onChange={({ target: { value } }) => {
        setFieldValue(parseNumber(value));
        setDisplayValue(value);
      }}
      error={error}
      helperText={helperText}
      disabled={disabled}
      InputProps={inputProps}
      onBlur={() => {
        const parsedValue = parseNumber(fieldValue);
        if (parsedValue) {
          if ((max || max === 0) && parsedValue > max) {
            onChange(max);
            setFieldValue(max);
            setDisplayValue(
              Intl.NumberFormat(...numberDisplayArgs).format(max),
            );
          } else if ((min || min === 0) && parsedValue < min) {
            onChange(min);
            setFieldValue(min);
            setDisplayValue(
              Intl.NumberFormat(...numberDisplayArgs).format(min),
            );
          } else {
            onChange(parsedValue);
            setFieldValue(parsedValue);
            setDisplayValue(
              Intl.NumberFormat(...numberDisplayArgs).format(parsedValue),
            );
          }
        } else if (!isNaN(Number(defaultValue))) {
          const newValue = parseNumber(Number(defaultValue));
          setFieldValue(newValue);
          setDisplayValue(
            Intl.NumberFormat(...numberDisplayArgs).format(newValue),
          );
          onChange(defaultValue);
        }
      }}
      onFocus={() => {
        setDisplayValue(value ?? defaultValue ?? '');
      }}
    />
  );
};

AppNumberInput.prototype = {
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  gbpStartAdornment: PropTypes.boolean,
  placeholder: PropTypes.string,
};

AppNumberInput.defaultProps = {
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  variant: 'outlined',
  defaultValue: 0,
  decimals: 0,
  placeholder: '',
};

export default AppNumberInput;
