import { makeStyles } from '@material-ui/core';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppTextInput from 'components/FormElements/AppTextInput';
import { FirmSelector } from 'components/FirmSelector';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Status } from 'further-types/eis-wizard';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    flex: 1,
    gridGap: '1rem',
    marginRight: '5rem',
  },
}));

const ListCertificateFilters = ({
  filters,
  search,
  setSearch,
  handleChangeFilters,
}) => {
  const { isSuperAdmin } = useGetRole();
  const classes = useStyles();

  const statusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Completed', value: Status.Complete },
    { label: 'Not Completed', value: Status.NotComplete },
    { label: 'N/A', value: Status.NA },
  ];

  return (
    <div className={classes.flex}>
      <AppTextInput
        placeholder="Search by company or share class"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <AppSelectBox
        label="Select status"
        value={filters.status}
        data={statusOptions}
        valueKey="value"
        labelKey="label"
        onChange={(event) =>
          handleChangeFilters({ status: event.target.value })
        }
      />
      {isSuperAdmin && (
        <FirmSelector
          firmId={filters.firmId}
          onChange={(firmId) => handleChangeFilters({ firmId })}
          placeholder="Select firm"
        />
      )}
    </div>
  );
};

export default ListCertificateFilters;
