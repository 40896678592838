import moment from 'moment';
import { startCase, lowerCase, take } from 'lodash';
import { Button } from '@material-ui/core';
import Table from 'components/Table';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import AlertDialog from 'components/AlertDialog';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import { investorReportingType } from 'constants/investorReporting';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDisclosure } from 'further-ui/hooks';

import { INVESTOR_REPORTING_ADD, ADD_CUSTOM_UPDATE } from 'constants/routes';
import { Link } from 'react-router-dom';

import ActionTools from './ActionTools';
import { usePagination } from 'hooks/ui/usePagination';
import { useInvestorReporting } from 'hooks/data/investorReporting/useInvestorReporting';
import { useFirm } from 'hooks/data/firm/useFirm';
import useFilters from 'hooks/ui/useFilters';
import useStyles from './styles';
import { ReactNode } from 'react';
import { useExitStatement } from 'hooks/data/exit';

const staticColumns = [
  {
    label: 'Update date',
    key: 'submitDate',
    sort: true,
    render: (elm) => <>{moment.utc(elm?.submitDate).format('DD/MM/YYYY')} </>,
  },
  {
    label: 'Update type',
    sort: true,
    key: 'updateType',
  },
  {
    label: 'Status',
    key: 'status',
    sort: true,
    render: (elm) => <div>{startCase(lowerCase(elm?.status))} </div>,
  },
  {
    label: 'Firm name',
    key: 'firmId',
    sort: false,
    render: (elm) => <>{elm?.firmId?.firmName} </>,
  },
  {
    label: 'Report name',
    key: 'reportName',
    sort: false,
    render: (elm) => {
      const title =
        elm.updateType === investorReportingType.DEPLOYMENT_UPDATE ||
        elm.updateType === investorReportingType.CUSTOM_UPDATE
          ? elm.subject
          : elm.managerOverViewTitle;
      return <>{title}</>;
    },
  },
  {
    label: 'Sent to',
    key: 'sendTo',
    sort: false,
    render: (elm) =>
      take(elm.sendTo, 2).map((record: ReactNode, index) => (
        <span key={index}>
          {record}
          {elm.sendTo.length !== 1 && index !== 1 ? ', ' : ''}
          {elm.sendTo.length > 2 && index === 1
            ? ` and ${elm.sendTo.length - 2} more.`
            : ''}
        </span>
      )),
  },
];

const InvestorReporting = () => {
  const classes = useStyles();
  const { firmId, isSuperAdmin } = useGetRole();
  const deleteDisclosure = useDisclosure();
  const [canCreate, canUpdate, canDelete] = useGetPermissions([
    'create:report',
    'edit:report',
    'delete:report',
  ]);

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    toFirstPage,
    handleRequestSort,
  } = usePagination({
    id: 'investor-reporting',
    orderBy: 'submitDate',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('investor-reporting', {
    defaultFilters: { startDate: null, endDate: null, firmId },
    onFiltersChange: toFirstPage,
  });

  const onDeleteSuccess = () => {
    deleteDisclosure.onClose();
  };

  const {
    fetchInvestorReports,
    deleteInvestorReport: { mutate: deleteReport, isLoading: isRemoving },
  } = useInvestorReporting({
    onDeleteSuccess,
  });

  const {
    deleteExitStatement: {
      mutate: deleteExitStatement,
      isLoading: isRemovingExitStatement,
    },
  } = useExitStatement({
    id: deleteDisclosure.actionProps?.id,
    onDeleteSuccess,
  });

  const {
    firms: { data: firms },
  } = useFirm({ params: {} });

  const { data, isLoading } = fetchInvestorReports({
    page,
    perPage: rowsPerPage,
    type: order,
    keyName: orderBy,
    firmId: filters?.firmId?._id,
    startDate: filters?.startDate,
    endDate: filters?.endDate,
  });

  return (
    <PageContainer
      heading="Investor Reporting"
      breadcrumbs={[
        { label: 'Dashboard', link: '/' },
        { label: 'Investor Reporting', isActive: true },
      ]}
    >
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer style={{ paddingBottom: 5 }}>
                {isSuperAdmin ? (
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      //@ts-ignore
                      id="firmId"
                      options={firms?.result ?? []}
                      getOptionLabel={(option) => option.firmName}
                      filterSelectedOptions
                      value={filters?.firmId}
                      onChange={(_, firm) => {
                        handleChangeFilters({ firmId: firm });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Search a firm"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={isSuperAdmin ? 3 : 4}>
                  <MuiDateRangePicker
                    dateRange={filters ?? null}
                    onDateRangeChange={(dateRange) => {
                      handleChangeFilters(dateRange);
                    }}
                    TextFieldProps={{ placeholder: 'Select date' }}
                  />
                </Grid>

                <Grid item xs={12} md={isSuperAdmin ? 6 : 8}>
                  {canCreate && (
                    <div className={classes.createButton}>
                      <div className={classes.marginRight}>
                        <Link to={INVESTOR_REPORTING_ADD}>
                          <Button color="primary" variant="contained">
                            Create periodic update
                          </Button>
                        </Link>
                      </div>
                      <div>
                        <Link to={ADD_CUSTOM_UPDATE}>
                          <Button color="primary" variant="contained">
                            Create custom update
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </Grid>
              </GridContainer>

              <div className={classes.tableMt}>
                <Table
                  columns={[
                    ...staticColumns,
                    {
                      label: 'Tools',
                      sort: false,
                      render: (elm) => (
                        <ActionTools
                          classes={classes}
                          investorReport={elm}
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          deleteDisclosure={deleteDisclosure}
                        />
                      ),
                    },
                  ]}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  tablebody={data?.investorReportings ?? []}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={data?.total ?? 0}
                  pagination={true}
                  loading={isLoading}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          onClose={deleteDisclosure.onClose}
          open={deleteDisclosure.isOpen}
          title={`Are you sure you want to delete this ${
            deleteDisclosure.actionProps?.isExitStatement
              ? 'exit statement'
              : 'investor report'
          }?`}
          content="This action cannot be undone."
          btnLabels={{
            confirm: `Delete ${
              deleteDisclosure.actionProps?.isExitStatement
                ? 'exit statement'
                : 'investor report'
            }`,
            cancel: 'Go back',
          }}
          cancelBtnProps={{ variant: 'contained', color: 'primary' }}
          confirmBtnProps={{
            variant: 'outlined',
            color: 'primary',
            disabled: isRemoving || isRemovingExitStatement,
          }}
          onConfirm={() =>
            deleteDisclosure.actionProps?.isExitStatement
              ? deleteExitStatement(deleteDisclosure.actionProps.id)
              : deleteReport(deleteDisclosure.actionProps.id)
          }
        />
      </GridContainer>
    </PageContainer>
  );
};
export default InvestorReporting;
