import { useEffect, useState } from 'react';

import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';

import { Button, makeStyles } from '@material-ui/core';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import Table from 'components/Table';
import PageContainer from 'components/PageContainer';
import { Delete, CloudDownload } from '@material-ui/icons';
import {
  list,
  spreadsheetDownloadByUploadId,
  deleteUploadById,
} from 'services/api/interestPayment';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import InterestPaymentFilter from './Filters';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import downloadFile from 'utils/downloadFile';
import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  customButton: {
    color: theme.palette.text.secondary,
    '&.delete': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  dFlex: {
    display: 'flex',
  },
  tableColumnCenter: {
    minWidth: 100,
    textAlign: 'center',
  },
  tableColumnActions: {
    minWidth: 'auto',
    width: 200,
  },
}));

const InterestPaymentList = () => {
  const classes = useStyles();
  const handleApiRequest = useApiRequestHandler();
  const pagination = usePagination({
    orderBy: 'createdAt',
    order: 'desc',
    id: 'interest-payment',
  });

  const { filters, handleChangeFilters } = useFilters('interest-payment', {
    defaultFilters: {
      uploadDateRange: { startDate: null, endDate: null },
      paymentDateRange: { startDate: null, endDate: null },
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const [interestPaymentUploads, setInterestPaymentUploads] = useState([]);
  const [
    interestPaymentUploadsTotalCount,
    setInterestPaymentUploadsTotalCount,
  ] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();

  const columns = [
    {
      label: 'Payment date',
      key: 'dates',
      render: (elm) =>
        elm?.dates?.length > 1 ? 'Various' : dateToLabel(elm?.dates?.[0]),
    },

    {
      label: 'Upload date',
      key: 'createdAt',
      render: (elm) => <>{dateToLabel(elm?.createdAt)}</>,
    },
    {
      label: 'Amount',
      key: 'totalInterest',
      className: classes.tableColumnCenter,
      render: (elm) => {
        const totalInterest = parseFloat(elm?.totalInterest)?.toFixed(2);
        return (
          <div className={classes.tableColumnCenter}>
            {numberToCurrencyString(Number(totalInterest))}
          </div>
        );
      },
    },
    {
      label: '# Investors',
      key: 'uniqueInvestors',
      sort: false,
      className: classes.tableColumnCenter,
      render: (elm) => (
        <div className={classes.tableColumnCenter}>{elm?.uniqueInvestors}</div>
      ),
    },
    {
      label: 'Actions',
      sort: false,
      key: '',
      className: `${classes.tableColumnCenter} ${classes.tableColumnActions}`,
      render: (elm) => (
        <div className={classes.tableColumnCenter}>
          <Button
            title="Delete"
            className={`classes.customButton ${classes.deleteButton}`}
            onClick={() => handleDeleteWithConfirm(elm.uploadId)}
          >
            <Delete htmlColor="#E8453C" />
          </Button>
          <Button
            title="Download"
            className={classes.customButton}
            color="primary"
            onClick={() => handleDownload(elm.uploadId)}
          >
            <CloudDownload />
          </Button>
        </div>
      ),
    },
  ];

  // data loader

  const loadData = async () => {
    setLoading(true);
    await handleApiRequest(async () => {
      const response = await list(
        pagination.page,
        pagination.rowsPerPage,
        pagination.order,
        pagination.orderBy,
        filters.uploadDateRange,
        filters.paymentDateRange,
      );

      if (response.status === 200) {
        setInterestPaymentUploads(response?.data?.data);
        setInterestPaymentUploadsTotalCount(response?.data?.totalCount);
      }
    });

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [
    pagination.page,
    pagination.rowsPerPage,
    pagination.order,
    pagination.orderBy,
    filters.uploadDateRange.startDate,
    filters.paymentDateRange.startDate,
  ]);

  // table row action handlers
  const handleDeleteWithConfirm = (uploadId) => {
    setDeleteRecordId(openConfirmDialog ? null : uploadId);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = async () => {
    await handleApiRequest(async () => {
      const response = await deleteUploadById(deleteRecordId);

      if (response.status === 200) {
        loadData();
      }
    }, 'Delete error');

    setOpenConfirmDialog(false);
  };

  const handleDownload = async (uploadId) => {
    await handleApiRequest(async () => {
      const response = await spreadsheetDownloadByUploadId(uploadId);

      if (response.status === 200) {
        downloadFile(response.data, `InterestPayments-${uploadId}.csv`);
      }
    }, 'No data found.');
  };

  return (
    <PageContainer heading="Interest: Uploads summary">
      <CmtCard>
        <CmtCardContent>
          <InterestPaymentFilter
            filters={filters}
            handleChangeFilters={handleChangeFilters}
          />
          <Table
            columns={columns}
            onRequestSort={pagination.handleRequestSort}
            order={pagination.order}
            orderBy={pagination.orderBy}
            tablebody={interestPaymentUploads}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={interestPaymentUploadsTotalCount}
            pagination={true}
            loading={loading}
            variant="nohover"
          />
        </CmtCardContent>
      </CmtCard>
      <AlertDialog
        open={openConfirmDialog}
        title="Confirm delete"
        content={
          'Are you sure you want to remove this upload and all its associated records?'
        }
        onClose={handleDeleteWithConfirm}
        onConfirm={handleConfirmDelete}
      />
    </PageContainer>
  );
};

export default InterestPaymentList;
