import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useEffect } from 'react';
import { useNotification } from 'hooks/ui/useNotification';

async function createDeploymentUpdate(requestBody) {
  try {
    const { data: response } = await api.post(
      `deployment-update/create/${requestBody?.shareAllocationUploadId}`,
      requestBody,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

function useDeploymentUpdateCreateAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDeploymentUpdate,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['deployment-updates'] }),
  });
}

export function useDeploymentUpdate(params) {
  const notification = useNotification();
  const create = useDeploymentUpdateCreateAPI(params);

  useEffect(() => {
    const errorMessage = create.error?.response?.data?.responseMsg;

    if (errorMessage) {
      notification.error(errorMessage);
    }
  }, [create.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    const successMessage = create.data?.responseMsg;

    if (successMessage) {
      notification.success(successMessage);
    }
  }, [create.data?.responseMsg, notification]);

  return {
    create,
  };
}
