import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import Switch from 'components/Switch';
import FieldRow from 'components/FormElements/FieldRow';
import CkEditor from 'components/CkEditor';
import { stripTags } from 'hooks/form/useInvestorReportingForm';

import useStyles from '../styles';
import { Controller } from 'react-hook-form';
import { useState } from 'react';

const ClosedFund = ({
  fund,
  managers,
  index,
  isViewPage,
  control,
  setValue,
}) => {
  const [provideCommentary, setProvideCommentary] = useState(
    fund.provideCommentary,
  );
  const [shortDescriptionCharacterCount, setShortDescriptionCharacterCount] =
    useState(0);
  const shortDescriptionCharacterLimit = 220;
  const shortDescriptionCharacterError =
    shortDescriptionCharacterCount > shortDescriptionCharacterLimit;

  const classes = useStyles();

  return (
    <GridContainer>
      <Grid item md={9} xs={12}>
        <GridContainer spacing={6}>
          <FieldRow key={`title-${index}`} title="Update title">
            <Controller
              name={`fundInformation[${index}][title]`}
              defaultValue={fund.title}
              control={control}
              render={({ field: { ref, ...rest }, formState: { errors } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  placeholder="e.g. Fund name - Q1 Quarterly Update"
                  fullWidth
                  error={!!errors?.fundInformation?.[index]?.title}
                  helperText={errors?.fundInformation?.[index]?.title?.message}
                  disabled={isViewPage}
                />
              )}
            />
          </FieldRow>
          <FieldRow
            title="Short description"
            informationText="This is a two line preview of the update that will show in each investor’s portal before they open the update."
          >
            <Controller
              name={`fundInformation[${index}][shortDescription]`}
              defaultValue={fund.shortDescription}
              control={control}
              render={({ field: { value, name }, formState: { errors } }) => (
                <>
                  <CkEditor
                    placeholder="A brief 30-35 word (220 character) preview of fund performance and what the update contains."
                    name={name}
                    maxlength={220}
                    onChange={(_, editor) => {
                      const value = editor.getData();

                      setShortDescriptionCharacterCount(
                        stripTags(value ?? '').length,
                      );

                      setValue(
                        `fundInformation[${index}][shortDescription]`,
                        editor.getData(),
                      );
                    }}
                    value={value}
                    disabled={isViewPage}
                  />
                  <p
                    className={classes.errorCk}
                    id={`fundInformation[${index}][shortDescription]`}
                  >
                    {
                      errors?.fundInformation?.[index]?.shortDescription
                        ?.message
                    }
                  </p>
                  {shortDescriptionCharacterCount > 0 && (
                    <p
                      className={`${classes.characterCount} ${
                        shortDescriptionCharacterError &&
                        classes.characterCountError
                      }`}
                    >
                      Character count: {shortDescriptionCharacterCount}{' '}
                      {shortDescriptionCharacterError &&
                        `(Character count must be ${shortDescriptionCharacterLimit} or less)`}
                    </p>
                  )}
                </>
              )}
            />
          </FieldRow>
          <FieldRow title="Provide commentary">
            <Controller
              name={`fundInformation[${index}][provideCommentary]`}
              defaultValue={!!fund.provideCommentary}
              control={control}
              render={({ field: { name, value } }) => (
                <Switch
                  name={name}
                  onChange={(event) => {
                    setValue(
                      `fundInformation[${index}][provideCommentary]`,
                      event.target.checked,
                    );
                    setProvideCommentary(event.target.checked);
                  }}
                  className={classes.switchCls}
                  checked={!!value}
                  disabled={isViewPage}
                />
              )}
            />
          </FieldRow>
          {provideCommentary ? (
            <>
              <FieldRow
                title="Fund manager commentary"
                informationText="This is your firm’s introduction, where you can provide background context to the update and discuss any other topics you feel relevant. It is attributed to the manager selected below."
              >
                <Controller
                  name={`fundInformation[${index}][commentary]`}
                  defaultValue={fund?.commentary}
                  control={control}
                  render={({
                    field: { value, name },
                    formState: { errors },
                  }) => (
                    <>
                      <CkEditor
                        placeholder="A multi-paragraph overview of progress since the last update."
                        name={name}
                        onChange={(_, editor) => {
                          setValue(
                            `fundInformation[${index}][commentary]`,
                            editor.getData(),
                          );
                        }}
                        value={value}
                        disabled={isViewPage}
                      />
                      <p
                        className={classes.errorCk}
                        id={`fundInformation[${index}][commentary]`}
                      >
                        {errors?.fundInformation?.[index]?.commentary?.message}
                      </p>
                    </>
                  )}
                />
              </FieldRow>
              <FieldRow title="Select manager">
                <Controller
                  name={`fundInformation[${index}][accountManagerId]`}
                  defaultValue={fund?.accountManagerId}
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    formState: { errors },
                  }) => (
                    <AppSelectBox
                      {...rest}
                      inputRef={ref}
                      data={managers}
                      valueKey="_id"
                      labelKey="name"
                      variant="outlined"
                      error={
                        !!errors?.fundInformation?.[index]?.accountManagerId
                      }
                      helperText={
                        errors?.fundInformation?.[index]?.accountManagerId
                          ?.message
                      }
                      disabled={isViewPage}
                    />
                  )}
                />
              </FieldRow>
              <FieldRow title="Manager's role">
                <Controller
                  name={`fundInformation[${index}][role]`}
                  defaultValue={fund?.role}
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    formState: { errors },
                  }) => (
                    <TextField
                      inputRef={ref}
                      {...rest}
                      placeholder="e.g. Partner, Fund name"
                      fullWidth
                      error={errors?.fundInformation?.[index]?.role}
                      helperText={
                        errors?.fundInformation?.[index]?.role?.message
                      }
                      disabled={isViewPage}
                    />
                  )}
                />
              </FieldRow>
            </>
          ) : null}
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default ClosedFund;
