import React from 'react';

import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import { sectors, stages } from 'constants/companyProperties';
import FieldRow from 'components/FormElements/FieldRow';
import CardHeader from 'components/CardHeader';
import { Grid } from '@material-ui/core';

const StageAndSector = ({ errorText, setValues, values }) => {
  return (
    <>
      <Grid item md={12}>
        <CardHeader title="Stage and sector" />
      </Grid>
      <FieldRow title="Stage*" centerTitle>
        <Autocomplete
          id="stageAutocomplete"
          options={['', ...Object.values(stages)]}
          filterSelectedOptions
          onChange={(_event, stage) => {
            setValues({ ...values, stage });
          }}
          value={values.stage}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name="stage"
              placeholder="Select stage"
              fullWidth
              error={errorText.stage}
              helperText={errorText.stage}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Sector*" centerTitle>
        <Autocomplete
          id="sectorAutocomplete"
          options={['', ...Object.values(sectors)]}
          filterSelectedOptions
          onChange={(_event, sector) => {
            setValues({ ...values, sector });
          }}
          value={values.sector}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name="sector"
              placeholder="Select sector"
              fullWidth
              error={errorText.sector}
              helperText={errorText.sector}
            />
          )}
        />
      </FieldRow>
    </>
  );
};

export default StageAndSector;
