import { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles({
  label: {
    padding: 0,
    margin: 0,
    fontSize: 13,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#656565',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
  informationText: {
    padding: 0,
    margin: '0 0 0 0',
    fontSize: 12,
    color: '#656565',
    width: '100%',
  },
  tooltipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: -2,
  },
  labelWrapper: {
    position: 'relative',
  },
  topTitle: {
    height: 35,
  },
});

type FormLabelProps = {
  title: string;
  informationText?: string | ReactNode;
  tooltipText?: string | number | ReactNode;
  topTitle?: boolean;
};

const FormLabel: FC<FormLabelProps> = ({
  title,
  informationText,
  tooltipText,
  topTitle, // topTitle prop aligns labels to match the top of the input fields (ck editor)
}) => {
  const classes = useStyles();

  const tooltip = tooltipText ? (
    <Tooltip leaveDelay={250} interactive arrow title={tooltipText}>
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  ) : null;

  return (
    <div className={classes.labelWrapper}>
      <label className={clsx(classes.label, topTitle && classes.topTitle)}>
        {title}
        {tooltip}
      </label>
      {informationText && (
        <span className={classes.informationText}>{informationText}</span>
      )}
    </div>
  );
};

export default FormLabel;
