import AlertDialog from 'components/AlertDialog';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  typography: {
    textAlign: 'center',
  },
  bottomText: {
    marginTop: 4,
  },
}));

interface NoBankAccountWithdrawalModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const NoBankAccountWithdrawalModal = ({
  onConfirm,
  onCancel,
  isOpen,
}: NoBankAccountWithdrawalModalProps) => {
  const classes = useStyles();

  return (
    <AlertDialog
      open={isOpen}
      title={'No bank account for one or more investors'}
      content={
        <>
          <Typography className={classes.typography}>
            <div>
              One or more of these investors has empty or incomplete bank
              details on their profile. Proceeding will result in a withdrawal
              file with missing bank details. Bank details can be edited by the
              investor on their “My account” page.
            </div>
          </Typography>
        </>
      }
      onConfirm={onConfirm}
      onClose={onCancel}
      btnLabels={{
        confirm: 'Proceed',
        cancel: 'Go back',
      }}
    />
  );
};

export default NoBankAccountWithdrawalModal;
