import React from 'react';
import { InfoOutlined } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  shareSplitWarning: {
    marginTop: 4,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: '#b0b0b0',
    textUnderlineOffset: '2px',
    color: '#656565',

    '& svg': {
      marginTop: -2,
      marginLeft: 4,
      marginRight: 8,
    },
  },
}));
export const TooltipTriggerText = ({ content, icon }) => {
  const classes = useStyles();

  return (
    <span className={classes.shareSplitWarning}>
      {content} {icon ? <InfoOutlined style={{ fontSize: 18 }} /> : null}
    </span>
  );
};
