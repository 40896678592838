import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useGetRole } from 'hooks/ui/useGetRole';
import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import { numberToDisplayString } from 'utils/numbers';
import { numberToCurrencyString, getCompanyLabel } from 'further-ui/utils';
import SharePriceInfoTooltip from '../ExitDetailsForm/SharePriceInfoTooltip';
import { TaxReliefElegibleType } from 'further-types/exit';
import TaxReliefElegibleAllocations from '../ExitDetailsForm/TaxReliefElegibleAllocations';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    maxWidth: '20rem',
    gridGap: '1rem',

    '& > div': {
      width: '100%',
    },
  },
  alignButtonRight: {
    display: 'flex',
    maxWidth: '20rem',
    justifyContent: 'flex-end',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
  flex: {
    display: 'flex',
  },
}));

const EditExitDetailsForm = ({
  previousExitDetails,
  onSubmit,
  exitDetailsStepCompleted,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();

  const [exitDate, setExitDate] = useState(new Date());
  const [sharePrice, setSharePrice] = useState();
  const [taxableValueSharePrice, setTaxableValueSharePrice] = useState();
  const [chargeAccruedFees, setChargeAccruedFees] = useState();
  const [taxReliefElegible, setTaxReliefElegible] = useState();
  const [taxReliefElegibleAllocationIds, setTaxReliefElegibleAllocationIds] =
    useState([]);

  const [errors, setErrors] = useState({});

  const disableEdit = exitDetailsStepCompleted;

  useEffect(() => {
    if (previousExitDetails.exitDate) {
      setExitDate(previousExitDetails.exitDate);
    }
    if (previousExitDetails.sharePrice) {
      setSharePrice(previousExitDetails.sharePrice);
    }

    if (previousExitDetails.taxableValueSharePrice) {
      setTaxableValueSharePrice(previousExitDetails.taxableValueSharePrice);
    }

    if (previousExitDetails.taxReliefElegible) {
      setTaxReliefElegible(previousExitDetails.taxReliefElegible);
      setTaxReliefElegibleAllocationIds(
        previousExitDetails.taxReliefElegibleAllocationIds ?? [],
      );
    }
  }, [
    previousExitDetails.exitDate,
    previousExitDetails.sharePrice,
    previousExitDetails.taxableValueSharePrice,
  ]);

  const validate = () => {
    const errorsToSet = {};
    if (!exitDate) {
      errorsToSet.exitDate = 'Please select the date the exit happened';
    }
    if (!sharePrice || Number(sharePrice) < previousExitDetails.sharePrice) {
      errorsToSet.sharePrice = `The received value share price must be equal to or greater than the original received value share price of ${numberToCurrencyString(
        previousExitDetails.sharePrice,
      )}. To edit an exit to a lower overall share price, please delete the original exit and process it again.`;
    }
    if (chargeAccruedFees !== false && chargeAccruedFees !== true) {
      errorsToSet.chargeAccruedFees = `Please select an option`;
    }
    if (!taxableValueSharePrice && Number(taxableValueSharePrice) !== 0) {
      errorsToSet.taxableValueSharePrice =
        'Please enter a value for the taxable value share price';
    }
    if (Number(sharePrice) > Number(taxableValueSharePrice)) {
      errorsToSet.taxableValueSharePrice = `The taxable value share price must be equal to or greater than the received value share price.`;
    }
    if (!taxReliefElegible) {
      errorsToSet.taxReliefElegible = `Please select an option`;
    }
    if (
      taxReliefElegible === TaxReliefElegibleType.Partial &&
      !taxReliefElegibleAllocationIds?.length
    ) {
      errorsToSet.taxReliefElegibleAllocationIds = `Please select at least one allocation`;
    }

    setErrors(errorsToSet);

    // return false if there's any errors
    return !Object.keys(errorsToSet).length;
  };

  return (
    <GridContainer>
      {isSuperAdmin && (
        <FieldRow title="Choose firm">
          <div className={classes.row}>{previousExitDetails.firmName}</div>
        </FieldRow>
      )}
      <>
        <FieldRow title="Exit amendment date" centerTitle>
          <div className={classes.row}>
            <AppDatePicker
              placeholder="Select date of exit"
              name="exitDate"
              value={exitDate}
              onChange={setExitDate}
              disableFuture={true}
              helperText={errors.exitDate}
              error={!!errors.exitDate}
              disabled={disableEdit}
            />
          </div>
        </FieldRow>
        <FieldRow title="Choose company" centerTitle>
          <div className={classes.row}>
            {getCompanyLabel(previousExitDetails.selectedCompany)}
          </div>
        </FieldRow>
        <FieldRow title="Shares to be sold" centerTitle>
          <div className={classes.row}>
            {numberToDisplayString(previousExitDetails.sharesToBeSold)}
          </div>
        </FieldRow>
        <FieldRow
          title="Taxable value share price"
          centerTitle
          tooltipText="This is typically the share price agreed in any legal sale documentation."
        >
          <div className={classes.row}>
            <NumberFormat
              onValueChange={({ value }) => setTaxableValueSharePrice(value)}
              value={taxableValueSharePrice}
              prefix="£"
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              customInput={AppTextInput}
              error={!!errors.taxableValueSharePrice}
              helperText={errors.taxableValueSharePrice}
              disabled={disableEdit}
            />
          </div>
        </FieldRow>
        <FieldRow
          title="Received value share price"
          centerTitle
          tooltipText={
            <span>
              This must be less than or equal to the{' '}
              <em>taxable value share price</em>, depending on whether all sale
              proceeds have been received or not.
            </span>
          }
        >
          <div className={classes.row}>
            <NumberFormat
              onValueChange={({ value }) => setSharePrice(value)}
              value={sharePrice}
              prefix="£"
              allowNegative={false}
              allowLeadingZeros={false}
              thousandSeparator={true}
              customInput={AppTextInput}
              error={!!errors.sharePrice}
              helperText={errors.sharePrice}
              disabled={disableEdit}
            />
          </div>
        </FieldRow>
        <FieldRow
          title="Charge accrued fees"
          centerTitle
          tooltipText="Deduct any fees that have been accrued to date but not paid by the investor. The maximum fee chargeable to any investor on exit is their full exit amount. Any accrued fees not charged now will remain on the system for charging at a later date."
        >
          <AppRadioButton
            name="chargeAccruedFees"
            label="Yes"
            value={1}
            checked={chargeAccruedFees === true}
            onChange={(event) => {
              setChargeAccruedFees(event.target.value === '1');
            }}
            error={!!errors.chargeAccruedFees}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="chargeAccruedFees"
            label="No"
            value={0}
            checked={chargeAccruedFees === false}
            onChange={(event) => {
              setChargeAccruedFees(event.target.value === '1');
            }}
            error={!!errors.chargeAccruedFees}
            disabled={disableEdit}
          />
          {errors.chargeAccruedFees && (
            <p className={classes.errorText}>{errors.chargeAccruedFees}</p>
          )}
        </FieldRow>
        <FieldRow
          title="S/EIS eligible exit"
          centerTitle
          tooltipText={
            <span>
              <span>
                Editing the S/EIS status of this exit will change the status
                from the original exit’s date, not the date of this edit.
              </span>
              <br />
              <br />
              <span>
                If this exit takes place over three years since your last EIS
                subscription in the company, select <em>Yes</em>. If this exit
                takes place within three years of your first EIS subscription in
                the company, select <em>No</em>. Where some tranches invested
                over three years before this exit, but others invested more
                recently, select Partial and choose the allocations that are
                S/EIS eligible (i.e. select those that invested over three years
                before this exit).
              </span>
            </span>
          }
        >
          <AppRadioButton
            name="taxReliefElegible"
            label="Yes"
            value={1}
            checked={taxReliefElegible === TaxReliefElegibleType.Yes}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.Yes);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="taxReliefElegible"
            label="No"
            value={0}
            checked={taxReliefElegible === TaxReliefElegibleType.No}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.No);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          <AppRadioButton
            name="taxReliefElegible"
            label="Partial"
            value={0}
            checked={taxReliefElegible === TaxReliefElegibleType.Partial}
            onChange={(_, checked) => {
              if (checked) {
                setTaxReliefElegible(TaxReliefElegibleType.Partial);
              }
            }}
            error={!!errors.taxReliefElegible}
            disabled={disableEdit}
          />
          {errors.taxReliefElegible && (
            <p className={classes.errorText}>{errors.taxReliefElegible}</p>
          )}
        </FieldRow>
        {taxReliefElegible === TaxReliefElegibleType.Partial && (
          <TaxReliefElegibleAllocations
            firmId={previousExitDetails.firmId}
            companyId={previousExitDetails.selectedCompany?._id}
            taxReliefElegibleAllocationIds={taxReliefElegibleAllocationIds}
            onChange={setTaxReliefElegibleAllocationIds}
            disabled={disableEdit}
            error={errors.taxReliefElegibleAllocationIds}
          />
        )}
      </>

      <Grid item md={5} xs={12}>
        <SharePriceInfoTooltip />
      </Grid>
      <Grid item md={7} xs={12}>
        {!disableEdit && (
          <div className={classes.alignButtonRight}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (validate())
                  onSubmit({
                    ...previousExitDetails,
                    exitDate,
                    sharePrice: Number(sharePrice),
                    taxableValueSharePrice: Number(taxableValueSharePrice),
                    chargeAccruedFees,
                    taxReliefElegible,
                    taxReliefElegibleAllocationIds,
                  });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </Grid>
    </GridContainer>
  );
};

export default EditExitDetailsForm;
