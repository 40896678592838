import { makeStyles } from '@material-ui/core';
import { WideTooltip } from 'components/Tooltip';
import { TooltipTriggerText } from 'components/TooltipTriggerText';

const useStyles = makeStyles((theme) => ({
  tooltipWrapper: {
    display: 'inline-block',
  },
}));

const SharePriceInfoTooltip = () => {
  const classes = useStyles();

  const content = (
    <>
      <p>
        In most exits, the received value and taxable value share prices will be
        the same, equalling the relevant sale share price. In cases of escrow or
        earnout, they will differ, as set out below.
      </p>
      <br />
      <p>
        <strong>Escrow:</strong> In the event of an escrow/holdback of sale
        consideration (e.g. where consideration is held back for potential
        warranty claims), ensure that (i) the <i>received value</i> share price
        is reduced pro rata to the escrowed amount, but (ii) the{' '}
        <i>taxable value</i> share price is equal to the full legally agreed
        consideration share price (i.e. as if an escrow were not taking place).
        When the escrow is released in future, you can edit this exit to
        increase the <i>received value</i> share price or, if the escrow is
        kept, you can reduce the <i>taxable value</i> share price.
      </p>
      <br />
      <p>
        <strong>Earnout:</strong> In the event of an earnout (where additional
        consideration may be paid, contingent on conditions subsequent to sale),
        please keep the <i>received value</i> and <i>taxable value</i> share
        prices equal (i.e. both equalling the agreed sale share price, subject
        to escrow, of course). Do not increase either share price to take
        account of contingent consideration, unless tax advice has been received
        that you should do so (in which case, increase the <i>taxable value</i>{' '}
        share price only). If an earnout is paid at a later date, you can edit
        this exit to increase both the <i>received value</i> share price and the
        <i>taxable value</i> share price to reflect any additional paid
        consideration.
      </p>
    </>
  );

  return (
    <WideTooltip title={content} arrow style={{ width: '700px' }}>
      <span className={classes.tooltipWrapper}>
        <TooltipTriggerText
          content={
            <span>
              Using <i>received value</i> and <i>taxable value</i> share prices
            </span>
          }
          icon
        />
      </span>
    </WideTooltip>
  );
};

export default SharePriceInfoTooltip;
