import { CircularProgress, Grid } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import SnapshotBox from 'components/SnapshotBox/SnapshotBox';

type Totals = {
  investors: number;
  investments: number;
  totalInitialInvestment: number;
  totalUnrealisedValue: number;
  totalRealisedValue: number;
};

type AdviserSnapshotProps = {
  totals: Totals;
  isLoading: boolean;
};

export const AdviserSnapshot = ({
  totals,
  isLoading,
}: AdviserSnapshotProps) => {
  const {
    investors = 0,
    investments = 0,
    totalInitialInvestment = 0,
    totalUnrealisedValue = 0,
    totalRealisedValue = 0,
  } = totals || {};

  const averageSubscriptionAmount =
    investments > 0 ? totalInitialInvestment / investments : 0;

  if (isLoading) {
    return (
      <GridContainer>
        <Grid item xs={12} style={{ textAlign: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Grid>
      </GridContainer>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs>
            <SnapshotBox value={investors} subtitle="Investors" />
          </Grid>
          <Grid item xs>
            <SnapshotBox value={investments} subtitle="Subscriptions" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs>
            <SnapshotBox
              value={totalInitialInvestment}
              subtitle="Total subscribed"
              isCurrency
            />
          </Grid>
          <Grid item xs>
            <SnapshotBox
              value={totalUnrealisedValue}
              subtitle="Unrealised value"
              isCurrency
            />
          </Grid>
          <Grid item xs>
            <SnapshotBox
              value={totalRealisedValue}
              subtitle="Realised value"
              isCurrency
            />
          </Grid>
          <Grid item xs>
            <SnapshotBox
              value={averageSubscriptionAmount}
              subtitle="Average subscription"
              isCurrency
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdviserSnapshot;
