import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/investment';
import { ApiResponse } from 'further-types/api';

function useGetInvestmentShareholdings(investmentId: string) {
  return useQuery({
    queryKey: ['investment-shareholdings', investmentId],
    queryFn: async () => {
      const response = await api.get<
        ApiResponse<Api.GetInvestmentShareholdingsResponse>
      >(`investment/${investmentId}/shareholdings`);

      return response.data.data;
    },
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
    enabled: !!investmentId,
  });
}

function useGetInvestmentFees(investmentId: string) {
  return useQuery({
    queryKey: ['investment-fees', investmentId],
    queryFn: async () => {
      const response = await api.get<
        ApiResponse<Api.GetInvestmentFeesResponse>
      >(`investment/${investmentId}/fees`);

      return response.data.data;
    },
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
    enabled: !!investmentId,
  });
}

function useGetInvestmentExits(investmentId: string) {
  return useQuery({
    queryKey: ['investment-exits', investmentId],
    queryFn: async () => {
      const response = await api.get<
        ApiResponse<Api.GetInvestmentExitsResponse>
      >(`investment/${investmentId}/exits`);

      return response.data.data;
    },
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
    enabled: !!investmentId,
  });
}

const useInvestmentDetails = () => {
  return {
    getShareholdings: useGetInvestmentShareholdings,
    getFees: useGetInvestmentFees,
    getExits: useGetInvestmentExits,
  };
};

export default useInvestmentDetails;
