import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import CardHeader from 'components/CardHeader';
import Summary from '../Common/Summary';
import { INVESTOR_REPORTING } from 'constants/routes';

import { getDeploymentUpdate } from '@redux/actions/DeploymentUpdate';

const useStyles = makeStyles((theme) => ({
  cardContentPadding: {
    padding: '2rem',
  },
}));

const ViewCustomUpdate = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [deploymentUpdate, setDeploymentUpdate] = useState();
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    {
      label: 'Investor Reporting',
      link: INVESTOR_REPORTING,
    },
  ];
  const heading = 'Investor reporting: View deployment update';
  const classes = useStyles();
  const fetchDeploymentUpdate = async () => {
    const response = await dispatch(getDeploymentUpdate(id));
    setDeploymentUpdate(response);
  };

  useEffect(() => {
    fetchDeploymentUpdate();
  }, [id]);

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader title={'Deployment update summary'} />
          <Summary
            data={deploymentUpdate}
            company={deploymentUpdate?.companyId}
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default ViewCustomUpdate;
