import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import InvestmentsTable from './InvestmentsTable';
import { useHistory } from 'react-router';
import { createAddInvestmentTransferRoute } from 'constants/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
  },
  heading: {
    color: theme.palette.text.secondary,
    fontSize: 22,
  },
}));

export default function Investments({ investorId }) {
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item xs={12} sm>
          <Typography variant="h2" className={classes.heading}>
            Subscriptions
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            color="primary"
            variant="contained"
            onClick={() => push(createAddInvestmentTransferRoute(investorId))}
            size="small"
          >
            Transfer subscriptions
          </Button>
        </Grid>
      </Grid>
      <InvestmentsTable investorId={investorId} />
    </>
  );
}
