import { CloudDownload } from '@material-ui/icons';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useDownloadPdfExitStatement } from 'hooks/data/investorReporting/useDownloadPdfExitStatement';

export const DownloadPdfExitStatementButton = ({
  exitStatementId,
  investorId,
  filename,
}) => {
  const { loading, download } = useDownloadPdfExitStatement({
    exitStatementId,
    investorId,
    filename,
  });

  return (
    <IconButton onClick={download} disabled={loading}>
      {loading ? <CircularProgress size={24} /> : <CloudDownload />}
    </IconButton>
  );
};
