import { Button } from '@material-ui/core';
import { investorTypeToLabel } from 'utils/investorType';
import { nationality } from 'further-ui/labels';
import { InvestorType } from 'further-types/investor';

import FieldRow from 'components/FormElements/FieldRow';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import AppSelectBox from 'components/FormElements/AppSelectBox';

import AppRadioButton from 'components/FormElements/AppRadioButton';
import InvestorAddress from './InvestorAddress';
import { Control, Controller, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import SwitchField from './SwitchField';
import TextField from './TextField';
import { InvestorFormValues } from 'helpers/investor/investorForm';

type Props = {
  id?: string;
  isSuperAdmin: boolean;
  control: Control<InvestorFormValues>;
};

const EntityDetails: React.FC<Props> = ({ id, isSuperAdmin, control }) => {
  const isDeceased = useWatch({ control, name: 'isDeceased' });

  return (
    <>
      <FieldRow title="Registered name*" centerTitle>
        <TextField name="registeredName" control={control} />
      </FieldRow>
      <FieldRow title="Registration number*" centerTitle>
        <TextField name="registrationNumber" control={control} />
      </FieldRow>
      <FieldRow title="Registration date*" centerTitle>
        <Controller
          name="registrationDate"
          control={control}
          render={({
            field: { ref, onChange, value, ...rest },
            fieldState: { error },
          }) => (
            <KeyboardDatePicker
              {...rest}
              format="DD/MM/YYYY"
              maxDate={format(new Date(), 'yyyy-MM-dd')}
              inputRef={ref}
              error={!!error}
              value={value ?? null}
              helperText={error?.message}
              InputLabelProps={{
                shrink: true,
              }}
              openOnFocus
              onChange={(date) => {
                onChange(date.toDate());
              }}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="Registration state*" centerTitle>
        <Controller
          name="registrationState"
          control={control}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <AppSelectBox
              {...rest}
              data={nationality}
              valueKey="Country"
              labelKey="Country"
              variant="outlined"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FieldRow>

      {/* Address */}
      <InvestorAddress control={control} isLegalEntity />

      <FieldRow title="Email address*" centerTitle>
        <TextField name="email" control={control} />
      </FieldRow>
      {isSuperAdmin && id && (
        <FieldRow title="Password" centerTitle>
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              // @ts-expect-error component needs to be converted to TS
              <AppPasswordInput
                {...rest}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      )}

      <FieldRow title="Key contact first name*" centerTitle>
        <TextField name="keyContactFirstName" control={control} />
      </FieldRow>
      <FieldRow title="Key contact surname*" centerTitle>
        <TextField name="keyContactSurname" control={control} />
      </FieldRow>
      <FieldRow title="Key contact phone number*" centerTitle>
        <TextField name="keyContactPhoneNumber" control={control} />
      </FieldRow>

      {id ? (
        <FieldRow title="Date registered*" centerTitle>
          <Controller
            name="dateRegister"
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <KeyboardDatePicker
                {...rest}
                disabled
                placeholder="Please enter date registered"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={() => {}}
                inputRef={ref}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}
      {isSuperAdmin ? (
        <FieldRow title="Investor type" centerTitle>
          <Controller
            name="investorType"
            control={control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <AppSelectBox
                {...rest}
                data={[
                  {
                    id: InvestorType.ProfessionalClient,
                    investorType: investorTypeToLabel(
                      InvestorType.ProfessionalClient,
                    ),
                  },
                ]}
                valueKey="id"
                labelKey="investorType"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FieldRow>
      ) : null}

      {id ? (
        <FieldRow title="Email verified?" centerTitle>
          <Controller
            name="isEmailVerify"
            control={control}
            render={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <AppRadioButton
                {...rest}
                label={'Yes'}
                checked={!!value}
                error={!!error}
                helperText={error?.message}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
              />
            )}
          />
          <Button variant="outlined" color="primary">
            Resend verification email
          </Button>
        </FieldRow>
      ) : null}

      <FieldRow title="External investor ID" centerTitle>
        <TextField name="externalInvestorId" control={control} />
      </FieldRow>

      <FieldRow title="Vulnerable client" centerTitle>
        <span>No</span>
        <SwitchField name="isVulnerableClient" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="Deceased" centerTitle>
        <span>No</span>
        <SwitchField name="isDeceased" control={control} />
        <span>Yes</span>
      </FieldRow>

      {isDeceased && (
        <FieldRow title="Date of death*" centerTitle>
          <Controller
            name="dateOfDeath"
            control={control}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => (
              <KeyboardDatePicker
                {...rest}
                format="DD/MM/YYYY"
                maxDate={format(new Date(), 'yyyy-MM-dd')}
                inputRef={ref}
                error={!!error}
                value={value ?? null}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                openOnFocus
                onChange={(date) => {
                  onChange(date.toDate());
                }}
              />
            )}
          />
        </FieldRow>
      )}

      <FieldRow title="Requires hard copy reports" centerTitle>
        <span>No</span>
        <SwitchField name="requiresHardCopyReports" control={control} />
        <span>Yes</span>
      </FieldRow>

      <FieldRow title="Will the investor be claiming tax relief?" centerTitle>
        <span>No</span>
        <SwitchField name="isClamingTax" control={control} />
        <span>Yes</span>
      </FieldRow>
      <FieldRow title="UK remittance taxpayer (BIR)" centerTitle>
        <span>No</span>
        <SwitchField name="isUkRemittanceTaxpayer" control={control} />
        <span>Yes</span>
      </FieldRow>
    </>
  );
};

export default EntityDetails;
