import React from 'react';

const FeeAction = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="launch_24px">
        <path
          id="icon/action/launch_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5V19H19V12H21V21H3V3H12V5H5ZM14 5V3H21V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z"
          fill="black"
          fillOpacity="0.38"
        />
      </g>
    </svg>
  );
};
export default FeeAction;
