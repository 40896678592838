import { useState } from 'react';
import { isEqual, pick } from 'lodash';

interface IFilters {
  [key: string]: any;
}

function useFilters<T extends IFilters>(
  filtersId: string,
  options: {
    defaultFilters?: T;
    onFiltersChange?: (x?: Partial<T>) => void;
  },
) {
  const [filters, setFilters] = useState<T>(() => {
    const serializedFilters = localStorage.getItem(`${filtersId}-filters`);
    const savedFilters = serializedFilters
      ? JSON.parse(serializedFilters)
      : null;

    return savedFilters ?? options?.defaultFilters ?? {};
  });

  const handleChangeFilters = (changedFilters: Partial<T>) => {
    const updatedKeys = Object.keys(changedFilters);
    const previousFilters = pick(filters, updatedKeys);
    const changeFiltersAreDifferent = !isEqual(changedFilters, previousFilters);

    const updatedFilters = {
      ...filters,
      ...changedFilters,
    };
    setFilters(updatedFilters);

    if (changeFiltersAreDifferent) {
      options?.onFiltersChange?.(updatedFilters);
    }

    localStorage.setItem(
      `${filtersId}-filters`,
      JSON.stringify(updatedFilters),
    );
  };

  return {
    filters,
    handleChangeFilters,
  };
}

export default useFilters;
