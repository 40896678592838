import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/organisation-user';

async function listOrgUsers({ queryKey }) {
  try {
    const [, organisationId] = queryKey;

    const {
      data: { data },
    } = await api.get(`organisation/${organisationId}/user/list`);
    return data;
  } catch (error) {
    throw error;
  }
}
type Params = {
  organisationId: string;
};

export function useListOrganisationUsers({ organisationId }: Params) {
  return useQuery({
    queryKey: ['organisation-users', organisationId],
    queryFn: listOrgUsers,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!organisationId,
  });
}

export function useDeleteOrganisationUser({ organisationId }: Params) {
  const queryClient = useQueryClient();
  const message = useNotification();

  return useMutation({
    mutationFn: (orgUserId: string) => {
      return api.delete(`organisation/user/${orgUserId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organisation-users', organisationId]);
      message.success('Organisation user deleted successfully');
    },
    onError: () => {
      message.error('Error deleting organisation user');
    },
  });
}

export function useCreateOrganisationUser({ organisationId }: Params) {
  const queryClient = useQueryClient();
  const message = useNotification();

  return useMutation({
    mutationFn: (data: Api.CreateOrganisationUserParams) => {
      return api.post(`organisation/user`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organisation-users', organisationId]);
      message.success('Organisation user created');
    },
    onError: (error: any) => {
      message.error(
        error?.response?.data?.responseMsg ||
          'Error creating organisation user',
      );
    },
  });
}

export function useOrganisationUser(params: Params) {
  return {
    create: useCreateOrganisationUser(params),
    remove: useDeleteOrganisationUser(params),
  };
}
