import React from 'react';

const EIS1Download = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H2V20H22V6H12L10 4Z" fill="black" fill-opacity="0.38" />
      <path
        d="M9.31823 15H6.21723L6.21723 10.1H9.31823V10.975H7.16223V12.074H9.12223V12.914H7.16223V14.125H9.31823V15ZM11.2441 15H10.2991V10.1H11.2441V15ZM12.0819 13.362L13.0759 13.145C13.1366 13.537 13.2369 13.817 13.3769 13.985C13.5216 14.1483 13.7456 14.23 14.0489 14.23C14.3196 14.23 14.5319 14.181 14.6859 14.083C14.8446 13.985 14.9239 13.845 14.9239 13.663C14.9239 13.467 14.8469 13.3177 14.6929 13.215C14.5436 13.1077 14.1982 12.97 13.6569 12.802C13.1576 12.648 12.7982 12.459 12.5789 12.235C12.3642 12.011 12.2569 11.7287 12.2569 11.388C12.2569 11.0053 12.4039 10.6787 12.6979 10.408C13.0012 10.1327 13.4049 9.995 13.9089 9.995C14.3849 9.995 14.7862 10.1093 15.1129 10.338C15.4396 10.5667 15.6449 10.898 15.7289 11.332L14.8119 11.563C14.6672 11.101 14.3662 10.87 13.9089 10.87C13.6709 10.87 13.4912 10.9167 13.3699 11.01C13.2486 11.0987 13.1879 11.2223 13.1879 11.381C13.1879 11.5257 13.2556 11.6377 13.3909 11.717C13.5216 11.7917 13.8692 11.9247 14.4339 12.116C14.9706 12.3027 15.3416 12.5057 15.5469 12.725C15.7476 12.9397 15.8479 13.2313 15.8479 13.6C15.8479 14.034 15.6846 14.3933 15.3579 14.678C15.0312 14.9627 14.5949 15.105 14.0489 15.105C13.4842 15.105 13.0316 14.958 12.6909 14.664C12.3549 14.3653 12.1519 13.9313 12.0819 13.362ZM18.3655 15H17.4625V11.15L16.4055 11.815V10.8L17.5325 10.1H18.3655V15Z"
        fill="white"
      />
    </svg>
  );
};
export default EIS1Download;
