import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { uniq } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import CompanyUpdate from './CompanyUpdate';
import { status } from 'constants/investorReporting';
import useInvestorReportingForm, {
  companyInformationSchema,
} from 'hooks/form/useInvestorReportingForm';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from 'further-ui/hooks';
import { getCompanyLabel } from 'further-ui/utils';
import useStyles from './styles';
import useAccordionStyles from '../FundInformationStep/styles';
import DraftButtons from '../DraftButtons';
import PublishConfirmationDialog from './PublishConfirmationDialog';
import { useDownload } from 'hooks/ui/useDownload';

const isCompanyUpdateProvidingData = (
  currentCompanyUpdate,
  allCompaniesUpdates,
) =>
  allCompaniesUpdates.some((companyUpdate) =>
    companyUpdate.copyFromCompanies.includes(currentCompanyUpdate.companyId),
  );

const getCompaniesWithSameLegalName = (
  allCompaniesData,
  currentCompanyUpdate,
) => {
  if (!isCompanyUpdateProvidingData(currentCompanyUpdate, allCompaniesData)) {
    const currentUpdateIndex = allCompaniesData.findIndex(
      ({ companyId }) => currentCompanyUpdate.companyId === companyId,
    );

    return allCompaniesData.filter(
      (companyData, index) =>
        currentUpdateIndex > index &&
        companyData.companyId !== currentCompanyUpdate.companyId &&
        companyData.legalName === currentCompanyUpdate.legalName &&
        !companyData.copyFromCompanies.length,
    );
  }
  return [];
};

const isCompanyUpdateCopyingFromAnother = (companyUpdateA, companyUpdateB) =>
  companyUpdateA.copyFromCompanies.includes(companyUpdateB.companyId);

const mergeCopiedCompany = (sourceCompanyUpdate, targetCompanyUpdate) => {
  const {
    additionalMetrics,
    media,
    mediaType,
    attachments,
    updateSummary,
    additionalTitle,
    additionalValue,
    description,
  } = sourceCompanyUpdate;

  return {
    ...targetCompanyUpdate,
    additionalMetrics,
    media,
    mediaType,
    attachments,
    updateSummary,
    additionalTitle,
    additionalValue,
    description,
    copyFromCompanies: [sourceCompanyUpdate.companyId],
  };
};

const CompanyInformationStep = ({
  handleStepBack,
  isViewPage,
  sortedCompanyUpdates,
}) => {
  const classes = useStyles();
  const accordionClasses = useAccordionStyles();

  const { submitForm, scrollToFirstError } = useInvestorReportingForm();
  const formContext = useInvestorReportingFormContext();
  const {
    investorReport,
    firmId,
    companyInformation,
    setCompanyInformation,
    reportStatus,
    setNotifyInvestors,
    notifyInvestors,
  } = formContext;
  const investorReportId = investorReport?._id;
  const [submitting, setSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [expanded, setExpanded] = useState([0]);
  const confirmationDisclosure = useDisclosure();

  const fetchPdfSample = useDownload({
    filename: 'sample.pdf',
    errorMessage: 'The sample update is still being created. Please try again.',
  });

  const { handleSubmit, control, setValue, getValues, formState, watch } =
    useForm({
      shouldFocusError: true,
      resolver: zodResolver(companyInformationSchema),
      criteriaMode: 'all',
      defaultValues: {
        companyInformation: sortedCompanyUpdates.map((update) => ({
          ...update,
          copiedFromAnotherCompany: update.copyFromCompanies.length > 0,
        })),
        notifyInvestors,
      },
    });

  useEffect(() => {
    const { unsubscribe } = watch(() => {
      setIsDirty(true);
    });

    return unsubscribe;
  }, [watch]);

  const handleCompanyUpdateChange = (companyUpdate, prevData, index) => {
    if (companyUpdate.copyFromCompanies?.length) {
      const companyCopyingFrom = prevData.find(
        ({ companyId }) => companyUpdate.copyFromCompanies[0] === companyId,
      );

      if (companyCopyingFrom) {
        prevData[index] = mergeCopiedCompany(companyCopyingFrom, companyUpdate);
      }
    } else {
      prevData[index] = companyUpdate;
    }

    const updatedCompaniesUpdates = prevData.map((targetCompanyUpdate) => {
      if (
        isCompanyUpdateCopyingFromAnother(targetCompanyUpdate, companyUpdate)
      ) {
        return mergeCopiedCompany(companyUpdate, targetCompanyUpdate);
      }
      return targetCompanyUpdate;
    });

    return updatedCompaniesUpdates;
  };

  const processQuickSave = async () => {
    const data = getValues();
    const updatedCompanies = sortedCompanyUpdates.reduce(
      (acc, company, index) =>
        handleCompanyUpdateChange(
          {
            ...company,
            ...data.companyInformation[index],
          },
          acc,
          index,
        ),
      companyInformation,
    );
    setCompanyInformation(updatedCompanies);

    await submitForm(
      { ...formContext, companyInformation: updatedCompanies },
      status.DRAFT,
      true,
    );
    setIsDirty(false);
  };

  const handleDownloadSample = () => {
    if (!fetchPdfSample.isLoading && investorReportId) {
      fetchPdfSample.mutate(
        `investor-reporting/export-sample-pdf/${investorReportId}`,
      );
    }
  };

  const processManualFormSubmission = (selectedStatus) => async () => {
    const data = getValues();
    const updatedCompanies = sortedCompanyUpdates.reduce(
      (acc, company, index) =>
        handleCompanyUpdateChange(
          {
            ...company,
            ...data.companyInformation[index],
          },
          acc,
          index,
        ),
      companyInformation,
    );
    setCompanyInformation(updatedCompanies);

    setSubmitting(true);
    await submitForm(
      { ...formContext, companyInformation: updatedCompanies },
      selectedStatus,
    );
    setSubmitting(false);
    confirmationDisclosure.onClose();
  };

  const onValidationError = (errors) => {
    const errorIndices = [];
    errors?.companyInformation?.forEach((value, index) => {
      if (value !== undefined) {
        errorIndices.push(index);
      }
    });

    const mergedIndices = uniq([...expanded, ...errorIndices]);
    setExpanded(mergedIndices);

    scrollToFirstError(errors);
  };

  const saveDraft = () => {
    const onSuccess = processManualFormSubmission(status.DRAFT);
    handleSubmit(onSuccess, onValidationError)();
  };

  const publishUpdate = () => {
    const onSuccess = confirmationDisclosure.onOpen;
    handleSubmit(onSuccess, onValidationError)();
  };

  const onConfirmPublishUpdate = () => {
    const onSuccess = processManualFormSubmission(status.SUBMITTED);
    handleSubmit(onSuccess)();
  };

  return (
    <form>
      <CardHeader title="Enter company information" />

      {sortedCompanyUpdates.map((single, index) => (
        <Accordion
          key={single.companyId}
          className={accordionClasses.customAccordion}
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.includes(index)}
        >
          <AccordionSummary
            className={clsx(
              accordionClasses.cmAccHeader,
              accordionClasses.expends,
            )}
            aria-controls="panel1bh-content"
            onClick={() => {
              if (expanded.includes(index)) {
                setExpanded(expanded.filter((item) => item !== index));
              } else {
                setExpanded([...expanded, index]);
              }
            }}
          >
            <div className={accordionClasses.accordionHeader}>
              <span className={accordionClasses.accordionHeaderFundName}>
                {getCompanyLabel(single)}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails className={accordionClasses.cmAccDetail}>
            <CompanyUpdate
              index={index}
              single={single}
              duplicateEntries={getCompaniesWithSameLegalName(
                companyInformation,
                single,
              )}
              investorReportId={investorReportId}
              firmId={firmId}
              control={control}
              setValue={setValue}
              isViewPage={isViewPage}
              enableLoadLastUpdate={reportStatus !== status.SUBMITTED}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      <div className={classes.buttonRow}>
        <div className={classes.buttonLeftContainer}>
          <Button
            variant="outlined"
            onClick={handleStepBack}
            data-testid={`nav-back`}
          >
            Back
          </Button>
        </div>

        <div className={classes.buttonRowMutations}>
          {!isViewPage && (
            <>
              {reportStatus !== status.SUBMITTED && (
                <Button
                  variant="outlined"
                  data-testid={`submit-draft`}
                  disabled={submitting}
                  type="button"
                  name="draft"
                  onClick={saveDraft}
                >
                  Save draft and exit
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                type="button"
                name="publish"
                onClick={publishUpdate}
                data-testid={`submit`}
              >
                {investorReportId ? 'Publish update' : 'Submit'}
              </Button>
            </>
          )}
        </div>
      </div>

      <DraftButtons
        onDownloadSample={handleDownloadSample}
        onQuickSave={processQuickSave}
        isDownloading={fetchPdfSample.isLoading}
        allowDownload={!!investorReportId && !isDirty}
      />

      {Object.keys(formState.errors).length > 0 && (
        <div className={classes.overallFormError}>
          There are one or more errors in the company information above. Please
          correct all errors and try again.
        </div>
      )}

      <PublishConfirmationDialog
        disclosure={confirmationDisclosure}
        notifyInvestors={notifyInvestors}
        setNotifyInvestors={setNotifyInvestors}
        setValue={setValue}
        control={control}
        reportStatus={reportStatus}
        onConfirmPublishUpdate={onConfirmPublishUpdate}
        submitting={submitting}
      />
    </form>
  );
};

export default CompanyInformationStep;
