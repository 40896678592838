import { useQuery } from '@tanstack/react-query';

import axios from 'axios';

async function fetchPartialInvestors({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await axios.get(`investor/partially-investor`, {
      params,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export function useIncompleteRegistrations({ params }) {
  return useQuery({
    queryKey: ['incomplete-registrations', { params }],
    queryFn: fetchPartialInvestors,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}
