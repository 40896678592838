const getFirmsInTranches = (
  trancheIds: string[],
  tranches: { _id: string; firmId: { firmName: string; _id: string } }[],
) => {
  const selectedFirmNames = new Set([]);
  const selectedFirmIds = new Set([]);
  trancheIds.forEach((trancheId) => {
    const tranche = tranches.find((tranche) => tranche._id === trancheId);
    if (tranche) {
      selectedFirmNames.add(tranche.firmId.firmName);
      selectedFirmIds.add(tranche.firmId._id);
    }
  });

  return {
    selectedFirmNames: Array.from(selectedFirmNames),
    selectedFirmIds: Array.from(selectedFirmIds),
  };
};

export default getFirmsInTranches;
