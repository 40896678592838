import axios from 'axios';
import { GET_USER_LIST, RESET_USER } from 'constants/actionTypes';

export const getUserProfile = (id) => {
  return () => {
    return axios
      .get(`user/list/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const updateUserProfile = (data) => {
  return () => {
    return axios
      .put(`user/update/${data?.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getUserList = ({ page, perPage, type, keyName, search }) => {
  return (dispatch) => {
    return axios
      .get(`user/list`, {
        params: { page, perPage, type, keyName, search },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_USER_LIST,
            payload: response.data.data,
          });
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const addUser = (formData) => {
  return () => {
    return axios
      .post(`user/create`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const deleteUser = (id) => {
  return () => {
    return axios
      .delete(`user/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER,
    });
  };
};
