import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { sumBy } from 'lodash';
import { IconButton, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

import CardHeader from 'components/CardHeader';
import Table from 'components/Table';
import { dateToLabel } from 'further-ui/utils';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import { numberToDisplayString } from 'utils/numbers';
import { exportToCustodian } from '@redux/actions/UploadShareHolding';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  summaryRow: {
    backgroundColor: '#FBFBFA',
    marginTop: 4,
  },
  summaryValue: {
    color: '#282525',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 10px 10px 6px',
  },
}));

const AllocationRecords = ({ companyId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useNotification();
  const allocations = useAllocations({
    params: {
      companyId,
    },
  });
  const handleDownload = (id) => {
    dispatch(exportToCustodian(id, notification));
  };
  const columns = [
    {
      label: 'Date',
      key: 'date',
      sort: false,
      render: (elm) => dateToLabel(elm?.date),
    },
    {
      label: 'Shares',
      key: 'totalNumberOfShare',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.totalNumberOfShare),
    },
    {
      label: 'Investors',
      key: 'numberOfInvestors',
      sort: false,
      render: (elm) => numberToDisplayString(elm?.numberOfInvestors),
    },
    {
      label: 'Actions',
      render: (elm) => (
        <IconButton title="Download" onClick={() => handleDownload(elm._id)}>
          <CloudDownload color="primary" />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <CardHeader title="Allocation record" />
      <Table
        columns={columns}
        tablebody={allocations?.data?.uploadShareHoldings ?? []}
        loading={allocations?.isFetching}
        TableFooter={
          <tfoot>
            <tr className={classes.summaryRow}>
              <td className={classes.summaryValue} align="right">
                Total shares held
              </td>
              <td className={classes.summaryValue}>
                {numberToDisplayString(
                  sumBy(
                    allocations?.data?.uploadShareHoldings,
                    'totalNumberOfShare',
                  ),
                )}
              </td>
              <td />
              <td />
            </tr>
          </tfoot>
        }
      />
    </Grid>
  );
};

export default AllocationRecords;
