import { Tooltip } from 'components/Tooltip';
import { Button } from '@material-ui/core';
import usePlatformInvites from 'hooks/data/investor/useInvestorPlatformInvites';
import { useNotification } from 'hooks/ui/useNotification';
import { useGetRole } from 'hooks/ui/useGetRole';

const InvestorsBulkActions = ({ selectedInvestors, refetchInvestors }) => {
  const { success } = useNotification();
  const { isSuperAdmin } = useGetRole();

  const {
    resendInvites: { mutate: resendInvites, isLoading },
  } = usePlatformInvites({
    onResendInvitesSuccess: () => {
      success('Investor invites sent successfully.');
      refetchInvestors();
    },
  });

  if (isSuperAdmin) {
    return (
      <Tooltip title="Resending investor invites is only possible for firm admin users.">
        <span>
          <Button color="primary" variant="contained" disabled>
            Resend invites for selected investors
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={isLoading}
      onClick={() =>
        resendInvites(selectedInvestors.map((investor) => investor._id))
      }
    >
      Resend invites for selected investors
    </Button>
  );
};
export default InvestorsBulkActions;
