import { useMemo } from 'react';
import { useSnackbar } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMemo(
    () => ({
      success: (message, options) =>
        enqueueSnackbar(message, { variant: 'success', ...options }),
      error: (message, options) =>
        enqueueSnackbar(message, { variant: 'error', ...options }),
      warning: (message, options) =>
        enqueueSnackbar(message, { variant: 'warning', ...options }),
      info: (message, options) =>
        enqueueSnackbar(message, { variant: 'info', ...options }),
    }),
    [enqueueSnackbar],
  );
};
