import { api } from 'lib/httpClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/tag';

async function fetchAllTags({ queryKey }) {
  const [, filters] = queryKey;
  const response = await api.get('/tag', {
    params: {
      ...filters,
    },
  });
  return response.data.data;
}

function useDeleteTag() {
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();
  const queryClient = useQueryClient();

  return useMutation({
    useErrorBoundary: false,
    mutationFn: async (tagId: string) => {
      const result = await api.delete<ApiResponse<Api.DeleteTagResponse>>(
        `tag/${tagId}`,
      );
      return result.data;
    },
    onSuccess: async (data) => {
      notification.success(data.responseMsg);
      await queryClient.invalidateQueries({
        queryKey: ['tag'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });
}

export function useTags({ name }: { name?: string } = {}) {
  const tags = useQuery({
    queryKey: [
      'tag',
      {
        name,
      },
    ],
    queryFn: fetchAllTags,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  return {
    tags,
    deleteTag: useDeleteTag(),
  };
}
