import React, { useState } from 'react';
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/FormElements/AppTextInput';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import AppChipInput from 'components/FormElements/AppChipInput';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useGetRole } from 'hooks/ui/useGetRole';
import FieldRow from 'components/FormElements/FieldRow';
import { FirmSelector } from 'components/FirmSelector';
import { useFirm } from 'hooks/data/firm/useFirm';

const useStyles = makeStyles(() => ({
  addAssociatedFirmButton: {
    height: '100%',
  },
}));

const BasicDetailsInput = ({
  isBeingEdited,
  adviserData,
  setAdviserData,
  parentOrganisation,
  errorText,
  canEdit,
}) => {
  const [addAssociatedFirmMenuAnchor, setAddAssociatedFirmMenuAnchor] =
    useState(null);
  const { isSuperAdmin } = useGetRole();
  const classes = useStyles();
  const { firms } = useFirm({ params: {} });

  const firmData = firms.data?.result ?? [];
  const disableInput = isBeingEdited && !canEdit;

  const onFirmChange = (firmId) => {
    if (
      adviserData?.changeToInitialInvestmentFees.length === 1 &&
      !adviserData?.changeToInitialInvestmentFees[0].firmId
    ) {
      setAdviserData({
        ...adviserData,
        firmId,
        changeToInitialInvestmentFees: [
          { ...adviserData.changeToInitialInvestmentFees[0], firmId },
        ],
      });
    } else {
      setAdviserData({ ...adviserData, firmId });
    }
  };

  return (
    <>
      {!isBeingEdited && isSuperAdmin && (
        <FieldRow title="Firm Name" centerTitle>
          <FirmSelector
            firmId={adviserData?.firmId}
            onChange={onFirmChange}
            placeholder="Select firm"
            error={!!errorText?.errorValue?.firmId}
            helperText={errorText?.errorValue?.firmId}
            required
          />
        </FieldRow>
      )}
      {isBeingEdited && isSuperAdmin && (
        <>
          <Grid item md={8} xs={12}>
            <AppChipInput
              label="Associated firms"
              disabled={!isSuperAdmin}
              fullWidth
              value={adviserData?.associatedFirms?.map((single) => {
                return single.firmName;
              })}
              handleDeleteChip={(_chip, index) => {
                adviserData.associatedFirms.splice(index, 1);
                setAdviserData({ ...adviserData });
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              aria-controls="add-associated-firm-menu"
              aria-haspopup="true"
              variant="outlined"
              className={classes.addAssociatedFirmButton}
              endIcon={<KeyboardArrowDown />}
              onClick={(event) =>
                setAddAssociatedFirmMenuAnchor(event.currentTarget)
              }
            >
              Add new associated firm
            </Button>
            <Menu
              id="add-associated-firm-menu"
              anchorEl={addAssociatedFirmMenuAnchor}
              keepMounted
              open={Boolean(addAssociatedFirmMenuAnchor)}
              onClose={() => setAddAssociatedFirmMenuAnchor(null)}
            >
              {firmData
                .filter(
                  // filter out firms that are already associated
                  (firm) =>
                    !adviserData?.associatedFirms?.find(
                      (single) => single._id === firm._id,
                    ),
                )
                .map((firm) => (
                  <MenuItem
                    onClick={(e) => {
                      setAdviserData({
                        ...adviserData,
                        associatedFirms: [...adviserData.associatedFirms, firm],
                      });
                      setAddAssociatedFirmMenuAnchor(null);
                    }}
                  >
                    {firm.firmName}
                  </MenuItem>
                ))}
            </Menu>
          </Grid>
        </>
      )}
      <FieldRow
        title="Select organisation"
        informationText="(if applicable)"
        centerTitle
      >
        <Autocomplete
          id="name"
          disabled={disableInput}
          options={parentOrganisation}
          getOptionLabel={(option) => option.name || ''}
          filterSelectedOptions
          value={adviserData?.organisationData}
          onChange={(event, newValue) => {
            setAdviserData({
              ...adviserData,
              organisationData: {
                name: newValue?.name,
                organisationId: newValue?._id,
              },
            });
          }}
          onReset={() => {
            setAdviserData({
              ...adviserData,
              organisationData: {
                name: null,
                organisationId: null,
              },
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select organisation (if applicable)"
            />
          )}
        />
      </FieldRow>
      <FieldRow title="First name*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="firstName"
          placeholder="Enter first name"
          fullWidth
          value={adviserData?.firstName}
          error={!!errorText?.errorValue?.firstName}
          helperText={errorText?.errorValue?.firstName}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              firstName: event.target.value,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Middle name" centerTitle>
        <TextField
          disabled={disableInput}
          name="middleName"
          placeholder="Enter middle name"
          fullWidth
          value={adviserData?.middleName}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              middleName: event.target.value,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Surname*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="lastName"
          placeholder="Enter surname"
          fullWidth
          value={adviserData?.lastName}
          error={!!errorText?.errorValue?.lastName}
          helperText={errorText?.errorValue?.lastName}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              lastName: event.target.value,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Email address*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="email"
          placeholder="Enter email"
          fullWidth
          value={adviserData?.email}
          error={!!errorText?.errorValue?.email}
          helperText={errorText?.errorValue?.email}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              email: event.target.value,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Phone number*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="phoneNumber"
          placeholder="Enter phone number"
          fullWidth
          value={adviserData?.phoneNumber}
          error={!!errorText?.errorValue?.phoneNumber}
          helperText={errorText?.errorValue?.phoneNumber}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              phoneNumber: event.target.value,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Personal FCA number*" centerTitle>
        <TextField
          required
          disabled={disableInput}
          name="fcaNumber"
          placeholder="Enter FCA number"
          fullWidth
          value={adviserData?.fcaNumber}
          error={!!errorText?.errorValue?.fcaNumber}
          helperText={errorText?.errorValue?.fcaNumber}
          onChange={(event) =>
            setAdviserData({
              ...adviserData,
              fcaNumber: event.target.value.toUpperCase(),
            })
          }
        />
      </FieldRow>
    </>
  );
};

export default BasicDetailsInput;
