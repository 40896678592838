import { useLayoutContext } from 'contexts/LayoutContext';
import SidebarContent from './SidebarContent';
import CmtDrawer from 'components/CmtDrawer';

const DrawerContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isSidebarOpen, setSidebarOpen } = useLayoutContext();

  return (
    //@ts-expect-error
    <CmtDrawer
      variant="temporary"
      open={isSidebarOpen}
      onClose={() => setSidebarOpen(false)}
      classes={{
        paper: 'Cmt-drawer-sidebar',
      }}
    >
      <SidebarContent>{children}</SidebarContent>
    </CmtDrawer>
  );
};

export default DrawerContent;
