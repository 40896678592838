import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  flxc: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '30px 0',
  },
  introCopy: {
    color: '#656565',
    marginBottom: 20,
    marginTop: -16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonLeftMargin: {
    marginLeft: 12,
  },
  amountContainer: {
    display: 'flex',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  root: {
    marginBottom: 20,
  },
  marginTop: {
    marginTop: 10,
  },
  bulkFileDownloadInfo: {
    fontSize: '14px',
    lineHeight: '22px',
    '& button': {
      color: theme.palette.primary.main,
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
