import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Delete, Edit, FlashOn } from '@material-ui/icons';
import {
  EIS_ADD,
  createEisEditRoute,
  createKiFundCertificatesBatchEditRoute,
} from 'constants/routes';
import { dateToLabel } from 'further-ui/utils';
import { downloadEIS1Certificate } from '@redux/actions/UploadShareHolding';
import { EIS1Download, EIS3Download, EIS5Download } from 'components/SVGIcons';
import { useNotification } from 'hooks/ui/useNotification';
import { useDownloadByEisWizardId } from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { FC } from 'react';
import { Disclosure } from 'further-ui/hooks';
import { Api } from 'further-types/eis-wizard';
import useDownloadKiFundCertificates from 'hooks/data/kiFundCertificate/useDownloadKiFundCertificates';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.green,
  },
  disabledButton: {
    minWidth: '35px',
    color: '#BDBDBD',
  },
  deleteButton: {
    color: theme.palette.text.red,
  },
  dFlex: {
    display: 'flex',
  },
}));

type Props = {
  tasklistItem: Api.GetEisWizardTaskListResponse['results'][number];
  deleteDisclosure: Disclosure;
};

const RowActions: FC<Props> = ({ tasklistItem, deleteDisclosure }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    shareAllocationUploadId,
    companyId,
    companyName,
    taxReliefElegible,
    createdWithoutCertificates,
    kiFundCertificatesBatchId,
    eisWizard,
    hasKiCertificates,
  } = tasklistItem;
  const notification = useNotification();
  const downloadCertificates = useDownloadByEisWizardId();
  const downloadEis5Certficates = useDownloadKiFundCertificates();

  const isDownloading =
    downloadCertificates.isLoading || downloadEis5Certficates.isDownloading;

  const handleDownloadPdfs = async () => {
    if (kiFundCertificatesBatchId) {
      downloadEis5Certficates.download(kiFundCertificatesBatchId);
    } else {
      downloadCertificates.download({
        eisWizardId: eisWizard.id,
        allocationName: dateToLabel(tasklistItem.allocationDate),
        companyName,
        investmentTypeLabel:
          eisWizard.investmentType === 'EIS'
            ? 'EIS certificates'
            : 'SEIS certificates',
      });
    }
  };

  const handleDownloadEIS1 = async () => {
    await dispatch(
      downloadEIS1Certificate(
        {
          id: tasklistItem.shareAllocationUploadId,
          isExportToCustodian: true,
        },
        notification,
      ),
    );
  };

  const downloadTitle = kiFundCertificatesBatchId
    ? 'Download EIS5'
    : 'Download EIS3';
  const DownloadIcon = kiFundCertificatesBatchId ? EIS5Download : EIS3Download;
  const editItemRoute = eisWizard?.id
    ? createEisEditRoute(eisWizard.id)
    : createKiFundCertificatesBatchEditRoute(kiFundCertificatesBatchId);

  const tooltipText =
    'Tax certificates for this allocation cannot be newly processed or deleted while an EIS5 relating to this allocation has been created. Please delete the relevant EIS5 and try again.';

  return (
    <div className={classes.dFlex}>
      {eisWizard?.id || kiFundCertificatesBatchId ? (
        <>
          {!createdWithoutCertificates && (
            <Button
              title={downloadTitle}
              className={classes.customButton}
              onClick={handleDownloadPdfs}
              disabled={createdWithoutCertificates || isDownloading}
            >
              {isDownloading ? (
                <CircularProgress size={18} />
              ) : (
                <DownloadIcon />
              )}
            </Button>
          )}

          {hasKiCertificates ? (
            <>
              <Tooltip title={tooltipText}>
                <div>
                  <Button
                    title="Edit"
                    className={classes.disabledButton}
                    disabled
                  >
                    <Edit />
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title={tooltipText}>
                <div>
                  <Button
                    title="Delete"
                    className={classes.disabledButton}
                    disabled
                  >
                    <Delete />
                  </Button>
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Link to={editItemRoute}>
                <Button title="Edit" className={classes.customButton}>
                  <Edit />
                </Button>
              </Link>
              <Button
                title="Delete"
                className={`${classes.customButton} ${classes.deleteButton}`}
                onClick={() => deleteDisclosure.stageAction(tasklistItem)}
              >
                <Delete />
              </Button>
            </>
          )}
        </>
      ) : taxReliefElegible ? (
        <>
          {hasKiCertificates ? (
            <Tooltip title={tooltipText}>
              <div>
                <Button
                  title="Create certificates for this allocation"
                  className={classes.disabledButton}
                >
                  <FlashOn />
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Link
              to={`${EIS_ADD}?presetCompanyId=${companyId}&presetShareAllocationUploadId=${shareAllocationUploadId}`}
            >
              <Button
                title="Create certificates for this allocation"
                className={classes.customButton}
              >
                <FlashOn />
              </Button>
            </Link>
          )}

          <Button onClick={handleDownloadEIS1} title="Download EIS1">
            <EIS1Download />
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default RowActions;
