import { useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import downloadFile from 'utils/downloadFile';
import { Api } from 'further-types/companies';

async function exportCompanyShareholdings(
  query: Api.ExportInvesteeShareholdingsQuery,
) {
  const response = await api.get(
    `investee/${query.companyId}/shareholdings-export`,
    {
      params: query,
      responseType: 'blob',
    },
  );
  if (response.status === 200) {
    downloadFile(
      response.data,
      `${query.tradingName}-Shareholdings-${Date.now()}.xlsx`,
    );
    return response.data;
  }
  throw new Error('Export failed');
}

type ExportCompanyShareholdingsParams = {
  tradingName: string;
};

function useExportCompanyShareholdings(
  params: Api.ExportInvesteeShareholdingsQuery,
) {
  const exportCompanyShareholdingsMutation = useMutation<
    Blob,
    Error,
    Api.ExportInvesteeShareholdingsQuery
  >({
    mutationFn: exportCompanyShareholdings,
  });

  return {
    exportCompanyShareholdings: ({
      tradingName,
    }: ExportCompanyShareholdingsParams) =>
      exportCompanyShareholdingsMutation.mutateAsync({
        tradingName,
        ...params,
      }),
    isExportingCompanyShareholdings:
      exportCompanyShareholdingsMutation.isLoading,
  };
}

export default useExportCompanyShareholdings;
