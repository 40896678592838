import React, { Children, cloneElement, isValidElement, useRef } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardProps } from '@material-ui/core';

import { getBackgroundStyle, getOverLayStyle } from 'utils/uiHelpers';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    boxShadow: '2px 20px 40px #EBEBEC',
    height: '100%',
    '&.flex': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .Cmt-card-content': {
      position: 'relative',
      zIndex: 1,
    },
  },
  cardOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
}));

interface CmtCardInterface extends CardProps {
  backgroundColor?: string | string[];
  backgroundImage?: string;
  gradientDirection?: string;
  overlay?: {
    colors: string;
    opacity: number;
    direction: string;
  };
  flex?: boolean;
}

const CmtCard: React.FC<CmtCardInterface> = ({
  backgroundColor,
  backgroundImage,
  gradientDirection,
  className,
  children,
  overlay = { colors: '', opacity: 0, direction: '' },
  flex,
  ...cardProps
}) => {
  const backgroundStyles = getBackgroundStyle(
    backgroundColor,
    backgroundImage,
    gradientDirection,
  );
  const overlayStyles = getOverLayStyle(overlay);

  const classes = useStyles();

  const headerRef = useRef<{
    onHeaderMouseEntered: () => void;
    onHeaderMouseLeft: () => void;
  }>(null);

  const handleMouseEnter = () => {
    if (headerRef.current) headerRef.current.onHeaderMouseEntered();
  };

  const handleMouseLeave = () => {
    if (headerRef.current) headerRef.current.onHeaderMouseLeft();
  };

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      //@ts-ignore
      if (child.type.render && child.type.render.name) {
        //@ts-ignore
        if (child.type.render.name === 'CmtCardHeader') {
          //@ts-ignore
          return cloneElement(child, { ref: headerRef });
        }
      } else {
        return cloneElement(child);
      }
    }
    return child;
  });

  return (
    <Card
      className={clsx(classes.root, className, flex && 'flex')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={backgroundStyles ?? undefined}
      {...cardProps}
    >
      {childrenWithProps}
      {overlay?.colors && (
        <Box
          className={classes.cardOverlay}
          style={overlayStyles ?? undefined}
        />
      )}
    </Card>
  );
};

export default CmtCard;
