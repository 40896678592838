import { Button } from '@material-ui/core';
import { Edit, Delete, Visibility, PictureAsPdf } from '@material-ui/icons';
import {
  createInvestorReportingEditRoute,
  createCustomUpdateViewRoute,
  createDeploymentUpdateViewRoute,
  createInvestorReportingViewRoute,
  createInvestorReportingPdfDownloadRoute,
  createExitStatementViewRoute,
  createExitStatementPdfDownloadRoute,
  createUpdateExitStatementRoute,
} from 'constants/routes';
import { investorReportingType, status } from 'constants/investorReporting';
import { Link } from 'react-router-dom';

const ActionTools = ({
  investorReport,
  classes,
  canUpdate,
  canDelete,
  deleteDisclosure,
}) => {
  const isCustomUpdate =
    investorReport.updateType === investorReportingType.CUSTOM_UPDATE;
  const isDeploymentUpdate =
    investorReport.updateType === investorReportingType.DEPLOYMENT_UPDATE;
  const isPeriodicUpdate =
    investorReport.updateType === investorReportingType.PERIODIC;
  const isExitStatement =
    investorReport.updateType === investorReportingType.EXIT_STATEMENT;

  const isSubmitted = investorReport.status === status.SUBMITTED;

  const showViewButton =
    investorReport?.viewable ||
    isCustomUpdate ||
    isDeploymentUpdate ||
    isExitStatement;
  const viewButtonLink = isCustomUpdate
    ? createCustomUpdateViewRoute(investorReport?._id)
    : isDeploymentUpdate
    ? createDeploymentUpdateViewRoute(investorReport?._id)
    : isExitStatement
    ? createExitStatementViewRoute(investorReport?._id)
    : createInvestorReportingViewRoute(investorReport?._id);

  const editButtonLink = isExitStatement
    ? createUpdateExitStatementRoute(investorReport?._id)
    : createInvestorReportingEditRoute(investorReport?._id);

  const showEditButton =
    investorReport && canUpdate && !isCustomUpdate && !isDeploymentUpdate;
  const showDeleteButton = canDelete && !isCustomUpdate && !isDeploymentUpdate;

  return (
    <div className={classes.dFlex}>
      {showViewButton && (
        <Link to={viewButtonLink}>
          <Button title="View" color="primary" className={classes.editButton}>
            <Visibility />
          </Button>
        </Link>
      )}

      {showEditButton ? (
        <Link to={editButtonLink}>
          <Button color="primary" className={classes.editButton}>
            <Edit />
          </Button>
        </Link>
      ) : null}

      {isPeriodicUpdate && isSubmitted && (
        <Link to={createInvestorReportingPdfDownloadRoute(investorReport?._id)}>
          <Button color="primary">
            <PictureAsPdf />
          </Button>
        </Link>
      )}

      {isExitStatement && (
        <Link to={createExitStatementPdfDownloadRoute(investorReport?._id)}>
          <Button color="primary">
            <PictureAsPdf />
          </Button>
        </Link>
      )}

      {showDeleteButton && (
        <Button
          title="Delete"
          onClick={() =>
            deleteDisclosure.stageAction({
              id: investorReport._id,
              isExitStatement,
            })
          }
        >
          <Delete htmlColor="#E8453C" />
        </Button>
      )}
    </div>
  );
};

export default ActionTools;
