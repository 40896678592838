import { orderBy } from 'lodash';

export const getCurrentSharePrice = (company) => {
  if (company?.revaluation?.length) {
    const latestRevaluation = orderBy(company.revaluation, 'date', 'desc')[0];
    return latestRevaluation.amount;
  }
  return Number(company?.initialPrice);
};

export const getLastValuationDate = (company) => {
  if (company?.revaluation?.length) {
    const latestRevaluation = orderBy(company.revaluation, 'date', 'desc')[0];
    return latestRevaluation.date;
  }
  return Number(company?.date);
};
