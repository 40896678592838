import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';

const shouldRunTracking = () => process.env.REACT_APP_STAGE === 'production';

export const initLogrocket = (user) => {
  if (user && shouldRunTracking()) {
    LogRocket.init('sq3uml/furthers-admin-panel');

    LogRocket.identify(user._id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      firmId: user.firmId,
      roleSlug: user.roleId?.roleSlug,
    });
  }
};

export const initSentry = () => {
  if (shouldRunTracking()) {
    Sentry.init({
      dsn: 'https://800c52120f56cfab08746fcefeb732b3@o4504655840739328.ingest.us.sentry.io/4507021992984576',
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1,
    });
  }
};

export const identifySentry = (user) => {
  if (shouldRunTracking()) {
    Sentry.setUser({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      firmId: user.firmId,
      roleSlug: user.roleId?.roleSlug,
    });
  }
};
