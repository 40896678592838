import { SET_USER_INFORMATION, RESET_USER } from 'constants/actionTypes';
import { initLogrocket, identifySentry } from 'utils/tracking';

export const userInformation = (user) => {
  return (dispatch) => {
    if (user && user.user) {
      initLogrocket(user.user);
      identifySentry(user.user);
    }

    dispatch({
      type: SET_USER_INFORMATION,
      payload: user,
    });
  };
};
export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER,
    });
  };
};
