import { useMemo, useState } from 'react';

export const usePagination = (defaults = {}) => {
  const [pagination, setPagination] = useState(() => {
    const savedPagination = defaults.id
      ? JSON.parse(localStorage.getItem(`${defaults.id}-pagination`))
      : {};

    return {
      order: savedPagination?.order ?? defaults.order,
      orderBy: savedPagination?.orderBy ?? defaults.orderBy,
      page: savedPagination?.page ?? defaults.page ?? 1,
      rowsPerPage: savedPagination?.rowsPerPage ?? defaults.pageSize ?? 10,
    };
  });

  const handleChangePagination = (changedPagination) => {
    const updatedPagination = {
      ...pagination,
      ...changedPagination,
    };
    setPagination(updatedPagination);

    if (defaults.id) {
      localStorage.setItem(
        `${defaults.id}-pagination`,
        JSON.stringify(updatedPagination),
      );
    }
  };

  const handleRequestSort = (_, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === 'asc';

    handleChangePagination({
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const changePageByStep = (step) => {
    if (pagination.page + step > 0) {
      handleChangePagination({ page: pagination.page + step });
    }
  };

  const handleChangePage = (_, newPage) => {
    if (newPage >= 0) {
      handleChangePagination({ page: newPage + 1 });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    handleChangePagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const genericSorter = (a, b) => {
    const { order, orderBy } = pagination;

    if (!orderBy || !order) return 0;
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };

  return useMemo(
    () => ({
      orderBy: pagination.orderBy,
      order: pagination.order,
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      handleRequestSort,
      handleChangePage,
      handleChangeRowsPerPage,
      genericSorter,
      toFirstPage: () => handleChangePagination({ page: 1 }),
      pageBack: () => changePageByStep(-1),
      pageForward: () => changePageByStep(1),
      properties: pagination,
    }),
    [
      pagination.page,
      pagination.rowsPerPage,
      pagination.order,
      pagination.orderBy,
    ],
  );
};
