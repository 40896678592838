import axios from 'axios';

export const getCompanyLastUpdate = (companyId, reportingId, firmId) => {
  return () => {
    return axios
      .get(`investor-reporting/company-last-update`, {
        params: { companyId, reportingId, firmId },
      })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};
