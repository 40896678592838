import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api, InvesteeDTO } from 'further-types/companies';

type CompanyQueryKey = ['company', { params: { companyId: string } }];

const fetchCompany = async ({ queryKey }: { queryKey: CompanyQueryKey }) => {
  const [, { params }] = queryKey;
  const { data } = await api.get<ApiResponse<Api.GetInvesteeResponse>>(
    `investee/list/${params.companyId}`,
  );
  return data.data;
};

function useCompany(companyId: string) {
  return useQuery<InvesteeDTO, unknown, InvesteeDTO, CompanyQueryKey>({
    queryKey: ['company', { params: { companyId } }],
    queryFn: fetchCompany,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}

export default useCompany;
