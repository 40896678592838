import {
  Box,
  Button,
  TextField,
  TextFieldProps,
  useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

type SearchFieldProps = TextFieldProps & {
  onSearch: (query: string) => void;
  allowEmptyQuery?: boolean;
  autoSearchOnDebounce?: boolean;
  autoSearchOnBlur?: boolean;
  useExplicitButton?: boolean;
  debounceTime?: number;
  width?: string | number;
};

const SearchField: React.FC<SearchFieldProps> = ({
  onSearch,
  allowEmptyQuery,
  autoSearchOnDebounce,
  autoSearchOnBlur,
  useExplicitButton,
  debounceTime,
  width,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, debounceTime ?? 300);

  const handleSearch = () => {
    if (!query?.length && !allowEmptyQuery) return;
    onSearch(query);
  };

  useEffect(() => {
    if (!autoSearchOnDebounce) return;
    handleSearch();
  }, [debouncedQuery]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        <TextField
          {...props}
          value={query ?? ''}
          type="text"
          inputProps={{ className: classes.input }}
          onChange={(e) => setQuery(e.target.value)}
          onBlur={autoSearchOnBlur ? handleSearch : undefined}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <SearchIcon
          htmlColor={theme.palette.text.secondary}
          className={classes.icon}
          onClick={handleSearch}
        />
      </Box>
      {useExplicitButton ? (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleSearch}
        >
          Search
        </Button>
      ) : null}
    </Box>
  );
};

export default SearchField;
