import { Link } from 'react-router-dom';
import { ALL_FUNDS } from 'constants/routes';
import { Divider } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import CmtCard from 'components/CmtCard';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import GridContainer from 'components/GridContainer';
import Box from 'components/Box';
import useClosedTranchesByDeploymentStatusCount from 'hooks/data/dashboards/useClosedTranchesByDeploymentStatusCount';
import Skeleton from '@material-ui/lab/Skeleton';

const TrancheCountsByStatus = ({ classes, firmId }) => {
  const { data: trancheCountsByStatus, isFetching } =
    useClosedTranchesByDeploymentStatusCount({
      firmId,
    });

  return (
    <CmtCard>
      {/** @ts-ignore */}
      <CmtCardHeader
        title="Closed tranches"
        className={classes.compactCardHeader}
        icon={<LockOutlined className={classes.green} />}
      >
        <Link
          className={classes.viewAll}
          to={`${ALL_FUNDS}?fundStatus=closed-all`}
        >
          View all
        </Link>
      </CmtCardHeader>
      <Divider className={classes.divider} />
      <CmtCardContent>
        <GridContainer>
          <Grid item md={6} xs={12}>
            <Box
              title={
                isFetching ? (
                  <Skeleton variant="text" animation="wave" />
                ) : (
                  trancheCountsByStatus.fullyDeployed
                )
              }
              subtitle="Fully deployed"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              title={
                isFetching ? (
                  <Skeleton variant="text" animation="wave" />
                ) : (
                  trancheCountsByStatus.notFullyDeployed
                )
              }
              subtitle="Closed, not fully deployed"
            />
          </Grid>
        </GridContainer>
      </CmtCardContent>
    </CmtCard>
  );
};

export default TrancheCountsByStatus;
