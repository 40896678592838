import React from 'react';
import { Breadcrumbs, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { startCase } from 'lodash';

const useStyles = makeStyles(() => ({
  textSm: {
    fontSize: 12,
  },
  linkBlock: {
    display: 'block',
    color: 'inherit',
  },
  cursor: {
    cursor: 'pointer',
  },
  textColor: {
    color: '#828885',
  },
}));

const formatLabel = (input, withoutFormatting) => {
  if (input.includes('/')) {
    return input
      .split('/')
      .map((segment) => (withoutFormatting ? segment : startCase(segment)))
      .join('/');
  } else {
    return withoutFormatting ? input : startCase(input);
  }
};

const PageBreadcrumbs = ({ items, ...rest }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs className="bread-crumbs" aria-label="breadcrumb" {...rest}>
      {items.map((item, index) =>
        item.onClick ? (
          <div key={index} onClick={item.onClick} className={classes.cursor}>
            <Typography
              className={clsx(
                classes.textSm,
                !item.isActive && classes.textColor,
              )}
              color="textPrimary"
            >
              {formatLabel(item.label, item.withoutFormatting)}
            </Typography>
          </div>
        ) : item.isActive ? (
          <Typography
            key={index}
            className={classes.textSm}
            color="textPrimary"
          >
            {formatLabel(item.label, item.withoutFormatting)}
          </Typography>
        ) : (
          <NavLink
            key={index}
            className={clsx(classes.textSm, classes.linkBlock)}
            color="inherit"
            to={item.link || '/'}
          >
            {formatLabel(item.label, item.withoutFormatting)}
          </NavLink>
        ),
      )}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
