import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepper: {
    background: 'transparent',
    marginBottom: '2rem',
    paddingLeft: 0,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    padding: theme.spacing(8),
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(4),
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: theme.spacing(2),
  },
  toggle: {
    display: 'flex',
    gridGap: '.5rem',
    alignItems: 'center',
    marginRight: 15,
  },
  notifyInvestorWarning: {
    marginTop: 4,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationColor: '#b0b0b0',
    textUnderlineOffset: '2px',
    color: '#656565',

    '& svg': {
      marginTop: -2,
      marginLeft: 4,
      marginRight: 8,
    },
  },
}));

export default useStyles;
