import { sumBy } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { numberToCurrencyString } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  },
  tableFooterTd: {
    padding: '2rem 0 2rem 6px',
    fontWeight: 'bold',
  },
}));

const ReviewShareholdingsTableFooter = ({ shareholdings, isBeingEdited }) => {
  const classes = useStyles();

  const tableFooterSumValues = {
    sharesAllocatedForSale: sumBy(shareholdings, 'sharesAllocatedForSale'),
    additionalGrossReceivedValue: sumBy(
      shareholdings,
      'additionalGrossReceivedValue',
    ),
    totalSaleValue: sumBy(shareholdings, 'totalSaleValue'),
    performanceFee: sumBy(shareholdings, 'performanceFee'),
    accruedFeesCharged: sumBy(shareholdings, 'accruedFeesCharged'),
    totalToBeReturned: sumBy(shareholdings, 'totalToBeReturned'),
    netTaxableValue: sumBy(shareholdings, 'netTaxableValue'),
  };

  return (
    <tbody>
      <tr className={classes.tableFooter}>
        <td className={classes.tableFooterTd} colSpan={3}></td>
        <td className={classes.tableFooterTd}>
          {numberToDisplayString(tableFooterSumValues.sharesAllocatedForSale)}
        </td>
        <td className={classes.tableFooterTd}>
          {numberToCurrencyString(tableFooterSumValues.totalSaleValue)}
        </td>
        {isBeingEdited && (
          <td className={classes.tableFooterTd}>
            {numberToCurrencyString(
              tableFooterSumValues.additionalGrossReceivedValue,
            )}
          </td>
        )}
        <td className={classes.tableFooterTd}>
          {numberToCurrencyString(tableFooterSumValues.performanceFee)}
        </td>
        <td className={classes.tableFooterTd}>
          {numberToCurrencyString(tableFooterSumValues.accruedFeesCharged)}
        </td>
        <td className={classes.tableFooterTd}>
          {numberToCurrencyString(tableFooterSumValues.totalToBeReturned)}
        </td>
        <td className={classes.tableFooterTd}></td>
        <td className={classes.tableFooterTd}>
          {numberToCurrencyString(tableFooterSumValues.netTaxableValue)}
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewShareholdingsTableFooter;
