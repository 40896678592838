// TODO: Refactor the drop image / preview image method to a function
import { useEffect, useState } from 'react';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import PageContainer from 'components/PageContainer';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useParams } from 'react-router';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { Button, Divider, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FIRM } from 'constants/routes';
import { zodResolver } from '@hookform/resolvers/zod';
import { history } from '@redux/store';

import { FormProvider, useForm } from 'react-hook-form';
import FundPageInformation from './FundPageInformation';
import AccountManagersTable from './AccountManagersTable';
import useFileUpload from 'hooks/ui/useFileUpload';
import { z } from 'zod';
import { useNotification } from 'hooks/ui/useNotification';
import WhitelabellingSettings from './WhitelabellingSettings';
import GeneralInformation from './GeneralInformation';
import FirmLogos from './FirmLogos';
import BankAccountDetailsForFees from './BankAccountDetailsForFees';
import BankAccountDetailsForIncomingPayments from './BankAccountDetailsForIncomingPayments';
import {
  CompanyBreakdownDisplayMode,
  TaxReliefDisplayMode,
  InvestmentIdentifierDisplayMode,
  TileType,
  ShowSubscriptionPagePerformanceChartMode,
} from 'further-types/firm';
import { isValidEmail } from 'further-ui/utils';
import { UploadType } from 'further-types/files';
import InvestorPortalSettings from './InvestorPortalSettings';
import WithdrawalFees from './WithdrawalFees';
import { sortBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  errorTextGroup: {
    color: 'red',
    fontSize: 12,
    margin: '12px 0',
  },
  divider: {
    display: 'flex',
    width: '100%',
    margin: '24px 0 -12px 12px',
    opacity: 0.5,
  },
  switchLabel: {
    margin: '0 10px',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  bodyCopy: {
    color: '#656565',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBook,
    margin: '12px',
    width: '100%',
  },
  subHeading: {
    fontSize: 13,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#656565',
  },
  subLabel: {
    fontSize: 13,
    letterSpacing: 0,
    fontWeight: 'bold',
    color: '#656565',
  },
  inlineField: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addAnotherFeeButton: {
    padding: '0 !important',
    marginLeft: '1rem',
  },
  addFeeButton: {
    padding: '0 !important',
  },
  subDivider: {
    display: 'flex',
    margin: '16px auto',
    width: '80%',
    opacity: 0.5,
  },
}));

const schema = z.object({
  firmName: z
    .string({ required_error: 'Please enter a firm name.' })
    .min(1, 'Please enter a firm name.'),
  description: z.string().max(125).optional(),
  fundDescription: z.string().max(200).optional(),
  keyContactName: z
    .string({ required_error: 'Please enter a key contact name.' })
    .min(1, 'Please enter a key contact name.'),
  keyContactEmail: z
    .string({ required_error: 'Please enter a valid email address.' })
    .email({ message: 'Please enter a valid email address.' })
    .min(1, 'Please enter a valid email address.'),
  secondaryContactName: z.string().nullish().optional(),
  secondaryContactEmail: z
    .string()
    .optional()
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'Please enter a valid email address.',
    }),
  systemEmailRecipient: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        const trimmedValue = value.split(';').map((email) => email.trim());
        const isValid = trimmedValue.every((email) => {
          if (email) {
            return isValidEmail(email);
          }
          return true;
        });
        return isValid;
      },
      {
        message:
          'Separate emails with a semi-colon, e.g. name@domain.com;name2@domain.com',
      },
    ),
  excelFilesPassword: z.string().nullish().optional(),
  domain: z.string({ required_error: 'Please enter subdomain.' }),
  aboutFirm: z.string().nullable().optional(),
  firmLogo: z.string().nullable().optional(),
  investmentFirmLogo: z.string().nullable().optional(),
  contactDetails: z.object({
    keyChecks: z.array(z.string().nullish()),
    phoneHours: z.string().optional(),
    email: z
      .string()
      .email({ message: 'Please enter a valid email address.' })
      .optional(),
    phoneNo: z.string().optional(),
  }),
  whiteLabelConfig: z.object({
    colorBgLogo: z.object({ url: z.string().optional() }),
    whiteBgLogo: z.object({ url: z.string().optional() }),
    primaryColor: z.string().optional(),
    primaryHoverColor: z.string().optional(),
    secondaryColor: z.string().optional(),
    bgColor: z.string().optional(),
    clientServiceEmail: z
      .string({ required_error: 'Please enter a valid email address.' })
      .email({ message: 'Please enter a valid email address.' }),
    showInvestmentLink: z.boolean().optional(),
    enableKyb: z.boolean().optional(),
    showMultipleOnInvestment: z.boolean().optional(),
    showMultipleOnInvestedCapital: z.boolean().optional(),
    allowPortalCashWithdrawals: z.boolean().optional(),
    configurableTile1: z.nativeEnum(TileType).optional(),
    configurableTile2: z.nativeEnum(TileType).optional(),
    hideCashBalanceBefore: z.date().optional().nullable(),
    syndicatesArrangement: z
      .enum(['taxYear', 'calendarYear', 'all'])
      .optional(),
    showSubscriptionPagePerformanceChart: z
      .nativeEnum(ShowSubscriptionPagePerformanceChartMode)
      .optional(),
    syndicatePortalDisplayName: z
      .string({
        required_error: 'Please enter a display name for syndicates.',
      })
      .min(1, 'Please enter a display name for syndicates.'),
    emailFooterText: z.string().optional(),
    taxReliefDisplayMode: z
      .boolean()
      .transform((checked) =>
        checked ? TaxReliefDisplayMode.Actual : TaxReliefDisplayMode.Forecast,
      ),
    companyBreakdownDisplayMode: z
      .boolean()
      .transform((checked) =>
        checked
          ? CompanyBreakdownDisplayMode.UnderSummaryTable
          : CompanyBreakdownDisplayMode.SeparateTab,
      ),
    investmentIdentifierDisplayMode: z
      .nativeEnum(InvestmentIdentifierDisplayMode)
      .optional(),
  }),
  bankAccountForFeesExport: z.object({
    accountName: z
      .string({ required_error: 'Please enter a bank account name.' })
      .min(1, 'Please enter a bank account name.'),
    accountNumber: z
      .string({ required_error: 'Please enter a bank account number.' })
      .min(1, 'Please enter a bank account number.')
      .regex(/^[0-9]{8}$/g, {
        message: 'Please enter an 8 digit account number.',
      }),
    accountSortCode: z
      .string({ required_error: 'Please enter a bank account sort code.' })
      .min(1, 'Please enter a bank account sort code.')
      .regex(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
        message: 'Please enter an 6 digit account sort code.',
      }),
    reference: z
      .string({ required_error: 'Please enter a bank transfer reference.' })
      .min(1, 'Please enter a bank transfer reference.'),
  }),
  summaryAttachments: z
    .array(
      z.object({
        fileName: z.string().optional(),
        filePath: z.string().optional(),
      }),
    )
    .optional(),
  withdrawalFeeText: z.string().optional(),
  withdrawalFees: z
    .array(
      z.object({
        vat: z.number(),
        amount: z.number().positive(),
        rangeEnd: z.number().optional().nullable(),
        rangeStart: z.number().optional().nullable(),
      }),
    )
    .optional()
    .refine(
      (fees) => {
        if (!fees.length) return true;

        return !fees.some(
          (fee) => fee.rangeEnd && !fee.rangeStart && fee.rangeStart !== 0,
        );
      },
      {
        message:
          'A `From` value must be entered if a `To` value is supplied. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees.length) return true;

        return !fees.some(
          (fee) => fee.rangeEnd && fee.rangeStart >= fee.rangeEnd,
        );
      },
      {
        message:
          'The start of each range must be less than the end of the range. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees.length) return true;

        // Sort fees by rangeStart to simplify overlap checking
        const sortedFees = sortBy(fees, 'rangeStart', 'asc');

        return !sortedFees.find(
          (fee, idx) =>
            sortedFees[idx - 1] &&
            fee.rangeStart &&
            fee.rangeStart <= (sortedFees[idx - 1]?.rangeEnd || Infinity), // If the previous fee has no rangeEnd, no new higher ranges can apply
        );
      },
      {
        message:
          'There is an overlap in the withdrawal fee ranges. Please adjust the ranges accordingly.',
      },
    )
    .refine(
      (fees) => {
        if (!fees.length) return true;

        // An open range must be the only fee
        return !(
          fees.length > 1 &&
          fees.some((fee) => !fee.rangeStart && !fee.rangeEnd)
        );
      },
      {
        message:
          'A fee with no range must be the only fee. Please adjust the ranges accordingly',
      },
    ),
});

const InvestmentFirmAddEditScreen = () => {
  const classes = useStyles();
  const { uploadFile } = useFileUpload();
  const [accountManagerData, setKeyManagersData] = useState([]);
  const { id } = useParams();
  const { error, success } = useNotification();

  const isCreatePage = !id;

  const { firm, createFirm, updateFirm, createAccountManager } = useFirm({
    params: { firmId: id },
  });

  const [previewImages, setPreviewImages] = useState({
    firmLogo: null,
    investmentFirmLogo: null,
    whiteLabelConfig: {
      colorBgLogo: {
        url: null,
      },
      whiteBgLogo: {
        url: null,
      },
    },
  });

  useEffect(() => {
    if (firm.data) {
      setPreviewImages({
        firmLogo: firm.data?.firmLogo,
        investmentFirmLogo: firm.data?.investmentFirmLogo,
        whiteLabelConfig: {
          colorBgLogo: {
            url: firm.data?.whiteLabelConfig?.colorBgLogo?.url,
          },
          whiteBgLogo: {
            url: firm.data?.whiteLabelConfig?.whiteBgLogo?.url,
          },
        },
      });
      setKeyManagersData(firm.data.accountManagerData);
    }
  }, [firm.data]);

  const formMethods = useForm({
    shouldFocusError: true,
    resolver: zodResolver(schema),
    criteriaMode: 'all',
    values: {
      withdrawalFees: firm.data?.withdrawalFees ?? [],
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Firms', link: '/firm' },
    {
      label: id ? 'Update Firm' : 'Add Firm',
      link: '/',
      isActive: true,
    },
  ];

  const onSubmit = async (data) => {
    try {
      if (isCreatePage) {
        const response = await createFirm.mutateAsync({ data });
        if (response.status === 200) {
          success(response.data.responseMsg);
          const managerResponse = await createAccountManager.mutateAsync({
            accountManagerData,
            firmId: response.data.data._id,
          });
          setKeyManagersData(managerResponse.data);
        }
      } else {
        const response = await updateFirm.mutateAsync(data);
        success(response.responseMsg);
      }

      history.push(FIRM);
    } catch (err) {
      error(err);
    }
  };

  const handleImageUpload = async (files, onSuccess) => {
    if (!files?.[0]?.path) return;
    try {
      const { url } = await uploadFile(files[0], UploadType.FirmLogos);
      onSuccess(url);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  const handleFileUpload = async (files, onSuccess) => {
    if (!files?.[0]?.path) return;
    try {
      const { filePath } = await uploadFile(files[0], UploadType.FirmDocuments);

      onSuccess(files?.[0]?.path, filePath);
    } catch (_) {
      error("Something went wrong - the file couldn't be uploaded");
    }
  };

  if (firm.isLoading && !isCreatePage) return <div>Loading...</div>;

  return (
    <PageContainer
      heading={id ? 'Firm: Firm Details' : 'Add Firm'}
      breadcrumbs={breadcrumbs}
    >
      <CmtCard>
        <FormProvider {...formMethods}>
          <form>
            <CmtCardHeader title="General information" />
            <CmtCardContent>
              <GridContainer md={12}>
                <GeneralInformation
                  control={control}
                  errors={errors}
                  firm={firm}
                  classes={classes}
                  handleFileUpload={handleFileUpload}
                />
                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader title="Firm logos" />
            <CmtCardContent>
              <GridContainer md={12}>
                <FirmLogos
                  control={control}
                  setValue={setValue}
                  firm={firm}
                  handleImageUpload={handleImageUpload}
                  previewImages={previewImages}
                  setPreviewImages={setPreviewImages}
                />
                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader title="White labelling settings" />
            <CmtCardContent>
              <GridContainer md={12}>
                <WhitelabellingSettings
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  firm={firm}
                  handleImageUpload={handleImageUpload}
                  previewImages={previewImages}
                  setPreviewImages={setPreviewImages}
                  classes={classes}
                />
                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader title="Investor portal settings" />
            <CmtCardContent>
              <GridContainer md={12}>
                <InvestorPortalSettings
                  control={control}
                  firm={firm}
                  errors={errors}
                  classes={classes}
                />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader title="Withdrawal fees" />
            <CmtCardContent>
              <GridContainer md={10}>
                <WithdrawalFees
                  control={control}
                  firm={firm}
                  errors={errors}
                  classes={classes}
                />

                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            {!isCreatePage && (
              <>
                <CmtCardHeader title="Fund bank account details for incoming payments" />
                <CmtCardContent>
                  <GridContainer md={12}>
                    <BankAccountDetailsForIncomingPayments firm={firm.data} />
                    <Divider className={classes.divider} />
                  </GridContainer>
                </CmtCardContent>
              </>
            )}

            <CmtCardHeader title="Fund bank account details for fee exports" />
            <CmtCardContent>
              <GridContainer md={12}>
                <BankAccountDetailsForFees
                  control={control}
                  firm={firm}
                  errors={errors}
                  classes={classes}
                />
                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader
              title="Individual manager information"
              tooltipText="Anyone in this list may be featured in the Manager’s update section of your investor reporting."
            />
            <CmtCardContent>
              <GridContainer md={12}>
                <AccountManagersTable
                  accountManagerData={accountManagerData ?? []}
                  setKeyManagersData={setKeyManagersData}
                />
                <Divider className={classes.divider} />
              </GridContainer>
            </CmtCardContent>

            <CmtCardHeader title="Fund page information" />
            <CmtCardContent>
              <GridContainer md={12}>
                <FundPageInformation
                  firmId={id}
                  portfolioData={firm.data?.portfolioData ?? []}
                  firm={firm}
                  errors={errors}
                  isCreatePage={isCreatePage}
                />
              </GridContainer>
            </CmtCardContent>

            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} justifyContent="flex-end" container>
                  <Link to={FIRM}>
                    <Button variant="outlined">Cancel</Button>
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      updateFirm.isLoading ||
                      createAccountManager.isLoading ||
                      createFirm.isLoading
                    }
                    className={classes.marginLeftBtn}
                  >
                    {isCreatePage ? 'Save' : 'Update'}
                  </Button>
                </Grid>
              </GridContainer>
            </CmtCardContent>

            {Object.values(errors).length > 0 && (
              <CmtCardContent>
                <p className={classes.errorText}>
                  There is an error with one or more fields above. Please review
                  all errors and try again.
                </p>
              </CmtCardContent>
            )}
          </form>
        </FormProvider>
      </CmtCard>
    </PageContainer>
  );
};

export default InvestmentFirmAddEditScreen;
