import {
  numberToCurrencyString,
  maskAccNumber,
  maskSortCode,
} from 'further-ui/utils';

const commonColumns = (firmId) => [
  {
    label: 'Investor name',
    key: 'investorFullName',
    sort: false,
    render: (elm) => `${elm?.fullName}`,
  },
  {
    label: 'Bank details',
    key: 'bankDetails',
    sort: false,
    render: (elm) => {
      const accNo = elm?.bankDetails?.[0]?.bankAccountNumber;
      const sc = elm?.bankDetails?.[0]?.bankSortCode;
      if (accNo && sc) return `${maskAccNumber(accNo)} ${maskSortCode(sc)}`;
      return 'No bank details found';
    },
  },
  {
    label: 'Available to withdraw',
    key: 'amountAvailable',
    sort: false,
    render: (elm) => {
      const cashBalance =
        elm?.cashBalances?.find((cb) => cb.firm._id === firmId)?.cashBalance ||
        0;
      return numberToCurrencyString(cashBalance);
    },
  },
];
export default commonColumns;
