import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import FieldRow from 'components/FormElements/FieldRow';
import TextField from 'components/FormElements/AppTextInput';

const BankAccountDetailsForFees = ({ control, errors, firm, classes }) => (
  <>
    <FieldRow title="Bank account name*" centerTitle>
      <Controller
        name="bankAccountForFeesExport.accountName"
        defaultValue={firm.data?.bankAccountForFeesExport?.accountName}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Enter bank account name"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.bankAccountForFeesExport?.accountName?.message}
      </p>
    </FieldRow>
    <FieldRow title="Bank account number*" centerTitle>
      <Controller
        name="bankAccountForFeesExport.accountNumber"
        defaultValue={firm.data?.bankAccountForFeesExport?.accountNumber}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumberFormat
            {...rest}
            inputRef={ref}
            placeholder="Enter bank account number"
            format="########"
            customInput={TextField}
            type="text"
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.bankAccountForFeesExport?.accountNumber?.message}
      </p>
    </FieldRow>

    <FieldRow title="Bank account sort code*" centerTitle>
      <Controller
        name="bankAccountForFeesExport.accountSortCode"
        defaultValue={firm.data?.bankAccountForFeesExport?.accountSortCode}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumberFormat
            {...rest}
            inputRef={ref}
            placeholder="Enter bank sort code"
            format="##-##-##"
            customInput={TextField}
            type="text"
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.bankAccountForFeesExport?.accountSortCode?.message}
      </p>
    </FieldRow>
    <FieldRow title="Bank transfer reference*" centerTitle>
      <Controller
        name="bankAccountForFeesExport.reference"
        defaultValue={firm.data?.bankAccountForFeesExport?.reference}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            {...rest}
            inputRef={ref}
            placeholder="Enter bank transfer reference"
            fullWidth
          />
        )}
      />
      <p className={classes.errorText}>
        {errors.bankAccountForFeesExport?.reference?.message}
      </p>
    </FieldRow>
  </>
);

export default BankAccountDetailsForFees;
