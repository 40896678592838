import { investorTypeToLabel } from 'utils/investorType';
import { InvestorType } from 'further-types/investor';
import { dateToLabel } from 'further-ui/utils';
import { IconButton, makeStyles } from '@material-ui/core';
import { useDisclosure } from 'further-ui/hooks';
import { useGetRole } from 'hooks/ui/useGetRole';
import { CloudDownload, Delete } from '@material-ui/icons';

import AlertDialog from 'components/AlertDialog';

const useStyles = makeStyles(() => ({
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  certificationRow: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  reviewBtn: {
    height: 20,
  },
}));

type Props = {
  certification: {
    _id: string;
    investorType: InvestorType;
    certificationPerformedAt: string;
  };
  onDelete: (certificationId: string) => void;
  onDownload: (certification: Props['certification']) => void;
  isDeleting: boolean;
};

const ExpiredCertificationResult: React.FC<Props> = ({
  certification,
  onDelete,
  isDeleting,
  onDownload,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const deleteDisclosure = useDisclosure();

  return (
    <div key={certification._id} className={classes.certificationRow}>
      <div>
        <span className={classes.value}>
          {investorTypeToLabel(certification.investorType)}
        </span>
        <span>
          {` ${dateToLabel(
            certification.certificationPerformedAt,
            null,
            true,
          )}`}
        </span>
      </div>
      <IconButton
        size="small"
        title="Download"
        className={classes.reviewBtn}
        onClick={() => onDownload(certification)}
      >
        <CloudDownload color="primary" />
      </IconButton>
      {isSuperAdmin && (
        <IconButton
          size="small"
          title="Download"
          className={classes.reviewBtn}
          disabled={isDeleting}
          onClick={() => deleteDisclosure.onOpen()}
        >
          <Delete color="error" />
        </IconButton>
      )}
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Warning"
        content="Are you sure you wish to delete this classification? This cannot be undone."
        btnLabels={{ confirm: 'Delete', cancel: 'Go back' }}
        confirmBtnProps={{
          variant: 'contained',
          color: 'primary',
          disabled: isDeleting,
        }}
        onConfirm={() => {
          onDelete(certification._id);
          deleteDisclosure.onClose();
        }}
      />
    </div>
  );
};

export default ExpiredCertificationResult;
