import { FC, useState } from 'react';
import { isEmpty } from 'lodash';
import { dateToLabel } from 'further-ui/utils';
import Table from 'components/Table';
import { useListDelegatedAccessGiven } from 'hooks/data/delegatedAccess/useListDelegatedAccess';
import { useGetRole } from 'hooks/ui/useGetRole';
import { Typography, Box, Button } from '@material-ui/core';
import { Api } from 'further-types/delegated-access';
import AddNewDelegatedAccessUser from './AddNewDelegatedAccessUser';
import EditDelegatedAccessUserEmail from './EditDelegatedAccessUserEmail';
import DelegatedAccessUserActions from './DelegatedAccessUserActions';

type Props = {
  investorId: string;
  headerClassName: string;
};

const columns = [
  {
    label: 'Delegated access user',
    key: 'investorName',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      `${elm?.delegateReceivingAccess?.fullName}`,
  },
  {
    label: 'Email address',
    key: 'email',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      elm?.delegateReceivingAccess?.email,
  },
  {
    label: 'Access given on',
    key: 'accessGrantedDate',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) =>
      dateToLabel(elm?.accessGrantedDate),
  },
  {
    label: 'Copied into emails?',
    key: 'accessGrantedDate',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) => (
      <EditDelegatedAccessUserEmail
        copyIntoSystemEmails={elm.copyIntoSystemEmails}
        delegatedAccessId={elm._id}
      />
    ),
  },
  {
    label: 'Actions',
    key: 'actions',
    sort: false,
    render: (elm: Api.DelegatedAccessGiven) => (
      <DelegatedAccessUserActions delegateAccessId={elm._id} />
    ),
  },
];

const DelegatedUsersTable = ({ users }) => (
  <Table
    columns={columns}
    tablebody={users ?? []}
    variant="nohover"
    emptyMessage="This investor has no delegated access users."
    pagination={false}
  />
);

const DelegatedAccessUsers: FC<Props> = ({ investorId, headerClassName }) => {
  const { isSuperAdmin, firmId } = useGetRole();
  const [isAddNewPanelOpen, setIsAddNewPanelOpen] = useState(false);
  const usersWithAccessByFirm = useListDelegatedAccessGiven({
    investorId,
  });

  const toggleAddNewDelegatedAccessUserPanel = () => {
    setIsAddNewPanelOpen(!isAddNewPanelOpen);
  };

  const addNewDelegatedAccessButton = (
    <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button onClick={toggleAddNewDelegatedAccessUserPanel} color="primary">
        Add new delegated access user
      </Button>
    </Box>
  );

  if (!isSuperAdmin) {
    const users = usersWithAccessByFirm.data?.[firmId];

    return (
      <>
        <Typography variant="h2" className={headerClassName}>
          Delegated access users
        </Typography>
        <DelegatedUsersTable users={users} />
        {addNewDelegatedAccessButton}
        {!isAddNewPanelOpen ? null : (
          <AddNewDelegatedAccessUser
            investorId={investorId}
            headerClassName={headerClassName}
            closePanel={toggleAddNewDelegatedAccessUserPanel}
          />
        )}
      </>
    );
  }

  const usersByFirm = !isEmpty(usersWithAccessByFirm.data)
    ? Object.entries(usersWithAccessByFirm.data)
    : [[]];

  return (
    <>
      {usersByFirm.map(
        (
          [_firmId, users], // eslint-disable-line @typescript-eslint/no-unused-vars
          i,
        ) => (
          <div key={i} style={{ marginTop: '3rem' }}>
            <Typography variant="h2" className={headerClassName}>
              {users
                ? `Delegated access users - ${users[0]?.firmId?.firmName}`
                : 'Delegated access users'}
            </Typography>
            <DelegatedUsersTable users={users} />
          </div>
        ),
      )}
      {addNewDelegatedAccessButton}
      {!isAddNewPanelOpen ? null : (
        <AddNewDelegatedAccessUser
          investorId={investorId}
          headerClassName={headerClassName}
          closePanel={toggleAddNewDelegatedAccessUserPanel}
        />
      )}
    </>
  );
};

export default DelegatedAccessUsers;
