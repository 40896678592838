import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import LogRocket from 'logrocket';

const logger = createLogger({});
const logRocket = LogRocket.reduxMiddleware();
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

function configureStore(initialState = {}) {
  const store = createStore(
    reducers(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(...[routeMiddleware, thunk, logger, logRocket]),
    ),
  );
  return store;
}

export default configureStore;
export { history };
