import { Controller } from 'react-hook-form';
import FieldRow from 'components/FormElements/FieldRow';
import Dropzone from 'components/Dropzone';

const FirmLogos = ({
  control,
  setValue,
  firm,
  handleImageUpload,
  previewImages,
  setPreviewImages,
}) => (
  <>
    <FieldRow
      title="PDF update front page logo"
      informationText="(Primary colour background)"
    >
      <Controller
        name="firmLogo"
        defaultValue={firm.data?.firmLogo}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Dropzone
            {...rest}
            ref={ref}
            onDropFile={async (files) => {
              await handleImageUpload(files, (url) => {
                setValue('firmLogo', url);
                setPreviewImages({
                  ...previewImages,
                  firmLogo: url,
                });
              });
            }}
            files={
              previewImages.firmLogo
                ? [
                    {
                      preview: previewImages.firmLogo,
                    },
                  ]
                : []
            }
            onRemove={() => {
              setValue('firmLogo', null);
              setPreviewImages({
                ...previewImages,
                firmLogo: null,
              });
            }}
          />
        )}
      />
    </FieldRow>
    <FieldRow
      title="Favicon and Further site logo"
      informationText="(Square only, any colour background)"
    >
      <Controller
        name="investmentFirmLogo"
        defaultValue={firm.data?.investmentFirmLogo}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Dropzone
            {...rest}
            ref={ref}
            onDropFile={async (files) => {
              await handleImageUpload(files, (url) => {
                setValue('investmentFirmLogo', url);
                setPreviewImages({
                  ...previewImages,
                  investmentFirmLogo: url,
                });
              });
            }}
            files={
              previewImages.investmentFirmLogo
                ? [
                    {
                      preview: previewImages.investmentFirmLogo,
                    },
                  ]
                : []
            }
            onRemove={() => {
              setValue('investmentFirmLogo', null);
              setPreviewImages({
                ...previewImages,
                investmentFirmLogo: null,
              });
            }}
          />
        )}
      />
    </FieldRow>
    <FieldRow
      title="Portal and email header logo"
      informationText="(Transparent/no background)"
    >
      <Controller
        name="whiteLabelConfig.whiteBgLogo.url"
        defaultValue={firm.data?.whiteLabelConfig?.whiteBgLogo?.url}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Dropzone
            {...rest}
            ref={ref}
            onDropFile={async (files) => {
              await handleImageUpload(files, (url) => {
                setValue('whiteLabelConfig.whiteBgLogo.url', url);
                setPreviewImages({
                  ...previewImages,
                  whiteLabelConfig: {
                    ...previewImages.whiteLabelConfig,
                    whiteBgLogo: { url },
                  },
                });
              });
            }}
            files={
              previewImages.whiteLabelConfig.whiteBgLogo.url
                ? [
                    {
                      preview: previewImages.whiteLabelConfig.whiteBgLogo.url,
                    },
                  ]
                : []
            }
            onRemove={() => {
              setValue('whiteLabelConfig.whiteBgLogo.url', null);
              setPreviewImages({
                ...previewImages,
                whiteLabelConfig: {
                  ...previewImages.whiteLabelConfig,
                  whiteBgLogo: { url: null },
                },
              });
            }}
          />
        )}
      />
    </FieldRow>
    <FieldRow
      title="Subscription flow logo"
      informationText="(Square only, primary colour background)"
    >
      <Controller
        name="whiteLabelConfig.colorBgLogo.url"
        defaultValue={firm.data?.whiteLabelConfig?.colorBgLogo?.url}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Dropzone
            {...rest}
            ref={ref}
            onDropFile={async (files) => {
              await handleImageUpload(files, (url) => {
                setValue('whiteLabelConfig.colorBgLogo.url', url);
                setPreviewImages({
                  ...previewImages,
                  whiteLabelConfig: {
                    ...previewImages.whiteLabelConfig,
                    colorBgLogo: { url },
                  },
                });
              });
            }}
            files={
              previewImages.whiteLabelConfig.colorBgLogo.url
                ? [
                    {
                      preview: previewImages.whiteLabelConfig.colorBgLogo.url,
                    },
                  ]
                : []
            }
            onRemove={() => {
              setValue('whiteLabelConfig.colorBgLogo.url', null);
              setPreviewImages({
                ...previewImages,
                whiteLabelConfig: {
                  ...previewImages.whiteLabelConfig,
                  colorBgLogo: { url: null },
                },
              });
            }}
          />
        )}
      />
    </FieldRow>
  </>
);
export default FirmLogos;
