import Grid from '@material-ui/core/Grid';
import { Fragment } from 'react';
import { format, parseISO } from 'date-fns';
import GridContainer from 'components/GridContainer';
import { dateofBirthToLabel, dateToLabel } from 'further-ui/utils';
import useStyles from './styles';
import DownloadTransferButton from '../DownloadTransferButton';

type Props = {
  investorDetail: any;
};

const LegalEntityDetails: React.FC<Props> = ({ investorDetail }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Registered name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.registeredName}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Registration number</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.registrationNumber}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Registration date</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.registrationDate
                ? dateofBirthToLabel(investorDetail?.registrationDate)
                : '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Registration state</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.registrationState}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Email address</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.email || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Key contact first name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.keyContactFirstName || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Key contact surname</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.keyContactSurname || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Key contact phone number</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.keyContactPhoneNumber || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Date registered</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.createdAt
                ? format(parseISO(investorDetail?.createdAt), 'dd/MM/yyyy')
                : '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Last login</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {dateToLabel(investorDetail.lastLoginDate, '-', true, true)}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Email verified</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.isEmailVerify ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        {investorDetail.address && investorDetail.address.length
          ? investorDetail.address.map((single, index) => {
              return (
                <Fragment key={index}>
                  <GridContainer>
                    <Grid item xs={12} md={5}>
                      <span className={classes.label}>
                        {index === 0 ? 'Current address' : 'Previous address'}
                      </span>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {single?.isManual ? (
                        <div className={classes.value}>
                          <div>{single?.address1}</div>
                          <div>{single?.address2}</div>
                          <div>{single?.city}</div>
                          <div>
                            {/* we force postcodes to be a string on investor upload but there may be some legacy 
                              number postcodes remaining so check type before converting to upperCase */}
                            {typeof single?.postcode === 'string'
                              ? single?.postcode.toUpperCase()
                              : single?.postcode}
                          </div>
                          <div>{single?.country}</div>
                        </div>
                      ) : (
                        <div className={classes.value}>
                          <div>{single?.address1}</div>
                          <div>{single?.address2}</div>
                          <div>{single?.city}</div>
                          <div>
                            {typeof single?.postcode === 'string'
                              ? single?.postcode.toUpperCase()
                              : single?.postcode}
                          </div>
                          <div>{single?.country}</div>
                        </div>
                      )}
                    </Grid>
                  </GridContainer>
                  {index === 0 && (
                    <GridContainer>
                      <Grid item xs={5}>
                        <span className={classes.label}>
                          This was the entity’s registered address before 3
                          years?
                        </span>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={classes.value}>
                          {investorDetail?.address &&
                          investorDetail?.address.length === 1
                            ? 'Yes'
                            : 'No'}
                        </span>
                      </Grid>
                    </GridContainer>
                  )}
                </Fragment>
              );
            })
          : []}
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>External investor ID</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.externalInvestorId || '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Vulnerable client</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.isVulnerableClient ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Deceased</span>
          </Grid>
          <Grid item xs={1}>
            <span className={classes.value}>
              {investorDetail?.isDeceased ? 'Yes' : 'No'}
            </span>
          </Grid>

          {investorDetail?.isDeceased && (
            <Grid item xs={8} md={5} className={classes.downloadTransferButton}>
              <DownloadTransferButton
                investorId={investorDetail._id}
                investorName={investorDetail.registeredName}
              />
            </Grid>
          )}
        </GridContainer>
      </Grid>

      {investorDetail?.isDeceased && (
        <Grid item xs={12} md={8}>
          <GridContainer>
            <Grid item xs={5}>
              <span className={classes.label}>Date of death</span>
            </Grid>
            <Grid item xs={7}>
              <span className={classes.value}>
                {investorDetail?.dateOfDeath
                  ? dateofBirthToLabel(investorDetail?.dateOfDeath)
                  : '-'}
              </span>
            </Grid>
          </GridContainer>
        </Grid>
      )}

      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>UK remittance taxpayer (BIR)</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetail?.isUkRemittanceTaxpayer ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
    </>
  );
};

export default LegalEntityDetails;
