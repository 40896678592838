import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { dateToLabel, numberToCurrencyString } from 'further-ui/utils';
import Chip from 'components/Chip';
import { Box, Divider, IconButton, Tooltip } from '@material-ui/core';
import Table from 'components/Table';
import { usePagination } from 'hooks/ui/usePagination';
import { InfoOutlined } from '@material-ui/icons';
import { useInvestments } from 'hooks/data/investment/useInvestments';

export const InvestmentsByTrancheTable = ({ trancheId, classes }) => {
  const pagination = usePagination();

  const investmentsQuery = useInvestments({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      type: pagination.order,
      keyName: pagination.orderBy,
      fundId: trancheId,
    },
  });

  const columns = [
    {
      label: 'Investor name',
      key: 'fullName',
    },
    {
      label: 'Email',
      key: 'email',
      render: (elm) => elm?.investorId?.email,
    },
    {
      label: 'Subscription',
      key: 'investmentAmount',
      render: (elm) => numberToCurrencyString(elm?.investmentAmount),
    },
    {
      label: (
        <Box display="flex" alignItems="center" gridGap="4px">
          <span>Total current value</span>
          <Tooltip
            title={
              <>
                This is the combined value of all current holdings, exits and
                uninvested paid in capital. Where there are unpaid commitments,
                this figure may be materially lower than subscribed investment
                capital.
              </>
            }
          >
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      key: 'investmentValue',
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.investmentValue),
    },
    {
      label: 'Payment status',
      key: 'isPaymentReceived',
      render: (elm) => (
        <>
          {elm?.isFullyFunded ? (
            <Chip small variant="success">
              Fully funded
            </Chip>
          ) : elm?.isPartiallyFunded ? (
            <Chip small variant="disabled">
              Partially funded
            </Chip>
          ) : (
            <Chip small variant="disabled">
              No payment
            </Chip>
          )}
        </>
      ),
    },
    {
      label: 'Adviser',
      key: 'adviser',
      render: (elm) => elm.adviserId?.fullName || '-',
    },
    {
      label: 'Subscription ID',
      key: 'externalInvestmentId',
      render: (elm) => elm?.externalInvestmentId || '-',
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
  ];

  return (
    <CmtCard>
      {/* @ts-ignore */}
      <CmtCardHeader title="All Subscriptions"></CmtCardHeader>
      <Divider className={classes.divider} />
      <CmtCardContent className={classes.scrollableTable}>
        <Table
          columns={columns}
          tablebody={investmentsQuery?.data?.result ?? []}
          order={pagination.order}
          orderBy={pagination.orderBy}
          onRequestSort={pagination.handleRequestSort}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={investmentsQuery?.data?.total ?? 0}
          pagination={true}
          loading={investmentsQuery.isFetching}
          emptyMessage="No fund subscriptions yet"
          variant="nohover"
        />
      </CmtCardContent>
    </CmtCard>
  );
};
