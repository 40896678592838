import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';

import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import FieldRow from 'components/FormElements/FieldRow';
import AppSelectBox from 'components/FormElements/AppSelectBox';

import { TAG } from 'constants/routes';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { TagTaxReliefType } from 'further-types/tag';
import { useTag } from 'hooks/data/tag/useTag';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
}));

const TagForm = () => {
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [taxReliefType, setTaxReliefType] = useState<TagTaxReliefType>();
  const [isSyndicate, setIsSyndicate] = useState(false);
  const [errorText, setErrorText] = useState<{
    name?: string;
    taxReliefType?: string;
  }>({});
  const handleApiRequest = useApiRequestHandler();

  const { tag, createTag, updateTag } = useTag(id);

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Fund Types', link: '/tag' },
    {
      label: id ? 'Update Fund Type' : 'Add Fund Type',
      link: '/',
      isActive: true,
    },
  ];

  const validate = () => {
    const errors: {
      name?: string;
      taxReliefType?: string;
    } = {};
    let isError = false;
    if (!name) {
      errors.name = 'Please enter fund type.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const onSubmit = async () => {
    const validateData = validate();
    if (!validateData.isError) {
      await handleApiRequest(async (success) => {
        const response = id
          ? await updateTag.mutateAsync({
              name,
              taxReliefType,
              isSyndicate,
            })
          : await createTag.mutateAsync({ name, taxReliefType, isSyndicate });

        success(response.responseMsg);
        history.push(TAG);
      });
    }
  };

  useEffect(() => {
    if (!tag.data) return;

    setName(tag.data.data.name);
    setTaxReliefType(tag.data.data.taxReliefType);
    setIsSyndicate(tag.data.data.isSyndicate);
  }, [tag.data]);

  return (
    <React.Fragment>
      <PageContainer
        heading={id ? 'Update Fund Type' : 'Add Fund Type'}
        breadcrumbs={breadcrumbs}
      >
        <CmtCard>
          <CmtCardContent>
            <GridContainer md={6}>
              <FieldRow title="Fund Type">
                <TextField
                  required
                  name="name"
                  fullWidth
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                  error={!!errorText.name}
                  helperText={errorText.name}
                />
              </FieldRow>
              <FieldRow title="Tax relief type">
                <AppSelectBox
                  required
                  name="taxReliefType"
                  fullWidth
                  value={taxReliefType}
                  data={Object.values(TagTaxReliefType).map(
                    (taxReliefType) => ({
                      id: taxReliefType,
                      title: taxReliefType,
                    }),
                  )}
                  onChange={(event) =>
                    setTaxReliefType(event.target.value as TagTaxReliefType)
                  }
                  error={!!errorText.taxReliefType}
                  helperText={errorText.taxReliefType}
                />
              </FieldRow>
              <FieldRow title="Is Syndicate?">
                <AppSelectBox
                  required
                  name="isSyndicate"
                  fullWidth
                  value={isSyndicate}
                  data={[
                    {
                      id: true,
                      title: 'Yes',
                    },
                    {
                      id: false,
                      title: 'No',
                    },
                  ]}
                  onChange={(event) =>
                    setIsSyndicate(event.target.value as boolean)
                  }
                />
              </FieldRow>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  {id ? 'Update' : 'Save'}
                </Button>
                <Link to={TAG}>
                  <Button className={classes.marginLeftBtn} variant="contained">
                    Cancel
                  </Button>
                </Link>
              </Grid>
            </GridContainer>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </React.Fragment>
  );
};
export default TagForm;
