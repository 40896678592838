import { useEffect } from 'react';
import {
  TableCell,
  TableBody,
  TableRow,
  Grid,
  TableHead,
  makeStyles,
  Button,
  Table,
} from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import FieldRow from 'components/FormElements/FieldRow';
import {
  getCachedGridData,
  clearGridDataFromCache,
} from 'helpers/uploadInvestorInvestment/gridHelpers';
import { useInvestmentUpload } from 'hooks/data/investment/useInvestmentUpload';
import { useNotification } from 'hooks/ui/useNotification';
import { numberToCurrencyString } from 'further-ui/utils';

const useStyles = makeStyles((theme) => ({
  totalInvestorTable: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    padding: '0 0 24px',
    '& > .MuiGrid-item': {
      padding: '10px 0',
    },
  },
  note: {
    fontSize: 16,
    color: '#656565',
    marginBottom: theme.spacing(6),
  },
  scrollableTable: {
    overflow: 'auto',
    padding: '10px 0',
    margin: '0 0 24px',
    clear: 'both',
  },
  TableHead: {
    backgroundColor: 'rgba(234, 233, 232, .2)',
  },
  TableHeadCell: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 120,
    },
    borderBottomColor: '#EDEDED',
    lineHeight: '22px',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'normal',
  },
  TableBodyCell: {
    borderBottomColor: '#EDEDED',
  },
  ButtonGroup: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  SaveButton: {
    minWidth: '120px',
    float: 'right',
  },
}));

const PreviewTable = ({
  totalInvestors,
  existingInvestorAccounts,
  handleSubmitCallback,
  handleBack,
  tranches,
  firmId,
}) => {
  const classes = useStyles();

  const investmentUpload = useInvestmentUpload();
  const { error } = useNotification();

  const loading = investmentUpload.save.isLoading;

  useEffect(() => {
    if (investmentUpload.save.isSuccess) {
      handleSubmitCallback();
      clearGridDataFromCache();
    }
  }, [investmentUpload.save.isSuccess]);

  useEffect(() => {
    if (investmentUpload.save.isError) {
      error('Something went wrong. Please try again later.');
    }
  }, [investmentUpload.save.isError]);

  const gridData = getCachedGridData();
  const handleSubmitClick = () =>
    investmentUpload.save.mutate({
      gridData,
      firmId,
    });

  const getInvestorName = (row: {
    email: string;
    registered_name?: string;
    firstname?: string;
    surname?: string;
  }) => {
    const existingInvestor = existingInvestorAccounts.find(
      (investor: { email: string }) => investor.email === row.email,
    );
    return existingInvestor
      ? existingInvestor.fullName
      : row.registered_name
      ? row.registered_name
      : `${row.firstname} ${row.surname}`;
  };

  return (
    <Grid item md={12}>
      <CardHeader title="Upload preview" />
      <div className={classes.totalInvestorTable}>
        <FieldRow key="1" title="Date">
          {new Date().toLocaleDateString()}
        </FieldRow>
        <FieldRow key="2" title="Investors">
          {totalInvestors}
        </FieldRow>
        <FieldRow key="3" title="Total subscription value being uploaded">
          {numberToCurrencyString(
            gridData.reduce((acc, curr) => acc + curr.investment_amount, 0),
          )}
        </FieldRow>
      </div>
      {existingInvestorAccounts?.length > 0 && (
        <>
          <div className={classes.note}>
            The following uploaded investors have an email address and date of
            birth that match an existing Further account. Upon clicking submit,
            the system will upload all uploaded subscriptions but will connect
            subscriptions made by the below investors with their existing
            Further accounts. All new and existing investors will be visible
            within your investor database.
          </div>
          <div className={classes.scrollableTable}>
            <Table>
              <TableHead className={classes.TableHead}>
                <TableRow>
                  <TableCell className={classes.TableHeadCell}>Name</TableCell>
                  <TableCell className={classes.TableHeadCell}>
                    Birth/registration date
                  </TableCell>
                  <TableCell className={classes.TableHeadCell}>
                    Email address
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existingInvestorAccounts.map((row) => {
                  return (
                    <TableRow key={row.index}>
                      <TableCell className={classes.TableBodyCell}>
                        {getInvestorName(row)}
                      </TableCell>
                      <TableCell className={classes.TableBodyCell}>
                        {row.dob}
                      </TableCell>
                      <TableCell className={classes.TableBodyCell}>
                        {row.email}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      )}
      <p className={classes.note}>
        All investors and subscriptions will be uploaded upon clicking submit.
      </p>

      <div className={classes.scrollableTable}>
        <Table>
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell className={classes.TableHeadCell}>Name</TableCell>
              <TableCell className={classes.TableHeadCell}>
                Birth/registration date
              </TableCell>
              <TableCell className={classes.TableHeadCell}>Tranche</TableCell>
              <TableCell className={classes.TableHeadCell}>
                Subscription amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gridData.map((row) => {
              const tranche = tranches?.find((t) => t._id === row.tranche);
              const trancheLabel = `${tranche?.firmId.firmName} ${tranche?.fundName}`;

              return (
                <TableRow key={row.index}>
                  <TableCell className={classes.TableBodyCell}>
                    {getInvestorName(row)}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {row.dob}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {trancheLabel}
                  </TableCell>
                  <TableCell className={classes.TableBodyCell}>
                    {numberToCurrencyString(row.investment_amount)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.ButtonGroup}>
        {handleBack && (
          <Button
            component="label"
            color="primary"
            variant="outlined"
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          color={loading ? 'secondary' : 'primary'}
          component="label"
          disabled={loading}
          onClick={handleSubmitClick}
          className={classes.SaveButton}
        >
          {loading ? 'Saving...' : 'Submit'}
        </Button>
      </div>
    </Grid>
  );
};

export default PreviewTable;
