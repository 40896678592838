import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
  },
  label: {
    color: '#656565',
    fontSize: 12,
  },
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  download: {
    marginLeft: 10,
  },
  downloadTransferButton: {
    height: 26,
    paddingTop: '8px !important',
    marginBottom: 20,
  },
  exportSpinner: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 10,
  },
  hiddenDatePickerInput: {
    visibility: 'hidden',
    width: 0,
  },
}));

export default useStyles;
