import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/collection-history';

export function useCollectionHistoryEvents(
  params: Api.ListCollectionHistoryEventsRequestQuery,
) {
  return useQuery({
    queryKey: ['collection-history', params],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.ListCollectionHistoryEventsResponseBody>>(
          '/collection-history/events',
          { params },
        )
      ).data.data,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
    enabled: !!params.collection,
    retry: 1,
  });
}

export function useGetCollectionNames() {
  return useQuery({
    queryKey: ['collection-history-collection-names'],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.ListCollectionNamesResponseBody>>(
          '/collection-history/collection-names',
        )
      ).data.data,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}
