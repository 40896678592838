import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import InvestmentDetails from './InvestmentDetails';
import { InvestmentDetails as InvestmentDetailsType } from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import { dateToLabel } from 'further-ui/utils';
import { useFormContext } from 'react-hook-form';

type InvestmentsListProps = {
  investments: InvestmentDetailsType[];
};

const hasLiveShareholdings = (investment: InvestmentDetailsType) =>
  !!investment.shareholdings.filter(
    (shareholding) => shareholding.noOfShare > 0,
  ).length;

const InvestmentsList: React.FC<InvestmentsListProps> = ({ investments }) => {
  const classes = useStyles();
  const { setValue } = useFormContext();

  const handleClearShares = (investment: InvestmentDetailsType) => {
    investment.shareholdings
      .filter((shareholding) => shareholding.noOfShare > 0)
      .forEach((shareholding) => {
        setValue(
          `sharesToTransfer[${investment.investmentId}][${shareholding.id}]`,
          0,
        );
      });
  };

  const handleSelectAllShares = (investment: InvestmentDetailsType) => {
    investment.shareholdings
      .filter((shareholding) => shareholding.noOfShare > 0)
      .forEach((shareholding) => {
        setValue(
          `sharesToTransfer[${investment.investmentId}][${shareholding.id}]`,
          shareholding.noOfShare,
        );
      });
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="30px">
      {investments.filter(hasLiveShareholdings).map((investment) => (
        <div key={investment.investmentId} className={classes.tableHeader}>
          <Box className={classes.investmentHeader}>
            <Typography variant="h6" component="div">
              {investment.fundName} - {dateToLabel(investment.investmentDate)}
            </Typography>
            <Box display="flex" gridGap={16} className={classes.tableButtons}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleClearShares(investment)}
              >
                Clear all shares
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleSelectAllShares(investment)}
              >
                Select all shares
              </Button>
            </Box>
          </Box>
          <InvestmentDetails investment={investment} />
        </div>
      ))}
    </Box>
  );
};

export default InvestmentsList;
