import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  transferLabel: {
    width: 'calc(100% + 48px)',
    top: 0,
    left: 0,
    color: theme.palette.text.white,
    backgroundColor: theme.palette.background.rootColor,
    padding: '6px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '25px',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-24px',
    marginRight: '-24px',
    marginTop: '-24px',
  },
}));

export default useStyles;
