import { FC } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { InvestmentPaymentType } from 'further-types/payment';
import { Api } from 'further-types/investment';
import { Tooltip } from 'components/Tooltip';

type TableElement = Api.InvestmentPaymentTransaction & { isFormRow: boolean };

type Props = {
  elm: TableElement;
  onAddPayment: () => void;
  isAddingPayment: boolean;
  unreconcile: (id: string) => void;
  isUnreconciling: boolean;
};

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  addPaymentButton: {
    whiteSpace: 'nowrap',
    lineHeight: '19px !important',
  },
});

const unreconcileLabel = (paymentType: InvestmentPaymentType) =>
  paymentType === InvestmentPaymentType.CashBalance ? 'Reverse' : 'Unreconcile';

const tooltipLabel = (
  <div>
    Removing this payment would result in <em>funds received</em> becoming lower
    than <em>allocated funds</em> plus{' '}
    <em>funds reserved for future cash fees</em>. Please remove an allocation or
    add a fee discount before unreconciling this payment.
  </div>
);

const FormActions: FC<Props> = ({
  elm,
  onAddPayment,
  isAddingPayment,
  unreconcile,
  isUnreconciling,
}) => {
  const classes = useStyles();

  return !elm.isFormRow ? (
    <div className={classes.actionsContainer}>
      {elm.canBeUnreconciled ? (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isUnreconciling || isAddingPayment}
          onClick={() => unreconcile(elm._id)}
          size="small"
        >
          {isUnreconciling ? (
            <CircularProgress size={20} style={{ margin: '0 1.5rem' }} />
          ) : (
            unreconcileLabel(elm.paymentType)
          )}
        </Button>
      ) : (
        <Tooltip title={tooltipLabel}>
          <span>
            <Button
              variant="outlined"
              color="secondary"
              disabled={true}
              size="small"
            >
              {unreconcileLabel(elm.paymentType)}
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  ) : (
    <div className={classes.actionsContainer}>
      <Button
        variant="outlined"
        color="primary"
        disabled={isAddingPayment || isUnreconciling}
        onClick={onAddPayment}
        size="small"
        className={classes.addPaymentButton}
      >
        {isAddingPayment ? (
          <CircularProgress size={19} style={{ margin: '0 1.5rem' }} />
        ) : (
          'Add payment'
        )}
      </Button>
    </div>
  );
};
export default FormActions;
