// @ts-nocheck
import CmtCard from 'components/CmtCard';
import { Divider, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { BusinessCenter, HowToReg, PeopleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import Box from 'components/Box';
import { numberToDisplayString } from 'utils/numbers';
import { ADVISER, INVESTOR, ORGANISATION, COMPANY } from 'constants/routes';
import Skeleton from '@material-ui/lab/Skeleton';
import useEntitySummary from 'hooks/data/dashboards/useEntitySummary';

type Props = {
  parentClasses: Record<string, string>;
  firmId: string;
};

const useStyles = makeStyles(() => ({
  cardHeader: {
    padding: '20px 20px 15px',
  },
}));

const EntitySummary: React.FC<Props> = ({ parentClasses, firmId }) => {
  const classes = useStyles();

  const { data, isFetching } = useEntitySummary({ firmId });

  return (
    <>
      <Grid item sm={6} md={3} xs={12}>
        <CmtCard>
          <CmtCardHeader
            className={classes.cardHeader}
            title="Investors"
            icon={<HowToReg className={parentClasses.green} />}
          />
          <Divider className={parentClasses.divider} />
          <CmtCardContent>
            <Box
              title={
                isFetching ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  numberToDisplayString(data?.investors)
                )
              }
              subtitle={
                <span>
                  <Link to={INVESTOR} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CmtCard>
          <CmtCardHeader
            className={classes.cardHeader}
            title="Advisers"
            icon={<PeopleOutline className={parentClasses.green} />}
          />
          <Divider className={parentClasses.divider} />
          <CmtCardContent>
            <Box
              title={
                isFetching ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  numberToDisplayString(data?.advisers)
                )
              }
              subtitle={
                <span>
                  <Link to={ADVISER} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CmtCard>
          <CmtCardHeader
            className={classes.cardHeader}
            title="Organisations"
            icon={<PeopleOutline className={parentClasses.green} />}
          />
          <Divider className={parentClasses.divider} />
          <CmtCardContent>
            <Box
              title={
                isFetching ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  numberToDisplayString(data?.organisations)
                )
              }
              subtitle={
                <span>
                  <Link to={ORGANISATION} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>
      <Grid item sm={6} md={3} xs={12}>
        <CmtCard>
          <CmtCardHeader
            className={classes.cardHeader}
            title="Companies"
            icon={<BusinessCenter className={parentClasses.green} />}
          />
          <Divider className={parentClasses.divider} />
          <CmtCardContent>
            <Box
              title={
                isFetching ? (
                  <Skeleton animation="wave" variant="text" />
                ) : (
                  numberToDisplayString(data?.investees)
                )
              }
              subtitle={
                <span>
                  <Link to={COMPANY} className={parentClasses.link}>
                    View all
                  </Link>
                </span>
              }
            />
          </CmtCardContent>
        </CmtCard>
      </Grid>
    </>
  );
};

export default EntitySummary;
