import { useState } from 'react';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import { Divider } from '@material-ui/core';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import InvestmentInflowChart from './InvestmentInflowChart';
import useInvestmentInflowSummary from 'hooks/data/dashboards/useInvestmentInflowSummary';

const InvestmentsInflowSummary = ({ parentClasses, firmId }) => {
  const labels = [
    { title: 'All time', slug: 'all-time' },
    { title: 'Year to date', slug: 'this-year' },
    { title: 'UK tax year to date', slug: 'this-uk-tax-year' },
    { title: 'Past year', slug: 'past-year' },
    { title: 'Past UK tax year', slug: 'past-uk-tax-year' },
    { title: 'Past 6 months', slug: 'past-six-months' },
    { title: 'Past quarter', slug: 'past-quarter' },
    { title: 'Past month', slug: 'past-month' },
    { title: 'Past week', slug: 'past-week' },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(labels[0].slug);

  const { data: investmentInflowSummary } = useInvestmentInflowSummary({
    firmId,
    dateRangeKey: selectedDateRange,
  });

  const dataSource = investmentInflowSummary || {
    inflowByDate: [],
    investmentAmount: 0,
    investors: 0,
  };

  return (
    <CmtCard flex>
      {/* @ts-ignore */}
      <CmtCardHeader title="Lifetime fundraise record" subTitle="All tranches">
        <AppSelectBox
          fullWidth
          style={{ minWidth: '180px' }}
          data={labels}
          label="Date range"
          valueKey="slug"
          variant="outlined"
          labelKey="title"
          value={selectedDateRange}
          onChange={(e) => setSelectedDateRange(e.target.value as string)}
        />
      </CmtCardHeader>

      <Divider className={parentClasses.divider} />
      <CmtCardContent>
        {dataSource.inflowByDate.length > 0 && (
          <InvestmentInflowChart data={dataSource.inflowByDate} />
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

export default InvestmentsInflowSummary;
