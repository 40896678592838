import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { z } from 'zod';
import Grid from '@material-ui/core/Grid';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { useForm, Controller } from 'react-hook-form';
import { userInformation } from '@redux/actions/Auth';
import DropzoneComponent from 'components/Dropzone';
import { DASHBOARD } from 'constants/routes';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  validatePassword,
  validateUserName,
  validationMessages,
} from 'further-ui/utils';
import useMyProfile from 'hooks/data/currentUser/useMyProfile';
import { useNotification } from 'hooks/ui/useNotification';
import useFileUpload from 'hooks/ui/useFileUpload';
import { UploadType } from 'further-types/files';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  dropZone: {
    minHeight: 0,
  },
}));

const schema = z.object({
  firstName: z
    .string()
    .nonempty('Please enter last name.')
    .refine((firstName) => !validateUserName(firstName), {
      message: validationMessages.nameValidationError,
    }),
  lastName: z
    .string()
    .nonempty('Please enter last name.')
    .refine((lastName) => !validateUserName(lastName), {
      message: validationMessages.nameValidationError,
    }),
  email: z
    .string()
    .email({ message: 'Please enter a valid email address.' })
    .nonempty({ message: 'Please enter email address.' }),
  profilePic: z.string().optional(),
  password: z
    .string()
    .refine((password) => !validatePassword(password), {
      message: validationMessages.passwordValidationError,
    })
    .optional(),
});

const Profile = () => {
  const dispatch = useDispatch();
  const { success, error } = useNotification();
  const { uploadFile } = useFileUpload();
  const classes = useStyles();
  const [profilePicPreview, setProfilePicPreview] = useState();
  const [profilePic, setProfilePic] = useState();

  const {
    profile: { data, isFetching },
    updateProfile: { mutate, isLoading },
  } = useMyProfile();

  useEffect(() => {
    if (!data) return;

    setProfilePicPreview(data.profilePic);
    setProfilePic(data.profilePic);
  }, [data]);

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    resolver: zodResolver(schema),
    criteriaMode: 'all',
  });

  const onSubmit = async (formData) => {
    const diff = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== data[key]) {
        acc.push(key);
      }
      return acc;
    }, []);

    mutate(
      {
        ...formData,
        profilePic: profilePic?.includes('.com/')
          ? profilePic.split('.com/')[1]
          : profilePic,
        updates: diff,
      },
      {
        onError: () => {
          error('Error when trying to update the profile.');
        },
        onSuccess: (data) => {
          success('Profile updated successfully.');
          dispatch(userInformation({ user: data?.data }));
          resetField('password');
        },
      },
    );
  };

  const handleProfileImage = async (file) => {
    if (!file?.[0]?.path) return;

    try {
      const { filePath } = await uploadFile(file[0], UploadType.UserImages);

      setProfilePicPreview(file?.[0]?.preview);
      setProfilePic(filePath);
    } catch (_) {
      error(`Something went wrong - the file couldn't be uploaded`);
    }
  };

  return (
    <PageContainer
      className=""
      heading="Profile"
      breadcrumbs={[
        { label: 'Dashboard', link: '/dashboard' },
        { label: 'Profile', link: '/', isActive: true },
      ]}
    >
      {isFetching ? (
        <CircularProgress />
      ) : (
        <CmtCard>
          <CmtCardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstName"
                    defaultValue={data?.firstName}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        label="First name"
                        variant="outlined"
                        required
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lastName"
                    defaultValue={data?.lastName}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        label="Last name"
                        variant="outlined"
                        required
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    defaultValue={data?.email}
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <TextField
                        {...rest}
                        inputRef={ref}
                        label="Email Address"
                        variant="outlined"
                        required
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        fullWidth
                        disabled
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <AppPasswordInput
                        {...rest}
                        inputRef={ref}
                        label="Password"
                        error={errors.password?.message}
                        labelWidth={80}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    component="h4"
                    variant="inherit"
                    className={classes.commanMargin}
                    required
                  >
                    Profile Image
                  </Typography>
                  <DropzoneComponent
                    onDropFile={(files) => handleProfileImage(files)}
                    onRemove={() => {
                      setProfilePicPreview(null);
                      setProfilePic(null);
                    }}
                    files={
                      profilePicPreview ? [{ preview: profilePicPreview }] : []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                  >
                    Update
                  </Button>
                  <Link to={DASHBOARD}>
                    <Button
                      className={classes.marginLeftBtn}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Link>
                </Grid>
              </GridContainer>
            </form>
          </CmtCardContent>
        </CmtCard>
      )}
    </PageContainer>
  );
};

export default Profile;
