import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchAccountManagers({ queryKey }) {
  const [, firmId] = queryKey;
  const {
    data: { data },
  } = await api.get(`investor-reporting/account-managers`, {
    params: { firmId },
  });
  return data;
}

export function useReportingAccountManagers(firmId) {
  return useQuery({
    queryKey: ['investor-reporting-account-managers', firmId],
    queryFn: fetchAccountManagers,
    enabled: !!firmId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
