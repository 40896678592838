import React, { useState, memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import AppSelectBox from 'components/FormElements/AppSelectBox';

const useStyles = makeStyles(() => ({
  placeholder: {
    color: '#999999',
  },
}));

const TrancheMultiSelect = ({
  tranches,
  handleChangeSelectedTranches,
  disabled,
  value,
  selectAllLabel = 'Select all',
}) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    selectedTrancheIds: value || [],
    tranchesSelectAll: false,
  });

  // This function handles the change event for the multiselect in the tranches dropdown.
  // It updates the state with the selected tranche ids.
  const handleTrancheMultiSelectChange = async (event) => {
    const {
      target: { value },
    } = event;
    let trancheIds = formState.selectedTrancheIds;
    let tranchesSelectAll = formState.tranchesSelectAll;
    if (value.includes('all')) {
      // if already checked all, then uncheck all
      if (formState.tranchesSelectAll) {
        trancheIds = [];
      } else {
        trancheIds = tranches.map((fund) => fund._id);
      }
      tranchesSelectAll = !tranchesSelectAll;
    } else {
      if (value.length === trancheIds.length) {
        if (!tranchesSelectAll) tranchesSelectAll = !tranchesSelectAll;
      } else {
        tranchesSelectAll = false;
      }
      trancheIds = value;
    }
    // set the new state
    setFormState({
      ...formState,
      tranchesSelectAll,
      selectedTrancheIds: trancheIds,
    });
    handleChangeSelectedTranches(trancheIds);
  };

  return (
    <>
      <AppSelectBox
        data={tranches}
        valueKey="_id"
        displayEmpty
        name="trancheIds"
        labelKey="label"
        multiple
        variant="outlined"
        disabled={disabled}
        value={formState.selectedTrancheIds}
        isCheckedAll={formState.tranchesSelectAll}
        selectAllLabel={selectAllLabel}
        onChange={handleTrancheMultiSelectChange}
        renderValue={(selected) => (
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            {selected.length === 0 ? (
              <span className={classes.placeholder}>Select tranche(s)</span>
            ) : (
              selected
                .map((trancheId) => {
                  return tranches.find((val) => val?._id === trancheId)?.label;
                })
                .join(', ')
            )}
          </Box>
        )}
      />
    </>
  );
};

export default memo(TrancheMultiSelect);
