import { createRoot } from 'react-dom/client';
import App from './App';
import 'react-app-polyfill/stable';
import { initSentry } from 'utils/tracking';

initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
