import React from 'react';
import moment from 'moment';
import { IconButton, Grid, Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import GridContainer from 'components/GridContainer';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import FieldRow from 'components/FormElements/FieldRow';
import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import { TooltipTriggerText } from 'components/TooltipTriggerText';

const useStyles = makeStyles(() => ({
  vCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type RevaluationRowsProps = {
  revaluations: Record<string, any>[];
  setRevaluations: (x: Record<string, any>[]) => void;
  errorTextRevaluation: Record<string, any>[];
  companyId: string;
  classes: Record<string, any>;
};

const RevaluationRows: React.FC<RevaluationRowsProps> = ({
  revaluations,
  setRevaluations,
  errorTextRevaluation,
  companyId,
  classes,
}) => {
  const componentClasses = useStyles();

  const handleInputChange = (e, index, isDate) => {
    if (isDate) {
      const list = [...revaluations];
      list[index]['date'] = e;
      setRevaluations(list);
    } else {
      const { name, value } = e;
      const list = [...revaluations];
      list[index][name] = value;
      setRevaluations(list);
    }
  };

  const handleRemoveRow = (index) => {
    const list = [...revaluations];
    list.splice(index, 1);
    setRevaluations(list);
  };

  const handleAddRow = () => {
    setRevaluations([
      ...revaluations,
      {
        amount: '',
        date: null,
      },
    ]);
  };

  const isDisabled = (revaluation) =>
    revaluation.setVia === 'EXIT' ||
    revaluation.setVia === 'EXIT_AMENDMENT' ||
    revaluation.setVia === 'SHARE_SPLIT' ||
    revaluation.setVia === 'ALLOCATION_WIZARD' ||
    revaluation.setVia === 'INVESTOR_REPORTING';

  const setViaToLabel = (type, shareSplitFactor) => {
    switch (type) {
      case 'DIRECT':
        return 'Set in the company page';
      case 'INVESTOR_REPORTING':
        return 'Set via the update function';
      case 'EXIT':
        return 'Set via an exit';
      case 'EXIT_AMENDMENT':
        return 'Set via an edited exit';
      case 'SHARE_SPLIT':
        return `Set via a share split (Factor: ${shareSplitFactor})`;
      case 'ALLOCATION_WIZARD':
        return 'Set via the allocation wizard';
      default:
        return '';
    }
  };

  return (
    <Grid item xs={12}>
      {revaluations &&
        revaluations.map((x, i) => {
          return (
            <GridContainer key={i} className={classes.rowWrapper}>
              <FieldRow
                title={`Revaluation ${i + 1}`}
                centerTitle
                informationText={setViaToLabel(
                  x.setVia,
                  x.shareSplitId?.shareSplitFactor,
                )}
              >
                <GridContainer alignItems="center">
                  <Grid item md={5}>
                    <NumberFormat
                      onValueChange={({ value }) => {
                        handleInputChange(
                          {
                            name: 'amount',
                            value,
                          },
                          i,
                          false,
                        );
                      }}
                      required
                      disabled={isDisabled(x)}
                      name="amount"
                      placeholder="Enter amount"
                      value={x.amount}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      prefix="£"
                      customInput={AppTextInput}
                      error={errorTextRevaluation[i]?.amount}
                      helperText={errorTextRevaluation[i]?.amount}
                    />
                  </Grid>

                  <Grid item md={5}>
                    <KeyboardDatePicker
                      required
                      placeholder="Select date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="date"
                      value={x.date}
                      onChange={(e) => handleInputChange(e, i, true)}
                      error={errorTextRevaluation[i]?.date}
                      helperText={errorTextRevaluation[i]?.date}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={isDisabled(x)}
                      maxDate={moment().format('YYYY-MM-DD')}
                    />
                  </Grid>

                  <Grid item md={2}>
                    <IconButton
                      size="small"
                      className={classes.deleteButton}
                      onClick={() => handleRemoveRow(i)}
                      disabled={isDisabled(x)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </GridContainer>
              </FieldRow>
            </GridContainer>
          );
        })}
      <div className={classes.buttonContainer}>
        {companyId && (
          <Tooltip
            arrow
            title="If there has been a share split since the company's last share price update, please process this in the share split function before adding a new revaluation."
          >
            <div className={componentClasses.vCenter}>
              <TooltipTriggerText content="Share split?" icon />
            </div>
          </Tooltip>
        )}
        <Button onClick={handleAddRow} variant="text" color="primary">
          Add revaluation
        </Button>
      </div>
    </Grid>
  );
};

export default RevaluationRows;
