// email validator 'e.g. user@domain.ext'
export const emailValidator = (email, required = true) => {
  if (!email) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const email_regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email_regex.test(email.replace(/\s+/g, ''));
};

// validate text is not empty or only spaces
export const textValidator = (text, required = true) => {
  if (!text) {
    return !required; // if it's not required and it's empty, it's valid
  }
  return text.replace(/\s+/g, '').length > 0;
};

// validate date format 'e.g. 01/01/2020'
export const dateValidator = (date, required = true) => {
  if (!date) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const dateTrimmed = date.replace(/\s+/g, '');
  if (dateTrimmed.length === 0) {
    return false;
  }
  const date_regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  if (!date_regex.test(dateTrimmed)) {
    return false;
  }
  const parts = dateTrimmed.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  return day > 0 && day <= monthLength[month - 1];
};

// verify it's a number
export const numberValidator = (number, required = true) => {
  if (!number) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const number_regex = /^\d+$/;
  return number_regex.test(number);
};

// verify it's a number with decimal places
export const decimalValidator = (number, required = true) => {
  if (!number) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const number_regex = /^\d+(\.\d+)?$/;
  return number_regex.test(number);
};

// validate custom dropdowns (the value must be one of the options)
export const customDropdownValidator = (
  value,
  options = [],
  required = true,
) => {
  if (!value) {
    return !required; // if it's not required and it's empty, it's valid
  }
  if (!options || options.length === 0) {
    return value !== null;
  }
  return options.filter((option) => option.value === value).length > 0;
};

// bank account validation 'e.g. 00000000'
export const bankAccountValidator = (account, required = true) => {
  if (!account) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const account_regex = /^\d{8}$/;
  return account_regex.test(account);
};

// sort code validation 'e.g. 00-00-00'
export const sortCodeValidator = (sortCode, required = true) => {
  if (!sortCode) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const sortCode_regex = /^\d{2}-\d{2}-\d{2}$/;
  return sortCode_regex.test(sortCode);
};

// phone numbervalidtation e.g. Numbers, + and () only
export const phoneValidator = (phone, required = true) => {
  if (!phone) {
    return !required; // if it's not required and it's empty, it's valid
  }
  const phone_regex = /^[0-9+()]+$/;
  return phone_regex.test(phone);
};
