import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/FormElements/AppTextInput';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  addBtn: {
    textAlign: 'right',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function InvestmentStrategy({
  handleCancel,
  handleInvestmentStrategy,
  id,
  investmentStrategy,
  setInvestmentStrategy,
}) {
  const classes = useStyles();

  return (
    <GridContainer>
      <Grid item xs={12} md={11}>
        <TextField
          name="investmentStrategy"
          placeholder="Enter the firm's subscription strategy for this tranche in approximately 200-300 words."
          fullWidth
          onChange={(event) => setInvestmentStrategy(event.target.value)}
          value={investmentStrategy}
          variant="outlined"
          multiline
          minRows={6}
        />
      </Grid>
      <Grid item xs={12} md={11}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            onClick={handleInvestmentStrategy}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
