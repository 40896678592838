import { FC, ReactNode, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import clsx from 'clsx';
import { Box, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TableHeading from './TableHeading';
import TableItem from './TableItem';

export const useStyles = makeStyles((theme) => ({
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
  tableBody: {
    position: 'relative',
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      border: '1px solid',
      borderColor: theme.palette.common.dark,
      color: theme.palette.common.dark,
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
    },
  },
  paginationComman: {
    marginTop: 10,
    marginBottom: 0,
    '& .MuiToolbar-root': {
      display: 'flex',
      alignItems: 'center',
      right: '23px',
    },
  },
  cmTable: {
    '& th': {
      whiteSpace: 'nowrap',
      paddingTop: '10px',
      fontSize: '14px',
      fontWeight: '600',
    },
    '& th:first-child': {
      paddingLeft: '10px',
    },
    '& th:last-child': {
      paddingRight: '10px',
    },
    '& td:first-child': {
      paddingLeft: '10px',
    },
    '& td:last-child': {
      paddingRight: '10px',
    },
  },
  '@media screen and (max-width: 359.98px)': {
    paginationComman: {
      '& .MuiToolbar-root': {
        position: 'relative!important',
      },
    },
  },
  // common table footer styling classes
  tFootRow: {
    backgroundColor: '#fbfbfa',

    '&:nth-child(2)': {
      borderBottom: '1px solid #ededed',
      borderTop: '1px solid #ededed',

      '& td': {},
    },
  },
  tFootCell: {
    fontWeight: 'bold',
    padding: '8px 8px 8px 6px',

    '&:nth-child(1)': {
      textAlign: 'right',
      paddingRight: 24,
    },
  },
}));

type Column = {
  label: string | ReactNode;
  key?: string;
  sort?: boolean;
  render?: (row: any, index?: number) => ReactNode | string;
};

type Props = {
  columns: Array<Column>;
  tablebody: Array<any>;
  onRequestSort?: () => void;
  order?: any;
  orderBy?: any;
  onChangeSearch?: () => void;
  search?: string;
  page?: number;
  onPageChange?: () => void;
  count?: number;
  pagination?: boolean;
  loading?: boolean;
  rowsPerPage?: number;
  onRowsPerPageChange?: () => void;
  emptyMessage?: string;
  TableFooter?: ReactNode;
  CustomTableHeading?: ReactNode;
  variant?: string;
  selectable?: boolean;
  onSelectionChange?: (selections: any[]) => void;
  hideEmptyMessage?: boolean;
  collapsedContent?: (props) => ReactNode;
};

const AppTable: FC<Props> = ({
  columns,
  tablebody,
  onRequestSort,
  order,
  orderBy,
  onChangeSearch,
  search,
  page,
  onPageChange,
  count = 0,
  pagination,
  loading,
  rowsPerPage,
  onRowsPerPageChange,
  emptyMessage,
  TableFooter,
  CustomTableHeading,
  variant = 'nohover',
  selectable,
  onSelectionChange,
  hideEmptyMessage = false,
  collapsedContent,
}) => {
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState([]);

  const updateSelection = (selectedIndexes) => {
    setSelectedRowsIndexes(selectedIndexes);
    onSelectionChange?.(
      tablebody?.filter((_, index) => selectedIndexes.includes(index)) ?? [],
    );
  };

  const handleSelect = (selectedRowIndex) => {
    if (!tablebody.length) return;

    const selectedIndexes = selectedRowsIndexes.includes(selectedRowIndex)
      ? selectedRowsIndexes.filter((index) => index !== selectedRowIndex)
      : [...selectedRowsIndexes, selectedRowIndex];

    updateSelection(selectedIndexes);
  };
  const handleSelectAll = () => {
    if (!tablebody.length) return;

    const selectedIndexes =
      selectedRowsIndexes.length < tablebody.length
        ? [...Array(tablebody.length).keys()]
        : [];

    updateSelection(selectedIndexes);
  };

  useEffect(() => {
    if (!selectable) return;

    updateSelection([]);
  }, [selectable, page, rowsPerPage]);

  const classes = useStyles();
  return (
    <div className="Cmt-table-responsive">
      {search ? (
        <Box
          pr={3}
          marginLeft={'auto'}
          marginBottom={3}
          className={classes.searchRoot}
        >
          <InputBase
            placeholder={'Search here...'}
            inputProps={{ 'aria-label': 'search' }}
            onChange={onChangeSearch}
          />
          <SearchIcon />
        </Box>
      ) : (
        ''
      )}
      <Table className={classes.cmTable}>
        <TableHead>
          <TableHeading
            columns={columns}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
            selectable={selectable}
            selectedCount={selectedRowsIndexes.length}
            totalCount={tablebody.length}
            onSelect={handleSelectAll}
            collapsedContent={collapsedContent}
          />
          {CustomTableHeading ? CustomTableHeading : null}
        </TableHead>
        <TableBody className={classes.tableBody}>
          <TableItem
            hideEmptyMessage={hideEmptyMessage}
            columns={columns}
            row={tablebody}
            loading={loading}
            emptyMessage={emptyMessage}
            variant={variant}
            showSkeleton={tablebody.length === 0 && loading}
            rowsPerPage={rowsPerPage}
            selectable={selectable}
            onSelect={handleSelect}
            selectedRowsIndexes={selectedRowsIndexes}
            collapsedContent={collapsedContent}
          />
        </TableBody>
        {TableFooter ? TableFooter : null}
      </Table>
      {pagination ? (
        <div className={clsx(classes.paginationComman)}>
          <TablePagination
            color="primary"
            component="div"
            count={count}
            page={page === 0 ? 0 : page - 1}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppTable;
