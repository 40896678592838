import moment from 'moment';
import FieldRow from 'components/FormElements/FieldRow';
import GridContainer from 'components/GridContainer';
import AppTextInput from 'components/FormElements/AppTextInput';
import NumberFormat from 'react-number-format';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import { Button, Grid } from '@material-ui/core';
import { getLastValuationDate } from 'helpers/company/getCurrentSharePrice';

const DetailsStep = ({
  company,
  shareSplitDate,
  setShareSplitDate,
  shareSplitFactor,
  setShareSplitFactor,
  steps,
  setStep,
  currentSharePriceDisplay,
  classes,
}) => {
  const lastValuationDate = getLastValuationDate(company.data);
  const shareSplitDateIsBeforeLastRevaluation =
    shareSplitDate &&
    moment(shareSplitDate).isSameOrBefore(moment(lastValuationDate), 'day');
  const datePickerErrorText =
    'The share split date must be after the last revaluation date for this company.';

  return (
    <GridContainer item md={8}>
      <FieldRow title="Company" centerTitle>
        {company.data?.tradingName}
      </FieldRow>
      <FieldRow title="Share split date" centerTitle>
        <KeyboardDatePicker
          required
          placeholder="Select date"
          name="date"
          value={shareSplitDate}
          onChange={(val) => setShareSplitDate(val)}
          maxDate={moment().format('YYYY-MM-DD')}
          helperText={
            shareSplitDateIsBeforeLastRevaluation ? datePickerErrorText : ''
          }
          error={shareSplitDateIsBeforeLastRevaluation}
        />
      </FieldRow>
      <FieldRow title="Current share price" centerTitle>
        {currentSharePriceDisplay}
      </FieldRow>
      <FieldRow
        title="Share split factor"
        centerTitle
        tooltipText="This is the factor by which shares will be split. A factor of 10 will multiple all shares by 10 (so that 10 shares of £1 each become 100 shares of £0.10 each). A factor below 1 will result in fewer shares (e.g. a factor of 0.5 will turn 10 shares of £1 each into 5 shares of £2 each)."
      >
        <NumberFormat
          decimalScale={20}
          onValueChange={({ value }) => {
            setShareSplitFactor(value);
          }}
          disabled={company.isLoading}
          value={shareSplitFactor}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          customInput={AppTextInput}
        />
      </FieldRow>
      <Grid item className={classes.buttonAlignEnd}>
        <Button
          onClick={() => setStep(steps.SUMMARY)}
          disabled={
            !shareSplitFactor ||
            !shareSplitDate ||
            company.isLoading ||
            shareSplitDateIsBeforeLastRevaluation
          }
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </Grid>
    </GridContainer>
  );
};

export default DetailsStep;
