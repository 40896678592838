import { useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import CardHeader from 'components/CardHeader';
import ExitDetailsForm from './ExitDetailsForm';
import ReviewShareholdings from './ReviewShareholdings';
import ButtonsAndErrorModals from './ButtonsAndErrorModals';
import FullPageSuccess from 'components/FullPageSuccess';
import {
  EXITS,
  EXITS_EDIT,
  createExitStatementAddRoute,
} from 'constants/routes';
import { downloadExits } from '@redux/actions/Exit';
import { useExit } from 'hooks/data/exit';
import EditExitDetailsForm from './EditExitDetailsForm';
import { useNotification } from 'hooks/ui/useNotification';
import { BottomTextContent } from './BottomTextContent';

const useStyles = makeStyles(() => ({
  header: {
    marginTop: '3rem',
  },
}));

const CreateExit = () => {
  const { id } = useParams();
  const isBeingEdited = useRouteMatch(EXITS_EDIT)?.isExact;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [previousExitDetails, setPreviousExitDetails] = useState({});
  const [exitDetails, setExitDetails] = useState({});
  const [exitDetailsStepCompleted, setExitDetailsStepCompleted] =
    useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [shareholdings, setShareholdings] = useState([]);
  const [exitId, setExitId] = useState(id);
  const [amendmentId, setAmendmentId] = useState(null);
  const [disableTable, setDisableTable] = useState(false);
  const notification = useNotification();

  const [isDownloading, setIsDownloading] = useState(false);

  const { exit, create, edit } = useExit({ params: { id } });

  const handleDownload = async () => {
    setIsDownloading(true);
    await dispatch(downloadExits(exitId, notification));
    setIsDownloading(false);
  };

  useEffect(() => {
    if (isBeingEdited && id && exit.data) {
      const {
        firmId,
        date,
        companyId,
        noSharesExited,
        sharePrice,
        taxReliefElegible,
        taxReliefElegibleAllocationIds,
        taxableValueSharePrice,
      } = exit.data || {};
      const details = {
        firmId: firmId._id,
        exitDate: date,
        selectedCompanyId: companyId?._id,
        selectedCompany: companyId,
        sharesToBeSold: Number(noSharesExited),
        sharePrice: Number(sharePrice),
        taxableValueSharePrice: Number(taxableValueSharePrice),
        taxReliefElegible,
        taxReliefElegibleAllocationIds,
      };
      setExitDetails({
        ...details,
        chargeAccruedFees: true,
      });
      setPreviousExitDetails({
        ...details,
        firmName: exit.data?.firmId?.firmName,
      });
    }
  }, [id, isBeingEdited, exit.data]);

  useEffect(() => {
    if (create.data || edit.data) {
      setAmendmentId(edit?.data?.exitAmendmentId);
      setIsSuccess(true);
    }

    if (create.data) {
      setExitId(create.data?._id);
    }
  }, [create.data, edit.data]);

  if (isSuccess) {
    return (
      <FullPageSuccess
        title="Your exit has been successfully processed"
        additionalText="Your investors will now be able to view this exit in their investor portals. Would you like to send an exit update?"
        proceedText="Send exit update"
        proceedLink={createExitStatementAddRoute(exitId, amendmentId)}
        secondaryText="Do this later"
        secondaryLink={EXITS}
        bottomText={
          <BottomTextContent
            disableButton={isDownloading}
            handleDownload={handleDownload}
          />
        }
      />
    );
  }

  const removeEmptyShareholdingRows = (sh) => {
    if (
      !sh.sharesAllocatedForSale &&
      !sh.accruedFeesCharged &&
      !sh.performanceFee &&
      !sh.totalSaleValue
    )
      return false;
    return true;
  };

  const onSubmit = async () => {
    const payload = {
      firmId: exitDetails.firmId,
      sharePrice: exitDetails.sharePrice,
      taxableValueSharePrice: exitDetails.taxableValueSharePrice,
      taxReliefElegible: exitDetails.taxReliefElegible,
      taxReliefElegibleAllocationIds:
        exitDetails.taxReliefElegibleAllocationIds,
      shareholdingsToBeExited: shareholdings
        .filter(removeEmptyShareholdingRows)
        .map((sh) => ({
          accruedFeesCharged: sh.accruedFeesCharged,
          performanceFee: sh.performanceFee,
          sharePrice: sh.sharePrice,
          taxableValueSharePrice: sh.taxableValueSharePrice,
          shareholdingId: sh.shareholdingId,
          sharesAllocatedForSale: sh.sharesAllocatedForSale,
          totalSaleValue: sh.totalSaleValue, // NB. this is the "received value" cell
          taxableValue: sh.grossTaxableValue,
          ...(isBeingEdited
            ? {
                additionalGrossReceivedValue: sh.additionalGrossReceivedValue,
              }
            : {}),
        })),
    };

    if (isBeingEdited) {
      edit.mutate({
        ...payload,
        exitAmendmentDate: moment(exitDetails.exitDate).format('DD/MM/YYYY'),
        exitId,
      });
    } else {
      create.mutate({
        ...payload,
        exitDate: moment(exitDetails.exitDate).format('DD/MM/YYYY'),
        companyId: exitDetails.selectedCompanyId,
      });
    }
  };

  const onCancel = () => {
    setExitDetailsStepCompleted(false);
    isBeingEdited
      ? setExitDetails({
          ...previousExitDetails,
        })
      : setExitDetails({});
    setShareholdings([]);
    setDisableTable(false);
  };

  const pageTitle = isBeingEdited
    ? 'Exits: Edit exit'
    : 'Exits: Process new exit';

  return (
    <PageContainer heading={pageTitle}>
      <CmtCard>
        <CmtCardContent>
          <CardHeader title="Enter exit details" />
          {isBeingEdited ? (
            <EditExitDetailsForm
              previousExitDetails={previousExitDetails}
              exitDetails={exitDetails}
              onSubmit={(details) => {
                setExitDetails(details);
                setExitDetailsStepCompleted(true);
              }}
              exitDetailsStepCompleted={exitDetailsStepCompleted}
            />
          ) : (
            <ExitDetailsForm
              onSubmit={(details) => {
                setExitDetails(details);
                setExitDetailsStepCompleted(true);
              }}
              exitDetailsStepCompleted={exitDetailsStepCompleted}
            />
          )}

          {exitDetailsStepCompleted && (
            <>
              <CardHeader
                className={classes.header}
                title="Review and edit exit results"
              />
              <ReviewShareholdings
                exitDetails={exitDetails}
                previousExitDetails={previousExitDetails}
                shareholdings={shareholdings}
                setShareholdings={setShareholdings}
                disableTable={disableTable}
                isBeingEdited={isBeingEdited}
                exitId={exitId}
              />
              <ButtonsAndErrorModals
                exitDetails={exitDetails}
                shareholdings={shareholdings}
                setShareholdings={setShareholdings}
                onSubmit={onSubmit}
                onCancel={onCancel}
                isLoading={edit.isLoading || create.isLoading}
                disableTable={disableTable}
                onAmendmentsFileUpload={() => setDisableTable(true)}
                isBeingEdited={isBeingEdited}
              />
            </>
          )}
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default CreateExit;
