import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useEffect, useState } from 'react';
import { useNotification } from 'hooks/ui/useNotification';
import downloadFile from 'utils/downloadFile';

async function fetchAdviser({ queryKey }) {
  try {
    const [, id] = queryKey;

    const {
      data: { data },
    } = await api.get(`adviser/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

async function createAdviser(adviserData) {
  try {
    const { data: response } = await api.post(`adviser/create`, adviserData);
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateAdviser(id, adviserData) {
  try {
    const { data: response } = await api.put(
      `adviser/update/${id}`,
      adviserData,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

function useAdviserCreateAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAdviser,
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['advisers'] }),
        queryClient.invalidateQueries({ queryKey: ['organisations'] }),
      ]),
  });
}

function useAdviserFetchAPI({ params: { id } }) {
  return useQuery({
    queryKey: ['adviser', id],
    queryFn: fetchAdviser,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}

function useAdviserUpdateAPI({ params: { id } }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => updateAdviser(id, data),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['adviser', id] }),
        queryClient.invalidateQueries({ queryKey: ['advisers'] }),
        queryClient.invalidateQueries({ queryKey: ['organisations'] }),
      ]),
  });
}

function useExportStatement(id) {
  const [isFetching, setIsFetching] = useState(false);

  return {
    isFetching,
    fetch: async () => {
      try {
        setIsFetching(true);
        const response = await api.get(`adviser/${id}/statements`, {
          responseType: 'blob',
        });

        if (response.status !== 200) {
          throw Error('Unexpected export response.');
        }

        const filename = response.headers['content-disposition']
          ?.split('filename=')?.[1]
          ?.replaceAll('"', '');

        downloadFile(response.data, filename);
      } catch (error) {
        throw error;
      } finally {
        setIsFetching(false);
      }
    },
  };
}

export function useAdviser(params) {
  const notification = useNotification();
  const create = useAdviserCreateAPI(params);
  const update = useAdviserUpdateAPI(params);

  useEffect(() => {
    const errorMessage =
      create.error?.response?.data?.responseMsg ??
      update.error?.response?.data?.responseMsg;

    if (errorMessage) {
      notification.error(errorMessage);
    }
  }, [
    create.error?.response?.data?.responseMsg,
    update.error?.response?.data?.responseMsg,
    notification,
  ]);

  useEffect(() => {
    const successMessage = create.data?.responseMsg ?? update.data?.responseMsg;

    if (successMessage) {
      notification.success(successMessage);
    }
  }, [create.data?.responseMsg, update.data?.responseMsg, notification]);

  return {
    create,
    update,
    fetch: useAdviserFetchAPI(params),
    exportStatement: useExportStatement(params?.params?.id),
  };
}
