import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Grid from '@material-ui/core/Grid';
import { Button, makeStyles } from '@material-ui/core';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { dateToLabel, dateofBirthToLabel } from 'further-ui/utils';
import {
  investorTypeToLabel,
  investorTypeDropdownList,
} from 'utils/investorType';
import { usePagination } from 'hooks/ui/usePagination';
import useFilters from 'hooks/ui/useFilters';
import { useDebounce } from 'usehooks-ts';
import { useIncompleteRegistrations } from 'hooks/data/investor/useIncompleteRegistrations';
import { useGetRole } from 'hooks/ui/useGetRole';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  marginSelect: {
    marginBottom: 10,
  },
  exportBtn: {
    textAlign: 'right',
  },
  Switches: {
    '& .MuiSwitch-colorSecondary': {
      color: '#56b26c',
    },
  },
}));

const columns = [
  { label: 'Investor name', key: 'fullName' },
  { label: 'Email', key: 'email' },
  {
    label: 'Date of birth',
    key: 'dob',
    render: (elm) => <>{elm.dob ? dateToLabel(elm?.dob) : ''}</>,
  },
  { label: 'Phone number', key: 'phoneNumber' },
  {
    label: 'Investor type',
    key: 'investorType',
    render: (elm) => investorTypeToLabel(elm?.investorType),
  },
  {
    label: 'Created date',
    key: 'createdAt',
    render: (elm) => dateToLabel(elm?.createdAt),
  },
];

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Incomplete Registrations', isActive: true },
];

const exportExcelSheet = (exportData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Incomplete registrations');

  worksheet.columns = [
    { header: 'Investor name', key: 'fullName' },
    { header: 'Email', key: 'email' },
    { header: 'Email Verification', key: 'emailVerified' },
    { header: 'Date of Birth', key: 'dob' },
    { header: 'Phone Number', key: 'phoneNumber' },
    { header: 'Investor Type', key: 'investorType' },
    { header: 'Created Date', key: 'createdAt' },
  ];

  exportData.forEach((item) => {
    worksheet.addRow({
      fullName: item.fullName,
      email: item.email,
      emailVerified: item.isEmailVerify ? 'Verified' : 'Unverified',
      dob: item.dob ? dateofBirthToLabel(item.dob) : '',
      phoneNumber: item.phoneNumber,
      investorType: investorTypeToLabel(item.investorType),
      createdAt: dateToLabel(item.createdAt),
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'Incomplete registrations.xlsx',
    );
  });
};

const IncompleteRegistrations = () => {
  const classes = useStyles();
  const { firmId } = useGetRole();
  const pagination = usePagination({ id: 'incomplete-registrations-table' });
  const { filters, handleChangeFilters } = useFilters(
    'incomplete-registrations-table',
    {
      defaultFilters: {
        investorType: null,
        search: null,
      },
      onFiltersChange: pagination.toFirstPage,
    },
  );

  const debouncedFilters = useDebounce(filters, 400);

  const params = {
    firmId,
    investorType: debouncedFilters.investorType?.id,
    search: debouncedFilters.search,
    page: pagination.page,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
  };

  const { data, isFetching } = useIncompleteRegistrations({ params });

  return (
    <PageContainer heading="Incomplete Registrations" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={4} className={classes.marginSelect}>
                  <TextField
                    type="search"
                    name="newPassword"
                    placeholder="Search by investor name or email"
                    fullWidth
                    value={filters.search}
                    onChange={(event) => {
                      handleChangeFilters({ search: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Autocomplete
                    // @ts-ignore
                    id="investorType"
                    options={investorTypeDropdownList}
                    getOptionLabel={(option) => option.investorType || ''}
                    filterSelectedOptions
                    value={filters.investorType}
                    onChange={(_, newValue) => {
                      handleChangeFilters({ investorType: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select investor type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={classes.exportBtn}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!data}
                    onClick={() =>
                      exportExcelSheet(data?.orderByInvestor || [])
                    }
                  >
                    Export
                  </Button>
                </Grid>
              </GridContainer>
              <Table
                columns={columns}
                onRequestSort={pagination.handleRequestSort}
                order={pagination.order}
                orderBy={pagination.orderBy}
                tablebody={data?.result || []}
                onPageChange={pagination.handleChangePage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                count={data?.total || 0}
                pagination={true}
                loading={isFetching}
                variant="nohover"
              />
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};
export default IncompleteRegistrations;
