import clsx from 'clsx';
import useStyles from './styles';

const SidebarContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.appSidebar, 'Cmt-sidebar')}>
      <div className={clsx(classes.appSidebarContent, 'Cmt-sidebar-content')}>
        {children}
      </div>
    </div>
  );
};

export default SidebarContent;
