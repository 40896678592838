import { Grid, Button, makeStyles, CircularProgress } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { Tooltip } from 'components/Tooltip';
import { useTags } from 'hooks/data/tag/useTags';
import { FundStatus } from 'constants/fundProperties';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    paddingBottom: 2,
  },
  actionButton: {
    marginRight: 8,
  },
  tooltipIcon: {
    fontSize: '18px',
    cursor: 'pointer',
    transform: 'translateY(3px)',
    marginTop: '0.4rem',
    marginLeft: '-1rem',
  },
  datePickerContainer: {
    position: 'relative',
    paddingRight: 25,
  },
  tooltipContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const statusFilterOptions = [
  {
    fundStatus: FundStatus.OPEN,
    fullyDeployed: null,
    name: 'Open',
    key: 'open',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: false,
    name: 'Closed (In deployment)',
    key: 'closed-in-deployment',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: true,
    name: 'Closed (Fully deployed)',
    key: 'closed-fully-deployed',
  },
  {
    fundStatus: FundStatus.CLOSED,
    fullyDeployed: undefined,
    name: 'Closed (All)',
    key: 'closed-all',
  },
];

const TranchesFilters = ({
  filters,
  onFiltersChange,
  onExportClick,
  isExportLoading,
}) => {
  const classes = useStyles();

  const { tags } = useTags({ name: 'EIS' });

  const qs = new URLSearchParams(window.location.search);
  const fundStatusParam = qs.get('fundStatus');

  useEffect(() => {
    if (fundStatusParam) {
      const foundStatus = statusFilterOptions.find(
        (status) => status.key === fundStatusParam,
      );
      if (foundStatus) {
        onFiltersChange({
          status: foundStatus,
        });
      }
    }
  }, [fundStatusParam]);

  const selectedStatus =
    statusFilterOptions.find((option) => option.key === filters?.status?.key) ||
    null;

  return (
    <GridContainer>
      <Grid item md={3} xs={12}>
        <TextField
          required
          name="fundName"
          placeholder="Search by tranche name"
          value={filters?.fundName}
          fullWidth
          onChange={(event) => {
            onFiltersChange({
              fundName: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        {
          <AppAutocomplete
            //@ts-ignore
            name="tagId"
            options={tags?.data ?? []}
            getOptionLabel={(option) => option?.name}
            filterSelectedOptions
            value={filters?.tagId}
            onChange={(_, newValue) => {
              onFiltersChange({
                tagId: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Fund type"
              />
            )}
          />
        }
      </Grid>
      <Grid item md={3} xs={12}>
        <AppAutocomplete
          //@ts-ignore
          id="status"
          options={statusFilterOptions}
          getOptionLabel={(option) => option?.name ?? ''}
          value={selectedStatus}
          filterSelectedOptions
          onChange={(_, newValue) => {
            onFiltersChange({
              ...filters,
              status: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select tranche status"
            />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <div className={classes.datePickerContainer}>
          <AppDatePicker
            placeholder="Select snapshot date"
            name="snapshotDate"
            value={filters.snapshotDate}
            onChange={(date) => {
              onFiltersChange({
                snapshotDate: moment(date).endOf('day').toDate(),
              });
            }}
            disableFuture={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div className={classes.tooltipContainer}>
            <Tooltip
              title={
                <>
                  Use the date picker to view this table on any date in the
                  past. <em>Invested capital</em>, <em>Uninvested capital</em>{' '}
                  and <em>Current value</em> will change to reflect their
                  historic values as at the selected date.
                </>
              }
              placement="top"
            >
              <InfoOutlined className={classes.tooltipIcon} />
            </Tooltip>
          </div>
        </div>
      </Grid>
      <Grid item md={1} xs={12}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={onExportClick}
            disabled={isExportLoading}
          >
            {isExportLoading && (
              <CircularProgress
                style={{
                  color: 'rgba(0, 0, 0, 0.26)',
                  marginRight: 10,
                }}
                size={18}
              />
            )}
            Export
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
};

export default TranchesFilters;
