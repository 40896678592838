import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import FieldRow from 'components/FormElements/FieldRow';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import AppDatePicker from 'components/FormElements/AppDatePicker';
import { useGetRole } from 'hooks/ui/useGetRole';
import { FirmSelector } from 'components/FirmSelector';
import { getSumOfSharesHeldOnDate } from '@redux/actions/Exit';
import { numberToDisplayString } from 'utils/numbers';
import { createShareSplitAddRoute } from 'constants/routes';
import AppNumberInput from 'components/FormElements/AppNumberInput';
import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import CompanyListSelect from 'components/CompanyListSelect';
import SharePriceInfoTooltip from './SharePriceInfoTooltip';
import { useNotification } from 'hooks/ui/useNotification';
import { TaxReliefElegibleType } from 'further-types/exit';
import TaxReliefElegibleAllocations from './TaxReliefElegibleAllocations';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    maxWidth: '20rem',
    gridGap: '1rem',

    '& > div': {
      width: '100%',
    },
  },
  shareCountUnknown: {
    fontStyle: 'italic',
    color: '#999',
  },
  inputFix: {
    '& .MuiInputBase-input': {
      height: 'auto',
    },
  },
  alignButtonRight: {
    display: 'flex',
    maxWidth: '20rem',
    justifyContent: 'flex-end',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
  fullWidth: {
    width: '100%',
  },
}));

const ExitDetailsForm = ({ onSubmit, exitDetailsStepCompleted }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSuperAdmin, firmId: usersFirmId } = useGetRole();
  const [firmId, setFirmId] = useState(usersFirmId);
  const [exitDate, setExitDate] = useState(new Date());
  const [selectedCompany, setSelectedCompany] = useState();
  const [sharesToBeSold, setSharesToBeSold] = useState(0);
  const [maxSharesAvailableForSale, setMaxSharesAvailableForSale] = useState();
  const [
    maxSharesAvailableForSaleLoading,
    setMaxSharesAvailableForSaleLoading,
  ] = useState(false);
  const [sharePrice, setSharePrice] = useState(0);
  const [taxableValueSharePrice, setTaxableValueSharePrice] = useState(0);
  const [isReceivedValueSharePriceEdited, setIsReceivedValueSharePriceEdited] =
    useState(false);
  const [chargeAccruedFees, setChargeAccruedFees] = useState();
  const [taxReliefElegible, setTaxReliefElegible] = useState();
  const [taxReliefElegibleAllocationIds, setTaxReliefElegibleAllocationIds] =
    useState([]);
  const [errors, setErrors] = useState({});
  const notification = useNotification();

  const disableEdit = exitDetailsStepCompleted;

  const resetForm = () => {
    setExitDate(new Date());
    setSelectedCompany(null);
    setSharesToBeSold(0);
    setSharePrice(0);
    setChargeAccruedFees();
    setTaxReliefElegible();
    setTaxReliefElegibleAllocationIds([]);
    setMaxSharesAvailableForSale(null);
    setIsReceivedValueSharePriceEdited(false);
    setTaxableValueSharePrice(0);
  };

  useEffect(() => {
    if (!exitDetailsStepCompleted) {
      resetForm();
    }
  }, [exitDetailsStepCompleted]);

  useEffect(() => {
    const fetchMaxSharesAvailableForSale = async () => {
      if (selectedCompany?._id && exitDate) {
        setMaxSharesAvailableForSale(null);
        setMaxSharesAvailableForSaleLoading(true);

        const noOfShares = await dispatch(
          getSumOfSharesHeldOnDate(
            {
              firmId,
              companyId: selectedCompany._id,
              exitDate: moment(exitDate).format('DD/MM/YYYY'),
            },
            notification,
          ),
        );

        setMaxSharesAvailableForSaleLoading(false);
        setMaxSharesAvailableForSale(Math.round(noOfShares));
      }
    };
    if (selectedCompany && exitDate) {
      void fetchMaxSharesAvailableForSale();
    }
  }, [selectedCompany, exitDate]);

  useEffect(() => {
    if (firmId) {
      resetForm();
    }
  }, [firmId]);

  const validate = () => {
    const errorsToSet = {};
    if (!exitDate) {
      errorsToSet.exitDate = 'Please select the date the exit happened';
    }
    if (!selectedCompany) {
      errorsToSet.selectedCompany = 'Please select a company';
    }
    if (!sharesToBeSold || Number(sharesToBeSold) <= 0) {
      errorsToSet.sharesToBeSold =
        'Please enter the number of shares to be sold';
    }
    if (sharesToBeSold > maxSharesAvailableForSale) {
      errorsToSet.sharesToBeSold =
        'Please ensure the number of shares to be sold are equal to, or below, the total number of shares available';
    }

    if (
      sharePrice === null ||
      sharePrice === undefined ||
      sharePrice === '' ||
      Number(sharePrice) < 0
    ) {
      errorsToSet.sharePrice = `Please enter the share price`;
    }
    if (chargeAccruedFees !== false && chargeAccruedFees !== true) {
      errorsToSet.chargeAccruedFees = `Please select an option`;
    }
    if (Number(sharePrice) > Number(taxableValueSharePrice)) {
      errorsToSet.taxableValueSharePrice = `The taxable value share price must be equal to or greater than the received value share price.`;
    }
    if (!taxReliefElegible) {
      errorsToSet.taxReliefElegible = `Please select an option`;
    }

    if (
      taxReliefElegible === TaxReliefElegibleType.Partial &&
      !taxReliefElegibleAllocationIds?.length
    ) {
      errorsToSet.taxReliefElegibleAllocationIds = `Please select at least one allocation`;
    }
    setErrors(errorsToSet);

    // return false if there's any errors
    return !Object.keys(errorsToSet).length;
  };

  return (
    <GridContainer>
      {isSuperAdmin && (
        <FieldRow title="Choose firm">
          <div className={classes.row}>
            <FirmSelector
              firmId={firmId}
              onChange={setFirmId}
              disabled={disableEdit}
            />
          </div>
        </FieldRow>
      )}
      <FieldRow title="Exit date" centerTitle>
        <div className={classes.row}>
          <AppDatePicker
            placeholder="Select date of exit"
            name="exitDate"
            value={exitDate}
            onChange={setExitDate}
            disableFuture={true}
            helperText={errors.exitDate}
            error={!!errors.exitDate}
            disabled={disableEdit}
          />
        </div>
      </FieldRow>
      <FieldRow title="Choose company" centerTitle>
        <div className={classes.row}>
          {firmId ? (
            <CompanyListSelect
              firmId={firmId}
              includeFirmNameInLabel={false}
              value={selectedCompany}
              handleChangeCallback={setSelectedCompany}
              error={!!errors.selectedCompany}
              helperText={errors.selectedCompany}
              disabled={disableEdit}
            />
          ) : (
            <span className={classes.shareCountUnknown}>
              First select a firm
            </span>
          )}
        </div>
      </FieldRow>
      <FieldRow title="Total shares" centerTitle>
        <div className={classes.row}>
          {!selectedCompany ? (
            <span className={classes.shareCountUnknown}>
              First select a company
            </span>
          ) : maxSharesAvailableForSaleLoading ? (
            <CircularProgress size={20} />
          ) : (
            numberToDisplayString(maxSharesAvailableForSale)
          )}
        </div>
      </FieldRow>
      <FieldRow
        title="Shares to be sold"
        centerTitle
        tooltipText={
          <span>
            If there has been a share split between the company's last share
            price update and this exit, please update this in the share split
            function for this company{' '}
            {selectedCompany ? (
              <NavLink
                style={{ color: '#56B26C' }}
                target="_blank"
                to={createShareSplitAddRoute(selectedCompany._id)}
              >
                here
              </NavLink>
            ) : (
              ''
            )}{' '}
            before processing an exit.
          </span>
        }
      >
        <div className={classes.row}>
          <AppNumberInput
            value={sharesToBeSold}
            className={classes.inputFix}
            error={!!errors.sharesToBeSold}
            helperText={errors.sharesToBeSold}
            disabled={disableEdit}
            min={0}
            max={maxSharesAvailableForSale}
            decimals={2}
            onChange={(value) => setSharesToBeSold(value)}
          />

          <Button
            variant="outlined"
            color="primary"
            disabled={disableEdit}
            onClick={() => setSharesToBeSold(maxSharesAvailableForSale)}
          >
            All
          </Button>
        </div>
      </FieldRow>
      <FieldRow
        title="Taxable value share price"
        centerTitle
        tooltipText="This is typically the share price agreed in any legal sale documentation."
      >
        <div className={classes.row}>
          <NumberFormat
            onValueChange={({ value }) => {
              if (!isReceivedValueSharePriceEdited) setSharePrice(value);
              setTaxableValueSharePrice(value);
            }}
            value={taxableValueSharePrice}
            prefix="£"
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator={true}
            customInput={AppTextInput}
            error={!!errors.taxableValueSharePrice}
            helperText={errors.taxableValueSharePrice}
            disabled={disableEdit}
          />
        </div>
      </FieldRow>
      <FieldRow
        title="Received value share price"
        centerTitle
        tooltipText={
          <span>
            This must be less than or equal to the{' '}
            <em>taxable value share price</em>, depending on whether all sale
            proceeds have been received or not.
          </span>
        }
      >
        <div className={classes.row}>
          <NumberFormat
            onValueChange={({ value }, { source }) => {
              if (source === 'event') {
                setIsReceivedValueSharePriceEdited(true);
              }
              setSharePrice(value);
            }}
            value={sharePrice}
            prefix="£"
            allowNegative={false}
            allowLeadingZeros={false}
            thousandSeparator={true}
            customInput={AppTextInput}
            error={!!errors.sharePrice}
            helperText={errors.sharePrice}
            disabled={disableEdit}
          />
        </div>
      </FieldRow>
      <FieldRow
        title="Charge accrued fees"
        centerTitle
        tooltipText="Deduct any fees that have been accrued to date but not paid by the investor. The maximum fee chargeable to any investor on exit is their full exit amount. Any accrued fees not charged now will remain on the system for charging at a later date."
      >
        <AppRadioButton
          name="chargeAccruedFees"
          label="Yes"
          value={1}
          checked={chargeAccruedFees === true}
          onChange={(event) => {
            setChargeAccruedFees(event.target.value === '1');
          }}
          error={!!errors.chargeAccruedFees}
          disabled={disableEdit}
        />
        <AppRadioButton
          name="chargeAccruedFees"
          label="No"
          value={0}
          checked={chargeAccruedFees === false}
          onChange={(event) => {
            setChargeAccruedFees(event.target.value === '1');
          }}
          error={!!errors.chargeAccruedFees}
          disabled={disableEdit}
        />
        {errors.chargeAccruedFees && (
          <p className={classes.errorText}>{errors.chargeAccruedFees}</p>
        )}
      </FieldRow>

      <FieldRow
        title="S/EIS eligible exit"
        centerTitle
        tooltipText={
          <span>
            If this exit takes place over three years since your last EIS
            subscription in the company, select <em>Yes</em>. If this exit takes
            place within three years of your first EIS subscription in the
            company, select <em>No</em>. Where some tranches invested over three
            years before this exit, but others invested more recently, select
            Partial and choose the allocations that are S/EIS eligible (i.e.
            select those that invested over three years before this exit).
          </span>
        }
      >
        <AppRadioButton
          name="taxReliefElegible"
          label="Yes"
          value={1}
          checked={taxReliefElegible === TaxReliefElegibleType.Yes}
          onChange={(_, checked) => {
            if (checked) {
              setTaxReliefElegible(TaxReliefElegibleType.Yes);
            }
          }}
          error={!!errors.taxReliefElegible}
          disabled={disableEdit}
        />
        <AppRadioButton
          name="taxReliefElegible"
          label="No"
          value={0}
          checked={taxReliefElegible === TaxReliefElegibleType.No}
          onChange={(_, checked) => {
            if (checked) {
              setTaxReliefElegible(TaxReliefElegibleType.No);
            }
          }}
          error={!!errors.taxReliefElegible}
          disabled={disableEdit}
        />
        <AppRadioButton
          name="taxReliefElegible"
          label="Partial"
          value={0}
          checked={taxReliefElegible === TaxReliefElegibleType.Partial}
          onChange={(_, checked) => {
            if (checked) {
              setTaxReliefElegible(TaxReliefElegibleType.Partial);
            }
          }}
          error={!!errors.taxReliefElegible}
          disabled={disableEdit}
        />
        {errors.taxReliefElegible && (
          <p className={classes.errorText}>{errors.taxReliefElegible}</p>
        )}
      </FieldRow>
      {taxReliefElegible === TaxReliefElegibleType.Partial &&
        firmId &&
        selectedCompany?._id && (
          <TaxReliefElegibleAllocations
            firmId={firmId}
            companyId={selectedCompany?._id}
            taxReliefElegibleAllocationIds={taxReliefElegibleAllocationIds}
            onChange={setTaxReliefElegibleAllocationIds}
            disabled={disableEdit}
            error={errors.taxReliefElegibleAllocationIds}
          />
        )}

      <Grid item md={5} xs={12}>
        <SharePriceInfoTooltip />
      </Grid>
      <Grid item md={7} xs={12}>
        {!disableEdit && (
          <div className={classes.alignButtonRight}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (validate())
                  onSubmit({
                    firmId,
                    exitDate,
                    selectedCompanyId: selectedCompany._id,
                    sharesToBeSold: Number(sharesToBeSold),
                    sharePrice: Number(sharePrice),
                    taxableValueSharePrice: Number(taxableValueSharePrice),
                    chargeAccruedFees,
                    taxReliefElegible,
                    taxReliefElegibleAllocationIds,
                  });
              }}
            >
              Next
            </Button>
          </div>
        )}
      </Grid>
    </GridContainer>
  );
};

export default ExitDetailsForm;
