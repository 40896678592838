import { Link } from 'react-router-dom';
import CmtCardHeader from 'components/CmtCard/CmtCardHeader';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { Divider, Button } from '@material-ui/core';
import { ViewQuilt } from '@material-ui/icons';
import Table from 'components/Table';
import { createOpenTrancheRoute } from 'constants/routes';
import { numberToDisplayString } from 'utils/numbers';
import useOpenTranches from 'hooks/data/dashboards/useOpenTranches';

const OpenTranchesTable = ({ firmId, classes }) => {
  const { data } = useOpenTranches({ firmId });

  const columns = [
    {
      sort: false,
      label: 'Tranche',
      key: 'firmAndTrancheName',
      render: (elm) => `${elm?.firmName} - ${elm?.fundName}`,
    },
    {
      sort: false,
      label: 'Scheduled close date',
      key: 'finishDate',
      render: (elm) => dateToLabel(elm?.finishDate),
    },
    {
      sort: false,
      label: 'Subscriptions (#)',
      key: 'sumInvestments',
      render: (elm) => numberToDisplayString(elm?.sumInvestments),
    },
    {
      sort: false,
      label: 'Subscriptions (£)',
      key: 'sumInvestmentAmount',
      render: (elm) =>
        numberToCurrencyString(elm?.sumInvestmentAmount, {
          hidePenniesIfInteger: true,
        }),
    },
    {
      sort: false,
      label: '',
      render: (elm) => (
        <Link to={createOpenTrancheRoute(elm?.trancheId)}>
          <Button title="View tranche dashboard">
            <ViewQuilt />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <CmtCard>
      {/* @ts-ignore */}
      <CmtCardHeader title="Current open tranches"></CmtCardHeader>
      <Divider className={classes.divider} />
      <CmtCardContent className={classes.scrollableTable}>
        <Table
          columns={columns}
          tablebody={data || []}
          pagination={false}
          emptyMessage="No open tranches found"
          variant="nohover"
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default OpenTranchesTable;
