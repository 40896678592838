import { Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import { OverallPerformance } from './OverallPerformance';
import { PortfolioSnapshotGraph } from './PortfolioSnapshotGraph';
import { useState } from 'react';
import CardHeader from 'components/CardHeader';
import { Exits, InvestmentBalance, Shareholding } from '.';

const useStyles = makeStyles(() => ({
  tabTitle: {
    fontSize: 14,
    marginBottom: 0,
  },
}));

type OverallPerformanceTabsProps = {
  investmentBalance: InvestmentBalance;
  combinedShareholdings: Array<Shareholding>;
  exits: Exits;
};

export const OverallPerformanceTabs = ({
  investmentBalance,
  combinedShareholdings,
  exits,
}: OverallPerformanceTabsProps) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={
            <CardHeader
              title="Overall performance"
              className={classes.tabTitle}
            />
          }
        />
        <Tab
          label={
            <CardHeader
              title="Portfolio snapshot"
              className={classes.tabTitle}
            />
          }
        />
      </Tabs>
      <Box mt={2}>
        {activeTab === 0 && (
          <OverallPerformance investmentBalance={investmentBalance} />
        )}
        {activeTab === 1 && (
          <PortfolioSnapshotGraph
            combinedShareholdings={combinedShareholdings}
            exits={exits}
          />
        )}
      </Box>
    </Box>
  );
};
