import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import moment from 'moment';
import {
  Button,
  makeStyles,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Delete, Edit, FilterList, Email } from '@material-ui/icons';
import { sumBy, isEqual } from 'lodash';
import { useDebounce } from 'usehooks-ts';

import GridContainer from 'components/GridContainer';
import Table, { useStyles as useTableStyles } from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Dialog from 'components/ConfirmDialog';
import Autocomplete from 'components/FormElements/AppAutocomplete';
import TextField from 'components/FormElements/AppTextInput';
import { deleteInvestment } from '@redux/actions/Investment';
import { Link } from 'react-router-dom';
import { createInvestmentEditRoute } from 'constants/routes';
import AlertDialog from 'components/AlertDialog';
import {
  useInvestments,
  useInvestmentsExport,
  useSendPaymentReminder,
} from 'hooks/data/investment/useInvestments';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useSortTranches } from 'hooks/ui/useSortTranches';
import { useFunds } from 'hooks/data/fund/useFunds';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import { usePagination } from 'hooks/ui/usePagination';
import { exportExcelSheet } from 'helpers/investment/exportExcelSheet';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';
import { FilterPanel } from './FilterPanel';
import Chip from 'components/Chip';
import { Box } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';

const totalCurrentValueTooltipText = (
  <>
    This is the combined value of all current holdings, exits and uninvested
    paid in capital. Where there are unpaid commitments, this figure may be
    materially lower than subscribed investment capital.
  </>
);

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  exportBtn: {
    textAlign: 'right',
  },
  customWidth: {
    minWidth: 150,
    backgroundColor: '#f3f1ef',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperChild: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    minWidth: '150px',
  },
  mt: {
    marginTop: '10px',
  },
  mbc: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '@media screen and (max-width: 767px)': {
    dialogRootUnitCost: {
      '& .MuiDialog-paperWidthSm': {
        minWidth: '95%',
        maxWidth: '95%',
      },
    },
  },
  icon: {
    height: '24px',
    width: '24px',
  },
}));
const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Subscriptions', isActive: true },
];

const isFilterPanelApplied = (filters) => {
  const defaultFilters = {
    amount: [0, 0],
    startDate: null,
    endDate: null,
    paymentStatus: null,
  };
  return !isEqual(
    {
      paymentStatus: filters.paymentStatus,
      amount: filters.amount,
      startDate: filters.startDate,
      endDate: filters.endDate,
    },
    defaultFilters,
  );
};

const Investment = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const [record, setRecord] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openApplyFilter, setOpenApplyFilter] = useState(false);
  const [errorText, setErrorText] = useState<{
    startDate?: string;
    endDate?: string;
  }>({});
  const [includeExportData, setIncludeExportData] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const { firmId } = useGetRole();
  const { firms } = useFirm({ params: { firmId } });
  //@ts-ignore
  const { tranches } = useFunds({ leanResponse: true });
  const sortedTranches = useSortTranches(tranches?.data);
  const handleApiRequest = useApiRequestHandler();
  const { sendPaymentReminder } = useSendPaymentReminder();
  const pagination = usePagination({
    id: 'investments-table',
    orderBy: 'investmentDate',
    order: 'desc',
  });

  const { filters, handleChangeFilters } = useFilters('investments-table', {
    defaultFilters: {
      firmId,
      fundId: urlParams.get('fundId') ? [urlParams.get('fundId')] : [],
      fundType: null,
      investorName: '',
      adviserId: null,
      searchByInvestorAndInvestment: null,
      amount: [0, 0],
      startDate: null,
      endDate: null,
      paymentStatus: null,
    },
    onFiltersChange: (newFilters) => {
      setFiltersApplied(isFilterPanelApplied(newFilters));
      pagination.toFirstPage();
    },
  });
  const [filtersApplied, setFiltersApplied] = useState(
    isFilterPanelApplied(filters),
  );

  const advisers = useAdvisers({
    params: {
      firmId,
      isEmailVerify: true,
    },
  });

  const debouncedInvestorAndInvestmentSearch = useDebounce(
    filters.searchByInvestorAndInvestment,
    400,
  );

  const [deletePermission, updatePermission] = useGetPermissions([
    'delete:investment',
    'edit:investment',
  ]);
  const { isSuperAdmin } = useGetRole();

  const params = {
    page: pagination.page,
    firmId: filters.firmId?._id,
    //@ts-ignore
    fundId: filters.fundId?._id,
    fundType: filters.fundType?._id,
    perPage: pagination.rowsPerPage,
    type: pagination.order,
    keyName: pagination.orderBy,
    paymentStatus: filters.paymentStatus,
    minInvestment: filters.amount[0],
    maxInvestment: filters.amount[1],
    searchByInvestorAndInvestment: debouncedInvestorAndInvestmentSearch,
    adviserId: filters.adviserId?._id,
    startDate: undefined,
    endDate: undefined,
  };
  if (filters.startDate && filters.endDate) {
    params.startDate = moment(filters.startDate).startOf('days').toDate();
    params.endDate = moment(filters.endDate).endOf('days').toDate();
  }
  const investmentsQuery = useInvestments({
    params,
  });
  const investmentsExportQuery = useInvestmentsExport({
    params: { ...params, includeExportData },
  });

  const handleSendPaymentReminder = (elm) => {
    sendPaymentReminder.mutate(elm._id);
  };

  const validate = () => {
    const errors: {
      startDate?: string;
      endDate?: string;
    } = {};
    let isError = false;
    if (!filters.startDate) {
      errors.startDate = 'Please select start date.';
      isError = true;
    }
    if (!filters.endDate) {
      errors.endDate = 'Please select end date.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const columns = [
    {
      label: 'Investor name',
      key: 'fullName',
    },
    {
      label: 'Email',
      key: 'email',
      render: (elm) => elm?.investorId?.email,
    },
    {
      label: 'Tranche',
      key: 'trancheName',
    },
    {
      label: 'Subscription date',
      key: 'investmentDate',
      render: (elm) => dateToLabel(elm?.investmentDate),
    },
    {
      label: 'Subscription',
      key: 'investmentAmount',
      render: (elm) => numberToCurrencyString(elm?.investmentAmount),
    },
    {
      label: (
        <Box display="flex" alignItems="center" gridGap="4px">
          <span>Total current value</span>
          <Tooltip title={totalCurrentValueTooltipText}>
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      key: 'investmentValue',
      render: (elm) =>
        numberToCurrencyString(elm?.investmentBalance?.investmentValue),
    },
    {
      label: 'Payment status',
      key: 'isPaymentReceived',
      render: (elm) => (
        <>
          {elm?.isFullyFunded ? (
            <Chip small variant="success">
              Fully funded
            </Chip>
          ) : elm?.isPartiallyFunded ? (
            <Chip small variant="disabled">
              Partially funded
            </Chip>
          ) : (
            <Chip small variant="disabled">
              No payment
            </Chip>
          )}
        </>
      ),
    },
    {
      label: 'Adviser',
      key: 'adviser',
      render: (elm) => elm.adviserId?.fullName || '-',
    },
    {
      label: 'Subscription ID',
      key: 'externalInvestmentId',
      render: (elm) => elm?.externalInvestmentId || '-',
    },

    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <div className={classes.dFlex}>
          {updatePermission && (
            <Link to={createInvestmentEditRoute(elm?._id)}>
              <Button
                title="Edit"
                color="primary"
                className={classes.customButton}
              >
                <Edit />
              </Button>
            </Link>
          )}
          {deletePermission && (
            <Button
              title="Delete"
              className={classes.customButton}
              onClick={() => handleDelete(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
          {!elm?.isFullyFunded &&
            !elm?.isPartiallyFunded &&
            !elm.investorId.disableAllEmailsForFirm && (
              <Button
                title="Send payment reminder"
                color="primary"
                className={classes.customButton}
                onClick={() => handleSendPaymentReminder(elm)}
              >
                <Email />
              </Button>
            )}
        </div>
      ),
    },
  ];

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = async () => {
    await handleApiRequest(async (showSuccess) => {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteInvestment(record._id),
      );
      if (response.status === 200) {
        showSuccess(response.data.responseMsg);
        investmentsQuery.refetch();
      }
    });
    setOpenConfirmDialog(false);
  };

  const handleFinish = () => {
    pagination.handleChangePage(null, 0);
    if (filters.startDate || filters.endDate) {
      const { isError } = validate();
      if (!isError) {
        setOpenApplyFilter(!openApplyFilter);
      }
    } else {
      setOpenApplyFilter(!openApplyFilter);
    }
  };

  const filterClose = () => {
    handleChangeFilters({
      paymentStatus: null,
      amount: [0, 0],
      startDate: null,
      endDate: null,
    });
    setOpenApplyFilter(!openApplyFilter);
    setErrorText({});
    setFiltersApplied(false);
  };

  const exportExcelData = () => {
    setIncludeExportData(true);
  };

  useEffect(() => {
    if (
      includeExportData &&
      investmentsExportQuery.data?.result &&
      !investmentsExportQuery.isFetching
    ) {
      exportExcelSheet(investmentsExportQuery?.data?.result);
      setIncludeExportData(false);
    }
  }, [
    includeExportData,
    investmentsExportQuery.data,
    investmentsExportQuery.isFetching,
  ]);
  useEffect(() => {
    const setPrefetchFundValue = () => {
      if (sortedTranches?.length && urlParams.get('fundId')) {
        const fundId = sortedTranches?.find(
          (item) => item._id === urlParams.get('fundId'),
        );
        handleChangeFilters({ fundId });
      }
    };
    setPrefetchFundValue();
  }, [sortedTranches, urlParams.get('fundId')]);

  return (
    <PageContainer heading="Subscriptions" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer className={classes.mbc}>
                <Grid item xs={12} md={isSuperAdmin ? 3 : 5}>
                  <TextField
                    type="search"
                    name="searchByInvestorAndInvestment"
                    placeholder="Search by investor name, email or subscription ID"
                    fullWidth
                    value={filters.searchByInvestorAndInvestment}
                    onChange={(event) => {
                      handleChangeFilters({
                        searchByInvestorAndInvestment: event.target.value,
                      });
                    }}
                  />
                </Grid>
                {isSuperAdmin && (
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      //@ts-ignore
                      id="firmId"
                      options={firms?.data?.result}
                      getOptionLabel={(option) => option.firmName}
                      filterSelectedOptions
                      value={filters.firmId}
                      onChange={(_, newValue) => {
                        handleChangeFilters({ firmId: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Firm"
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    //@ts-ignore
                    id="fundId"
                    options={sortedTranches}
                    getOptionLabel={(option) => option.label}
                    value={filters.fundId}
                    filterSelectedOptions
                    onChange={(_, newValue) => {
                      handleChangeFilters({ fundId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Tranche"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    //@ts-ignore
                    id="adviserId"
                    options={
                      advisers.data?.adviser.filter(
                        (adviser) => !!adviser.fullName,
                      ) || []
                    }
                    getOptionLabel={(option) => option?.fullName}
                    value={filters.adviserId}
                    filterSelectedOptions
                    onChange={(_, newValue) => {
                      handleChangeFilters({ adviserId: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Adviser"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <div className={classes.actionButtonContainer}>
                    <div className={classes.filterButtonContainer}>
                      <IconButton
                        aria-label="filter list"
                        onClick={() => setOpenApplyFilter(!openApplyFilter)}
                      >
                        {filtersApplied ? (
                          <img
                            src="images/customIcons/filter-applied.png"
                            className={classes.icon}
                            alt="filters applied"
                          />
                        ) : (
                          <FilterList />
                        )}
                      </IconButton>
                    </div>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={exportExcelData}
                      disabled={
                        investmentsExportQuery?.isFetching ||
                        !investmentsQuery?.data?.result?.length
                      }
                    >
                      {investmentsExportQuery?.isFetching && (
                        <CircularProgress
                          style={{
                            color: 'rgba(0, 0, 0, 0.26)',
                            marginRight: 10,
                          }}
                          size={18}
                        />
                      )}
                      Export
                    </Button>
                  </div>
                </Grid>
              </GridContainer>

              <Table
                columns={columns}
                onRequestSort={pagination.handleRequestSort}
                order={pagination.order}
                orderBy={pagination.orderBy}
                tablebody={investmentsQuery?.data?.result ?? []}
                onPageChange={pagination.handleChangePage}
                onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                count={investmentsQuery?.data?.total ?? 0}
                pagination={true}
                loading={investmentsQuery.isFetching}
                TableFooter={
                  <tfoot>
                    <tr className={tableClasses.tFootRow}>
                      <td colSpan={4} />
                      <td className={tableClasses.tFootCell}>
                        {numberToCurrencyString(
                          sumBy(
                            investmentsQuery.data?.result,
                            'investmentAmount',
                          ),
                        )}
                      </td>
                      <td className={tableClasses.tFootCell}>
                        {numberToCurrencyString(
                          sumBy(
                            investmentsQuery.data?.result,
                            'investmentBalance.investmentValue',
                          ),
                        )}
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                }
                variant="nohover"
              />
            </CmtCardContent>
          </CmtCard>
          <AlertDialog
            open={openConfirmDialog}
            title={`This cannot be undone and all information relating to the subscription will be lost.`}
            content={
              <>
                <p className={classes.mbc}>
                  Any money transferred to you must be refunded to the investor
                  before the subscription is deleted.
                </p>
                <p className={classes.mbc}>
                  Any money that was transferred from the investor's cash
                  balance to pay for this subscription will be returned to their
                  cash balance.
                </p>
              </>
            }
            size="md"
            onClose={handleDelete}
            onConfirm={handleConfirmDelete}
            btnLabels={{ cancel: 'Go back', confirm: 'Delete subscription' }}
            cancelBtnProps={{ variant: 'outlined' }}
            confirmBtnProps={{ color: 'secondary' }}
          />
          {
            //@ts-ignore
            <Dialog
              open={openApplyFilter}
              title={`Advanced Filters`}
              content={FilterPanel({
                classes,
                filters,
                handleChangeFilters,
                errorText,
              })}
              onClose={() => filterClose()}
              onConfirm={handleFinish}
              btnLabels={{
                cancel: 'Reset',
                confirm: 'Apply',
              }}
              size={'sm'}
            />
          }
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Investment;
