import React from 'react';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography, makeStyles } from '@material-ui/core';
import { AccountType } from 'further-types/investor';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#656565',
    fontSize: 22,
    marginBottom: 18,
  },
  label: {
    color: '#656565',
    fontSize: 12,
  },
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  divider: {
    margin: '40px 0 30px',
  },
}));

type Props = {
  bankDetails: Array<{
    bankAccountName?: string;
    bankName?: string;
    bankSortCode?: string;
    bankAccountNumber?: string;
    iban?: string;
    swift?: string;
    isUsPerson?: boolean;
  }>;
  investorDetails: {
    nationalInsuranceNumber?: string;
    uniqueTaxpayerReference?: string;
    taxResidencies?: Array<{ country?: string }>;
    isClamingTax?: boolean;
    registeredTaxNumber?: string;
    accountType: AccountType;
  };
};

const TaxAndBankDetails: React.FC<Props> = ({
  bankDetails,
  investorDetails,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" className={classes.heading}>
        Tax and bank details
      </Typography>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Bank account name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.bankAccountName ?? '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Bank name</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.bankName ?? '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Bank sort code</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.bankSortCode ?? '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Bank account number</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.bankAccountNumber ?? '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>IBAN</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>{bankDetails[0]?.iban ?? '-'}</span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>SWIFT/BIC</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.swift ?? '-'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      {investorDetails.accountType === AccountType.LegalEntity ? (
        <Grid item xs={12} md={8}>
          <GridContainer>
            <Grid item xs={5}>
              <span className={classes.label}>Registered tax number</span>
            </Grid>
            <Grid item xs={7}>
              <span className={classes.value}>
                {investorDetails?.registeredTaxNumber ?? '-'}
              </span>
            </Grid>
          </GridContainer>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={8}>
            <GridContainer>
              <Grid item xs={5}>
                <span className={classes.label}>National insurance number</span>
              </Grid>
              <Grid item xs={7}>
                <span className={classes.value}>
                  {investorDetails?.nationalInsuranceNumber ?? '-'}
                </span>
              </Grid>
            </GridContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            <GridContainer>
              <Grid item xs={5}>
                <span className={classes.label}>
                  Unique Taxpayer Reference number
                </span>
              </Grid>
              <Grid item xs={7}>
                <span className={classes.value}>
                  {investorDetails?.uniqueTaxpayerReference ?? '-'}
                </span>
              </Grid>
            </GridContainer>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Tax domicile/residency</span>
          </Grid>
          <Grid item xs={7}>
            {investorDetails?.taxResidencies?.length
              ? investorDetails.taxResidencies.map(({ country }) => {
                  return (
                    <div key={country}>
                      <span className={classes.value}>{country}</span>
                    </div>
                  );
                })
              : '-'}
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>Claiming tax relief</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {investorDetails?.isClamingTax ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <GridContainer>
          <Grid item xs={5}>
            <span className={classes.label}>US person?</span>
          </Grid>
          <Grid item xs={7}>
            <span className={classes.value}>
              {bankDetails[0]?.isUsPerson ? 'Yes' : 'No'}
            </span>
          </Grid>
        </GridContainer>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default TaxAndBankDetails;
