import downloadFile from 'utils/downloadFile';
import axios from 'axios';

export const deleteInvestment = (id) => {
  return () => {
    return axios
      .delete(`investment/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getPartiallyInvestment = (value) => {
  return () => {
    return axios
      .get('investment/partially-investment', { params: { ...value } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      });
  };
};

// This is for share allocation upload
export const getExcelFile = (formData, notification) => {
  return () => {
    // This needs to use native fetch because the repsonse could either
    // be an excel file (blob) or a json object which contains an error
    // message. Axios doesn't handle this well and requires us to declare
    // the responseType in the request before it's known, so use fetch instead.
    const baseUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const opts = {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      `${baseUrl}/upload-shareholding/download-share-allocation-template-file`,
      opts,
    )
      .then((response) => {
        const responseType = response.headers.get('Content-Type');

        if (responseType.startsWith('application/vnd.openxmlformats')) {
          return response.blob().then((blob) => {
            downloadFile(blob, `Allocation-upload-sheet-${Date.now()}.xlsx`);
            return Promise.resolve(response);
          });
        } else {
          return response.json().then((data) => {
            notification.error(data.responseMsg);
            return Promise.resolve(response);
          });
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};
// This is for share allocation upload
export const uploadExcelFile = (formData) => {
  return () => {
    return axios
      .post(`upload-shareholding/process-share-allocations-from-file`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
// This is for share allocation upload
export const uploadFinalExcelFile = (shareAllocationUploadId) => {
  return () => {
    return axios
      .get(
        `upload-shareholding/create-share-allocations/${shareAllocationUploadId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
