import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    color: theme.palette.text.tableText,
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.background.header,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
  },
  tableCellCollapsible: {
    minWidth: 50,
  },
  noSortingColumnSpacing: {
    marginRight: '18px',
  },
  tooltipIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
}));

const TableHeading = ({
  columns,
  onRequestSort,
  order,
  orderBy,
  totalCount,
  selectable,
  selectedCount,
  onSelect,
  collapsedContent,
}) => {
  const onSortOrderChange = (property) => (event) => {
    onRequestSort && onRequestSort(event, property);
  };
  const classes = useStyles();

  const ColumnTooltip = ({ content }) => (
    <Tooltip title={content} placement="top">
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  );

  return (
    <TableRow>
      {selectable ? (
        <TableCell padding="checkbox" className={`${classes.tableCellRoot}`}>
          <Checkbox
            color="primary"
            indeterminate={!!(selectedCount && selectedCount < totalCount)}
            checked={!!(selectedCount && selectedCount === totalCount)}
            onChange={onSelect}
            inputProps={{
              'aria-label': 'select all rows',
            }}
          />
        </TableCell>
      ) : null}
      {columns && columns.length
        ? columns.map((column, index) =>
            column.sort === false ? (
              <TableCell
                size={column.size}
                key={index}
                className={`${classes.tableCellRoot} ${column.className ?? ''}`}
              >
                {column.label}
                {column.tooltipText && (
                  <ColumnTooltip content={column.tooltipText} />
                )}
              </TableCell>
            ) : (
              <TableCell
                key={index}
                size={column.size}
                className={`${classes.tableCellRoot} ${
                  column.className ? column.className : ''
                }`}
                sortDirection={orderBy === column.key ? order : false}
              >
                <TableSortLabel
                  hideSortIcon={!column.key || !onRequestSort}
                  active={column.key ? orderBy === column.key : false}
                  direction={orderBy === column.key ? order : 'asc'}
                  onClick={onSortOrderChange(column.key)}
                  className={`${
                    (!column.key || !onRequestSort) &&
                    classes.noSortingColumnSpacing
                  }`}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ),
          )
        : []}
      {collapsedContent && (
        <TableCell
          className={` ${classes.tableCellRoot} ${classes.tableCellCollapsible}`}
        />
      )}
    </TableRow>
  );
};

export default TableHeading;
