import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function AdditionalInformation({
  additionalInfo,
  additionalInfoError,
  handleCancel,
  handelClickAdditional,
  handleInputChangeAdditional,
  id,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer>
      <Grid item xs={12} md={11}>
        <GridContainer>
          <FieldRow
            title="Daily summary notification list"
            informationText="Separate emails by a semi-colon"
          >
            <TextField
              name="additionalInfo"
              placeholder="Enter the email addresses you wish to receive daily subscription notifications. Please separate each email with a semi-colon. e.g. 'a@a.com;b@b.com'"
              fullWidth
              onChange={handleInputChangeAdditional}
              value={additionalInfo}
              variant="outlined"
              error={additionalInfoError}
              helperText={additionalInfoError}
              multiline
              minRows={4}
            />
          </FieldRow>
        </GridContainer>
      </Grid>

      <Grid item xs={12} md={11}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handelClickAdditional}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
