import React, { useState, useContext } from 'react';

const LayoutContext = React.createContext();

const LayoutContextProvider = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const layoutContextValue = {
    isSidebarOpen,
    setSidebarOpen,
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContextProvider;
