import { Grid, Slider, Tooltip } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import { numberToCurrencyString } from 'further-ui/utils';
import { InvestmentPaymentStatus } from 'further-types/investment';

const ValueLabelComponent = ({ children, open, value }) => (
  <Tooltip
    open={open}
    enterTouchDelay={0}
    placement="bottom"
    title={`${value}`}
  >
    {children}
  </Tooltip>
);
const paymentMethod = [
  { paymentStatus: InvestmentPaymentStatus.FullyFunded, text: 'Fully funded' },
  {
    paymentStatus: InvestmentPaymentStatus.PartiallyFunded,
    text: 'Partially funded',
  },
  { paymentStatus: InvestmentPaymentStatus.NoPayment, text: 'No payment' },
];

export const FilterPanel = ({
  classes,
  filters,
  handleChangeFilters,
  errorText,
}) => (
  <GridContainer>
    <Grid item md={12} xs={12}>
      <h3>Subscription amount</h3>
      <div className={classes.wrapper}>
        <span className={classes.text}>
          Range :{' '}
          {numberToCurrencyString(filters.amount?.[0], {
            hidePennies: true,
          })}{' '}
          -{' '}
          {numberToCurrencyString(filters.amount?.[1], {
            hidePennies: true,
          })}
        </span>
        <Slider
          ValueLabelComponent={ValueLabelComponent}
          className={classes.mt}
          value={filters.amount}
          onChange={(_, newValue) => {
            handleChangeFilters({ amount: newValue as number[] });
          }}
          valueLabelDisplay="auto"
          min={1}
          step={1}
          max={2000000}
          track={false}
        />
      </div>
    </Grid>
    <Grid item md={12} xs={12}>
      <h3>Payment status</h3>
      <div className={classes.mt}>
        <AppSelectBox
          fullWidth
          data={paymentMethod}
          placeholder="Search by payment status"
          valueKey="paymentStatus"
          variant="outlined"
          labelKey="text"
          name="paymentStatus"
          value={filters.paymentStatus}
          onChange={(e) => {
            handleChangeFilters({
              paymentStatus: e.target.value,
            });
          }}
        />
      </div>
    </Grid>
    <Grid item md={6} xs={12}>
      <h3>Start date</h3>
      <div>
        <KeyboardDatePicker
          className={classes.mt}
          required
          name="startDate"
          label=""
          value={filters.startDate}
          maxDate={filters.endDate ? filters.endDate : undefined}
          onChange={(date) => {
            handleChangeFilters({ startDate: date });
          }}
          placeholder="Please select start date"
          error={!!errorText.startDate}
          helperText={errorText.startDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </Grid>
    <Grid item md={6} xs={12}>
      <h3>End date</h3>
      <div>
        <KeyboardDatePicker
          className={classes.mt}
          required
          name="endDate"
          label=""
          value={filters.endDate}
          onChange={(date) => {
            handleChangeFilters({ endDate: date });
          }}
          minDate={filters.startDate ? filters.startDate : undefined}
          error={!!errorText.endDate}
          helperText={errorText.endDate}
          placeholder="Please select end date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </Grid>
  </GridContainer>
);
