import { FC, useState } from 'react';
import {
  Menu,
  MenuItem,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDropDown, CheckCircle } from '@material-ui/icons';
import { useInvestorDisability } from 'hooks/data/investor/useInvestorDisability';
import SingleFirmEmailToggle from './SingleFirmEmailToggle';
import { useFirm } from 'hooks/data/firm/useFirm';
import { useGetRole } from 'hooks/ui/useGetRole';

const useStyles = makeStyles(() => ({
  row: {
    marginBottom: '0.25rem',
  },
  allOtherFirmsRow: {
    marginBottom: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  divider: {
    height: '1px',
    margin: '0.5rem 0',
    width: '100%',
    borderBottom: '1px dashed #e0e0e0',
  },
}));

interface Props {
  investorId: string;
  firmSpecificSettings: Array<{
    firmId: string;
    firmName: string;
    disableAllEmails: boolean;
  }>;
}

const MultiFirmEmailToggle: FC<Props> = ({
  firmSpecificSettings,
  investorId,
}) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { firmId } = useGetRole();
  const { firms } = useFirm({ params: { firmId } });

  const { mutate: updateInvestorDisability, isLoading } =
    useInvestorDisability();

  const hasFirmSpecificSettings = !!firmSpecificSettings?.length;

  return (
    <>
      {firmSpecificSettings &&
        firmSpecificSettings.map((firmSetting) => (
          <div className={classes.row}>
            <SingleFirmEmailToggle
              key={firmSetting.firmId}
              investorId={investorId}
              firmId={firmSetting.firmId}
              firmName={firmSetting.firmName}
              emailsCurrentlyDisabled={firmSetting.disableAllEmails}
            />
          </div>
        ))}

      {hasFirmSpecificSettings && <div className={classes.divider}></div>}

      <div className={classes.allOtherFirmsRow}>
        <CheckCircle color="primary" style={{ fontSize: '1rem' }} />
        <span>
          {hasFirmSpecificSettings
            ? 'All other firms - Enabled'
            : 'All firms - Enabled'}
        </span>
        <Button
          aria-controls="select-firm-menu"
          aria-haspopup="true"
          color="primary"
          variant="contained"
          size="small"
          style={{ height: '26px' }}
          endIcon={<ArrowDropDown />}
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          {isLoading ? <CircularProgress color="inherit" size={12} /> : 'Edit'}
        </Button>
        <Menu
          id="select-firm-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          {firms?.data?.result
            ?.filter(
              (firm) =>
                !firmSpecificSettings
                  .map((setting) => setting.firmId)
                  .includes(firm._id),
            )
            .map((firm) => (
              <MenuItem
                key={firm._id}
                onClick={() => {
                  updateInvestorDisability({
                    investorId,
                    firmId: firm._id,
                    disableAllEmails: true,
                  });
                  setMenuAnchorEl(null);
                }}
              >
                Disable emails for {firm.firmName}
              </MenuItem>
            ))}
        </Menu>
      </div>
    </>
  );
};

export default MultiFirmEmailToggle;
