import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/firm';

const fetchBulletin = async ({ queryKey }) => {
  const [, firmId] = queryKey;
  if (!firmId) return;
  const {
    data: { data },
  } = await api.get<ApiResponse<Api.InvestorBulletinResponse>>(
    `firm/${firmId}/bulletin`,
  );
  return data;
};

export function useInvestorBulletin(firmId: string | null) {
  const queryClient = useQueryClient();
  const handleApiException = useApiExceptionHandler();
  const notification = useNotification();

  const updateBulletin = useMutation({
    useErrorBoundary: false,
    mutationFn: async (data: Api.InvestorBulletinDto) => {
      const { data: result } = await api.post<
        ApiResponse<Api.InvestorBulletinResponse>
      >(`firm/${data.firmId}/bulletin`, data);
      return result.data;
    },
    onSuccess: async () => {
      notification.success('Investor bulletin updated successfully');
      queryClient.invalidateQueries({
        queryKey: ['bulletin'],
      });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const bulletin = useQuery({
    queryKey: ['bulletin', firmId],
    queryFn: fetchBulletin,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
    enabled: !!firmId,
  });

  return {
    updateBulletin,
    bulletin,
  };
}
