import { createInvestmentEditRoute } from 'constants/routes';
import { NoOfSharesHistoryType } from 'further-types/shareholding';
import { dateToLabel } from 'further-ui/utils';
import { CallSplit, CompareArrows } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { Box } from '@material-ui/core';

type Shareholding = {
  isTransferred?: boolean;
  noOfSharesHistory?: Array<{
    date: string;
    noOfShares: number;
    setVia?: NoOfSharesHistoryType;
  }>;
  sourceShareholding?: Shareholding;
  sharesCurrentlyHeld: number;
  investor?: {
    fullName: string;
  };
  investmentId?: string;
  createdAt?: string;
  transferDate?: string;
  fullName?: string;
  transferredShareholdings?: Array<Shareholding>;
  noOfShare?: number;
};

const getInitialTransferredSharesAmount = (shareholding: Shareholding) => {
  return (
    shareholding.noOfSharesHistory?.[0]?.noOfShares ??
    shareholding.sharesCurrentlyHeld ??
    shareholding.noOfShare ??
    0
  );
};

const ShareholdingLabel: React.FC<{
  label: string;
  shareholding: Shareholding;
  transferDate?: string;
  classes: Record<string, string>;
}> = ({ label, shareholding, transferDate, classes }) => {
  const previousShareSplits =
    shareholding.noOfSharesHistory?.filter(
      (item) => item.setVia === NoOfSharesHistoryType.ShareSplit,
    ) || [];

  const isPartOfTransfer =
    !!shareholding.sourceShareholding ||
    shareholding.isTransferred ||
    shareholding.transferredShareholdings?.length;

  if (!previousShareSplits?.length && !isPartOfTransfer)
    return <div className={classes.mediumColumn}>{label}</div>;

  const shareSplitDates = previousShareSplits
    .map((item) => dateToLabel(item.date))
    .join(' and ');

  const tooltipText = [
    ...(shareSplitDates?.length
      ? [`This company was subject to a share split on ${shareSplitDates}`]
      : []),
    ...(shareholding.sourceShareholding
      ? [
          `${getInitialTransferredSharesAmount(
            shareholding,
          )} shares were transferred from <a class="${
            classes.tooltipLink
          }" href="${createInvestmentEditRoute(
            shareholding.sourceShareholding.investmentId,
          )}" target="_blank">${
            shareholding.sourceShareholding.investor.fullName
          }</a> on ${dateToLabel(transferDate || shareholding.createdAt)}.`,
        ]
      : []),
    ...(shareholding.isTransferred
      ? [
          `${getInitialTransferredSharesAmount(
            shareholding,
          )} shares were transferred to  <a class="${
            classes.tooltipLink
          }" href="${createInvestmentEditRoute(
            shareholding.investmentId,
          )}" target="_blank">${
            shareholding.investor?.fullName
          }</a> on ${dateToLabel(shareholding.transferDate)}.`,
        ]
      : []),
    ...(shareholding.transferredShareholdings?.length
      ? shareholding.transferredShareholdings.map(
          (transferredShareholding, i) => [
            `${getInitialTransferredSharesAmount(
              transferredShareholding,
            )} shares were transferred to  <a class="${
              classes.tooltipLink
            }" href="${createInvestmentEditRoute(
              transferredShareholding.investmentId,
            )}" target="_blank">${
              transferredShareholding.investor?.fullName
            }</a> on ${dateToLabel(transferredShareholding.createdAt)}. </br>${
              i === shareholding.transferredShareholdings.length - 1
                ? ''
                : '<br />'
            }`,
          ],
        )
      : []),
  ].join(' ');

  const icons = [
    ...(shareSplitDates ? [CallSplit] : []),
    ...(isPartOfTransfer ? [CompareArrows] : []),
  ];

  return (
    <div className={classes.mediumColumn}>
      <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
        interactive
      >
        <Box alignItems="center" display="flex" gridGap={4}>
          {label}{' '}
          {icons.map((Icon, i) => (
            <Icon key={i} className={classes.splitIcon} />
          ))}
        </Box>
      </Tooltip>
    </div>
  );
};

export default ShareholdingLabel;
