import axios from 'axios';
import { addHttps } from 'utils/strings';

export const getCompanyById = (id) => {
  return () => {
    return axios
      .get(`investee/list/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const addCompany = (data) => {
  let companyData = data;

  companyData.url = addHttps(companyData.url);
  companyData.linkedinUrl = addHttps(companyData.linkedinUrl);
  companyData.twitterUrl = addHttps(companyData.twitterUrl);

  return () => {
    return axios
      .post(`investee/create`, companyData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};
export const updateCompany = (data) => {
  let companyData = data;

  companyData.url = addHttps(companyData.url);
  companyData.linkedinUrl = addHttps(companyData.linkedinUrl);
  companyData.twitterUrl = addHttps(companyData.twitterUrl);

  return () => {
    return axios
      .put(`investee/update/${companyData.id}`, companyData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};

export const deleteCompany = (id) => {
  return () => {
    return axios
      .delete(`investee/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteDividend = (id) => {
  return () => {
    return axios
      .delete(`investee/delete-dividend/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deleteNews = (id) => {
  return () => {
    return axios
      .delete(`investee/delete-new/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
