import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';
import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  marginLeftBtn: {
    marginLeft: 10,
  },
}));

export default function TrancheDataSnapshot({
  errorTextKeyData,
  handleCancel,
  handleClickKeyData,
  id,
  keyData,
  setKeyData,
  disabled = false,
  tagName = '',
}) {
  const classes = useStyles();

  const isEISorSEIS = tagName.startsWith('EIS') || tagName.startsWith('SEIS');

  return (
    <GridContainer item md={11}>
      <FieldRow title="Minimum subscription*" centerTitle>
        <NumberFormat
          required
          prefix="£"
          decimalScale={0}
          customInput={AppTextInput}
          name="minimumInvestment"
          placeholder="e.g. £5,000"
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator={true}
          onValueChange={({ value }) =>
            setKeyData({
              ...keyData,
              minimumInvestment: value,
            })
          }
          value={keyData.minimumInvestment}
          variant="outlined"
          error={errorTextKeyData.minimumInvestment}
          helperText={errorTextKeyData.minimumInvestment}
        />
      </FieldRow>
      <FieldRow title={`Income tax relief* (EIS, SEIS, VCT only)`} centerTitle>
        <TextField
          required={tagName === 'LP' ? false : true}
          name="incomeTaxRebate"
          placeholder="Enter the maximum income tax relief available to investors. e.g. 30%"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              incomeTaxRebate: event.target.value,
            })
          }
          value={keyData.incomeTaxRebate}
          variant="outlined"
          error={errorTextKeyData.incomeTaxRebate}
          helperText={errorTextKeyData.incomeTaxRebate}
        />
      </FieldRow>
      <FieldRow title={`Target return* (EIS, SEIS, LP only)`} centerTitle>
        <TextField
          required={tagName !== 'VCT' ? true : false}
          name="targetReturn"
          placeholder="e.g. 5.5x"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetReturn: event.target.value,
            })
          }
          value={keyData.targetReturn}
          variant="outlined"
          error={errorTextKeyData.targetReturn}
          helperText={errorTextKeyData.targetReturn}
        />
      </FieldRow>

      <FieldRow
        title={`Target portfolio size* (EIS, SEIS, LP only)`}
        centerTitle
      >
        <TextField
          required={tagName !== 'VCT' ? true : false}
          name="targetPortfolioSize"
          placeholder="e.g. 8-10 companies"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetPortfolioSize: event.target.value,
            })
          }
          value={keyData.targetPortfolioSize}
          error={errorTextKeyData.targetPortfolioSize}
          helperText={errorTextKeyData.targetPortfolioSize}
          variant="outlined"
        />
      </FieldRow>

      <FieldRow title="Manager AUM" centerTitle>
        <TextField
          name="fundSize"
          fullWidth
          placeholder="Enter the AUM managed by your fund manager group. e.g. £2.2bn"
          onChange={(event) =>
            setKeyData({ ...keyData, fundSize: event.target.value })
          }
          value={keyData.fundSize}
          variant="outlined"
          error={errorTextKeyData.fundSize}
          helperText={errorTextKeyData.fundSize}
        />
      </FieldRow>
      <FieldRow title="Fund AUM" centerTitle>
        <TextField
          name="fundAum"
          placeholder="Enter the AUM of the fund relating to this tranche. e.g. £200m"
          fullWidth
          onChange={(event) =>
            setKeyData({ ...keyData, fundAum: event.target.value })
          }
          value={keyData.fundAum}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Sector focus" centerTitle>
        <TextField
          name="sector"
          fullWidth
          placeholder="Enter this tranche's sector focus. e.g. Consumer, B2B Fintech"
          onChange={(event) =>
            setKeyData({ ...keyData, sector: event.target.value })
          }
          value={keyData.sector}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Tranche geography" centerTitle>
        <TextField
          name="fundGeography"
          placeholder="Enter this tranche's geographic focus. e.g. UK"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              fundGeography: event.target.value,
            })
          }
          value={keyData.fundGeography}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Fund manager group founding date" centerTitle>
        <TextField
          name="launchedIn"
          placeholder="Enter the year your fund manager group began trading. e.g. 2002"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              launchedIn: event.target.value,
            })
          }
          value={keyData.launchedIn}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow
        title={`Size of current raise (EIS, SEIS, VCT only)`}
        centerTitle
      >
        <TextField
          name="sizeOfCurrentRaise"
          placeholder="Enter the target raise amount for this tranche. e.g. £60m"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              sizeOfCurrentRaise: event.target.value,
            })
          }
          value={keyData.sizeOfCurrentRaise}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Target fund size (LP only)" centerTitle>
        <TextField
          name="targetFundSize"
          placeholder="Enter the target fund size. e.g. £600m"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetFundSize: event.target.value,
            })
          }
          value={keyData.targetFundSize}
          variant="outlined"
        />
      </FieldRow>

      <FieldRow title="Expected hold period" centerTitle>
        <TextField
          name="expectedHoldPeriod"
          placeholder="e.g. 5+ years"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              expectedHoldPeriod: event.target.value,
            })
          }
          value={keyData.expectedHoldPeriod}
          variant="outlined"
        />
      </FieldRow>

      <FieldRow title="Current portfolio size (VCT only)" centerTitle>
        <TextField
          name="currentNumberOfCompanies"
          placeholder="e.g. 47 companies"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              currentNumberOfCompanies: event.target.value,
            })
          }
          value={keyData.currentNumberOfCompanies}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Target dividend (VCT only)" centerTitle>
        <TextField
          name="targetAnnualDividend"
          placeholder="e.g. 3p per annum"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              targetAnnualDividend: event.target.value,
            })
          }
          value={keyData.targetAnnualDividend}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow
        title={`VCT returns data label (VCT only)`}
        centerTitle
        tooltipText="This label is used on the www.joinfurther.com distribution site. Please enter a suitable return metric here, an example of which is provided in the input field to the right."
      >
        <TextField
          name="vctReturnDataLabel"
          placeholder="e.g. Last 5 years total return"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              vctReturnDataLabel: event.target.value,
            })
          }
          value={keyData.vctReturnDataLabel}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow
        title={`VCT returns data (VCT only)`}
        centerTitle
        tooltipText={
          'Please input the financial returns data that corresponds with your return data label. For example, "Last 3 years total return" would require a percentage or subscription multiple in this field'
        }
      >
        <TextField
          name="vctReturnData"
          placeholder="e.g. 66%"
          fullWidth
          onChange={(event) =>
            setKeyData({
              ...keyData,
              vctReturnData: event.target.value,
            })
          }
          value={keyData.vctReturnData}
          variant="outlined"
        />
      </FieldRow>

      <FieldRow title="Capital gains relief" centerTitle>
        <AppRadioButton
          key={'Open'}
          name="capitalGainsRelief"
          label={'Yes'}
          value={true}
          checked={keyData.capitalGainsRelief}
          onChange={() => setKeyData({ ...keyData, capitalGainsRelief: true })}
        />
        <AppRadioButton
          key={'closed'}
          name="capitalGainsRelief"
          disabled={isEISorSEIS || tagName === 'VCT'}
          label={'No'}
          value={false}
          checked={!keyData.capitalGainsRelief}
          onChange={() => setKeyData({ ...keyData, capitalGainsRelief: false })}
        />
      </FieldRow>
      <FieldRow title={`Inheritance tax relief`} centerTitle>
        <AppRadioButton
          key={'Open'}
          name="inheritanceTaxRelief"
          label={'Yes'}
          value={true}
          checked={keyData.inheritanceTaxRelief}
          onChange={() =>
            setKeyData({ ...keyData, inheritanceTaxRelief: true })
          }
        />
        <AppRadioButton
          key={'closed'}
          name="inheritanceTaxRelief"
          label={'No'}
          disabled={isEISorSEIS}
          value={false}
          checked={!keyData.inheritanceTaxRelief}
          onChange={() =>
            setKeyData({
              ...keyData,
              inheritanceTaxRelief: false,
            })
          }
        />
      </FieldRow>
      <FieldRow title="Loss relief" centerTitle>
        <AppRadioButton
          key={'Open'}
          name="lossProtection"
          label={'Yes'}
          value={true}
          checked={keyData.lossProtection}
          onChange={() => setKeyData({ ...keyData, lossProtection: true })}
        />
        <AppRadioButton
          key={'closed'}
          name="lossProtection"
          label={'No'}
          disabled={isEISorSEIS}
          value={false}
          checked={!keyData.lossProtection}
          onChange={() => setKeyData({ ...keyData, lossProtection: false })}
        />
      </FieldRow>
      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickKeyData}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
