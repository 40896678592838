import { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, Button, CircularProgress, Box } from '@material-ui/core';
import { Check, CloudDownload, Save } from '@material-ui/icons';
import { status } from 'constants/investorReporting';
import { useInvestorReportingFormContext } from 'contexts/InvestorReportingFormContext';
import { INVESTOR_REPORTING_VIEW } from 'constants/routes';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles(() => ({
  floatingButtonContainer: {
    position: 'sticky',
    bottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  floatingButton: {
    position: 'relative',
    bottom: '-2px',
    borderRadius: '10rem',
    width: '3rem',
    height: '3rem',
    minWidth: 'auto',
    padding: '10px !important',
  },
}));

type Props = {
  onQuickSave: () => Promise<void>;
  onDownloadSample: () => void;
  isDownloading: boolean;
  allowDownload: boolean;
};

const QuickSaveDraftButton: FC<Props> = ({
  onQuickSave,
  onDownloadSample,
  isDownloading,
  allowDownload,
}) => {
  const classes = useStyles();
  const [isQuickSaving, setIsQuickSaving] = useState(false);
  const [recentlyQuickSaved, setRecentlyQuickSaved] = useState(false);

  const onButtonClick = async () => {
    setIsQuickSaving(true);
    await onQuickSave();
    setIsQuickSaving(false);
    setRecentlyQuickSaved(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (recentlyQuickSaved) {
        setRecentlyQuickSaved(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [recentlyQuickSaved]);

  const isViewPage = useRouteMatch(INVESTOR_REPORTING_VIEW)?.isExact;
  const formContext = useInvestorReportingFormContext();
  const quickSaveDisabled =
    formContext?.investorReport?.status === status.SUBMITTED || isViewPage;

  if (quickSaveDisabled) return null;

  return (
    <div className={classes.floatingButtonContainer}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gridGap={8}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          disabled={isQuickSaving || recentlyQuickSaved || isDownloading}
          className={classes.floatingButton}
        >
          {isQuickSaving ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : recentlyQuickSaved ? (
            <Check style={{ color: 'white' }} />
          ) : (
            <Save />
          )}
        </Button>
        <Tooltip
          title="You have to save your report before you can download a sample."
          disableHoverListener={allowDownload}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={onDownloadSample}
              disabled={isDownloading || !allowDownload}
              className={classes.floatingButton}
            >
              {isDownloading ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                <CloudDownload
                  style={allowDownload ? { color: 'white' } : {}}
                />
              )}
            </Button>
          </div>
        </Tooltip>
      </Box>
    </div>
  );
};

export default QuickSaveDraftButton;
