import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useDelegatedAccess } from 'hooks/data/delegatedAccess/useDelegatedAccess';

type Props = {
  delegateAccessId: string;
};

const DelegatedAccessUserActions: FC<Props> = ({ delegateAccessId }) => {
  const { remove } = useDelegatedAccess();

  return (
    <IconButton onClick={() => remove.mutate(delegateAccessId)}>
      <Delete htmlColor="#E8453C" />
    </IconButton>
  );
};

export default DelegatedAccessUserActions;
