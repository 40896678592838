import axios from 'axios';

export const getDeploymentUpdate = (id) => {
  return () => {
    return axios
      .get(`deployment-update/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.data.deploymentUpdate);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  };
};
