import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchFirmPortfolioBySector({ queryKey }) {
  const [, firmId] = queryKey;
  const queryParams = qs.stringify({
    firmId,
  });

  const url = firmId
    ? `dashboard/firm-portfolio-by-sector?${queryParams}`
    : `dashboard/firm-portfolio-by-sector`;

  const { data } = await api.get(url);

  return data.data;
}

function useFirmPortfolioBySector({ firmId }) {
  return useQuery({
    queryKey: ['firm-portfolio-by-sector', firmId],
    queryFn: fetchFirmPortfolioBySector,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useFirmPortfolioBySector;
