import { Button } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import useStyles from './styles';

type Props = {
  visible: boolean;
  buttonText: string;
  onClick: MouseEventHandler;
};

const KycResultsButton: React.FC<Props> = ({
  visible,
  buttonText,
  onClick,
}) => {
  const classes = useStyles();

  return visible ? (
    <Button
      className={`${classes.reviewBtn}`}
      size="small"
      variant="outlined"
      color="primary"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  ) : null;
};

export default KycResultsButton;
