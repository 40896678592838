import { FC } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, IconButton } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import Table from 'components/Table';
import { numberToDisplayString } from 'utils/numbers';
import { numberToCurrencyString } from 'further-ui/utils';
import { Api } from 'further-types/organisation';
import {
  createAdviserEditRoute,
  createAdviserViewRoute,
} from 'constants/routes';
import SearchField from 'components/SearchField';
import { PaginationState } from 'further-types/utils';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  title: {
    color: '#656565',
    margin: '2rem 0',
  },
}));

type Props = {
  organisation: Api.Organisation;
  data: any; // TODO: refactor to use correct type - AdviserApi.AdviserListResponse
  isFetching: boolean;
  handleSearch: (query: string) => void;
  pagination: PaginationState;
};

const LinkedAdvisers: FC<Props> = ({
  organisation,
  data,
  isFetching,
  handleSearch,
  pagination,
}) => {
  const classes = useStyles();

  const columns = [
    {
      label: 'Name',
      key: 'fullName',
    },
    ...(organisation?.type === 'parent'
      ? [
          {
            label: 'Organisation',
            key: 'organisationName',
            render: (row) => row?.organisationId?.name,
            sort: false,
          },
        ]
      : []),
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Clients',
      key: 'investorsCount',
      render: (row) => numberToDisplayString(row.investorsCount),
      sort: false,
    },
    {
      label: 'Subscriptions',
      key: 'investmentsCount',
      render: (row) => numberToDisplayString(row.investmentsCount),
      sort: false,
    },
    {
      label: 'Total initial value',
      key: 'initialValue',
      render: (row) => numberToCurrencyString(row.initialValue),
      sort: false,
    },
    {
      label: 'Total current value',
      key: 'currentValue',
      render: (row) => numberToCurrencyString(row.currentValue),
      sort: false,
    },
    {
      label: 'Realised value',
      key: 'realisedValue',
      render: (row) => numberToCurrencyString(row.realisedValue),
      sort: false,
    },
    {
      label: 'Advice fees',
      key: 'adviceFees',
      render: (row) => numberToCurrencyString(row.adviceFees),
      sort: false,
    },
    {
      label: 'Actions',
      sort: false,
      render: (row) => (
        <div className={classes.buttons}>
          <Link to={createAdviserViewRoute(row?._id)}>
            <IconButton title="View adviser" color="primary">
              <Visibility />
            </IconButton>
          </Link>
          <Link to={createAdviserEditRoute(row?._id)}>
            <IconButton title="Edit adviser" color="primary">
              <Edit />
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={classes.flex}>
        <h2 className={classes.title}>Linked advisers</h2>
        <SearchField
          onSearch={handleSearch}
          autoSearchOnDebounce
          allowEmptyQuery
          variant="outlined"
          name="adviserSearch"
          id="adviserSearch"
          placeholder="Search by email, name, or FCA number"
          width={'20rem'}
          fullWidth
        />
      </div>

      <Table
        columns={columns}
        tablebody={data?.adviser ?? []}
        loading={isFetching}
        onRequestSort={pagination.handleRequestSort}
        order={pagination.order}
        orderBy={pagination.orderBy}
        page={pagination.page}
        onPageChange={pagination.handleChangePage}
        count={data?.totalAdviser ?? 0}
        pagination={true}
        rowsPerPage={pagination.rowsPerPage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        emptyMessage="No advisers found"
      />
    </>
  );
};

export default LinkedAdvisers;
