import { Button, Grid } from '@material-ui/core';

import SearchField from 'components/SearchField';
import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';

type FiltersType = {
  search?: string;
  startDate?: Date;
  finishDate?: Date;
};

type Props = {
  filters: FiltersType;
  onFiltersChange: (filters: FiltersType) => void;
  onBulkExport: () => void;
  showBulkExportButton: boolean;
  isBulkExportInProgress: boolean;
};

const Filters: React.FC<Props> = ({
  filters,
  onFiltersChange,
  showBulkExportButton,
  onBulkExport,
  isBulkExportInProgress,
}) => {
  return (
    <Grid container alignItems="center" spacing={6}>
      <Grid item xs md={4}>
        <SearchField
          variant="outlined"
          placeholder="Search by transferor or transferee name"
          allowEmptyQuery
          autoSearchOnDebounce
          onSearch={(search: string) => onFiltersChange({ search })}
          fullWidth
        />
      </Grid>
      <Grid item xs md={4}>
        <MuiDateRangePicker
          dateRange={{
            startDate: filters.startDate,
            endDate: filters.finishDate,
          }}
          onDateRangeChange={({ startDate, endDate }) => {
            onFiltersChange({
              startDate,
              finishDate: endDate,
            });
          }}
          TextFieldProps={{ placeholder: 'Select date' }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {showBulkExportButton ? (
          <Button
            variant="contained"
            color="primary"
            onClick={onBulkExport}
            disabled={isBulkExportInProgress}
          >
            Download transfer statements
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Filters;
