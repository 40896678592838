import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import Table from 'components/Table';
import useTaxYears from 'hooks/data/taxReport/useTaxYears';
import { usePagination } from 'hooks/ui/usePagination';
import { dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { TaxYearStatus } from 'further-types/annual-tax-report';
import { FC } from 'react';
import { useGetRole } from 'hooks/ui/useGetRole';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import useTaxReportAudit from 'hooks/data/taxReport/useTaxReportAudit';
import Switch from 'components/Switch';
import NoResponse from 'components/SVGIcons/NoResponse';
import { capitalize, startCase } from 'lodash';
import AlertDialog from 'components/AlertDialog';

type TaxYear = ReturnType<
  typeof useTaxYears
>['taxYears']['data']['results'][number];

const useStyles = makeStyles(() => ({
  switch: {
    display: 'flex',
    gridGap: '0.5rem',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Investor reporting' },
  {
    label: 'Tax report audit',
    isActive: true,
  },
];

const TaxReportAudit: FC = () => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const approvalDisclosure = useDisclosure<{ taxYear: TaxYear }>();

  const pagination = usePagination({
    orderBy: 'taxYearStart',
    order: 'desc',
    id: 'tax-report-audit',
  });

  const { page, rowsPerPage } = pagination;
  const { taxYears, updateTaxYear } = useTaxYears({ page, rowsPerPage });
  const { isDownloading, download } = useTaxReportAudit();

  const handleDownload = (taxYearStart: number, firmId: string) => {
    download(taxYearStart, firmId);
  };

  const handleUpdate = (
    taxYear: TaxYear,
    status: TaxYearStatus,
    taxReportDisabled: boolean,
  ) =>
    updateTaxYear.mutate({
      taxYearStart: taxYear.taxYearStart,
      firmId: taxYear.firmId,
      status,
      taxReportDisabled,
    });

  const columns = [
    {
      label: 'Tax year',
      sort: false,
      render: (elm: TaxYear) =>
        isSuperAdmin
          ? `${elm.firmName} - ${elm.taxYearStart}/${elm.taxYearStart + 1}`
          : `${elm.taxYearStart}/${elm.taxYearStart + 1}`,
    },
    {
      label: 'Approval deadline',
      sort: false,
      render: (elm: TaxYear) => dateToLabel(elm.approvalDeadline),
    },
    {
      label: 'Approved on',
      sort: false,
      render: (elm: TaxYear) => dateToLabel(elm.approvedAt),
    },
    {
      label: 'Status',
      sort: false,
      render: (elm: TaxYear) =>
        elm.status === TaxYearStatus.NoResponse ? (
          capitalize(startCase(TaxYearStatus.NoResponse))
        ) : (
          <div className={classes.switch}>
            <span>Unapproved</span>
            <Switch
              disabled={
                elm.status === TaxYearStatus.Approved || updateTaxYear.isLoading
              }
              checked={elm.status === TaxYearStatus.Approved}
              onChange={(e) => {
                if (e.target.checked) {
                  approvalDisclosure.onOpen();
                  approvalDisclosure.stageAction({
                    taxYear: elm,
                  });
                }
              }}
            />
            <span>Approved</span>
          </div>
        ),
    },
    isSuperAdmin && {
      label: 'Disabled',
      sort: false,
      render: (elm: TaxYear) => (
        <div className={classes.switch}>
          <span>No</span>
          <Switch
            disabled={elm.status === TaxYearStatus.Approved}
            checked={elm.taxReportDisabled}
            onChange={(e) => {
              handleUpdate(elm, elm.status, !!e.target.checked);
            }}
          />
          <span>Yes</span>
        </div>
      ),
    },
    {
      label: 'Action',
      sort: false,
      render: (elm: TaxYear) => (
        <>
          <IconButton
            onClick={() => handleDownload(elm.taxYearStart, elm.firmId)}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <CircularProgress size={24} />
            ) : (
              <CloudDownload color="primary" />
            )}
          </IconButton>
          {isSuperAdmin && elm.status === TaxYearStatus.NotApproved && (
            <IconButton
              onClick={() =>
                handleUpdate(
                  elm,
                  TaxYearStatus.NoResponse,
                  elm.taxReportDisabled,
                )
              }
              disabled={updateTaxYear.isLoading}
            >
              <NoResponse />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <PageContainer heading={'Tax report audit'} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent>
          <Table
            columns={columns}
            onRequestSort={pagination.handleRequestSort}
            order={pagination.order}
            orderBy={pagination.orderBy}
            tablebody={taxYears.data?.results ?? []}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            pagination={true}
            count={taxYears.data?.total ?? 0}
            loading={taxYears.isFetching}
            variant="nohover"
          />
          <AlertDialog
            open={approvalDisclosure.isOpen}
            onClose={approvalDisclosure.onClose}
            onConfirm={() => {
              handleUpdate(
                approvalDisclosure.actionProps.taxYear,
                TaxYearStatus.Approved,
                false,
              );
              approvalDisclosure.onClose();
            }}
            title="Approve report data"
            content={
              <p>
                Do you wish to approve your tax report data? You cannot undo
                this action once it is confirmed.
              </p>
            }
            btnLabels={{
              cancel: 'Go back',
              confirm: 'Approve',
            }}
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default TaxReportAudit;
