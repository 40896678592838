import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import { Alert } from '@material-ui/lab';
import {
  makeStyles,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Switch from 'components/Switch';
import { Controller } from 'react-hook-form';
import { status } from 'constants/investorReporting';
import { Disclosure } from 'further-ui/hooks';
import { Control, UseFormSetValue } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  toggleAlert: {
    margin: '1rem 0',
    justifyContent: 'center',
  },
  toggle: {
    display: 'flex',
    gridGap: '.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  disclosure: Disclosure;
  notifyInvestors: boolean;
  setNotifyInvestors: (value: boolean) => void;
  setValue: UseFormSetValue<any>;
  control: Control<any>;
  reportStatus: string;
  onConfirmPublishUpdate: () => void;
  submitting: boolean;
};

const PublishConfirmationDialog: FC<Props> = ({
  disclosure,
  notifyInvestors,
  setNotifyInvestors,
  setValue,
  control,
  reportStatus,
  onConfirmPublishUpdate,
  submitting,
}) => {
  const classes = useStyles();

  return (
    <AlertDialog
      open={disclosure.isOpen}
      onClose={disclosure.onClose}
      title="Are you sure?"
      content={
        <>
          {reportStatus === status.SUBMITTED ? (
            <p>Proceeding will publish this updated report to all investors.</p>
          ) : (
            <p>Proceeding will publish this report to all investors.</p>
          )}
          <Alert severity="info" icon={false} className={classes.toggleAlert}>
            <div className={classes.toggle}>
              <Typography>Notify investors</Typography>
              <Controller
                name="notifyInvestors"
                defaultValue={reportStatus === status.DRAFT}
                control={control}
                render={({ field: { name, value } }) => (
                  <Switch
                    name={name}
                    checked={!!value}
                    onChange={(event) => {
                      setValue('notifyInvestors', !!event.target.checked);
                      setNotifyInvestors(!!event.target.checked);
                    }}
                    data-testid={`switch-resend-emails`}
                  />
                )}
              />
            </div>
          </Alert>
          <p>
            {notifyInvestors
              ? `Investors will receive an email notifying them of this report’s publication. Sending this email cannot be undone.`
              : 'Investors will not receive an email notifying them of this report’s publication.'}
          </p>
        </>
      }
      btnLabels={{
        cancel: 'Go back',
        confirm: 'Confirm',
      }}
      confirmBtnComponent={
        <>
          {submitting && <CircularProgress size={20} />}
          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            type="button"
            name="proceed"
            onClick={onConfirmPublishUpdate}
          >
            Proceed
          </Button>
        </>
      }
    />
  );
};

export default PublishConfirmationDialog;
