import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/companies';

type CompanyShareholdingsQueryKey = [
  'company-shareholdings',
  Api.GetInvesteeShareholdingsQuery,
];

async function fetchCompanyShareholdings({
  queryKey,
}: {
  queryKey: CompanyShareholdingsQueryKey;
}) {
  const [, params] = queryKey;

  const { data } = await api.get<{ data: Api.GetInvesteeShareHoldingsResult }>(
    `investee/${params.companyId}/shareholdings`,
    {
      params,
    },
  );

  return data.data;
}

function useCompanyShareholdings(params: Api.GetInvesteeShareholdingsQuery) {
  return useQuery<
    Api.GetInvesteeShareHoldingsResult,
    Error,
    Api.GetInvesteeShareHoldingsResult,
    CompanyShareholdingsQueryKey
  >({
    queryKey: ['company-shareholdings', params],
    queryFn: fetchCompanyShareholdings,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}

export default useCompanyShareholdings;
