import React from 'react';
import { nationality } from 'further-ui/labels';
import { Control, Controller, useFieldArray } from 'react-hook-form';

import AppCheckbox from 'components/FormElements/AppCheckBox';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppTextInput from 'components/FormElements/AppTextInput';
import FieldRow from 'components/FormElements/FieldRow';
import { InvestorFormValues } from 'helpers/investor/investorForm';
import { format, subYears } from 'date-fns';

type Props = {
  control: Control<InvestorFormValues>;
  isLegalEntity?: boolean;
};

const InvestorAddress: React.FC<Props> = ({ control, isLegalEntity }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'address',
  });

  const checkboxText = isLegalEntity
    ? `This was the entity’s registered address before ${format(
        subYears(new Date(), 3),
        'dd/MM/yyyy',
      )}`
    : `Investor lived at this address before ${format(
        subYears(new Date(), 3),
        'dd/MM/yyyy',
      )}`;

  return (
    <>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <FieldRow
            title={
              i === 0
                ? 'Address line 1*'
                : `Previous address ${String.fromCharCode(65 + i - 1)} line 1*`
            }
            centerTitle
          >
            <Controller
              name={`address.${i}.address1`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppTextInput
                  {...rest}
                  inputRef={ref}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow
            title={
              i === 0
                ? 'Address line 2'
                : `Previous address ${String.fromCharCode(65 + i - 1)} line 2`
            }
            centerTitle
          >
            <Controller
              name={`address.${i}.address2`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppTextInput
                  {...rest}
                  inputRef={ref}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow
            title={
              i === 0
                ? 'City/Town*'
                : `Previous address ${String.fromCharCode(
                    65 + i - 1,
                  )} City/Town*`
            }
            centerTitle
          >
            <Controller
              name={`address.${i}.city`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppTextInput
                  {...rest}
                  inputRef={ref}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow
            title={
              i === 0
                ? 'Postcode*'
                : `Previous address ${String.fromCharCode(
                    65 + i - 1,
                  )} Postcode*`
            }
            centerTitle
          >
            <Controller
              name={`address.${i}.postcode`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppTextInput
                  {...rest}
                  inputRef={ref}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow
            title={
              i === 0
                ? 'Country*'
                : `Previous address ${String.fromCharCode(65 + i - 1)} Country*`
            }
            centerTitle
          >
            <Controller
              name={`address.${i}.country`}
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <AppSelectBox
                  {...rest}
                  inputRef={ref}
                  data={nationality}
                  valueKey="Country"
                  labelKey="Country"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow title="" centerTitle>
            <Controller
              name={`address.${i}.isApplyAddress`}
              control={control}
              render={({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                field: { ref, value, onChange, ...rest },
                fieldState: { error },
              }) => (
                /* @ts-expect-error */
                <AppCheckbox
                  {...rest}
                  key="isApplyAddress"
                  label={checkboxText}
                  checked={!!value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(event) => {
                    if (!event.target.checked) {
                      append([
                        {
                          address1: '',
                          city: '',
                          postcode: '',
                          country: '',
                          isApplyAddress: true,
                        },
                      ]);
                    } else {
                      for (let idx = fields.length - 1; idx > i; idx--) {
                        remove(idx);
                      }
                    }
                    onChange(event);
                  }}
                />
              )}
            />
          </FieldRow>
        </React.Fragment>
      ))}
    </>
  );
};
export default InvestorAddress;
