import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#656565',
    letterSpacing: 0,
    margin: '0rem 0 1.5rem 0',
  },
}));

type Props = {
  title?: string;
  className?: string;
};

const CardHeader: React.FC<Props> = ({ title = '', className }) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.title, className)}>{title}</Typography>
  );
};

export default CardHeader;
