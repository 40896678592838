import { Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Switch from 'components/Switch';
import useStyles from './styles';

const AccordionToggle = ({
  item,
  index,
  handleChangeSwitch,
  isViewPage,
  control,
  setValue,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.flexGap}>
      <Typography className={classes.accordionToggleLabel}>
        Copy from Manager’s overview
      </Typography>
      <Controller
        name={`fundInformation[${index}][copyFund]`}
        defaultValue={item?.copyFund}
        control={control}
        render={({ field: { name, value } }) => (
          <Switch
            name={name}
            onChange={(event) => {
              setValue(
                `fundInformation[${index}][copyFund]`,
                !event.target.checked,
              );
              handleChangeSwitch(index, event.target.checked);
            }}
            checked={!value}
            disabled={isViewPage}
          />
        )}
      />
      <Typography className={classes.accordionToggleLabel}>
        Enter different commentary
      </Typography>
    </div>
  );
};

export default AccordionToggle;
