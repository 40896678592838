import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { sortBy } from 'lodash';
import GridContainer from 'components/GridContainer';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import { useGetRole } from 'hooks/ui/useGetRole';
import useCompanies from 'hooks/data/company/useCompanies';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import FieldRow from 'components/FormElements/FieldRow';
import { pluralize } from 'utils/pluralize';
import {
  numberToCurrencyString,
  dateToLabel,
  getCompanyLabel,
} from 'further-ui/utils';
import { EIS_EDIT } from 'constants/routes';

const CompanyDetails = ({ setData, data, setSummaryData, errorText }) => {
  const { firmId } = useGetRole();
  const { data: companies } = useCompanies({ params: { firmId } });
  const isEditPage = useRouteMatch(EIS_EDIT)?.isExact;
  const allocations = useAllocations({
    params: { companyId: data.companyId },
  });

  const companiesWithLabel = useMemo(
    () =>
      companies?.result?.map((company) => ({
        label: getCompanyLabel(company, { includeFirmName: !firmId }),
        ...company,
      })),
    [companies?.result],
  );

  const allocationsWithLabel = useMemo(
    () =>
      allocations?.data?.uploadShareHoldings
        ?.filter(
          (allocation) =>
            !allocation.hasKiCertificate && allocation.taxReliefElegible,
        )
        .map((allocation) => {
          const date = dateToLabel(allocation?.date);
          const amountInvested = numberToCurrencyString(
            allocation?.totalInvestmentAmount,
          );
          const noOfTranches = `${allocation?.numberOfTranches} ${pluralize(
            'tranche',
            allocation?.numberOfTranches,
          )}`;
          const noOfInvestments = `${
            allocation?.numberOfInvestments
          } ${pluralize('subscription', allocation?.numberOfInvestments)}`;
          const label = `${date} - ${amountInvested} - ${noOfTranches} - ${noOfInvestments}`;

          return {
            label,
            ...allocation,
          };
        }),
    [allocations?.data?.uploadShareHoldings],
  );

  useEffect(() => {
    if (data.companyId) {
      const selectedCompany = companiesWithLabel?.find(
        (company) => company._id === data.companyId,
      );
      setSummaryData((prevSummaryData) => ({
        ...prevSummaryData,
        companyName: selectedCompany?.label,
        legalName: selectedCompany?.legalName,
      }));
    }
  }, [data.companyId, companiesWithLabel, setSummaryData]);

  useEffect(() => {
    if (data.shareAllocationUploadId) {
      setSummaryData((prevSummaryData) => ({
        ...prevSummaryData,
        allocationName: allocationsWithLabel?.find(
          ({ _id }) => _id === data.shareAllocationUploadId,
        )?.label,
      }));
    }
  }, [data.shareAllocationUploadId, allocationsWithLabel, setSummaryData]);

  const noAllocationsForCompany =
    !allocations?.data?.uploadShareHoldings?.length &&
    !!data.companyId &&
    !allocations.isFetching;

  return (
    <GridContainer>
      <FieldRow title="Company*">
        <AppSelectBox
          required
          data={
            companiesWithLabel
              ? sortBy(companiesWithLabel, (c) => c.label?.toLowerCase())
              : []
          }
          valueKey="_id"
          disabled={isEditPage}
          name="companyId"
          labelKey="label"
          variant="outlined"
          value={data.companyId}
          error={errorText.companyId}
          helperText={errorText.companyId}
          onChange={(event) => {
            const companyId = event.target.value;
            setData({
              ...data,
              companyId,
              shareAllocationUploadId: null,
            });
          }}
        />
      </FieldRow>
      <FieldRow title="Allocation*">
        <AppSelectBox
          required
          data={allocationsWithLabel || []}
          disabled={isEditPage || !data.companyId || noAllocationsForCompany}
          valueKey="_id"
          name="shareAllocationUploadId"
          labelKey="label"
          variant="outlined"
          renderValue={(value) => {
            if (!data.companyId) return 'Please select a company first';
            if (noAllocationsForCompany)
              return 'No allocations have been made for this company yet';
            if (!data.shareAllocationUploadId) return 'Select allocation';
            return allocationsWithLabel?.find(({ _id }) => _id === value)
              ?.label;
          }}
          value={data.shareAllocationUploadId}
          error={errorText.shareAllocationUploadId}
          helperText={errorText.shareAllocationUploadId}
          onChange={(event) => {
            const shareAllocationUploadId = event.target.value;
            setData({ ...data, shareAllocationUploadId });
          }}
        />
      </FieldRow>
    </GridContainer>
  );
};
export default CompanyDetails;
