import { numberToCurrencyString } from 'further-ui/utils';
import PieChart, { useStyles as usePieChartStyles } from 'components/PieChart';
import { CmtTitle, CmtSubTitle } from 'components/CmtTypography';
import { makeStyles } from '@material-ui/core/styles';
import useFirmPortfolioBySector from 'hooks/data/dashboards/useFirmPortfolioBySector';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
  },
  title: {
    fontSize: '16px',
  },
  subTitleStyle: {
    marginBottom: 0,
    marginTop: 4,
    fontSize: 12,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pieChartSkeleton: {
    margin: '40px 0',
  },
  labelSkeleton: {
    margin: '5px 0',
  },
}));

const CompanyCategoryPieChart = ({ title, subtitle, firmId }) => {
  const classnames = useStyles();
  const pieChartClassnames = usePieChartStyles();

  const height = 390;
  const outerRadius = 110;
  const innerRadius = 80;

  const { data: deploymentData, isFetching } = useFirmPortfolioBySector({
    firmId,
  });

  return (
    <>
      <div className={classnames.heading}>
        <CmtTitle variant="h4" content={title} className={classnames.title} />
        <CmtSubTitle
          variant="h5"
          className={classnames.subTitleStyle}
          content={subtitle}
        />
      </div>
      {isFetching ? (
        <div className={classnames.skeletonContainer}>
          <Skeleton
            variant="circle"
            height={outerRadius * 2}
            width={outerRadius * 2}
            animation="wave"
            className={classnames.pieChartSkeleton}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width="100%"
            className={classnames.labelSkeleton}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width="100%"
            className={classnames.labelSkeleton}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width="100%"
            className={classnames.labelSkeleton}
          />
        </div>
      ) : (
        <PieChart
          data={deploymentData?.map((item) => ({
            key: item.categoryKey,
            value: item.categoryCurrentValue,
            companiesInCategory: item.companiesInCategory,
            categoryInitialInvestment: item.categoryInitialInvestment,
          }))}
          height={height}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          noDataMessage="No subscriptions yet"
          CustomTooltip={({ payload }) => {
            const item = payload[0];
            if (!item) return null;
            return (
              <div className={pieChartClassnames.tooltipWrapper}>
                <p>
                  <strong>{item.name}</strong>
                </p>
                <p>
                  Current value:{' '}
                  {numberToCurrencyString(item.value, {
                    hidePenniesIfInteger: true,
                  })}
                </p>
                <p>
                  Initial subscription:{' '}
                  {numberToCurrencyString(
                    item.payload?.categoryInitialInvestment,
                    { hidePenniesIfInteger: true },
                  )}
                </p>
                <p>
                  Companies in this category:{' '}
                  {item.payload?.companiesInCategory}
                </p>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default CompanyCategoryPieChart;
