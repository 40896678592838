import React, { ReactNode } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import FormLabel from 'components/FormElements/FormLabel';

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type Props = {
  title?: string;
  informationText?: string | React.ReactNode;
  children: React.ReactNode;
  centerTitle?: boolean;
  topTitle?: boolean;
  tooltipText?: ReactNode;
  spacing?: number;
};

const FieldRow = ({
  title,
  informationText,
  children,
  centerTitle,
  topTitle,
  tooltipText,
  spacing,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        md={4}
        xs={12}
        className={centerTitle ? classes.center : ''}
        style={{ paddingTop: spacing, paddingBottom: spacing }}
      >
        <FormLabel
          title={title}
          informationText={informationText}
          tooltipText={tooltipText}
          topTitle={topTitle}
        />
      </Grid>
      <Grid
        item
        md={1}
        xs={false}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      />
      <Grid
        item
        md={7}
        xs={12}
        style={{ paddingTop: spacing, paddingBottom: spacing }}
      >
        {children}
      </Grid>
    </>
  );
};

export default FieldRow;
