import React from 'react';
import { Tabs, Tab, makeStyles, Divider } from '@material-ui/core';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import TopAdviserTable from './TopAdviserTable';
import TopInvestorTable from './TopInvestorTable';
import TopOrganisationTable from './TopOrganisationTable';
import TopParentOrganisationTable from './TopParentOrganisationTable';

enum TabEnum {
  TOP_ADVISERS = 'TOP_ADVISERS',
  TOP_INVESTORS = 'TOP_INVESTORS',
  TOP_ORGANISATIONS = 'TOP_ORGANISATIONS',
  TOP_PARENT_ORGANISATIONS = 'TOP_PARENT_ORGANISATIONS',
}

const useStyles = makeStyles(() => ({
  tabs: {
    minHeight: 'auto',
    '& .MuiTabs-flexContainer': {
      gap: '20px',
    },
  },
  tab: {
    textTransform: 'none',
    padding: '5px',
    minHeight: 'auto',
    minWidth: 'unset !important',
    '& .MuiTab-wrapper': {
      display: 'inline',
      textAlign: 'left',
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  },
  indicator: {
    backgroundColor: 'black !important',
  },
  divider: {
    margin: '0 0 20px',
  },
  tableContainer: {
    height: '400px',
    overflowY: 'auto',
  },
  content: {
    position: 'relative',
  },
}));

const TopEntitiesTables = ({ firmId }) => {
  const [selectedTab, setSelectedTab] = React.useState<TabEnum>(
    TabEnum.TOP_INVESTORS,
  );

  const handleChange = (_event: React.ChangeEvent, newValue: TabEnum) => {
    setSelectedTab(newValue);
  };

  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardContent className={classes.content}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab
            label="Top Investors"
            value={TabEnum.TOP_INVESTORS}
            className={classes.tab}
          />
          <Tab
            label="Top Advisers"
            value={TabEnum.TOP_ADVISERS}
            className={classes.tab}
          />
          <Tab
            label="Top Organisations"
            value={TabEnum.TOP_ORGANISATIONS}
            className={classes.tab}
          />
          <Tab
            label="Top Parent Organisations"
            value={TabEnum.TOP_PARENT_ORGANISATIONS}
            className={classes.tab}
          />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.tableContainer}>
          {selectedTab === TabEnum.TOP_INVESTORS && (
            <TopInvestorTable firmId={firmId} />
          )}
          {selectedTab === TabEnum.TOP_ADVISERS && (
            <TopAdviserTable firmId={firmId} />
          )}
          {selectedTab === TabEnum.TOP_ORGANISATIONS && (
            <TopOrganisationTable firmId={firmId} type="child" />
          )}
          {selectedTab === TabEnum.TOP_PARENT_ORGANISATIONS && (
            <TopParentOrganisationTable firmId={firmId} />
          )}
        </div>
      </CmtCardContent>
    </CmtCard>
  );
};
export default TopEntitiesTables;
