import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { Edit, FlashOn, ViewQuilt, Mail } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import HoverInfoCard from 'components/HoverInfoCard';
import PageLoader from 'components/PageLoader';
import useClosedTrancheDashboard from 'hooks/data/dashboards/useClosedTrancheDashboard';
import {
  ALLOCATIONS_UPLOAD,
  DASHBOARD_MY_FIRM,
  EIS_ADD,
  INVESTOR_REPORTING_ADD,
  createSingleFirmRoute,
} from 'constants/routes';
import { InvestmentsByAdviserTable } from 'components/DashboardWidgets/InvestmentsByAdviserTable';
import { InvestmentsByLocation } from 'components/DashboardWidgets/InvestmentsByLocation';
import { InvestmentsByOrganisationTable } from 'components/DashboardWidgets/InvestmentsByOrganisationTable';
import { TrancheSnapshot } from 'components/DashboardWidgets/TrancheSnapshot';
import { TrancheCompaniesTable } from 'components/DashboardWidgets/TrancheCompaniesTable';
import { TrancheDeploymentSummary } from 'components/DashboardWidgets/TrancheDeploymentSummary';
import { TrancheValueByDate } from 'components/DashboardWidgets/TrancheValueByDate';
import { useGetRole } from 'hooks/ui/useGetRole';
import { InvestmentsByTrancheTable } from 'components/DashboardWidgets/InvestmentsByTrancheTable';

const useStyles = makeStyles((theme) => ({
  scrollableTable: {
    maxHeight: '500px',
    marginBottom: '24px',
    paddingBottom: '0 !important',
    overflow: 'auto',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  gridGap1: {
    gridGap: '1rem',
  },
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
    alignItems: 'stretch',

    '&> div': {
      height: 'auto',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  viewAll: {
    color: theme.palette.text.rootColor,
    textTransform: 'inherit',
    fontWeight: 700,
    letterSpacing: '.5px',
    backgroundColor: 'transparent !important',
    padding: '0',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const ClosedTrancheDashboard = () => {
  const { firmId } = useGetRole();
  const classes = useStyles();
  const { trancheId } = useParams();
  const [trancheSnapshot, setTrancheSnapshot] = useState({});
  const [trancheDetails, setTrancheDetails] = useState({});
  const [investmentsByAdviser, setInvestmentsByAdviser] = useState([]);
  const [investmentsByOrganisation, setInvestmentsByOrganisation] = useState(
    [],
  );
  const [investmentsByLocation, setInvestmentsByLocation] = useState({});
  const [trancheCompanies, setTrancheCompanies] = useState([]);
  const [deploymentSummary, setDeploymentSummary] = useState({});
  const [valueByDate, setValueByDate] = useState({});

  const closedTrancheDashboard = useClosedTrancheDashboard({
    params: { trancheId },
  });

  useEffect(() => {
    const response = closedTrancheDashboard.data;
    if (response) {
      setTrancheSnapshot(response?.trancheSnapshot);
      setTrancheDetails(response?.trancheSnapshot.trancheDetails);
      setInvestmentsByAdviser(response?.investmentsByAdviser);
      setInvestmentsByOrganisation(response?.investmentsByOrganisation);
      setInvestmentsByLocation(response?.investmentsByLocation);
      setTrancheCompanies(response?.companies);
      setDeploymentSummary(response?.deploymentSummary);
      setValueByDate(response?.valueByDate);
    }
  }, [closedTrancheDashboard.data]);

  return (
    <>
      {closedTrancheDashboard.isFetching ? (
        <PageLoader />
      ) : (
        <GridContainer spacing={5}>
          <Grid item xs={12}>
            <div>
              <h3 className={classes.masth}>
                {trancheDetails.firmId?.firmName}'s {trancheDetails.fundName}{' '}
                dashboard
              </h3>
            </div>
            <div style={{ marginTop: '24px' }}>
              <Grid item xs={12}>
                <TrancheValueByDate data={valueByDate} />
              </Grid>
            </div>
          </Grid>

          <Grid item md={6} xs={12} className={classes.flexGrid}>
            <TrancheSnapshot
              trancheSnapshot={trancheSnapshot}
              trancheDetails={trancheDetails}
              trancheId={trancheId}
              classes={classes}
            />
            <TrancheCompaniesTable
              rows={trancheCompanies}
              trancheId={trancheId}
              trancheName={trancheDetails.fundName}
              parentClasses={classes}
            />
          </Grid>

          <Grid item md={6} xs={12} className={classes.flexGrid}>
            <TrancheDeploymentSummary
              deploymentSummary={deploymentSummary}
              classes={classes}
            />
          </Grid>

          <Grid item md={8} xs={12}>
            <InvestmentsByTrancheTable
              trancheId={trancheId}
              classes={classes}
            />
          </Grid>

          <Grid item md={8} xs={12}>
            <InvestmentsByAdviserTable
              rows={investmentsByAdviser}
              classes={classes}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <InvestmentsByLocation
              investmentData={investmentsByLocation}
              classes={classes}
            />
          </Grid>

          <Grid
            item
            md={5}
            xs={12}
            className={clsx(classes.dcont, classes.gridGap1)}
          >
            <HoverInfoCard
              icon={<FlashOn style={{ color: '#ffffff' }} />}
              backgroundColor="#656565"
              subTitle="Start EIS Wizard"
              linkOnArrow={`${EIS_ADD}?trancheId=${trancheId}`}
            />
            <HoverInfoCard
              icon={<Edit style={{ color: '#ffffff' }} />}
              backgroundColor="#ECC9A2"
              subTitle="Create new allocation"
              linkOnArrow={ALLOCATIONS_UPLOAD}
            />
            <HoverInfoCard
              icon={<Mail style={{ color: '#ffffff' }} />}
              backgroundColor="#2E9A9A"
              subTitle="Create investor report"
              linkOnArrow={`${INVESTOR_REPORTING_ADD}?trancheId=${trancheId}`}
            />
            <HoverInfoCard
              icon={<ViewQuilt style={{ color: '#ffffff' }} />}
              backgroundColor="#56B26C"
              subTitle="View firm dashboard"
              linkOnArrow={
                firmId
                  ? DASHBOARD_MY_FIRM
                  : createSingleFirmRoute(trancheDetails.firmId?._id)
              }
            />
          </Grid>

          <Grid item md={7} xs={12}>
            <InvestmentsByOrganisationTable
              rows={investmentsByOrganisation}
              classes={classes}
            />
          </Grid>
        </GridContainer>
      )}
    </>
  );
};

export default ClosedTrancheDashboard;
