import axios from 'axios';

export const deleteFirm = (id) => {
  return () => {
    return axios
      .delete(`firm/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const deletePortfolio = (id) => {
  return () => {
    return axios
      .delete(`firm/portfolio/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const createPortfolio = (formData) => {
  return () => {
    return axios
      .post(`firm/portfolio`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response?.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const updatePortfolio = (data) => {
  return () => {
    return axios
      .put(`firm/portfolio/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response?.data);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
