import { useMutation } from '@tanstack/react-query';
import { useNotification } from 'hooks/ui/useNotification';
import { api } from 'lib/httpClient';

const useResendInvite = ({ onResendInviteSuccess }) => {
  const { error } = useNotification();

  return useMutation({
    mutationFn: async (adviserId) => {
      const { data: response } = await api.post(
        `adviser/resend-platform-invite/${adviserId}`,
      );
      return response;
    },
    onError: () => {
      error('Error when trying to resend platform invite to adviser');
    },
    onSuccess: async () => {
      onResendInviteSuccess?.();
    },
  });
};

const useAdviserPlatformInvites = (params = {}) => {
  return {
    resendInvite: useResendInvite(params),
  };
};

export default useAdviserPlatformInvites;
