import { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';

async function fetchById({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await api.get(`exit/${params.id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

function useStageExitForCreation() {
  return useMutation({
    mutationFn: async (requestBody) => {
      try {
        const { data: response } = await api.post(
          `exit/stage-for-creation`,
          requestBody,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
  });
}

function useStageExitForEdit() {
  return useMutation({
    mutationFn: async (requestBody) => {
      try {
        const { data: response } = await api.post(
          `exit/stage-for-edit`,
          requestBody,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
  });
}

function useCreateExit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestBody) => {
      try {
        const { data: response } = await api.post(`exit/create`, requestBody);
        await queryClient.invalidateQueries({ queryKey: ['exits'] });

        return response?.data;
      } catch (error) {
        throw error;
      }
    },
  });
}

function useEditExit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestBody) => {
      try {
        const { data: response } = await api.post(
          `exit/edit/${requestBody.exitId}`,
          requestBody,
        );
        await queryClient.invalidateQueries({ queryKey: ['exits'] });

        return response?.data;
      } catch (error) {
        throw error;
      }
    },
  });
}

function useDeleteExit() {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  return useMutation({
    mutationFn: async (props) => {
      const { exitId, exitAmendmentId } = props;
      const { data: response } = await api.delete(
        `exit/${exitId}${
          exitAmendmentId ? `/exit-amendment/${exitAmendmentId}` : ''
        }`,
      );

      return response?.data;
    },
    onSuccess: async (_data, props) => {
      props.onDeleteSuccess();
      notification.success('Exit deleted successfully');

      await queryClient.invalidateQueries({ queryKey: ['exits'] });
    },
    onError: (error) => {
      handleApiException(error);
    },
  });
}

export default function useExit({ params = {} }) {
  const notification = useNotification();

  const stageForCreation = useStageExitForCreation();
  const stageForEdit = useStageExitForEdit();

  const create = useCreateExit();
  const edit = useEditExit();
  const deleteExit = useDeleteExit();

  const getById = useQuery({
    queryKey: ['exit', { params }],
    queryFn: fetchById,
    enabled: !!params.id,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  /// error msgs
  useEffect(() => {
    const { responseMsg } = stageForEdit.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
  }, [stageForEdit.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    const { responseMsg } = stageForCreation.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
  }, [stageForCreation.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    const { responseMsg } = create.error?.response?.data || {};
    if (responseMsg)
      notification.error(responseMsg, { autoHideDuration: 12000 });
  }, [create.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    const { responseMsg } = edit.error?.response?.data || {};
    if (responseMsg)
      notification.error(responseMsg, { autoHideDuration: 12000 });
  }, [edit.error?.response?.data?.responseMsg, notification]);

  useEffect(() => {
    const { responseMsg } = getById.error?.response?.data || {};
    if (responseMsg) notification.error(responseMsg);
  }, [getById.error?.response?.data?.responseMsg, notification]);

  return {
    exit: getById,
    stageForEdit,
    stageForCreation,
    create,
    edit,
    deleteExit,
  };
}
