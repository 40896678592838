import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

type User = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  profilePic: string | null;
  roleId: {
    id: string;
    auth0RoleId: string;
  };
};

function useMyProfileUpdate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      const { data: response } = await api.put('user/profile', data);
      return response;
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user'] }),
  });
}

const useMyProfile = () => {
  return {
    profile: useQuery<User>({
      queryKey: ['user'],
      queryFn: async () => {
        const { data } = await api.get('/user/me');
        return data.result.user;
      },
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 60 * 1000,
    }),
    updateProfile: useMyProfileUpdate(),
  };
};

export default useMyProfile;
