import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { PaymentExpectationApi } from 'further-types/payment';

export default function useOutstandingPayments(params: {
  page: number;
  rowsPerPage: number;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
}) {
  return useQuery<PaymentExpectationApi.GetOutstandingPaymentsResponse>({
    queryKey: ['outstanding-payment-expectations', params],
    queryFn: async () =>
      (
        await api.get<
          ApiResponse<PaymentExpectationApi.GetOutstandingPaymentsResponse>
        >('/payment-expectation/outstanding', {
          params,
        })
      ).data.data,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}
