import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    color: '#656565',
    fontSize: 22,
    marginBottom: 18,
  },
  reviewBtn: {
    height: 26,
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  row: {
    minHeight: 50,
  },
  reviewBtnlft: {
    marginLeft: theme.spacing(4),
  },
  mBottom: {
    marginBottom: '0.5rem',
  },
  label: {
    color: '#656565',
    fontSize: 12,
  },
  value: {
    color: '#656565',
    display: 'inline-block',
    fontWeight: 'bold',
    minWidth: '100px',
  },
  notes: {
    flexGrow: 1,
    display: 'flex',
    gap: theme.spacing(4),

    '& > button': {
      flex: 'none',
    },
    '& .MuiInputBase-root': {
      minHeight: 0,
    },
  },
  notesInput: {
    height: '25px',
  },
  tooltipIcon: {
    position: 'relative',
    fontSize: '18px',
    cursor: 'pointer',
    top: '4px',
  },
  attachmentIcon: {
    fontSize: '18px',
  },
  attachmentLink: {
    fontSize: '12px',
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.text.rootColor,
    },
  },
  downloadButton: {
    height: 20,
  },
}));

export default useStyles;
