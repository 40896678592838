import React from 'react';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import Table from 'components/Table';

import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { RouteComponentProps, useHistory } from 'react-router';
import useAddFeesAndDiscounts, {
  InvestmentWithFee,
  InvestorFee,
} from 'hooks/data/investment/useAddFeesAndDiscounts';
import GridContainer from 'components/GridContainer';
import { FEE_DISCOUNT_SUCCESS } from 'constants/routes';
import useStyles from './styles';
import { useNotification } from 'hooks/ui/useNotification';
import { type as feeConst } from 'constants/typeConstant';
import { ChargeTo } from 'constants/chargeTo';

type Props = RouteComponentProps & {
  investmentFees: InvestmentWithFee[];
  investorFees: InvestorFee[];
  chargeTo: ChargeTo;
  onGoBack: () => void;
  firmId: string;
};

const investmentColumns = [
  {
    label: 'Fee name',
    render: (rec: InvestmentWithFee) => rec.newFee.feeName,
  },
  {
    label: 'Name',
    render: (rec: InvestmentWithFee) =>
      `${rec.investorId.fullName}${
        rec.externalInvestmentId ? ` (${rec.externalInvestmentId})` : ''
      }`,
  },
  {
    label: 'Subscription date',
    render: (rec: InvestmentWithFee) => {
      return dateToLabel(rec.investmentDate);
    },
  },
  {
    label: 'Original subscription',
    render: (rec: InvestmentWithFee) => {
      return numberToCurrencyString(rec.investmentAmount);
    },
  },
  {
    label: 'Fee/Discount',
    render: (rec: InvestmentWithFee) => {
      return rec.newFee.feeCalculationBasis === 'PERCENTAGE'
        ? `${rec.newFee.netFeePercentage}%`
        : numberToCurrencyString(rec?.newFee?.feeExVAT);
    },
  },
  {
    label: 'Fee adjustment',
    render: (rec: InvestmentWithFee) => {
      return `${
        rec.newFee.feeType === feeConst.feeTypes.discount ? '-' : ''
      }${numberToCurrencyString(rec?.newFee?.feeIncVAT)}`;
    },
  },
  {
    label: 'VAT',
    render: (rec: InvestmentWithFee) => {
      return rec.newFee.vat ? `${rec.newFee.vatFee}% VAT` : 'No VAT';
    },
  },
];

const investorColumns = [
  {
    label: 'Fee / Discount name',
    render: (rec: InvestorFee) => rec.cashMovement.notes,
  },
  {
    label: 'Investor',
    render: (rec: InvestorFee) => `${rec.investorId.fullName}`,
  },
  {
    label: 'Fee / Discount',
    render: (rec: InvestorFee) => {
      const source = rec.cashMovement.source;
      const amount = numberToCurrencyString(Math.abs(rec.cashMovement.amount));
      return source === 'INVESTOR_DISCOUNT' ? `-${amount}` : amount;
    },
  },
];

const Summary: React.FC<Props> = ({
  investmentFees,
  investorFees,
  onGoBack,
  chargeTo,
  firmId,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { createInvestmentFee, createInvestorFees } = useAddFeesAndDiscounts();
  const { error } = useNotification();

  const handleSubmit = async () => {
    try {
      const response =
        chargeTo === ChargeTo.INVESTOR
          ? await createInvestorFees({
              firmId,
              investorFees: investorFees.map(({ cashMovement }) => ({
                cashMovement,
              })),
            })
          : await createInvestmentFee({
              firmId,
              investmentFees: investmentFees.map(
                ({ investmentId, newFee }) => ({
                  investmentId,
                  fee: newFee,
                }),
              ),
            });

      if (response.statusCode === 200) {
        history.push(
          `${FEE_DISCOUNT_SUCCESS}?investmentCount=${
            (chargeTo === 'investor' ? investorFees : investmentFees).length
          }`,
        );
      }
    } catch (_) {
      error('Error when adding fees/disccounts');
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      <GridContainer>
        <Grid item xs={12}>
          <Typography component="h4" variant="inherit">
            Fee adjustments to be added
          </Typography>
          <br />

          <Table
            variant="nohover"
            columns={
              chargeTo === ChargeTo.INVESTOR
                ? investorColumns
                : investmentColumns
            }
            tablebody={
              chargeTo === ChargeTo.INVESTOR ? investorFees : investmentFees
            }
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttonContainer}>
            <Button variant="outlined" onClick={onGoBack}>
              Back
            </Button>
            <Button
              className={classes.buttonLeftMargin}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </GridContainer>
    </>
  );
};

export default Summary;
