import React from 'react';
import NumberFormat from 'react-number-format';
import {
  InputAdornment,
  IconButton,
  Button,
  makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from 'components/Tooltip';
import GridContainer from 'components/GridContainer';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import AppTextInput from 'components/FormElements/AppTextInput';
import Switch from 'components/Switch';
import HelpIcon from '@material-ui/icons/Help';
import { useGetRole } from 'hooks/ui/useGetRole';
import { FirmSelector } from 'components/FirmSelector';
import FieldRow from 'components/FormElements/FieldRow';
import { Delete } from '@material-ui/icons';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deleteButton: {
    color: '#E8453C',
  },
  switchLabel: {
    fontSize: 14,
    margin: '0 12px',

    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

const FeeAmendment = ({ adviserData, setAdviserData, errors }) => {
  const { isSuperAdmin } = useGetRole();
  const classes = useStyles();
  const { error } = useNotification();
  const {
    changeToInitialInvestmentFeesErrors = [],
    commissionOnInvestmentFeesErrors = [],
  } = errors;

  const addNewInitialFeeAmendment = () => {
    setAdviserData({
      ...adviserData,
      changeToInitialInvestmentFees: [
        ...adviserData.changeToInitialInvestmentFees,
        {
          firmId: '',
          amendByPercentage: 0,
        },
      ],
    });
  };

  const changeInitialFeeAmendment = (index, updates) => {
    if (updates.firmId) {
      const amendmentExistsForFirm =
        adviserData.changeToInitialInvestmentFees.find(
          (feeAmendment) => feeAmendment.firmId === updates.firmId,
        );
      if (amendmentExistsForFirm) {
        error('A fee amendment already exists for this firm');
        return;
      }
    }

    const changeToInitialInvestmentFees =
      adviserData.changeToInitialInvestmentFees.map((feeAmendment, i) => {
        if (i === index) {
          return {
            ...feeAmendment,
            ...updates,
          };
        }
        return feeAmendment;
      });
    setAdviserData({
      ...adviserData,
      changeToInitialInvestmentFees,
    });
  };

  const removeInitialFeeAmendment = (index) => {
    const changeToInitialInvestmentFees =
      adviserData.changeToInitialInvestmentFees.filter(
        (feeAmendment, i) => i !== index,
      );
    setAdviserData({
      ...adviserData,
      changeToInitialInvestmentFees,
    });
  };

  const addNewCommissionAmendment = () => {
    setAdviserData({
      ...adviserData,
      commissionOnInitialInvestmentFees: [
        ...adviserData.commissionOnInitialInvestmentFees,
        {
          firmId: '',
          amendByPercentage: 0,
          vatPercentage: 0,
        },
      ],
    });
  };

  const changeCommissionFeeAmendment = (index, updates) => {
    if (updates.firmId) {
      const amendmentExistsForFirm =
        adviserData.commissionOnInitialInvestmentFees.find(
          (feeAmendment) => feeAmendment.firmId === updates.firmId,
        );
      if (amendmentExistsForFirm) {
        error('A comission amendment already exists for this firm');
        return;
      }
    }

    const commissionOnInitialInvestmentFees =
      adviserData.commissionOnInitialInvestmentFees.map((feeAmendment, i) => {
        if (i === index) {
          return {
            ...feeAmendment,
            ...updates,
          };
        }
        return feeAmendment;
      });
    setAdviserData({
      ...adviserData,
      commissionOnInitialInvestmentFees,
    });
  };

  const removeCommissionFeeAmendment = (index) => {
    const commissionOnInitialInvestmentFees =
      adviserData.commissionOnInitialInvestmentFees.filter(
        (feeAmendment, i) => i !== index,
      );
    setAdviserData({
      ...adviserData,
      commissionOnInitialInvestmentFees,
    });
  };

  const percentageInputCommonProps = {
    fullWidth: true,
    allowLeadingZeros: false,
    customInput: AppTextInput,
    InputProps: {
      endAdornment: (
        <InputAdornment
          position="end"
          style={{ position: 'absolute', right: 22, top: 10 }}
        >
          %
        </InputAdornment>
      ),
    },
  };

  return (
    <>
      <FieldRow
        title={
          isSuperAdmin
            ? 'Change initial fee for adviser?'
            : 'Change initial fee for this adviser?'
        }
        centerTitle
      >
        <div className={classes.flex}>
          <div>
            <span className={classes.switchLabel}>No</span>
            <span>
              <Switch
                checked={adviserData?.initialFeeHasChanges || false}
                onChange={(event) =>
                  setAdviserData({
                    ...adviserData,
                    initialFeeHasChanges: event.target.checked,
                  })
                }
              />
            </span>
            <span className={classes.switchLabel}>Yes</span>
          </div>

          {isSuperAdmin && adviserData?.initialFeeHasChanges && (
            <Button variant="outlined" onClick={addNewInitialFeeAmendment}>
              Add fee variation for a different firm
            </Button>
          )}
        </div>
      </FieldRow>

      {adviserData?.initialFeeHasChanges && (
        <Grid item md={12} xs={12}>
          {adviserData.changeToInitialInvestmentFees.map(
            (feeAmendment, index) => (
              <GridContainer alignItems="center">
                <FieldRow
                  key={index}
                  title="Enter net fee variation"
                  centerTitle
                >
                  <GridContainer alignItems="center">
                    {isSuperAdmin && (
                      <Grid item xs={5}>
                        <FirmSelector
                          placeholder="Select firm"
                          firmId={feeAmendment.firmId}
                          onChange={(firmId) =>
                            changeInitialFeeAmendment(index, { firmId })
                          }
                          error={
                            !!changeToInitialInvestmentFeesErrors[index]?.firmId
                          }
                        />
                      </Grid>
                    )}

                    <Grid item xs={4}>
                      <div className={classes.flex}>
                        <NumberFormat
                          {...percentageInputCommonProps}
                          allowNegative
                          onValueChange={({ value }) =>
                            changeInitialFeeAmendment(index, {
                              amendByPercentage: value,
                            })
                          }
                          value={feeAmendment.amendByPercentage}
                          error={
                            !!changeToInitialInvestmentFeesErrors[index]
                              ?.amendByPercentage
                          }
                        />

                        <Tooltip
                          placement="right"
                          title="This is the amount by which the initial fee is increased or decreased, for example entering 1% here changes a 2.5% initial fee to 3.5%."
                        >
                          <IconButton>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                        {isSuperAdmin && (
                          <Grid item md={2} xs={12}>
                            <IconButton
                              className={classes.deleteButton}
                              onClick={() => removeInitialFeeAmendment(index)}
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        )}
                      </div>
                    </Grid>
                  </GridContainer>
                </FieldRow>
              </GridContainer>
            ),
          )}
        </Grid>
      )}

      <FieldRow title="Adviser commission within initial fee?" centerTitle>
        <div className={classes.flex}>
          <div>
            <span className={classes.switchLabel}>No</span>
            <span>
              <Switch
                checked={adviserData?.commissionFeeHasChanges || false}
                onChange={(event) =>
                  setAdviserData({
                    ...adviserData,
                    commissionFeeHasChanges: event.target.checked,
                  })
                }
              />
            </span>
            <span className={classes.switchLabel}>Yes</span>
          </div>

          {isSuperAdmin && adviserData?.commissionFeeHasChanges && (
            <Button variant="outlined" onClick={addNewCommissionAmendment}>
              Add commission variation for a different firm
            </Button>
          )}
        </div>
      </FieldRow>

      <Grid item md={12} xs={12}>
        {adviserData?.commissionFeeHasChanges &&
          adviserData.commissionOnInitialInvestmentFees.map(
            (feeAmendment, index) => (
              <GridContainer>
                <FieldRow title="Enter commission" key={index} centerTitle>
                  <GridContainer alignItems="center">
                    {isSuperAdmin && (
                      <Grid item md={5}>
                        <FirmSelector
                          firmId={feeAmendment.firmId}
                          placeholder="Select firm"
                          onChange={(firmId) =>
                            changeCommissionFeeAmendment(index, { firmId })
                          }
                          error={
                            !!commissionOnInvestmentFeesErrors[index]?.firmId
                          }
                        />
                      </Grid>
                    )}

                    <Grid item md={2}>
                      <NumberFormat
                        {...percentageInputCommonProps}
                        allowNegative={false}
                        onValueChange={({ value }) =>
                          changeCommissionFeeAmendment(index, {
                            amendByPercentage: value,
                          })
                        }
                        value={feeAmendment.amendByPercentage}
                        error={
                          !!commissionOnInvestmentFeesErrors[index]
                            ?.amendByPercentage
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <div className={classes.flex}>
                        <AppSelectBox
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          data={[
                            { label: '20% VAT', value: 20 },
                            { label: 'No VAT', value: 0 },
                          ]}
                          valueKey="value"
                          name="vatPercentage"
                          value={feeAmendment.vatPercentage}
                          labelKey="label"
                          variant="outlined"
                          error={
                            !!commissionOnInvestmentFeesErrors[index]
                              ?.vatPercentage
                          }
                          helperText={
                            commissionOnInvestmentFeesErrors[index]
                              ?.vatPercentage
                          }
                          onChange={(event) => {
                            changeCommissionFeeAmendment(index, {
                              vatPercentage: event.target.value,
                            });
                          }}
                        />
                        <Tooltip
                          placement="right"
                          title="Any amount included here will be deducted from your total initial fee (as adjusted above) for each subscription associated with this adviser."
                        >
                          <IconButton>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                        {isSuperAdmin && (
                          <Grid item md={1} xs={12}>
                            <IconButton
                              className={classes.deleteButton}
                              onClick={() =>
                                removeCommissionFeeAmendment(index)
                              }
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        )}
                      </div>
                    </Grid>
                  </GridContainer>
                </FieldRow>
              </GridContainer>
            ),
          )}
      </Grid>
    </>
  );
};

export default FeeAmendment;
