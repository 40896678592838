import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  socialIcon: {
    maxWidth: 24,
  },
}));

interface SocialMediaLinkProps {
  url: string;
  iconSrc: string;
  altText: string;
}

export const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({
  url,
  iconSrc,
  altText,
}) => {
  const classes = useStyles();

  if (!url) return null;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Button color="primary">
        <img src={iconSrc} alt={altText} className={classes.socialIcon} />
      </Button>
    </a>
  );
};

export default SocialMediaLink;
