import { getFileSignedUrl } from 'hooks/ui/useFileUpload';
import { useDownload } from 'hooks/ui/useDownload';
import { useState } from 'react';
import resourceExists from 'utils/resourceExists';

// This hook will try and download a PDF update for a specific investor & reporting ID.
// 1. It will get the signed URL for where the file should be in S3
// 2. It will see if the file exists in S3 `resourceExists()`
// 3. If it does exist in S3, it will download it from there
// 4. If it doesn't exist in S3, it will call `investor-reporting/export-pdf-by-investor/...` which generates it on demand

export function useDownloadPdfUpdate(params) {
  const { reportingId, investorId, filename } = params;

  const download = useDownload({
    mimeType: 'application/pdf',
    filename,
    errorMessage: 'Investor update PDF could not be downloaded.',
  });
  const [loading, setLoading] = useState(false);

  const processDownload = async () => {
    if (!loading) {
      setLoading(true);
      const url = await getFileSignedUrl(
        `pdf-update/${reportingId}/${investorId}.pdf`,
      );

      // the signed url given above is only valid for getObject requests, hence a HEAD request
      // won't work. A more performant future solution would be to have an endpoint that returns
      // signed headObject urls OR ensure the get is properly cached for the subsequent download
      if (await resourceExists(url, true)) {
        // file exists in s3 - take it from there
        download.mutate(url);
      } else {
        // file is not in s3, perhaps it's still being generated or it was
        // never generated, so generate it on demand
        download.mutate(
          `investor-reporting/export-pdf-by-investor/${reportingId}/${investorId}`,
        );
      }
      setLoading(false);
    }
  };

  return { download: processDownload, loading: loading || download.isLoading };
}
