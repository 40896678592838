import { FC } from 'react';
import { useParams } from 'react-router';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { CircularProgress } from '@material-ui/core';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';
import { useFetchOrganisation } from 'hooks/data/organisation/useOrganisation';
import OrganisationDetails from './OrganisationDetails';
import LinkedAdvisers from './LinkedAdvisers';
import LinkedInvestments from './LinkedInvestments';
import OrganisationUsers from './OrganisationUsers';
import { useLinkedInvestments } from './hooks/useLinkedInvestments';
import { useLinkedAdvisers } from './hooks/useLinkedAdvisers';
import OrganisationSnapshot from './OrganisationSnapshot';

const breadcrumbs = [
  { label: 'Dashboard' },
  { label: 'Organisation', link: '/organisation' },
  {
    label: 'View Organisation',
    link: '/',
    isActive: true,
  },
];

const ViewOrganisation: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isFetching } = useFetchOrganisation({ id });
  const {
    handleSearch: handleSearchLinkedInvestments,
    pagination: linkedInvestmentsPagination,
    data: linkedInvestmentsData,
    isFetching: isFetchingLinkedInvestments,
  } = useLinkedInvestments(data?.organisation?._id);

  const {
    handleSearch: handleSearchLinkedAdvisers,
    pagination: linkedAdvisersPagination,
    data: linkedAdvisersData,
    isFetching: isFetchingLinkedAdvisers,
  } = useLinkedAdvisers(data?.organisation?._id);

  const snapshotTotals = {
    ...linkedInvestmentsData?.totals,
    totalAdvisers: linkedAdvisersData?.totalAdviser,
    childOrganisationsCount: data?.organisation?.childOrganisationsCount,
  };

  const isLoadingSnapshot =
    isFetching || isFetchingLinkedAdvisers || isFetchingLinkedInvestments;

  return (
    <>
      <PageContainer
        heading="Organisation: View organisation"
        breadcrumbs={breadcrumbs}
      >
        <CmtCard>
          <CmtCardContent>
            {isFetching ? (
              <GridContainer
                justifyContent="center"
                style={{ marginTop: '2rem' }}
              >
                <CircularProgress />
              </GridContainer>
            ) : (
              <OrganisationDetails organisation={data?.organisation} />
            )}
            {data?.organisation && (
              <>
                <OrganisationSnapshot
                  totals={snapshotTotals}
                  isParent={data?.organisation?.type === 'parent'}
                  isLoading={isLoadingSnapshot}
                />
                <OrganisationUsers organisationId={data.organisation?._id} />
                <LinkedAdvisers
                  organisation={data.organisation}
                  data={linkedAdvisersData}
                  isFetching={isFetchingLinkedAdvisers}
                  handleSearch={handleSearchLinkedAdvisers}
                  pagination={linkedAdvisersPagination}
                />
                <LinkedInvestments
                  linkedInvestments={{
                    data: linkedInvestmentsData,
                    isFetching: isFetchingLinkedInvestments,
                  }}
                  handleSearch={handleSearchLinkedInvestments}
                  pagination={linkedInvestmentsPagination}
                />
              </>
            )}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </>
  );
};

export default ViewOrganisation;
