import { Controller, useFieldArray } from 'react-hook-form';

import FieldRow from 'components/FormElements/FieldRow';

import Grid from '@material-ui/core/Grid';
import AppSelectBox from 'components/FormElements/AppSelectBox';

import NumberFormat from 'react-number-format';
import { AppTextInput } from 'components/FormElements';
import { Button, Divider, IconButton } from '@material-ui/core';
import { Fragment } from 'react';
import { Delete } from '@material-ui/icons';

type Fee = {
  amount: number;
  vat: number;
  rangeStart: number;
  rangeEnd: number;
  id: string;
};

const WithdrawalFees = ({ control, errors, classes, firm }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'withdrawalFees',
  });

  return (
    <>
      <p className={classes.bodyCopy}>
        Use this section to manage any withdrawal fees. Multiple withdrawal fees
        can be set, if you have different fees for small or large withdrawals.
      </p>

      <FieldRow>
        <div className={classes.actionsContainer}>
          {fields.length === 0 && (
            <Button
              variant="text"
              color="primary"
              className={classes.addFeeButton}
              onClick={() => {
                append({
                  amount: 0,
                  vat: 0,
                  rangeStart: 0,
                  rangeEnd: null,
                });
              }}
            >
              Add Fee
            </Button>
          )}
        </div>
      </FieldRow>

      {(fields as Fee[]).map((fee, index) => (
        <Fragment key={fee.id}>
          <FieldRow title="Enter withdrawal fee" centerTitle>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Controller
                  name={`withdrawalFees.${index}.amount`}
                  defaultValue={fee.amount}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <div className={classes.inlineField}>
                      <NumberFormat
                        {...rest}
                        inputRef={ref}
                        prefix="£"
                        onValueChange={({ value }) => {
                          onChange(+value);
                        }}
                        allowNegative={false}
                        decimalScale={2}
                        allowLeadingZeros={false}
                        thousandSeparator={true}
                        customInput={AppTextInput}
                        error={!!errors?.withdrawalFees?.[index]?.amount}
                        helperText={
                          errors?.withdrawalFees?.[index]?.amount?.message
                        }
                      />
                    </div>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={`withdrawalFees.${index}.vat`}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <div className={classes.inlineField}>
                      <p className={classes.subLabel}>VAT</p>
                      <AppSelectBox
                        required
                        {...rest}
                        inputRef={ref}
                        data={[
                          { label: '20% VAT', value: 20 },
                          { label: 'No VAT', value: 0 },
                        ]}
                        name="vat"
                        valueKey="value"
                        labelKey="label"
                        variant="outlined"
                        onChange={onChange}
                        error={!!errors?.withdrawalFees?.[index]?.vat}
                        helperText={
                          errors?.withdrawalFees?.[index]?.vat?.message
                        }
                      />
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </FieldRow>

          <FieldRow spacing={6}>
            <p className={classes.subHeading}>
              Range (if applicable - leave blank if fee applies to all
              withdrawals)
            </p>
          </FieldRow>

          <FieldRow>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name={`withdrawalFees.${index}.rangeStart`}
                  defaultValue={fee.rangeStart}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <>
                      <div className={classes.inlineField}>
                        <p className={classes.subLabel}>From</p>
                        <NumberFormat
                          {...rest}
                          inputRef={ref}
                          onValueChange={({ value }) => {
                            onChange(value ? +value : null);
                          }}
                          decimalScale={2}
                          prefix="£"
                          allowNegative={false}
                          allowLeadingZeros={false}
                          thousandSeparator={true}
                          customInput={AppTextInput}
                          error={!!errors?.withdrawalFees?.[index]?.rangeStart}
                          helperText={
                            errors?.withdrawalFees?.[index]?.rangeStart?.message
                          }
                        />
                      </div>
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={`withdrawalFees.${index}.rangeEnd`}
                  defaultValue={fee.rangeEnd}
                  control={control}
                  render={({ field: { ref, onChange, ...rest } }) => (
                    <div className={classes.inlineField}>
                      <p className={classes.subLabel}>To</p>
                      <NumberFormat
                        {...rest}
                        inputRef={ref}
                        onValueChange={({ value }) => {
                          onChange(value ? +value : null);
                        }}
                        decimalScale={2}
                        prefix="£"
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator={true}
                        customInput={AppTextInput}
                        error={!!errors?.withdrawalFees?.[index]?.rangeEnd}
                        helperText={
                          errors?.withdrawalFees?.[index]?.rangeEnd?.message
                        }
                      />
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </FieldRow>

          <FieldRow>
            <div className={classes.actionsContainer}>
              <IconButton
                className={classes.deleteButton}
                onClick={() => remove(index)}
              >
                <Delete />
              </IconButton>

              {(index === fields.length - 1 || !fields.length) && (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  className={classes.addAnotherFeeButton}
                  onClick={() => {
                    append({
                      // Set the new range to the previous fee's amount + 0.01
                      amount: fee.amount || 0,
                      vat: fee.vat || 0,
                      rangeStart: fee.rangeEnd ? fee.rangeEnd + 0.01 : null,
                      rangeEnd: null,
                    });
                  }}
                >
                  Add another fee and range
                </Button>
              )}
            </div>
          </FieldRow>

          {index !== fields.length - 1 && (
            <Divider className={classes.subDivider} />
          )}
        </Fragment>
      ))}

      <FieldRow>
        <p className={classes.errorTextGroup}>
          {errors?.withdrawalFees?.root?.message}
        </p>
      </FieldRow>

      <FieldRow
        title="Withdrawal text"
        informationText="Use this field to explain the withdrawal fee to investors"
        centerTitle
      >
        <Controller
          name="withdrawalFeeText"
          defaultValue={firm?.data?.withdrawalFeeText}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <AppTextInput
              {...rest}
              inputRef={ref}
              placeholder="e.g. There is a withdrawal fee of £10 for any withdrawals under £10,000."
              fullWidth
              multiline
            />
          )}
        />
        <p className={classes.errorText}>{errors.withdrawalFeeText?.message}</p>
      </FieldRow>
    </>
  );
};

export default WithdrawalFees;
