import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tooltipWrapper: {
    padding: '10px',
    backgroundColor: 'white',
    border: '1px solid rgb(204,204,204)',
    whiteSpave: 'nowrap',
  },
}));

const PieChartComponent = ({
  data,
  CustomTooltip,
  height = 270,
  outerRadius = 90,
  innerRadius = 70,
  noDataMessage,
  numberOfLegendToSkip = undefined,
}) => {
  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#3BB8D3',
    '#E46B88',
    '#246B82',
    '#AE43C9',
    '#E4AC1A',
  ];

  const hasNoData =
    !data || data.length === 0 || data.every((d) => d.value === 0);

  if (hasNoData)
    return (
      <ResponsiveContainer width="100%" height={height}>
        <PieChart margin={{ top: 0, bottom: 0 }}>
          <Pie
            data={[{ value: 1, key: noDataMessage || 'No Data' }]}
            dataKey="value"
            nameKey="key"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            legendType="circle"
          >
            <Cell fill="#ccc" />
          </Pie>
          <Legend verticalAlign="bottom" />
        </PieChart>
      </ResponsiveContainer>
    );

  const cappedLegendPayload = data
    .sort((a, b) => (b.value < a.value ? 1 : -1))
    .filter((d) => d.value > 0)
    .map((d, i) => ({
      value: d.key,
      type: 'circle',
      color: COLORS[i % COLORS.length],
    }))
    .slice(numberOfLegendToSkip || -10)
    .reverse();

  if (cappedLegendPayload.length < data.length) {
    cappedLegendPayload.push({
      value: `+ ${data.length - cappedLegendPayload.length} others`,
      type: 'circle',
      color: '#747474',
    });
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={height}>
        <PieChart margin={{ top: 0, bottom: 0 }}>
          <Pie
            data={data
              .sort((a, b) => (b.value < a.value ? 1 : -1))
              .filter((d) => d.value > 0)}
            dataKey="value"
            nameKey="key"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={90}
            endAngle={360 + 90}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            iconSize={8}
            payload={cappedLegendPayload}
            verticalAlign="bottom"
          />
          {CustomTooltip ? (
            <Tooltip content={<CustomTooltip />} />
          ) : (
            <Tooltip />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;
