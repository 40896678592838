import { makeStyles, Typography } from '@material-ui/core';
import { useGetRole } from 'hooks/ui/useGetRole';
import { NewNote } from './NewNote';
import { useNotes } from 'hooks/data/notes/useNotes';
import NoteGroup from '../NoteGroup';
import useCurrentUser from 'hooks/data/currentUser/useCurrentUser';
import { groupBy } from 'lodash';
import { NoteRelation } from 'further-types/notes';

const useStyles = makeStyles(() => ({
  notes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  title: {
    color: '#656565',
  },
}));

type NotesProps = {
  relationId: string;
  noteRelation: NoteRelation;
};

const Notes: React.FC<NotesProps> = ({ relationId, noteRelation }) => {
  const classes = useStyles();
  const { isSuperAdmin, firmId } = useGetRole();
  const user = useCurrentUser();
  const { createNote, getNotes, deleteNote } = useNotes({
    params: { firmId, relationId, noteRelation },
  });

  const notesData = getNotes?.data;

  if (!relationId) return null;

  const submitNote = (note) => {
    const newNote = {
      content: note,
      relationId,
      noteRelation,
    };
    createNote.mutate(newNote);
  };

  const superAdminNotes = notesData?.filter(
    (note) => !note.hasOwnProperty('firmId'),
  );

  const notesByFirm = groupBy(
    notesData?.filter((note) => note.hasOwnProperty('firmId')),
    'firm._id',
  );

  return (
    <div className={classes.notes}>
      {Object.keys(notesByFirm).length > 0 &&
        Object.keys(notesByFirm).map((id) => (
          <NoteGroup
            key={id}
            notes={notesByFirm[id]}
            title={`Notes - ${notesByFirm[id][0].firm.firmName}`}
            linkOnLastNote={firmId === id}
            deleteNote={deleteNote}
          />
        ))}
      {isSuperAdmin && superAdminNotes?.length > 0 && (
        <NoteGroup
          key={'superAdminNotes'}
          notes={superAdminNotes}
          title={'Notes - Further admin'}
          linkOnLastNote
          deleteNote={deleteNote}
        />
      )}
      {((isSuperAdmin && superAdminNotes?.length === 0) ||
        (!isSuperAdmin && Object.keys(notesByFirm).length === 0)) && (
        <Typography variant="h2" className={classes.title}>
          Notes
        </Typography>
      )}
      <NewNote submitNote={submitNote} image={user?.data?.profilePic} />
    </div>
  );
};

export default Notes;
