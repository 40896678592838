import moment from 'moment';
import { Grid, Divider } from '@material-ui/core';

import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import GridContainer from 'components/GridContainer';

import CardHeader from 'components/CardHeader';
import FieldRow from 'components/FormElements/FieldRow';

import NumberFormat from 'react-number-format';
import AppTextInput from 'components/FormElements/AppTextInput';
import React from 'react';

type InitialShareProceProps = {
  classes: Record<string, any>;
  setValue: (x: Record<string, any>) => void;
  value: Record<string, any>;
  errorText: Record<string, string>;
};

export const InitialSharePrice: React.FC<InitialShareProceProps> = ({
  classes,
  setValue,
  value,
  errorText,
}) => {
  return (
    <Grid item md={12} xs={12}>
      <Divider className={classes.divider} />
      <CardHeader title="Share price record" />
      <GridContainer>
        <FieldRow title="Initial share price" centerTitle>
          <GridContainer>
            <Grid item md={5}>
              <div className={classes.smallFlexContainer}>
                <span className={classes.spanLabel}>Initial price</span>
                <NumberFormat
                  onValueChange={(event) => {
                    setValue({
                      ...value,
                      initialPrice: event.value,
                    });
                  }}
                  required
                  name="initialPrice"
                  placeholder="Enter amount"
                  value={value.initialPrice}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  prefix="£"
                  customInput={AppTextInput}
                  error={!!errorText.initialPrice}
                  helperText={errorText.initialPrice}
                />
              </div>
            </Grid>
            <Grid item md={7}>
              <div className={classes.smallFlexContainer}>
                <span className={classes.spanLabel}>Initial date</span>
                <KeyboardDatePicker
                  placeholder="Select initial date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="date"
                  value={value?.date}
                  onChange={(date) => {
                    setValue({
                      ...value,
                      date,
                    });
                  }}
                  error={!!errorText.date}
                  helperText={errorText.date}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={moment().format('YYYY-MM-DD')}
                />
              </div>
            </Grid>
          </GridContainer>
        </FieldRow>
      </GridContainer>
    </Grid>
  );
};
