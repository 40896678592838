import { useParams } from 'react-router';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { CircularProgress, makeStyles } from '@material-ui/core';
import CardHeader from 'components/CardHeader';
import Summary from '../Common/Summary';
import { useExitStatement } from 'hooks/data/exit';

type Params = {
  id: string;
};

const useStyles = makeStyles(() => ({
  cardContentPadding: {
    padding: '2rem',
  },
}));

const ViewExitStatement = () => {
  const classes = useStyles();
  const heading = 'Investor communications: Exit statement';
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    { label: ' Exit statement', link: '/', isActive: true },
  ];

  const { id } = useParams<Params>();

  const {
    exitStatement: { data, isFetching },
  } = useExitStatement({ id });

  const statement = data?.exitStatement;

  if (isFetching) {
    return <CircularProgress size={20} />;
  }

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader title="Exit statement summary" />

          <Summary
            title={statement?.title}
            summary={statement?.summary}
            letter={statement?.letter}
            accountManager={statement?.accountManager?.name}
            role={statement?.managerRole}
            notes={statement?.notes}
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default ViewExitStatement;
