import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonRowMutations: {
    display: 'flex',
    gridGap: '1rem',
    alignItems: 'center',
  },
  divider: {
    margin: '2.5rem 0',
  },
  addMetricBtn: {
    color: theme.palette.text.green,
    display: 'flex',
    alignItems: 'center',
  },
  addMetricBtnGroup: {
    margin: '0 0 1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tradingNameRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  callout: {
    margin: '0 14px 32px 14px',
    borderColor: theme.palette.text.green,
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '2rem',

    '& .MuiListItemText-primary': {
      letterSpacing: 0,
      color: '#717070',
    },
  },
  calloutTitle: {
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#656565',
  },
  greenIcon: {
    color: theme.palette.text.green,
  },
  marginTop: {
    marginTop: 12,
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  additionalMetricsExtra: {
    color: 'rgba(0, 0, 0, 0.7)',
    marginLeft: 5,
  },
  deleteButton: {
    color: theme.palette.text.red,
  },
  buttonLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '1rem',
  },
  overallFormError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    color: theme.palette.text.red,
  },
}));

export default useStyles;
