import { api } from 'lib/httpClient';
import { useQuery } from '@tanstack/react-query';

async function fetchFundInfo({ queryKey }) {
  const [, params] = queryKey;
  const {
    data: { data },
  } = await api.get(`investor-reporting/closed-funds`, {
    params,
  });
  return data;
}

export function useReportingFundInfo(firmId, submitDate) {
  return useQuery({
    queryKey: ['investor-reporting-fund', { firmId, submitDate }],
    queryFn: fetchFundInfo,
    enabled: !!firmId && !!submitDate,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
