import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { Button, makeStyles } from '@material-ui/core';
import { Delete, Edit, Dvr } from '@material-ui/icons';
import clsx from 'clsx';

import TextField from 'components/FormElements/AppTextInput';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import CmtAvatar from 'components/CmtAvatar';
import PageContainer from 'components/PageContainer';
import { FrontEndSiteSubdomainUrlToStageMap } from 'constants/redirectConstant';

import { deleteFirm } from '@redux/actions/InvestmentFirm';
import { createFirmEditRoute, FIRM_ADD } from 'constants/routes';
import { Link } from 'react-router-dom';
import { useFirm } from 'hooks/data/firm/useFirm';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useDebounce } from 'usehooks-ts';
import { usePagination } from 'hooks/ui/usePagination';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import useFilters from 'hooks/ui/useFilters';

const stage = process.env.REACT_APP_STAGE;

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.green,
  },
  deleteButton: {
    color: theme.palette.text.red,
  },
  view: {
    padding: '10px 16px 6px',
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
  marginFirstName: {
    margin: '10px 0px 0px 10px',
  },
  link: {
    display: 'flex',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Firms', isActive: true },
];

const InvestmentFirm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [record, setRecord] = useState();
  const [deleted, setDeleted] = useState(false);
  const [createPermission, updatePermission, deletePermission] =
    useGetPermissions(['create:firm', 'edit:firm', 'delete:firm']);
  const { firmId } = useGetRole();

  const pagination = usePagination({
    id: 'firms-table',
  });
  const { filters, handleChangeFilters } = useFilters('firms-table', {
    defaultFilters: {
      search: '',
    },
    onFiltersChange: pagination.toFirstPage,
  });

  const debouncedSearch = useDebounce(filters.search, 200);

  const { firms } = useFirm({
    params: {
      page: pagination.page,
      perPage: pagination.rowsPerPage,
      firmId,
      type: pagination.order,
      keyName: pagination.orderBy,
      search: debouncedSearch,
    },
  });

  const handleDelete = (rec) => {
    setRecord(rec);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleConfirmDelete = () => {
    const firm = firms.data?.result;
    handleApiRequest(async (showSuccess) => {
      const response = await dispatch<{
        status: number;
        data?: { responseMsg: string };
      }>(
        //@ts-ignore
        deleteFirm(record?._id),
      );
      if (response.status === 200) {
        showSuccess(response.data.responseMsg);
        if (firm?.length === 1) {
          pagination.pageBack();
        } else {
          setDeleted(!deleted);
        }
      }
    });

    setOpenConfirmDialog(false);
  };

  const columns = [
    {
      label: 'Firm name',
      key: 'firmName',
      render: (elm) => (
        <div className={classes.dFlex}>
          <CmtAvatar
            //@ts-ignore
            size={40}
            src={elm?.investmentFirmLogo}
          />
          <div className={classes.marginFirstName}>{elm?.firmName}</div>
        </div>
      ),
    },
    {
      label: 'Key contact name',
      key: 'keyContactName',
    },
    {
      label: 'Key contact email',
      key: 'keyContactEmail',
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm) => (
        <div className={classes.dFlex}>
          {updatePermission && (
            <Link to={createFirmEditRoute(elm._id)} className={classes.link}>
              <Button
                title="Edit"
                color="primary"
                className={classes.customButton}
              >
                <Edit />
              </Button>
            </Link>
          )}
          {deletePermission && (
            <Button
              title="Delete"
              className={`${classes.customButton} ${classes.deleteButton}`}
              onClick={() => handleDelete(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
          {elm?.domain && (
            <a
              rel="noopener noreferrer"
              title="View"
              type="button"
              className={clsx(
                classes.tableMt,
                classes.view,
                classes.customButton,
              )}
              href={FrontEndSiteSubdomainUrlToStageMap[stage].replace(
                '{domain}',
                elm?.domain,
              )}
              target="_blank"
            >
              <Dvr />
            </a>
          )}
        </div>
      ),
    },
  ];

  return (
    <PageContainer heading="Firms" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="search"
                    name="search"
                    placeholder="Search by name or email"
                    fullWidth
                    value={filters.search}
                    onChange={(event) => {
                      handleChangeFilters({ search: event.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {createPermission && (
                    <div className={classes.addRoleBtn}>
                      <Link to={FIRM_ADD}>
                        <Button
                          color="primary"
                          variant="contained"
                          className="bt-cls"
                        >
                          Add Firm
                        </Button>
                      </Link>
                    </div>
                  )}
                </Grid>
              </GridContainer>
              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  onRequestSort={pagination.handleRequestSort}
                  order={pagination.order}
                  orderBy={pagination.orderBy}
                  tablebody={firms.data?.result ?? []}
                  onPageChange={pagination.handleChangePage}
                  onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  count={firms.data?.total}
                  pagination={true}
                  loading={firms.isFetching}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        {
          <AlertDialog
            open={openConfirmDialog}
            onClose={handleDelete}
            onConfirm={handleConfirmDelete}
            title={`Are you sure you wish to delete this firm?`}
            content={
              <p>
                This cannot be undone and all information relating to the firm
                will be lost.
              </p>
            }
            btnLabels={{
              cancel: 'Go back',
              confirm: 'Delete firm',
            }}
          />
        }
      </GridContainer>
    </PageContainer>
  );
};

export default InvestmentFirm;
