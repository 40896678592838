import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  TableRow,
  TableCell,
  Checkbox,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { Skeleton } from '@material-ui/lab';

import { alpha, makeStyles } from '@material-ui/core/styles';

import CmtObjectSummary from '../CmtObjectSummary';
import CmtAvatar from '../CmtAvatar';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:not(.TableItem-noHover):hover, &.active': {
      backgroundColor: theme.palette.background.rootColor,

      '& $tableCellRoot, & $titleRoot': {
        color: theme.palette.text.white,
      },
      '& $showContent': {
        width: 0,
      },
      '& $hideContent': {
        transform: 'translateX(0)',
        width: '100%',
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
    '&:first-child': {
      borderTopWidth: 0,
    },
    '&:not(.TableItem-noHover):hover': {
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
    },
    '&.collapse-table-row': {
      borderTop: '0 none',
      '& $tableCellRoot': {
        padding: 0,
      },
    },
    '&.active': {
      borderTop: '0 none',
      '&:hover': {
        transform: 'none',
        boxShadow: 'none',
      },
    },
  },
  tableCellRoot: {
    padding: '18px 6px 15px',
    fontSize: 14,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
  },
  tableCellClickable: {
    cursor: 'pointer',
  },
  tableCollapseCell: {
    borderBottom: '0 none',
  },
  tableCellHideShow: {
    width: '25%',
  },
  titleRoot: {
    color: theme.palette.text.secondary,
    letterSpacing: 0.25,
  },
  hideShowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  showContent: {
    transition: 'all 0.3s ease-in-out',
    width: 0,
    // overflow: 'hidden',
    position: 'absolute',
    left: 0,
  },
  hideContent: {
    transition: 'all 0.3s ease-in-out',
    transform: 'translateX(110%)',
    overflow: 'hidden',
  },
  hideShowLink: {
    cursor: 'pointer',
  },
  collapseTable: {
    paddingLeft: 60,
    '& td': {
      color: theme.palette.text.secondary,
      fontSize: 12,
      letterSpacing: 0.4,
      padding: 0,
      borderBottom: '0 none',
    },
  },
  mtArrow: {
    display: 'flex',
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 1,

    '& ~ *': {
      // this is to blur the table content
      filter: 'blur(1px)',
    },
  },
  ellipsis: {
    display: 'block',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TableItem = ({
  row,
  columns,
  loading,
  emptyMessage,
  variant,
  showSkeleton,
  rowsPerPage,
  selectable,
  onSelect,
  selectedRowsIndexes,
  hideEmptyMessage,
  collapsedContent,
}) => {
  const classes = useStyles();
  const rowClassname = variant === 'nohover' ? 'TableItem-noHover' : '';

  const rows = showSkeleton ? Array.from(Array(rowsPerPage).keys()) : row;
  const [expandedRows, setExpandedRows] = useState([]);

  const handleExpandedRow = (rowKey) => {
    if (expandedRows.includes(rowKey)) {
      setExpandedRows(expandedRows.filter((row) => row !== rowKey));
    } else {
      setExpandedRows([...expandedRows, rowKey]);
    }
  };

  useEffect(() => {
    setExpandedRows([]);
  }, [row, rowsPerPage]);

  return (
    <React.Fragment>
      {loading && (
        <tr className={clsx(classes.loadingContainer)}>
          <td>
            <CircularProgress />
          </td>
        </tr>
      )}
      {rows.length || showSkeleton ? (
        rows.map((sin, index) => {
          const isExpanded = expandedRows.includes(`row-${index}`);
          return (
            <React.Fragment key={`row-${index}`}>
              <TableRow className={clsx(classes.tableRowRoot, rowClassname)}>
                <React.Fragment key={`row-${index}`}>
                  {selectable ? (
                    <TableCell
                      className={clsx(
                        classes.tableCellRoot,
                        classes.tableCellFirst,
                      )}
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={selectedRowsIndexes.includes(index)}
                        onClick={() => onSelect(index)}
                      />
                    </TableCell>
                  ) : null}
                </React.Fragment>

                {columns.map((sinCol, i) => {
                  const displayValue = showSkeleton
                    ? ''
                    : sinCol.render
                    ? sinCol.render(sin, index)
                    : sin[sinCol.key];

                  return (
                    <React.Fragment key={`all-col-${i}`}>
                      <TableCell
                        key={i}
                        className={clsx(
                          classes.tableCellRoot,
                          classes.tableCellFirst,
                          collapsedContent ? classes.tableCellClickable : '',
                          sinCol.tdClassname,
                          !showSkeleton ? sinCol.getCellClassName?.(sin) : null,
                        )}
                        size={sinCol.size}
                        width={columns.length === 2 ? '50%' : ''}
                        onClick={() =>
                          collapsedContent && handleExpandedRow(`row-${index}`)
                        }
                      >
                        {sinCol.icon ? (
                          <CmtObjectSummary
                            avatar={
                              <CmtAvatar src={''} alt={sin[sinCol.key]} />
                            }
                            title={sin[sinCol.key]}
                            titleProps={{ className: classes.titleRoot }}
                            showItemBadge={false}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            align={'horizontal'}
                          />
                        ) : displayValue ? (
                          <div
                            className={sinCol.ellipsis ? classes.ellipsis : ''}
                          >
                            {displayValue}
                          </div>
                        ) : loading ? (
                          <Skeleton variant="text" />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                {collapsedContent && (
                  <TableCell className={clsx(classes.tableCellRoot)}>
                    <IconButton
                      size="medium"
                      onClick={() => handleExpandedRow(`row-${index}`)}
                    >
                      {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
              {isExpanded && (
                <TableRow>
                  <TableCell
                    className={clsx(classes.tableCollapseCell)}
                    colSpan={columns.length}
                  >
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      {collapsedContent(sin)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })
      ) : !hideEmptyMessage ? (
        <TableRow>
          <TableCell colSpan={columns?.length + (collapsedContent ? 1 : 0)}>
            <div className={clsx(classes.emptyContainer)}>
              {emptyMessage || 'No record found'}
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};

export default TableItem;
