import { sumBy } from 'lodash';
import { WithdrawalStatus } from 'further-types/withdrawal';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { makeStyles } from '@material-ui/core';
import {
  RequestSourceToLabelMap,
  StatusToLabelMap,
} from 'constants/withdrawalProperties';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { usePagination } from 'hooks/ui/usePagination';
import { Close } from '@material-ui/icons';
import FiltersAndActions from './FiltersAndActions';
import NotesAndAttachmentColumn from './NotesAndAttachmentColumn';
import useFilters from 'hooks/ui/useFilters';
import Actions from 'components/Actions';
import UndoAction from 'components/Actions/Undo';
import {
  useExportWithdrawals,
  useGetWithdrawals,
  useRejectWithdrawal,
  useRevertWithdrawal,
} from 'hooks/data/withdrawal/useWithdrawal';
import Action from 'components/Actions/Action';

const useStyles = makeStyles(() => ({
  tableFooter: {
    background: '#FBFBFA',
  },
  tableFooterTd: {
    padding: '0.5rem 0',
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
  },
}));

type Withdrawal = ReturnType<
  typeof useGetWithdrawals
>['data']['withdrawals'][number];

const ReviewWithdrawals: React.FC = () => {
  const classes = useStyles();

  const pagination = usePagination({
    id: 'withdrawal-requests-table',
    orderBy: 'createdAt',
    order: 'desc',
  });
  const { filters, handleChangeFilters } = useFilters(
    'withdrawal-requests-table',
    {
      defaultFilters: {
        startDate: null,
        endDate: null,
        status: WithdrawalStatus.Requested,
      },
      onFiltersChange: pagination.toFirstPage,
    },
  );
  const rejectWithdrawal = useRejectWithdrawal();
  const revertWithdrawal = useRevertWithdrawal();
  const withdrawals = useGetWithdrawals({
    ...pagination.properties,
    ...filters,
  });
  const exportWithdrawals = useExportWithdrawals({
    ...pagination.properties,
    ...filters,
  });

  const handleExport = (sendToCustodian?: boolean) => {
    exportWithdrawals.mutate(!!sendToCustodian);
  };

  const showRejectAction = filters.status === WithdrawalStatus.Requested;
  const showUndoAction = !showRejectAction;

  const columns = [
    {
      label: 'Investor Name',
      render: (elm: Withdrawal) => elm.investor.fullName,
    },
    {
      label: 'Net withdrawal amount',
      key: 'amount',
      render: (elm: Withdrawal) =>
        numberToCurrencyString(elm?.amount - (elm?.withdrawalFee || 0)),
    },
    {
      label: 'Withdrawal fee',
      key: 'withdrawalFee',
      render: (elm: Withdrawal) =>
        numberToCurrencyString(elm?.withdrawalFee || 0),
    },
    {
      label: 'Withdrawal request date',
      key: 'createdAt',
      render: (elm: Withdrawal) => dateToLabel(elm?.createdAt),
    },
    {
      label: 'Withdrawal type',
      key: 'requestSource',
      render: (elm: Withdrawal) => RequestSourceToLabelMap[elm?.requestSource],
    },
    {
      label: 'Name on bank account',
      key: 'nameOnBankAccount',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankAccountName,
      sort: false,
    },
    {
      label: 'Bank account number',
      key: 'bankAccountNumber',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankAccountNumber,
      sort: false,
    },
    {
      label: 'Bank sort code',
      key: 'bankSortCode',
      render: (elm: Withdrawal) => elm?.investorBankDetails?.bankSortCode,
      sort: false,
    },
    {
      label: 'Status',
      key: 'status',
      render: (elm: Withdrawal) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {StatusToLabelMap[elm?.status]}
        </span>
      ),
      sort: false,
    },
    {
      label: 'Notes',
      key: 'notes',
      render: (elm: Withdrawal) => <NotesAndAttachmentColumn {...elm} />,
      sort: false,
    },
    {
      label: 'Actions',
      render: (elm: Withdrawal) => (
        <Actions>
          {showRejectAction && (
            <Action
              label="Reject"
              onClick={() => rejectWithdrawal.mutate(elm._id)}
              icon={Close}
              color="danger"
            />
          )}
          {showUndoAction && (
            <UndoAction onClick={() => revertWithdrawal.mutate(elm._id)} />
          )}
        </Actions>
      ),
      hideCol: !showRejectAction && !showUndoAction,
      sort: false,
    },
  ];
  const tableEmtpyMessage = [
    WithdrawalStatus.ExportedToCustodian,
    WithdrawalStatus.Rejected,
  ].includes(filters.status)
    ? `No withdrawal requests found that have been ${StatusToLabelMap[
        filters.status
      ].toLowerCase()}`
    : 'No withdrawal requests found';

  const withdrawalsList = withdrawals.data?.withdrawals || [];

  return (
    <PageContainer heading="Withdrawals: Withdrawal record">
      <CmtCard>
        <CmtCardContent>
          <FiltersAndActions
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            resultCount={withdrawalsList.length}
            hasExportError={exportWithdrawals.isError}
            isExportLoading={exportWithdrawals.isLoading}
            onExport={handleExport}
          />
          <Table
            columns={columns.filter((col) => !col.hideCol)}
            tablebody={withdrawalsList}
            count={withdrawals.data?.totalCount || 0}
            orderBy={pagination.orderBy}
            order={pagination.order}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onRequestSort={pagination.handleRequestSort}
            onPageChange={pagination.handleChangePage}
            onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            pagination={true}
            emptyMessage={tableEmtpyMessage}
            variant="nohover"
            TableFooter={
              <tr className={classes.tableFooter}>
                <td className={classes.tableFooterTd}>Total</td>
                <td className={classes.tableFooterTd}>
                  {numberToCurrencyString(sumBy(withdrawalsList, 'amount'))}
                </td>
              </tr>
            }
          />
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};

export default ReviewWithdrawals;
