import { Grid } from '@material-ui/core';
import { Exits, Shareholding } from '.';
import { groupBy, sumBy } from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { numberToCurrencyString } from 'further-ui/utils';

type PortfolioItem = {
  companyName: string;
  unrealisedValue: number;
  realisedValue: number;
  initialInvestmentValue: number;
  shareClass: string;
};

type PortfolioSnapshotProps = {
  combinedShareholdings: Array<Shareholding>;
  exits: Exits;
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p style={{ margin: '0 0 10px 0', fontWeight: 'bold' }}>
          {payload[0].payload.fullLabel}
        </p>
        {payload.map((pld: any) => (
          <p key={pld.name} style={{ margin: '5px 0' }}>
            <span
              style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                backgroundColor: pld.color,
                marginRight: '5px',
              }}
            ></span>
            {pld.name}: {numberToCurrencyString(pld.value)}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export const PortfolioSnapshotGraph = ({
  combinedShareholdings,
  exits,
}: PortfolioSnapshotProps) => {
  const portfolioData = groupPortfolioData(combinedShareholdings, exits);

  const chartData = portfolioData
    .map((item) => ({
      ...item,
      totalExitValue: item.unrealisedValue + item.realisedValue,
      fullLabel: `${item.companyName} (${item.shareClass})`,
    }))
    .sort((a, b) => b.totalExitValue - a.totalExitValue);

  return (
    <Grid item xs={12}>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 35, bottom: 50 }}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis
            dataKey="companyName"
            tick={{ fontSize: 'smaller' }}
            tickMargin={15}
          />
          <YAxis
            tickFormatter={(value) => numberToCurrencyString(value)}
            tickMargin={5}
          />
          <Tooltip cursor={{ fill: '#C8C8C826' }} content={<CustomTooltip />} />
          <Legend wrapperStyle={{ paddingTop: '20px' }} />
          <Bar
            dataKey="initialInvestmentValue"
            fill="#ECC9A2"
            name="Initial investment value"
            barSize={30}
          ></Bar>
          <Bar
            dataKey="unrealisedValue"
            stackId="a"
            fill="#65C9B1"
            name="Unrealised value"
            barSize={30}
          ></Bar>
          <Bar
            dataKey="realisedValue"
            stackId="a"
            fill="#56B26C"
            name="Net realised value"
            barSize={30}
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

const groupPortfolioData = (
  combinedShareholdings: Array<Shareholding>,
  exits: Exits,
): PortfolioItem[] => {
  const allItems = [
    ...combinedShareholdings.map(
      ({ company, currentShareValue, currentCapitalAllocated, id }) => ({
        id,
        companyName: company.name,
        shareClass: company.shareClass,
        unrealisedValue: currentShareValue,
        realisedValue: 0,
        initialCapitalAllocated: currentCapitalAllocated || 0,
      }),
    ),
    ...exits.map(
      ({ company, netExitValue, capitalAllocated, shareholdingId }) => ({
        id: shareholdingId,
        companyName: company.name,
        shareClass: company.shareClass,
        unrealisedValue: 0,
        realisedValue: netExitValue,
        initialCapitalAllocated: capitalAllocated,
      }),
    ),
  ];

  const grouped = groupBy(allItems, (item) => item.id);

  return Object.entries(grouped).map(([key, group]) => ({
    key,
    companyName: `${group[0].companyName}`,
    shareClass: group[0].shareClass,
    unrealisedValue: sumBy(group, 'unrealisedValue'),
    realisedValue: sumBy(group, 'realisedValue'),
    initialInvestmentValue: sumBy(group, 'initialCapitalAllocated'),
  }));
};
