import { useState } from 'react';
import { format } from 'date-fns';
import { useDownload } from 'hooks/ui/useDownload';

const buildFilename = ({
  allocationName,
  companyName,
  investmentTypeLabel,
}) => {
  let filename = `${allocationName} - ${companyName} - `;
  if (investmentTypeLabel) {
    filename += `${investmentTypeLabel} - `;
  }
  const todaysDate = format(new Date(), 'dd/MM/yyyy');
  filename += `${todaysDate}.zip`;
  return filename;
};

type UseDownloadByEisWizardIdParams = {
  eisWizardId: string;
  allocationName: string;
  companyName: string;
  investmentTypeLabel?: string;
};
export const useDownloadByEisWizardId = () => {
  const [filename, setFilename] = useState<string>('');
  const download = useDownload({
    filename,
  });

  return {
    download: ({
      eisWizardId,
      allocationName,
      companyName,
      investmentTypeLabel,
    }: UseDownloadByEisWizardIdParams) => {
      setFilename(
        buildFilename({ allocationName, companyName, investmentTypeLabel }),
      );
      download.mutate(`eis-wizard/${eisWizardId}/certificates`);
    },
    isLoading: download.isLoading,
  };
};

type UseDownloadByAdviserIdParams = {
  adviserId: string;
  adviserName?: string;
};
export const useDownloadByAdviserId = () => {
  const [filename, setFilename] = useState<string>('');
  const download = useDownload({
    filename,
  });

  return {
    download: ({ adviserId, adviserName }: UseDownloadByAdviserIdParams) => {
      const todaysDate = format(new Date(), 'dd/MM/yyyy');
      setFilename(`${adviserName} - ${todaysDate}.zip`);

      download.mutate(`eis-wizard/adviser/${adviserId}/certificates`);
    },
    isLoading: download.isLoading,
  };
};

type UseDownloadByInvestmentIdParams = {
  investmentId: string;
  trancheName: string;
  fullName: string;
};
export const useDownloadByInvestmentId = () => {
  const [filename, setFilename] = useState<string>('');
  const download = useDownload({
    filename,
  });

  return {
    download: ({
      investmentId,
      trancheName,
      fullName,
    }: UseDownloadByInvestmentIdParams) => {
      const todaysDate = format(new Date(), 'dd/MM/yyyy');
      setFilename(`${trancheName} - ${fullName} - ${todaysDate}.zip`);

      download.mutate(`eis-wizard/investment/${investmentId}/certificates`);
    },
    isLoading: download.isLoading,
  };
};
