import Table from 'components/Table';
import { numberToCurrencyString, renderMultiple } from 'further-ui/utils';
import useTopAdvisers from 'hooks/data/dashboards/useTopAdvisers';
import { Link } from 'react-router-dom';
import { ADVISER, createAdviserViewRoute } from 'constants/routes';
import topAdviserTableStyles from './styles';
import { numberToDisplayString } from 'utils/numbers';
import { Button } from '@material-ui/core';
import { InfoOutlined, Visibility } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';

const TopAdviserTable = ({ firmId }) => {
  const { data: advisers, isFetching } = useTopAdvisers({ params: { firmId } });

  const classes = topAdviserTableStyles();
  const columns = [
    {
      label: 'Name',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          adviser.fullName
        ),
      sort: false,
    },
    {
      label: 'Subscription amount',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(adviser.totalInvestmentAmount)
        ),
      sort: false,
    },
    {
      label: 'Current value AUM',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(adviser.currentValueAum)
        ),
      sort: false,
    },
    {
      label: 'Total portfolio value',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(adviser.totalPortfolioValue)
        ),
      sort: false,
    },
    {
      label: (
        <div className={classes.tooltipContainer}>
          Blended multiple{' '}
          <Tooltip title="This is the live multiple on investment across all subscriptions linked to this adviser. The multiple considers only paid-up funds and does not include unpaid but committed capital.">
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        </div>
      ),
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          renderMultiple(adviser.blendedMultiple)
        ),
      sort: false,
    },
    {
      label: 'Subscriptions',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToDisplayString(adviser.totalInvestments)
        ),
      sort: false,
    },
    {
      label: 'Clients',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToDisplayString(adviser.investorCount)
        ),
      sort: false,
    },
    {
      label: '',
      render: (adviser) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          <Link to={createAdviserViewRoute(adviser._id)}>
            <Button
              title="View"
              color="primary"
              className={classes.actionButton}
            >
              <Visibility />
            </Button>
          </Link>
        ),
      sort: false,
    },
  ];

  return (
    <>
      <Link className={classes.link} to={ADVISER}>
        View all
      </Link>
      <Table
        columns={columns}
        tablebody={
          advisers?.length
            ? advisers
            : // Adding empty objects to show skeletons
              Array.from({ length: 10 }, () => ({}))
        }
        pagination={false}
        variant="nohover"
      />
    </>
  );
};

export default TopAdviserTable;
