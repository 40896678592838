import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { useState } from 'react';

async function fetchInvestors({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await api.get(`investor/list`, {
      params,
      headers: { 'Cache-Control': 'no-cache' },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export function useInvestors({ params }) {
  return useQuery({
    queryKey: ['investors', { params }],
    queryFn: fetchInvestors,
    keepPreviousData: true,
    enabled: !params.includeExportData,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

const exportInvestors = async ({ queryKey }) => {
  const [, { params }] = queryKey;

  const response = await api.post('investor/export', params, {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    throw Error('Unexpected export response.');
  }
  return response.data;
};

export function useInvestorsExport({ params }) {
  const { refetch } = useQuery({
    queryKey: ['investors', { params }],
    queryFn: exportInvestors,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });
  const [isExportLoading, setIsExportLoading] = useState(false);
  return {
    exportInvestors: async () => {
      setIsExportLoading(true);
      const data = await refetch();
      setIsExportLoading(false);
      return data;
    },
    isExportLoading,
  };
}
