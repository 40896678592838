import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import { Alert } from '@material-ui/lab';
import {
  makeStyles,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Switch from 'components/Switch';
import { Disclosure } from 'further-ui/hooks';

const useStyles = makeStyles(() => ({
  toggleAlert: {
    margin: '1rem 0',
    justifyContent: 'center',
  },
  toggle: {
    display: 'flex',
    gridGap: '.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  disclosure: Disclosure;
  notifyInvestors: boolean;
  setNotifyInvestors: (value: boolean) => void;
  isEdit: boolean;
  onConfirmCreateStatement: () => void;
  submitting: boolean;
};

const CreateConfirmationDialog: FC<Props> = ({
  disclosure,
  setNotifyInvestors,
  notifyInvestors,
  isEdit,
  onConfirmCreateStatement,
  submitting,
}) => {
  const classes = useStyles();

  return (
    <AlertDialog
      open={disclosure.isOpen}
      onClose={disclosure.onClose}
      title="Are you sure?"
      content={
        <>
          {isEdit ? (
            <p>
              Proceeding will publish this updated statement to all investors.
            </p>
          ) : (
            <p>Proceeding will publish this statement to all investors.</p>
          )}
          <Alert severity="info" icon={false} className={classes.toggleAlert}>
            <div className={classes.toggle}>
              <Typography>Notify investors</Typography>
              <Switch
                name={name}
                defaultValue={false}
                checked={!!notifyInvestors}
                onChange={(event) => setNotifyInvestors(!!event.target.checked)}
                data-testid={`switch-resend-emails`}
              />
            </div>
          </Alert>
          <p>
            {notifyInvestors
              ? `Investors will receive an email notifying them of this statement’s publication. Sending this email cannot be undone.`
              : 'Investors will not receive an email notifying them of this statement’s publication.'}
          </p>
        </>
      }
      btnLabels={{
        cancel: 'Go back',
        confirm: 'Confirm',
      }}
      confirmBtnComponent={
        <>
          {submitting && <CircularProgress size={20} />}
          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            type="button"
            name="proceed"
            onClick={onConfirmCreateStatement}
          >
            Proceed
          </Button>
        </>
      }
    />
  );
};

export default CreateConfirmationDialog;
