import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import { Delete, Visibility, CloudDownload, Email } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import AlertDialog from 'components/AlertDialog';
import PageContainer from 'components/PageContainer';
import { useAllocations } from 'hooks/data/allocation/useAllocations';
import {
  createDeploymentUpdateAddRoute,
  createUploadSheetViewRoute,
} from 'constants/routes';

import { exportToCustodian } from '@redux/actions/UploadShareHolding';
import { Link } from 'react-router-dom';
import { usePagination } from 'hooks/ui/usePagination';
import { useDisclosure } from 'further-ui/hooks';
import {
  dateToLabel,
  numberToCurrencyString,
  getCompanyLabel,
} from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';
import { useDebounce } from 'usehooks-ts';
import { useGetRole } from 'hooks/ui/useGetRole';
import FiltersAndActions from './FiltersAndActions';
import { useAllocation } from 'hooks/data/allocation/useAllocation';
import { useNotification } from 'hooks/ui/useNotification';
import useFilters from 'hooks/ui/useFilters';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
  flxCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textColor: {
    color: '#282525',
    marginBottom: '15px',
  },
  content: {
    fontFamily: 'MaisonNeue',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#7B7B7B',
  },
  sectionAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '42px',
    marginBottom: '40px',
  },
  dialog: {
    maxWidth: '624px!important',
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Upload shareholding', isActive: true },
];

const UploadSheetInvestment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteDisclosure = useDisclosure();
  const { firmId: usersFirmId } = useGetRole();

  const pagination = usePagination({
    orderBy: 'date',
    order: 'desc',
    id: 'uploaded-shareholding',
  });
  const { filters, handleChangeFilters } = useFilters('', {
    defaultFilters: {
      companyName: '',
      firmId: usersFirmId,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
    onFiltersChange: pagination.toFirstPage,
  });
  const debouncedSearch = useDebounce(filters.companyName, 500);

  const { delete: allocationDelete } = useAllocation({});
  const { error } = useNotification();
  const allocations = useAllocations({
    params: {
      page: pagination.page === 0 ? 1 : pagination.page,
      perPage: pagination.rowsPerPage,
      type: pagination.order,
      keyName: pagination.orderBy,
      search: debouncedSearch,
      firmId: filters.firmId,
      startDate: filters.dateRange?.startDate,
      endDate: filters.dateRange?.endDate,
    },
  });

  const columns = [
    {
      label: 'Date',
      key: 'date',
      render: (elm) => dateToLabel(elm?.date),
    },
    {
      label: 'Firm',
      key: 'firm',
      render: (elm) => elm.firmId?.firmName,
    },
    {
      label: 'Company name',
      key: 'company',
      render: (elm) => getCompanyLabel(elm.companyId),
    },
    {
      label: 'Number of shares',
      key: 'totalNumberOfShare',
      render: (elm) => numberToDisplayString(elm.totalNumberOfShare),
      sort: false,
    },
    {
      label: 'Total subscription amount',
      key: 'totalInvestmentAmount',
      render: (elm) => numberToCurrencyString(elm.totalInvestmentAmount),
      sort: false,
    },
    {
      label: 'Status',
      key: 'status',
      render: (elm) => <>{elm.status === 0 ? 'Pending' : 'Completed'}</>,
    },
    {
      label: 'Actions',
      sort: false,
      key: '',
      render: (elm) => (
        <div className={classes.dFlex}>
          <Link to={createUploadSheetViewRoute(elm?._id)}>
            <IconButton title="View">
              <Visibility />
            </IconButton>
          </Link>
          {elm.hasKiCertificate ? (
            <Tooltip title="Allocation can not be deleted while an EIS5 relating to this allocation has been created. Please delete the relevant EIS5 and try again.">
              <div>
                <IconButton
                  title="Delete"
                  className={classes.customButton}
                  disabled
                >
                  <Delete />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <IconButton
              title="Delete"
              className={classes.customButton}
              onClick={() => deleteDisclosure.stageAction(elm)}
            >
              <Delete htmlColor="#E8453C" />
            </IconButton>
          )}

          <IconButton title="Download" onClick={() => handleDownload(elm._id)}>
            <CloudDownload color="primary" />
          </IconButton>
          {!elm?.isDeploymentUpdateSent && (
            <Link
              to={createDeploymentUpdateAddRoute(elm?._id, elm?.companyId?._id)}
            >
              <IconButton title="Send deployment update">
                <Email />
              </IconButton>
            </Link>
          )}
        </div>
      ),
    },
  ];

  const handleDownload = (id) => {
    dispatch(exportToCustodian(id, { error }));
  };

  const handleConfirmDelete = async () => {
    try {
      //@ts-ignore
      await allocationDelete.mutateAsync({
        id: deleteDisclosure.actionProps._id,
      });

      if (allocations?.data?.uploadShareHoldings?.length === 1) {
        pagination.pageBack();
      } else {
        allocations.refetch();
      }
    } catch (err) {
      error(err.response.data.responseMsg);
    } finally {
      deleteDisclosure.onClose();
    }
  };

  const isRecordWithFinalDeal = () => {
    const { actionProps } = deleteDisclosure;
    if (!actionProps || !actionProps.includesFinalDeal) return false;
    return true;
  };

  return (
    <PageContainer
      heading="Allocations: Uploaded shareholdings"
      breadcrumbs={breadcrumbs}
    >
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <FiltersAndActions
                classes={classes}
                filters={filters}
                handleChangeFilters={handleChangeFilters}
                pagination={pagination}
              />
              <div className={classes.tableMt}>
                <Table
                  loading={allocations?.isFetching}
                  columns={columns}
                  onRequestSort={pagination.handleRequestSort}
                  order={pagination.order}
                  orderBy={pagination.orderBy}
                  tablebody={allocations?.data?.uploadShareHoldings || []}
                  onPageChange={pagination.handleChangePage}
                  onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  count={allocations?.data?.total || 0}
                  pagination={true}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          onClose={deleteDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          open={deleteDisclosure.isOpen}
          title="Confirm delete"
          content={
            <p>
              {isRecordWithFinalDeal() ? (
                <span>
                  Please note: Upon deleting this allocation, the subscription
                  value will return to the tranche’s deployable capital (along
                  with any investor residual cash, if still available and not
                  since been withdrawn).
                </span>
              ) : (
                <span>
                  Are you sure you wish to delete this shareholding allocation?
                </span>
              )}
            </p>
          }
          confirmBtnProps={{ disabled: allocationDelete.isLoading }}
          cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default UploadSheetInvestment;
