import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, IconButton, Grid, Divider } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: '#E8453C',
    margin: '10px 0',
  },
  divider: {
    margin: '20px 0',
  },
  formRow: {
    marginBottom: 18,
  },
  introCopy: {
    marginBottom: 24,
  },
}));

export default function FeeDisplay({
  errorTextFee,
  expectedAnnualisedFee,
  fee,
  feeDescription,
  handleAddClickFee,
  handleCancel,
  handleFee,
  handleInputChangeFee,
  handleRemoveClickFee,
  id,
  labels,
  setFeeDescription,
  setExpectedAnnualisedFee,
}) {
  const classes = useStyles();

  return (
    <GridContainer>
      <Grid item xs={12} md={11}>
        <Grid item xs={12}>
          <p className={classes.introCopy}>
            The data in this section will be used on this tranche’s fund page.
            This information is for display purposes only.
          </p>
        </Grid>
        <GridContainer item>
          <FieldRow title="Fee section 'more details' text">
            <TextField
              name="feeDescription"
              fullWidth
              placeholder="Use this section to explain any additional fee information not included in the fee details below, such as whether you charge investee companies fees and whether adviser or distributor charges are included in your fees or charged on top."
              onChange={(event) => setFeeDescription(event.target.value)}
              value={feeDescription}
              variant="outlined"
              multiline
              minRows={6}
            />
          </FieldRow>

          <FieldRow
            title="Expected annualised fee"
            centerTitle
            tooltipText="The expected annualised fee is a sum of all initial and ongoing fund fees, excluding performance fees, averaged over a 5 year hold period. Please add each of your fund fees below and then enter your 5 year average result here."
          >
            <TextField
              type="number"
              required
              name="expectedAnnualisedFee"
              placeholder="Enter fee %, e.g. 2.55"
              fullWidth
              onChange={(e) => {
                setExpectedAnnualisedFee(e.target.value);
              }}
              value={expectedAnnualisedFee}
              variant="outlined"
            />
          </FieldRow>
        </GridContainer>
      </Grid>

      {fee.map((x, i) => {
        return (
          <Grid item md={11} key={i}>
            <Divider className={classes.divider} />
            <FieldRow title="Fee name*">
              <TextField
                required
                name="expectedAnnualisedFeeText"
                placeholder="Enter fee name, e.g. Annual management charge"
                fullWidth
                onChange={(e) => handleInputChangeFee(e, i)}
                value={x.expectedAnnualisedFeeText}
                error={errorTextFee[i]?.expectedAnnualisedFeeText}
                helperText={errorTextFee[i]?.expectedAnnualisedFeeText}
                variant="outlined"
                className={classes.formRow}
              />
            </FieldRow>
            <FieldRow title="Fee percentage*">
              <TextField
                type="text"
                required
                name="expectedAnnualisedFee"
                placeholder="Enter fee %, e.g. 2"
                fullWidth
                onChange={(e) => handleInputChangeFee(e, i)}
                value={x.expectedAnnualisedFee}
                error={errorTextFee[i]?.expectedAnnualisedFee}
                helperText={errorTextFee[i]?.expectedAnnualisedFee}
                variant="outlined"
                className={classes.formRow}
              />
            </FieldRow>
            <FieldRow title="Fee information">
              <TextField
                name="expectedAnnualisedFeeTextSubtitle"
                placeholder="e.g. This fee is charged annually and covers the cost of making subscriptions and managing portfolio companies on an ongoing basis."
                fullWidth
                onChange={(e) => handleInputChangeFee(e, i)}
                value={x.expectedAnnualisedFeeTextSubtitle}
                error={errorTextFee[i]?.expectedAnnualisedFeeTextSubtitle}
                helperText={errorTextFee[i]?.expectedAnnualisedFeeTextSubtitle}
                variant="outlined"
                multiline
                minRows={6}
              />
            </FieldRow>
            <Grid item md={7} xs={12} className={classes.buttonContainer}>
              <IconButton
                size="small"
                className={classes.deleteButton}
                onClick={() => handleRemoveClickFee(i)}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}

      <Grid item md={11} xs={12}>
        <Divider className={classes.divider} />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.marginLeftBtn}
            onClick={handleAddClickFee}
            variant="text"
            color="primary"
          >
            Add fee
          </Button>
        </div>
      </Grid>
      <Grid item md={11} xs={12}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            onClick={handleFee}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
