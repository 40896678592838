import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    padding: '0 12px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 50,
  },
  bodyCopy: {
    color: '#656565',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    marginBottom: 15,
  },
  addMoreButton: {
    alignSelf: 'flex-end',
    background: 'none',
    border: 0,
    color: '#56b26c',
    cursor: 'pointer',
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: -40,
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
}));

export default useStyles;
