import axios from 'axios';
import { ERROR_404 } from 'constants/routes';

export const url = process.env.REACT_APP_API_URL;

const axiosUtil = {
  initalise: (store, history) => {
    axios.defaults.baseURL = url;
    axios.interceptors.request.use(
      (axiosConfig) => {
        const token = localStorage.getItem('token');
        if (token) {
          axiosConfig.headers['Authorization'] = `Bearer ${token}`;
        }
        return axiosConfig;
      },
      (error) => {
        Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          history.push(ERROR_404);
        }
        if (error?.response?.data?.isLogout) {
          const currentPath = `${history.location.pathname}${history.location.search}`;
          history.replace(`${currentPath}#l`);
        }
        return Promise.reject(error);
      },
    );
  },
};

export default axiosUtil;
