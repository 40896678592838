import React from 'react';

import { Grid, Button, makeStyles } from '@material-ui/core';

import { MuiDateRangePicker } from 'components/FormElements/DateRangePicker';
import GridContainer from 'components/GridContainer';
import { INTEREST_PAYMENTS_ADD } from 'constants/routes';
import { Link } from 'react-router-dom';

export const optionValueAll = 'ALL';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    marginTop: '15px',
  },
}));

const InterestPaymentFilter = ({ filters, handleChangeFilters }) => {
  const classes = useStyles();
  return (
    <GridContainer>
      <Grid item xs={12}>
        <GridContainer spacing={3}>
          <Grid item md={4} xs={12}>
            <label className={classes.label}>Select upload date range</label>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.uploadDateRange.startDate,
                endDate: filters.uploadDateRange.endDate,
              }}
              onDateRangeChange={(dateRange) => {
                filters.uploadDateRange.startDate = dateRange.startDate;
                filters.uploadDateRange.endDate = dateRange.endDate;
                handleChangeFilters({ ...filters });
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <label className={classes.label}>Select payment date range</label>
            <MuiDateRangePicker
              dateRange={{
                startDate: filters.paymentDateRange.startDate,
                endDate: filters.paymentDateRange.endDate,
              }}
              onDateRangeChange={(dateRange) => {
                filters.paymentDateRange.startDate = dateRange.startDate;
                filters.paymentDateRange.endDate = dateRange.endDate;
                handleChangeFilters({ ...filters });
              }}
              TextFieldProps={{ placeholder: 'Select date' }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.actions}>
              <Link to={INTEREST_PAYMENTS_ADD}>
                <Button color="primary" variant="contained">
                  Upload new interest payments
                </Button>
              </Link>
            </div>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default InterestPaymentFilter;
