import React from 'react';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import useGetInvestorsLeanResponse from 'hooks/data/investor/useGetInvestorsLeanResponse';
import AppAutocomplete from 'components/FormElements/AppAutocomplete';
import { useGetRole } from 'hooks/ui/useGetRole';

type InvestorSearchProps = {
  onInvestorSelect: (investorId?: string) => void;
  selectedInvestorId?: string;
  firmId?: string;
  error?: boolean;
  helperText?: string;
  ref?: React.Ref<any>;
};

const InvestorSearch: React.FC<InvestorSearchProps> = React.forwardRef(
  (
    {
      onInvestorSelect,
      selectedInvestorId,
      firmId,
      error,
      helperText,
      ...rest
    },
    ref,
  ) => {
    const { isSuperAdmin } = useGetRole();

    const { data: investors, isFetching } = useGetInvestorsLeanResponse({
      ...(isSuperAdmin ? {} : { firmId }),
    });

    if (isFetching) {
      return (
        <Grid item xs>
          <CircularProgress size={24} />
        </Grid>
      );
    }

    return (
      <AppAutocomplete
        {...rest}
        ref={ref}
        //@ts-ignore
        options={investors ?? []}
        id="investorSearch"
        getOptionLabel={(option: (typeof investors)[number]) =>
          `${option.fullName} (${option.email})`
        }
        filterSelectedOptions
        onChange={(_: unknown, investor: (typeof investors)[number]) => {
          if (!investor) return onInvestorSelect();
          onInvestorSelect(investor._id);
        }}
        value={investors?.find(({ _id }) => _id === selectedInvestorId)}
        renderInput={(params: object) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            variant="outlined"
            placeholder="Search by name or email"
          />
        )}
      />
    );
  },
);

export default InvestorSearch;
