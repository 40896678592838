import Table from 'components/Table';
import { numberToCurrencyString } from 'further-ui/utils';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import useStyles from './styles';
import { CashBalance } from 'hooks/data/investmentTransfer/useInvestmentTransfer';
import { Controller, useFormContext } from 'react-hook-form';
import AppTextInput from 'components/FormElements/AppTextInput';
import NumberFormat from 'react-number-format';

type CashBalancesListProps = {
  cashBalances: CashBalance[];
};

const CashBalancesList: React.FC<CashBalancesListProps> = ({
  cashBalances,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const columns = [
    ...(cashBalances.length > 1
      ? [
          {
            label: 'Firm',
            render: (cashBalance: CashBalance) => cashBalance.firm.firmName,
          },
        ]
      : []),
    {
      label: 'Current Balance',
      render: (cashBalance: CashBalance) =>
        numberToCurrencyString(cashBalance.cashBalance),
    },
    {
      label: 'Amount to transfer',
      render: (cashBalance: CashBalance) => (
        <Controller
          name={`balancesToTransfer[${cashBalance.firm._id}]`}
          defaultValue={0}
          control={control}
          render={({
            field: { value, onChange, ...rest },
            fieldState: { error },
          }) => (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={error?.message ?? ''}
            >
              {
                <NumberFormat
                  value={`${value}`}
                  onValueChange={({ value }) => {
                    onChange(value);
                  }}
                  prefix="£"
                  allowNegative={false}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  customInput={AppTextInput}
                  error={!!error}
                  {...rest}
                />
              }
            </Tooltip>
          )}
        />
      ),
    },
  ];

  return (
    <Grid item sm={12} md={6}>
      <Typography variant="h6" className={classes.tableHeader} component="div">
        Cash balance
      </Typography>
      <Table columns={columns} tablebody={cashBalances} />
    </Grid>
  );
};

export default CashBalancesList;
