import { sortBy } from 'lodash';

export function useSortTranches(tranches) {
  const mappedTranche = (tranches ?? []).map((x) => ({
    _id: x._id,
    label: `${x?.firmId?.firmName} ${x?.fundName}`,
  }));
  const sortedTranche = sortBy(mappedTranche ?? [], (x) =>
    x.label.toLowerCase(),
  );
  return sortedTranche;
}
