const investorTypes = {
  1: 'Everyday Investor',
  2: 'Sophisticated Investor',
  3: 'High Net Worth Investor',
  4: 'Professional Client',
  5: 'Advised',
};

export const investorTypeToLabel = (type) => investorTypes[type] ?? '';

export const investorTypeDropdownList = ['1', '2', '3', '4', '5'].map((id) => ({
  id,
  investorType: investorTypeToLabel(id),
}));
