import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PageContainer from 'components/PageContainer';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { Button, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';

import { updateAdviserFeeAmendments } from '@redux/actions/Adviser';
import { ADVISER } from 'constants/routes';
import { useGetRole } from 'hooks/ui/useGetRole';
import FeeAmendment from './FeeAmendment';
import AddressInput from './AddressInput';
import BasicDetailsInput from './BasicDetailsInput';
import BankDetailsInput from './BankDetailsInput';
import { useAdviser } from 'hooks/data/adviser/useAdviser';
import { useOrganisations } from 'hooks/data/organisation/useOrganisations';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { canEditAdviser } from '../../../../utils/adviserPermissions';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  textCapital: {
    textTransform: 'uppercase',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
}));

const AdviserForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const handleApiRequest = useApiRequestHandler();
  const { isSuperAdmin, firmId } = useGetRole();
  const adviser = useAdviser({ params: { id } });
  const parentOrganisations = useOrganisations({
    params: { isChild: true, firmId },
  });

  const emptyChangeToInitialInvestmentFees = {
    firmId: isSuperAdmin ? '' : firmId,
    amendByPercentage: 0,
  };
  const emptyCommissionOnInitialInvestmentFees = {
    firmId: isSuperAdmin ? '' : firmId,
    amendByPercentage: 0,
    vatPercentage: 0,
  };
  const emptyAddress = {
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    country: 'United Kingdom',
  };

  const [adviserData, setAdviserData] = useState({
    organisationData: {
      name: null,
      organisationId: null,
    },
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    fcaNumber: '',
    address: [emptyAddress],
    firmId: isSuperAdmin ? null : firmId,
    initialFeeHasChanges: false,
    changeToInitialInvestmentFees: [emptyChangeToInitialInvestmentFees],
    commissionFeeHasChanges: false,
    commissionOnInitialInvestmentFees: [emptyCommissionOnInitialInvestmentFees],
    associatedFirms: [],
    bankAccountName: '',
    bankAccountNumber: '',
    bankName: '',
    bankSortCode: '',
  });
  const [canEdit, setCanEdit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [errorText, setErrorText] = useState({
    errorValue: {},
    addressError: [],
    changeToInitialInvestmentFeesErrors: [],
    commissionOnInvestmentFeesErrors: [],
  });

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'Adviser', link: ADVISER },
    { label: id ? 'Update Adviser' : 'Add Adviser', link: '/', isActive: true },
  ];

  const validate = () => {
    const errors = {};
    let addressError = [];
    const changeToInitialInvestmentFeesErrors = [];
    const commissionOnInvestmentFeesErrors = [];

    if (!id && !adviserData?.firmId) {
      errors.firmId = 'Please select firm.';
    }
    if (!adviserData?.firstName) {
      errors.firstName = 'Please enter first name.';
    }
    if (!adviserData?.lastName) {
      errors.lastName = 'Please enter last name.';
    }
    if (!adviserData?.fcaNumber) {
      errors.fcaNumber = 'Please enter personal FCA number.';
    } else if (!new RegExp(/^[a-zA-Z0-9]+$/i).test(adviserData?.fcaNumber)) {
      errors.fcaNumber = 'An FCA number can only contain letters or numbers.';
    }
    if (!adviserData?.email) {
      errors.email = 'Please enter email address.';
    }
    if (!adviserData?.phoneNumber) {
      errors.phoneNumber = 'Please enter phone number.';
    }

    if (adviserData?.initialFeeHasChanges) {
      adviserData?.changeToInitialInvestmentFees.forEach((item, index) => {
        if (!item.amendByPercentage || item.amendByPercentage === '0') {
          changeToInitialInvestmentFeesErrors[index] = {
            amendByPercentage: 'Please enter net fee change.',
          };
        }

        if (!item.firmId) {
          changeToInitialInvestmentFeesErrors[index] = {
            ...changeToInitialInvestmentFeesErrors[index],
            firmId: 'Please select a firm.',
          };
        }
      });
    }
    if (adviserData?.commissionFeeHasChanges) {
      adviserData?.commissionOnInitialInvestmentFees.forEach((item, index) => {
        if (!item.amendByPercentage || item.amendByPercentage === '0') {
          commissionOnInvestmentFeesErrors[index] = {
            amendByPercentage: 'Please enter commission.',
          };
        }

        if (!item.firmId) {
          commissionOnInvestmentFeesErrors[index] = {
            ...commissionOnInvestmentFeesErrors[index],
            firmId: 'Please select a firm.',
          };
        }

        if (!item.vatPercentage && item.vatPercentage !== 0) {
          commissionOnInvestmentFeesErrors[index] = {
            ...commissionOnInvestmentFeesErrors[index],
            vatPercentage: 'Please enter the VAT rate for the commission.',
          };
        }
      });
    }

    if (adviserData?.address?.length) {
      const errorsAddress = {};
      adviserData?.address?.forEach((single) => {
        if (!single.address1) {
          errorsAddress.address1 = 'Please enter address line 1.';
        }
        if (!single?.country) {
          errorsAddress.country = 'Please enter country.';
        }
        if (Object.keys(errorsAddress).length) {
          addressError.push(errorsAddress);
        }
      });
    }

    setErrorText({
      errorValue: errors,
      addressError: addressError,
      changeToInitialInvestmentFeesErrors,
      commissionOnInvestmentFeesErrors,
    });

    return {
      errors,
      addressError,
      isError: !!(
        Object.keys(errors).length ||
        addressError.length ||
        changeToInitialInvestmentFeesErrors.length ||
        commissionOnInvestmentFeesErrors.length
      ),
    };
  };

  const onSubmit = async () => {
    let validateData = validate();

    if (!validateData.isError) {
      const {
        organisationData,
        initialFeeHasChanges,
        changeToInitialInvestmentFees,
        commissionFeeHasChanges,
        commissionOnInitialInvestmentFees,
        ...rest
      } = adviserData;

      if (!isSuperAdmin && id) {
        try {
          setIsUpdating(true);
          await handleApiRequest(async (showSuccess) => {
            const response = await dispatch(
              updateAdviserFeeAmendments({
                id,
                changeToInitialInvestmentFees: initialFeeHasChanges
                  ? changeToInitialInvestmentFees
                  : [],
                commissionOnInitialInvestmentFees: commissionFeeHasChanges
                  ? commissionOnInitialInvestmentFees
                  : [],
              }),
            );
            setIsUpdating(false);
            showSuccess(response.data.responseMsg);
          });
        } catch (e) {
          setIsUpdating(false);
        }
      }

      const value = { ...rest };

      if (organisationData?.organisationId) {
        value.organisationId = organisationData?.organisationId;
      } else {
        value.organisationId = null;
      }
      value.changeToInitialInvestmentFees = initialFeeHasChanges
        ? changeToInitialInvestmentFees
        : [];
      value.commissionOnInitialInvestmentFees = commissionFeeHasChanges
        ? commissionOnInitialInvestmentFees
        : [];

      if (!id) {
        adviser.create.mutate(value);
      } else if (canEdit) {
        value.id = id;
        value.associatedFirms =
          adviserData?.associatedFirms?.map((firm) => firm._id) ?? [];
        adviser.update.mutate(value);
      }
    }
  };

  useEffect(() => {
    if (adviser.create.isSuccess) {
      history.push(ADVISER);
    }
  }, [adviser.create.isSuccess]);

  useEffect(() => {
    if (adviser.fetch.data?.adviser && parentOrganisations.isFetched) {
      const {
        adviser: {
          firstName,
          lastName,
          middleName,
          email,
          changeToInitialInvestmentFees = [],
          commissionOnInitialInvestmentFees = [],
          organisationId,
          fcaNumber,
          phoneNumber,
          address,
          associatedFirms,
          hasMoreThanOneFirm,
          bankAccountName,
          bankAccountNumber,
          bankName,
          bankSortCode,
          allInvestmentsWithSingleFirm,
        },
      } = adviser.fetch.data;

      let findExistingOrganisation =
        parentOrganisations.data?.organisation?.filter(
          (org) => organisationId === org?._id,
        );

      const safeAddress = address?.length ? address : [emptyAddress];
      const initialFeeHasChanges = changeToInitialInvestmentFees.length > 0;
      const commissionFeeHasChanges =
        commissionOnInitialInvestmentFees?.length > 0;

      const data = {
        firstName,
        lastName,
        middleName,
        fcaNumber,
        email,
        address: safeAddress,
        initialFeeHasChanges,
        commissionFeeHasChanges,
        changeToInitialInvestmentFees: initialFeeHasChanges
          ? changeToInitialInvestmentFees
          : [emptyChangeToInitialInvestmentFees],
        commissionOnInitialInvestmentFees: commissionFeeHasChanges
          ? commissionOnInitialInvestmentFees
          : [emptyCommissionOnInitialInvestmentFees],
        organisationData: {
          name: findExistingOrganisation?.[0]?.name,
          organisationId: findExistingOrganisation?.[0]?._id,
        },
        phoneNumber,
        associatedFirms,
        hasMoreThanOneFirm,
        bankAccountName,
        bankAccountNumber,
        bankName,
        bankSortCode,
        allInvestmentsWithSingleFirm,
      };

      setAdviserData(data);
      setCanEdit(canEditAdviser(isSuperAdmin, data, firmId));
    }
  }, [adviser.fetch.data, parentOrganisations.isFetched]);

  return (
    <>
      {id && adviser.fetch.isLoading ? (
        <CircularProgress />
      ) : (
        <PageContainer
          heading={id ? 'Advisers: Update adviser' : 'Advisers: Create adviser'}
          breadcrumbs={breadcrumbs}
        >
          <CmtCard>
            <CmtCardContent>
              <GridContainer item md={11}>
                <BasicDetailsInput
                  isBeingEdited={id}
                  setAdviserData={setAdviserData}
                  adviserData={adviserData}
                  parentOrganisation={
                    parentOrganisations.data?.organisation ?? []
                  }
                  errorText={errorText}
                  canEdit={canEdit}
                />

                {adviserData?.address?.map((singleAddress, i) => (
                  <AddressInput
                    key={i}
                    address={singleAddress}
                    index={i}
                    isBeingEdited={id}
                    setAdviserData={setAdviserData}
                    adviserData={adviserData}
                    errorText={errorText}
                    canEdit={canEdit}
                  />
                ))}

                <BankDetailsInput
                  editDisabled={!(isSuperAdmin || canEdit) && id}
                  adviserData={adviserData}
                  setAdviserData={setAdviserData}
                  errorText={errorText}
                />

                <FeeAmendment
                  adviserData={adviserData}
                  setAdviserData={setAdviserData}
                  errors={errorText}
                />

                <Grid item md={12} xs={12}>
                  <div className={classes.buttonContainer}>
                    <Link to={ADVISER}>
                      <Button variant="outlined">Cancel</Button>
                    </Link>
                    <Button
                      className={classes.marginLeftBtn}
                      variant="contained"
                      color="primary"
                      disabled={
                        adviser.create.isLoading ||
                        adviser.update.isLoading ||
                        isUpdating
                      }
                      onClick={onSubmit}
                    >
                      {id ? 'Update' : 'Invite adviser'}
                    </Button>
                  </div>
                </Grid>
              </GridContainer>
            </CmtCardContent>
          </CmtCard>
        </PageContainer>
      )}
    </>
  );
};
export default AdviserForm;
