import React, { Fragment } from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import CkEditor from 'components/CkEditor';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: '#E8453C',
  },
  formRow: {
    marginBottom: 18,
  },
}));

export default function Faq({
  ckEditorHandleChange,
  errorFaq,
  faq,
  handleAddClickFaq,
  handleCancel,
  handleClickFaq,
  handleInputChangeFaq,
  handleRemoveClickFaq,
  id,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer>
      <Grid item xs={12} md={11}>
        {faq.map((x, i) => {
          return (
            <Fragment key={i}>
              <FieldRow title="Question">
                <TextField
                  required
                  name="question"
                  placeholder="Enter FAQ"
                  fullWidth
                  onChange={(e) => handleInputChangeFaq(e, i)}
                  value={x.question}
                  variant="outlined"
                  error={errorFaq[i]?.question}
                  helperText={errorFaq[i]?.question}
                  className={classes.formRow}
                />
              </FieldRow>
              <FieldRow title="Answer">
                <CkEditor
                  name="answer"
                  placeholder="Enter answer"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    ckEditorHandleChange(data, i);
                  }}
                  value={x.answer}
                />
                <p className={classes.errorCk}>
                  {errorFaq[i]?.answer ? errorFaq[i]?.answer : ''}
                </p>
                {faq.length && (
                  <div className={classes.buttonContainer}>
                    <IconButton
                      size="small"
                      className={classes.deleteButton}
                      onClick={() => handleRemoveClickFaq(i)}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </FieldRow>
            </Fragment>
          );
        })}
      </Grid>

      <Grid item xs={12} md={11}>
        <div className={classes.buttonContainer}>
          <Button onClick={handleAddClickFaq} variant="text" color="primary">
            Add FAQ
          </Button>
        </div>
      </Grid>

      <Grid item xs={12} md={11}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={handleClickFaq}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
