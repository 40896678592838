import React from 'react';
import useStyles from './styles';
import moment from 'moment';
import { Button } from '@material-ui/core';

type Props = {
  certificate?: {
    adviserId: string;
    date: string;
    url: string;
  };
  advisersList: Array<{ _id: string; fullName: string }>;
};

const CertificateItem: React.FC<Props> = ({ certificate, advisersList }) => {
  const classes = useStyles();
  const adviser = advisersList.find((a) => a._id === certificate.adviserId);

  return (
    <div className={classes.mBottom}>
      <span className={classes.value}>
        {moment(certificate.date).format('DD/MM/YYYY HH:mm:ss')} by{' '}
        {adviser?.fullName}
      </span>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        className={`${classes.reviewBtn} ${classes.reviewBtnlft}`}
        onClick={() => window.open(certificate?.url)}
      >
        Download document
      </Button>
    </div>
  );
};

export default CertificateItem;
