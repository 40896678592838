import LayoutContextProvider from 'contexts/LayoutContext';
import MainContainer from './MainContainer';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  appMainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '30px 15px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}));

const VerticalDefault: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <LayoutContextProvider>
      <MainContainer>
        <Box
          className={clsx(classes.appMainContent, 'Cmt-appMainContent')}
          bgcolor="background.main"
        >
          {children}
        </Box>
      </MainContainer>
    </LayoutContextProvider>
  );
};

export default VerticalDefault;
