import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/investment';
import { ApiResponse } from 'further-types/api';
import { useNotification } from 'hooks/ui/useNotification';
import { AxiosError } from 'axios';

export function usePayments(
  investmentId: string,
  paymentExpectationId?: string,
) {
  return useQuery<Api.GetInvestmentPaymentsResponse>({
    queryKey: ['payments', investmentId, paymentExpectationId],
    queryFn: async () => {
      const url = paymentExpectationId
        ? `investment/${investmentId}/payments?paymentExpectationId=${paymentExpectationId}`
        : `investment/${investmentId}/payments`;

      const { data } = await api.get<
        ApiResponse<Api.GetInvestmentPaymentsResponse>
      >(url);
      return data?.data;
    },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!investmentId,
  });
}

export function useMutatePayment(investmentId: string) {
  const queryClient = useQueryClient();
  const notification = useNotification();

  const onMutationSuccess = async () => {
    queryClient.invalidateQueries({ queryKey: ['payments', investmentId] });
    queryClient.invalidateQueries({ queryKey: ['investment', investmentId] });
    queryClient.invalidateQueries({ queryKey: ['investments'] });
  };

  const create = useMutation<
    ApiResponse<Api.MutateInvestmentPaymentResponse>,
    AxiosError<ApiResponse<unknown>>,
    any
  >({
    mutationFn: async (data: Api.CreateInvestmentPaymentRequest) => {
      const { data: response } = await api.post(
        `investment/${investmentId}/payments`,
        data,
      );
      return response;
    },
    onSuccess: onMutationSuccess,
  });

  const remove = useMutation<
    ApiResponse<Api.MutateInvestmentPaymentResponse>,
    AxiosError<ApiResponse<unknown>>,
    any
  >({
    mutationFn: async (paymentTransactionId: string) => {
      const { data: response } = await api.delete(
        `investment/${investmentId}/payments/${paymentTransactionId}`,
      );
      return response;
    },
    onSuccess: onMutationSuccess,
  });

  useEffect(
    function handleErrors() {
      const mutatationError =
        create.error?.response?.data?.responseMsg ||
        remove.error?.response?.data?.responseMsg;

      if (mutatationError?.length) {
        notification.error(mutatationError);
      }
    },
    [create.error, remove.error],
  );

  useEffect(
    function handleSuccess() {
      const mutationSuccessMessage =
        create.data?.responseMsg || remove.data?.responseMsg;

      if (mutationSuccessMessage?.length) {
        notification.success(mutationSuccessMessage);
      }
    },
    [create.data?.responseMsg, remove.data?.responseMsg],
  );

  return { create, remove };
}
