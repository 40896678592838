import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import {
  BusinessCenter,
  Description,
  MonetizationOn,
  ViewQuilt,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InvestmentsByDate from 'components/DashboardWidgets/InvestmentsByDate';
import GridContainer from 'components/GridContainer';
import HoverInfoCard from 'components/HoverInfoCard';
import { numberToCurrencyString } from 'further-ui/utils';
import PageLoader from 'components/PageLoader';
import useOpenTrancheDashboard from 'hooks/data/dashboards/useOpenTrancheDashboard';
import {
  DASHBOARD_MY_FIRM,
  createSingleFirmRoute,
  createTrancheEditRoute,
} from 'constants/routes';
import { InvestmentsByAdviserTable } from 'components/DashboardWidgets/InvestmentsByAdviserTable';
import { InvestmentsByLocation } from 'components/DashboardWidgets/InvestmentsByLocation';
import { InvestmentsByOrganisationTable } from 'components/DashboardWidgets/InvestmentsByOrganisationTable';
import { TrancheSnapshot } from 'components/DashboardWidgets/TrancheSnapshot';
import { RecentInvestmentsTable } from 'components/DashboardWidgets/RecentInvestmentsTable';
import { InvestmentsByTrancheTable } from 'components/DashboardWidgets/InvestmentsByTrancheTable';
import { useGetRole } from 'hooks/ui/useGetRole';

const useStyles = makeStyles((theme) => ({
  scrollableTable: {
    maxHeight: '350px',
    marginBottom: '24px',
    paddingBottom: '0 !important',
    overflow: 'auto',
  },
  green: {
    color: theme.palette.text.rootColor,
  },
  divider: {
    margin: '0 24px',
  },
  gridGap1: {
    gridGap: '1rem',
  },
  viewAll: {
    color: theme.palette.text.rootColor,
    textTransform: 'inherit',
    fontWeight: 700,
    letterSpacing: '.5px',
    backgroundColor: 'transparent !important',
    padding: '0',
  },
  dcont: {
    display: 'flex',
    flexDirection: 'column',
  },
  masth: {
    fontSize: '22px',
  },
  equalFlexSizing: {
    '& > *': {
      flex: 1,
    },
  },
  '@media screen and (max-width: 767px)': {
    masth: {
      fontSize: '18px',
    },
  },
}));

const OpenTrancheDashboard = () => {
  const { firmId } = useGetRole();
  const classes = useStyles();
  const { trancheId } = useParams();

  const [recentInvestments, setRecentInvestments] = useState([]);
  const [incompleteInvestments, setIncompleteInvestments] = useState(0);
  const [trancheSnapshot, setTrancheSnapshot] = useState({});
  const [trancheDetails, setTrancheDetails] = useState({});
  const [investmentsByAdviser, setInvestmentsByAdviser] = useState([]);
  const [investmentsByOrganisation, setInvestmentsByOrganisation] = useState(
    [],
  );
  const [investmentsByLocation, setInvestmentsByLocation] = useState({});
  const [initialFeesChargedToDate, setInitialFeesChargedToDate] = useState(0); // eslint-disable-line
  const [investmentsByDate, setInvestmentsByDate] = useState([]);

  const openTrancheDashboard = useOpenTrancheDashboard({
    params: { trancheId },
  });

  useEffect(() => {
    const response = openTrancheDashboard.data;
    if (response) {
      setRecentInvestments(response?.recentInvestments);
      setInvestmentsByDate(response?.investmentsByDate);
      setTrancheSnapshot(response?.trancheSnapshot);
      setTrancheDetails(response?.trancheSnapshot.trancheDetails);
      setInvestmentsByAdviser(response?.investmentsByAdviser);
      setInvestmentsByOrganisation(response?.investmentsByOrganisation);
      setIncompleteInvestments(response?.incompleteInvestments);
      setInvestmentsByLocation(response?.investmentsByLocation);
      setInitialFeesChargedToDate(
        numberToCurrencyString(response?.initialFeesChargedToDate.feeExVAT),
      );
    }
  }, [openTrancheDashboard.data]);

  if (openTrancheDashboard.isFetching) return <PageLoader />;

  return (
    <GridContainer spacing={5}>
      <Grid item xs={12}>
        <div>
          <h3 className={classes.masth}>
            {trancheDetails?.firmId?.firmName}'s {trancheDetails?.fundName}{' '}
            dashboard
          </h3>
        </div>
        <div style={{ marginTop: '24px' }}>
          <Grid item xs={12}>
            <InvestmentsByDate data={investmentsByDate} />
          </Grid>
        </div>
      </Grid>

      <Grid item md={6} xs={12}>
        <TrancheSnapshot
          trancheSnapshot={trancheSnapshot}
          trancheDetails={trancheDetails}
          trancheId={trancheId}
          classes={classes}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <RecentInvestmentsTable
          rows={recentInvestments}
          trancheId={trancheId}
          classes={classes}
        />
      </Grid>

      <Grid item xs={12}>
        <InvestmentsByTrancheTable trancheId={trancheId} classes={classes} />
      </Grid>

      <Grid item md={8} xs={12}>
        <InvestmentsByAdviserTable
          rows={investmentsByAdviser}
          classes={classes}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <InvestmentsByLocation
          investmentData={investmentsByLocation}
          classes={classes}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={clsx(
          classes.dcont,
          classes.gridGap1,
          classes.equalFlexSizing,
        )}
      >
        <HoverInfoCard
          icon={<BusinessCenter style={{ color: '#ffffff' }} />}
          backgroundColor="#56B26C"
          title={incompleteInvestments}
          subTitle="Incomplete subscriptions"
          linkOnArrow={`/incomplete-transaction?fundId=${trancheId}`}
        />
        <HoverInfoCard
          icon={<MonetizationOn style={{ color: '#ffffff' }} />}
          backgroundColor="#ECC9A2"
          title={initialFeesChargedToDate}
          subTitle="Initial fees due so far"
        />
      </Grid>

      <Grid item md={8} xs={12}>
        <InvestmentsByOrganisationTable
          rows={investmentsByOrganisation}
          classes={classes}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <HoverInfoCard
          icon={<Description style={{ color: '#ffffff' }} />}
          backgroundColor="#ECC9A2"
          subTitle="Edit tranche"
          linkOnArrow={createTrancheEditRoute(trancheId)}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <HoverInfoCard
          icon={<ViewQuilt style={{ color: '#ffffff' }} />}
          backgroundColor="#56B26C"
          subTitle="View firm dashboard"
          linkOnArrow={
            firmId
              ? DASHBOARD_MY_FIRM
              : createSingleFirmRoute(trancheDetails?.firmId?._id)
          }
        />
      </Grid>
    </GridContainer>
  );
};

export default OpenTrancheDashboard;
