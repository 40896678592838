import { Box, Button, TextField, Typography } from '@material-ui/core';
import {
  AppRadioButton,
  AppSelectBox,
  FieldRow,
} from 'components/FormElements';

import { FC } from 'react';
import { nationality } from 'further-ui/labels';
import { dateToLabel, getTaxYear } from 'further-ui/utils';
import GridContainer from 'components/GridContainer';
import useStyles from './styles';
import { Control, Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { SignatureType } from 'further-types/ki-fund-certificate';

import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import Switch from 'components/Switch';

const kiFundCertificateBatchSchema = z.object({
  fundReferenceNumber: z.string().min(1, 'Please enter field text.'),
  fundManagerAddress: z.object({
    address1: z.string().min(1, 'Please enter field text.'),
    address2: z.string(),
    city: z.string().min(1, 'Please enter field text.'),
    postcode: z.string().min(1, 'Please enter field text.'),
    country: z.string().min(1, 'Please select country.'),
  }),
  fundManagerSignatoryName: z.string().min(1, 'Please enter field text.'),
  capicityInWhichSigned: z.string().min(1, 'Please enter field text.'),
  signatureDate: z.date({
    required_error: 'Please select date.',
    invalid_type_error: 'Please select date.',
  }),
  signatureType: z.nativeEnum(SignatureType),
  notifyInvestors: z.boolean(),
});

export type FormValues = Required<
  Omit<z.infer<typeof kiFundCertificateBatchSchema>, 'fundManagerAddress'> & {
    fundManagerAddress: Omit<
      Required<
        z.infer<typeof kiFundCertificateBatchSchema>['fundManagerAddress']
      >,
      'address2'
    > & { address2?: string };
  }
>;

type Props = {
  onNext: (values: FormValues) => void;
  onBack: () => void;
  isEditMode?: boolean;
  defaultValues?: FormValues;
  selectedFund: {
    fundId: string;
    fundCloseDate: Date;
  };
};

const FormTextField: FC<{
  name: string;
  placeholder: string;
  control: Control<FormValues>;
}> = ({ name, control, placeholder }) => {
  return (
    <Controller
      //@ts-expect-error
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <TextField
          {...rest}
          inputRef={ref}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

const DetailsForm: FC<Props> = ({
  onNext,
  onBack,
  defaultValues,
  selectedFund,
}) => {
  const classes = useStyles();

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    shouldFocusError: true,
    resolver: zodResolver(kiFundCertificateBatchSchema),
    criteriaMode: 'all',
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onNext)}>
      <Box display="flex" flexDirection="column" gridGap="24px">
        <GridContainer>
          <FieldRow title="Approved EIS KI fund reference number*" centerTitle>
            <FormTextField
              name="fundReferenceNumber"
              placeholder="Enter reference number"
              control={control}
            />
          </FieldRow>
          <FieldRow
            title="Fund 'closed for subscription' date"
            centerTitle
            tooltipText={
              <span>
                This data is pre-populated with the tranche’s scheduled close
                date. If you wish to change this date, please navigate to{' '}
                <i>Tranches</i> in the side menu, select this tranche and change
                the <i>Scheduled close date</i> (in the <i>Basic information</i>{' '}
                section).
              </span>
            }
          >
            <Typography variant="body2" color="textSecondary">
              <strong>{dateToLabel(selectedFund.fundCloseDate)}</strong>, which
              is in the tax year ending{' '}
              <strong>
                5 April {getTaxYear(selectedFund.fundCloseDate).end}
              </strong>
            </Typography>
          </FieldRow>
          <FieldRow title="Fund manager address line 1*" centerTitle>
            <FormTextField
              name="fundManagerAddress.address1"
              placeholder="Enter address"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Fund manager address line 2" centerTitle>
            <FormTextField
              name="fundManagerAddress.address2"
              placeholder="Enter address"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Fund manager address city*" centerTitle>
            <FormTextField
              name="fundManagerAddress.city"
              placeholder="Enter city"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Fund manager address country*" centerTitle>
            <Controller
              name={'fundManagerAddress.country'}
              control={control}
              render={({
                field: { value, ...rest },
                fieldState: { error },
              }) => (
                <AppSelectBox
                  {...rest}
                  data={nationality}
                  valueKey="Country"
                  labelKey="Country"
                  value={value}
                  renderValue={(value) => <>{value || 'Select country'}</>}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </FieldRow>
          <FieldRow title="Fund manager address postal code*" centerTitle>
            <FormTextField
              name="fundManagerAddress.postcode"
              placeholder="Enter postal code"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Name of fund manager signatory*" centerTitle>
            <FormTextField
              name="fundManagerSignatoryName"
              placeholder="Enter name of fund manager signatory"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Capacity in which signed*" centerTitle>
            <FormTextField
              name="capicityInWhichSigned"
              placeholder="Enter capacity in which signed"
              control={control}
            />
          </FieldRow>
          <FieldRow title="Signature" centerTitle>
            <Controller
              name={'signatureType'}
              defaultValue={SignatureType.PlainText}
              control={control}
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <AppRadioButton
                  {...rest}
                  error={!!error}
                  helperText={error?.message}
                  checked={true}
                  label="Use plain text company representative name"
                />
              )}
            />
          </FieldRow>
          <FieldRow title="Signature date*" centerTitle>
            <Controller
              name={'signatureDate'}
              control={control}
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <KeyboardDatePicker
                  {...rest}
                  placeholder="Select signature date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(date) =>
                    setValue('signatureDate', date ? date.toDate() : null)
                  }
                  error={!!error}
                  helperText={error?.message}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              )}
            />
          </FieldRow>
        </GridContainer>
        <div className={classes.buttonRow}>
          <div className={classes.toggle}>
            <Tooltip title="If toggled, the system will send an email to each applicable investor notifying them that a new EIS certificate is available to view and download on their investor portal.">
              <span className={classes.notifyInvestorWarning}>
                Notify investors
                <InfoOutlined style={{ fontSize: 18 }} />
              </span>
            </Tooltip>
            <Controller
              name={'notifyInvestors'}
              control={control}
              render={({ field: { value, ...rest } }) => (
                <Switch
                  {...rest}
                  checked={!!value}
                  onChange={(event: any) =>
                    setValue('notifyInvestors', event.target.checked)
                  }
                />
              )}
            />
          </div>
          <Button color="primary" variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Next
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default DetailsForm;
