export const pluralize = (singular, count, customPlural) => {
  if (count === 1) return singular;
  if (customPlural) return customPlural;
  return `${singular}s`;
};

export const pluralizedCount = (singular, count, customPlural) => {
  const countStr = count === undefined ? '' : `${count} `;
  return `${countStr}${pluralize(singular, count, customPlural)}`;
};
