import React from 'react';
import FullPageSuccess from 'components/FullPageSuccess';
import { FEE_DISCOUNT_ADD } from 'constants/routes';
import { RouteComponentProps } from 'react-router';

const Success: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const queryParams = new URLSearchParams(history?.location?.search);

  return (
    <FullPageSuccess
      title={`Your fees have been successfully added`}
      additionalText={`Fees have been successfully added across ${queryParams.get(
        'investmentCount',
      )} investors.`}
      proceedText="Add another fee or discount"
      proceedLink={FEE_DISCOUNT_ADD}
      children={undefined}
    />
  );
};

export default Success;
