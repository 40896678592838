import { usePagination } from 'hooks/ui/usePagination';
import { useAdviserSubscriptions } from 'hooks/data/adviser/useAdviserSubscriptions';
import { Api } from 'further-types/adviser';

import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';

import useFilters from 'hooks/ui/useFilters';
import SearchField from 'components/SearchField';
import {
  useDownloadByAdviserId,
  useDownloadByInvestmentId,
} from 'hooks/data/eisWizard/useDownloadEisCertificatePdfs';
import { useGetRole } from 'hooks/ui/useGetRole';
import AdviserInvestmentsTable from './AdviserInvestmentsTable';
import CardHeader from 'components/CardHeader';
import { AdviserSnapshot } from './AdviserSnapshot';
import GridContainer from 'components/GridContainer';

type Props = {
  adviserId: string;
  adviserName?: string;
};

type Filters = {
  search: string;
};

type DownloadCertificatesButtonProps = {
  adviserId: string;
  adviserName?: string;
  disabled?: boolean;
};

const DownloadCertificatesButton = ({
  adviserId,
  adviserName,
  disabled,
}: DownloadCertificatesButtonProps) => {
  const classes = useStyles();
  const downloadByAdviserId = useDownloadByAdviserId();

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={classes.allEisButton}
      onClick={() => downloadByAdviserId.download({ adviserId, adviserName })}
      disabled={disabled || downloadByAdviserId.isLoading}
    >
      {downloadByAdviserId.isLoading ? (
        <CircularProgress
          style={{ color: 'rgba(0, 0, 0, 0.26)', marginRight: 10 }}
          size={18}
        />
      ) : null}
      Download all EIS certificates
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  divider: {
    margin: '1.5rem 0',
  },
  title: {
    margin: 0,
  },
  allEisButton: {
    marginLeft: 'auto',
    marginRight: '0.5rem',
  },
  searchBox: {
    width: '20rem',
  },
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  dFlex: {
    display: 'flex',
  },
  controlsContainer: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

const AdviserInvestments = ({ adviserId, adviserName }: Props) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const downloadByInvestmentId = useDownloadByInvestmentId();

  const handleInvestmentCertificatesDownload = (
    investment: Api.AdviserSubscription,
  ) => {
    downloadByInvestmentId.download({
      investmentId: investment._id,
      fullName: investment.investorId.fullName,
      trancheName: `${investment.firmId.firmName}-${investment.fundId.fundName}`,
    });
  };

  const pagination = usePagination({
    id: `adviserInvestments-${adviserId}`,
    order: 'desc',
    orderBy: 'investmentDate',
  });

  const { filters, handleChangeFilters } = useFilters<Filters>(
    `adviserInvestments-${adviserId}`,
    {
      onFiltersChange: () => pagination.toFirstPage(),
    },
  );
  const { data, isFetching } = useAdviserSubscriptions({
    adviserId,
    params: {
      page: pagination.page,
      pageSize: pagination.rowsPerPage,
      search: filters.search,
      order: pagination.order,
      orderBy: pagination.orderBy,
    },
  });

  return (
    <GridContainer>
      <Grid item xs={12}>
        <CardHeader title="Adviser snapshot" />
        <AdviserSnapshot isLoading={isFetching} totals={data?.totals} />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" className={classes.divider}>
          <CardHeader title="Linked subscriptions" className={classes.title} />

          <div className={classes.controlsContainer}>
            {data?.subscriptions.length ? (
              <DownloadCertificatesButton
                adviserId={adviserId}
                adviserName={adviserName}
              />
            ) : null}

            <SearchField
              className={classes.searchBox}
              onSearch={(search) => handleChangeFilters({ search })}
              variant="outlined"
              name="investorName"
              id="investorName"
              placeholder="Search by investor name"
              allowEmptyQuery
              autoSearchOnDebounce
              fullWidth
            />
          </div>
        </Grid>
        <AdviserInvestmentsTable
          subscriptions={data?.subscriptions ?? []}
          totalInvestments={data?.totals?.investments ?? 0}
          pagination={pagination}
          loading={isFetching}
          onDownloadCertificates={handleInvestmentCertificatesDownload}
          isSuperAdmin={isSuperAdmin}
        />
      </Grid>
    </GridContainer>
  );
};

export default AdviserInvestments;
