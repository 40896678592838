import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { removeEmptyParagraphs } from 'further-ui/utils';

const CkEditor = ({
  value,
  onChange,
  placeholder,
  disabled,
  onBlur,
  extraLine = false,
}) => {
  const handlePaste = (event, data, editor) => {
    event.stop();
    const textData = data.getData('text/html') || data.getData('text/plain');
    const sanitizedText = removeEmptyParagraphs(textData);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedText;

    tempDiv.querySelectorAll('[style]').forEach((el) => {
      el.removeAttribute('style');
    });

    editor.model.change(() => {
      const viewFragment = editor.data.processor.toView(tempDiv.innerHTML);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(
        modelFragment,
        editor.model.document.selection,
      );
    });
  };

  return (
    <CKEditor
      className="document-editor-editor"
      editor={DecoupledEditor}
      onReady={(editors) => {
        // Insert the toolbar before the editable area.
        editors.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editors.ui.view.toolbar.element,
            editors.ui.getEditableElement(),
          );

        if (extraLine) {
          editors.editing.view.change((writer) => {
            writer.setStyle(
              'min-height',
              '120px',
              editors.editing.view.document.getRoot(),
            );
          });
        }

        const editorElement = editors.ui.getEditableElement();
        editorElement.classList.add('text-component');

        // Listen for the paste event
        editors.editing.view.document.on('clipboardInput', (event, data) => {
          const { dataTransfer } = data;
          handlePaste(event, dataTransfer, editors);
        });
      }}
      config={{
        toolbar: ['|', 'bold', 'italic', 'link', 'underline'],
        placeholder,
      }}
      disabled={disabled}
      data={value || ''}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
export default CkEditor;
