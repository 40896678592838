import { FC } from 'react';
import AlertDialog from 'components/AlertDialog';
import Typography from '@material-ui/core/Typography';
import { Disclosure } from 'further-ui/hooks/useDisclosure';

type Props = {
  disclosure: Disclosure;
  onConfirm: (params: { recalculateFees: boolean }) => void;
  allowLeaveFeesUnchangedOption: boolean;
};

const FeesRecalculationWarningModal: FC<Props> = ({
  disclosure,
  onConfirm,
  allowLeaveFeesUnchangedOption,
}) => (
  <AlertDialog
    open={disclosure.isOpen}
    title="Update fees for this subscription?"
    content={
      <>
        <Typography>
          You are editing the total commitment of this subscription:. Should the
          system calculate fees based on the new total commitment amount or
          leave current fees unchanged?
        </Typography>
      </>
    }
    size="sm"
    onConfirm={() => onConfirm({ recalculateFees: true })}
    onAlternateConfirm={() => onConfirm({ recalculateFees: false })}
    onClose={disclosure.onClose}
    btnLabels={{
      confirm: 'Use new commitment amount',
      alternateConfirm: 'Leave fees unchanged',
      cancel: 'Cancel',
    }}
    alternateConfirmBtnProps={
      allowLeaveFeesUnchangedOption ? {} : { disabled: true }
    }
    cancelBtnProps={{
      color: 'secondary',
    }}
  />
);

export default FeesRecalculationWarningModal;
