import React, { useState } from 'react';
import { IconButton, Grid, Button, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import GridContainer from 'components/GridContainer';
import TextField from 'components/FormElements/AppTextInput';
import KeyboardDatePicker from 'components/FormElements/AppDatePicker';
import clsx from 'clsx';
import { deleteNews } from '@redux/actions/Company';
import { useDispatch } from 'react-redux';
import Dialog from 'components/ConfirmDialog';
import CardHeader from 'components/CardHeader';
import { useNotification } from 'hooks/ui/useNotification';

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  marginShareFile: {
    marginBottom: 30,
  },
}));

const NewsRows = ({ news, setNews, errorTextNews, classes }) => {
  const newsClasses = useStyles();
  const [newsIndex, setSelectedNewsIndex] = useState(0);
  const [openConfirmNews, setOpenConfirmNews] = React.useState(false);
  const dispatch = useDispatch();
  const { error, success } = useNotification();

  const handleInputChangeNews = (e, index, isDate) => {
    if (isDate) {
      const list = [...news];
      list[index]['date'] = e;
      setNews(list);
    } else {
      const { name, value } = e.target;
      const list = [...news];
      list[index][name] = value;
      setNews(list);
    }
  };

  const handleConfirmNewsDelete = () => {
    const list = [...news];
    var selectedNews = news[newsIndex];
    if (selectedNews._id) {
      dispatch(deleteNews(selectedNews._id))
        .then((response) => {
          if (response.status === 200) {
            success(response.data.responseMsg);
            list.splice(newsIndex, 1);
            setNews(list);
          }
        })
        .catch((err) => error(err.response.data.responseMsg));
    } else {
      list.splice(newsIndex, 1);
      setNews(list);
    }

    setOpenConfirmNews(!openConfirmNews);
  };

  const handleRemoveNews = (index) => {
    setSelectedNewsIndex(index);
    setOpenConfirmNews(!openConfirmNews);
  };

  const handleAddNews = () => {
    setNews([
      ...news,
      {
        title: '',
        url: '',
        date: null,
      },
    ]);
  };

  return (
    <GridContainer className={classes.cmxc}>
      <CardHeader title="News" />
      <GridContainer>
        <Grid item xs={12} md={12}>
          {news &&
            news.map((x, i) => {
              return (
                <div key={i} className={newsClasses.marginShareFile}>
                  <label className={newsClasses.fieldLabel}>{`News ${
                    i + 1
                  }`}</label>
                  <GridContainer>
                    <Grid item md={5} xs={12}>
                      <TextField
                        required
                        name="title"
                        placeholder="Enter title"
                        fullWidth
                        onChange={(e) => handleInputChangeNews(e, i)}
                        value={x.title}
                        error={errorTextNews[i]?.title}
                        helperText={errorTextNews[i]?.title}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        name="url"
                        placeholder="Enter URL"
                        fullWidth
                        onChange={(e) => handleInputChangeNews(e, i)}
                        value={x.url}
                        error={errorTextNews[i]?.url}
                        helperText={errorTextNews[i]?.url}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <KeyboardDatePicker
                        required
                        placeholder="Select date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="date"
                        value={x.date}
                        onChange={(e) => handleInputChangeNews(e, i, true)}
                        error={errorTextNews[i]?.date}
                        helperText={errorTextNews[i]?.date}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      {news.length && (
                        <IconButton
                          size="small"
                          className={classes.deleteButton}
                          onClick={() => handleRemoveNews(i)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Grid>
                  </GridContainer>
                </div>
              );
            })}
        </Grid>
      </GridContainer>
      <Grid item xs={12} lg={12} className={classes.addRevalutionBtn}>
        <Button
          className={clsx(classes.marginLeftBtn, 'mb-3')}
          onClick={handleAddNews}
          variant="text"
          color="primary"
        >
          Add News
        </Button>
      </Grid>
      <Dialog
        open={openConfirmNews}
        title={`Confirm delete`}
        content={'Are you sure you wish to delete this news item?'}
        onClose={() => setOpenConfirmNews(false)}
        onConfirm={handleConfirmNewsDelete}
      />
    </GridContainer>
  );
};

export default NewsRows;
