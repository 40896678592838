import { IconButton, makeStyles } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { CmtTitle } from 'components/CmtTypography';
import useClosedTrancheDeployments from 'hooks/data/dashboards/useClosedTrancheDeployments';
import { TrancheDeploymentSummary } from '../TrancheDeploymentSummary';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles(() => ({
  gridItem: {
    margin: '1.5rem 0 0.5rem',
  },
}));

const ClosedTrancheDeployments = ({ firmId, classes: parentClasses }) => {
  const { data } = useClosedTrancheDeployments({ firmId });
  const classes = useStyles();

  const TrancheItem = ({ closedTranche, parentClasses }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <Grid item md={6} xs={12} ref={ref}>
        {inView && (
          <TrancheDeploymentSummary
            tranche={closedTranche.tranche}
            deploymentSummary={closedTranche.deploymentSummary}
            classes={parentClasses}
            displayFundsRaisedInsteadOfDeployed
          />
        )}
      </Grid>
    );
  };

  return (
    <>
      {data?.length > 0 && (
        <Grid
          item
          xs={12}
          className={clsx(parentClasses.dFlex, classes.gridItem)}
        >
          <CmtTitle content="Tranche deployment progress" />
          <Tooltip
            arrow
            title="This section is an overview of the fund's closed tranches that are currently in deployment. To view all tranches (including deployed tranches), navigate to the Closed Tranches section in the side menu."
          >
            <IconButton>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {/* only show 100 closed tranches for now, in lieu of proper pagination/searching */}
      {data?.slice(0, 100).map((closedTranche) => (
        <TrancheItem
          key={closedTranche.tranche._id}
          closedTranche={closedTranche}
          parentClasses={parentClasses}
        />
      ))}
    </>
  );
};

export default ClosedTrancheDeployments;
