import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { AttachFile, Close, Warning } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    marginRight: '0.5rem',
  },
}));

export const FileUploadButton = ({
  onUpload,
  onDelete,
  uploadError,
  isUploading,
}) => {
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (uploadError) {
      setError(uploadError);
    }
  }, [uploadError]);

  if (isUploading) {
    return (
      <Button disabled color="primary" variant="outlined">
        <CircularProgress size={18} className={classes.spinner} />
        Uploading
      </Button>
    );
  }
  if (error) {
    return (
      <div className={classes.flex}>
        <Tooltip title={error}>
          <Warning color="error" />
        </Tooltip>
        <IconButton
          onClick={() => {
            setFile();
            onDelete();
            setError();
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  if (file) {
    return (
      <div className={classes.flex}>
        <Tooltip title={file.name}>
          <AttachFile />
        </Tooltip>
        <IconButton
          onClick={() => {
            setFile();
            onDelete();
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <Button
        onClick={() => inputFile.current.click()}
        color="primary"
        variant="outlined"
        style={{ minWidth: 130 }}
      >
        Choose file
      </Button>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => {
          setFile(e.target.files[0]);
          onUpload(e.target.files[0]);
        }}
      />
    </>
  );
};
