import React from 'react';
import Table from 'components/Table';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { numberToDisplayString } from 'utils/numbers';
import useStyles from './styles';
import { Box } from '@material-ui/core';
import ShareholdingLabel from 'components/ShareholdingLabel';
import { Shareholding } from '.';

type CurrentShareholdingsProps = {
  combinedShareholdings: Array<Shareholding>;
  transferDate?: string;
};

const CurrentShareholdings: React.FC<CurrentShareholdingsProps> = ({
  combinedShareholdings,
  transferDate,
}) => {
  const classes = useStyles();

  const columns = [
    {
      label: 'Company',
      key: 'companyId',
      sort: false,
      render: (shareholding: Shareholding) => (
        <ShareholdingLabel
          label={shareholding?.company?.name || ''}
          shareholding={shareholding}
          transferDate={transferDate}
          classes={classes}
        />
      ),
    },
    {
      label: 'Investment date',
      key: 'createdAt',
      sort: false,
      render: (shareholding: Shareholding) =>
        dateToLabel(shareholding.investmentDate),
    },
    {
      label: 'Initial share price',
      key: 'initialSharePrice',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.initialSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Shares held',
      key: 'noOfShare',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToDisplayString(shareholding.sharesCurrentlyHeld),
    },
    {
      label: 'Capital allocated',
      key: 'subscriptionAmount',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentCapitalAllocated),
    },
    {
      label: 'Current share price',
      key: 'currentSharePrice',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentSharePrice, {
          unlimitedDp: true,
        }),
    },
    {
      label: 'Current share value',
      key: 'currentShareValue',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.currentShareValue),
    },
    {
      label: 'Income tax relief',
      key: 'incomeTaxRelief',
      sort: false,
      render: (shareholding: Shareholding) =>
        numberToCurrencyString(shareholding.incomeTaxRelief),
    },
  ];

  const getTotalCapitalAndShareValue = (dataArray: Array<Shareholding>) => {
    let totalCapitalAllocated = 0;
    let totalShareValue = 0;

    dataArray.forEach((item) => {
      totalCapitalAllocated += item.isTransferred
        ? 0
        : item.currentCapitalAllocated;
      totalShareValue += item.isTransferred
        ? 0
        : Number(item.currentShareValue);
    });

    return {
      totalCapitalAllocated,
      totalShareValue,
    };
  };
  const nonEmptyShareHoldings =
    combinedShareholdings?.filter(
      ({ isFullyExited, sharesCurrentlyHeld }) =>
        !isFullyExited && sharesCurrentlyHeld,
    ) ?? [];
  const totals = getTotalCapitalAndShareValue(nonEmptyShareHoldings);

  return (
    <Table
      columns={columns.map((column) => ({
        ...column,
        getCellClassName: (shareholding: Shareholding) =>
          shareholding.isTransferred ? classes.inactiveCell : '',
      }))}
      tablebody={combinedShareholdings}
      emptyMessage="No current shareholdings found"
      variant="nohover"
      TableFooter={
        <tfoot>
          <tr className={classes.summaryRow}>
            <td className={classes.summaryValue} align="right" colSpan={4}>
              Total capital allocated
            </td>
            <td className={classes.summaryValue}>
              <Box alignItems="center" display="flex" gridGap={4}>
                {numberToCurrencyString(totals.totalCapitalAllocated)}
              </Box>
            </td>
            <td className={classes.summaryValue} align="right">
              Total share value
            </td>
            <td className={classes.summaryValue} colSpan={2}>
              <Box alignItems="center" display="flex" gridGap={4}>
                {numberToCurrencyString(totals.totalShareValue)}
              </Box>
            </td>
          </tr>
        </tfoot>
      }
    />
  );
};

export default CurrentShareholdings;
