import { makeStyles } from '@material-ui/core/styles';

const topAdviserTableStyles = makeStyles((theme: any) => ({
  loading: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    position: 'absolute',
    right: 24,
    top: 24,
    fontSize: '14px',
    color: theme.palette.text.rootColor,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  actionButton: {
    minWidth: '35px',
    color: theme.palette.text.green,
  },
  tooltipIcon: {
    cursor: 'pointer',
    fontSize: '13px',
    margin: '-2px 0 0 5px',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default topAdviserTableStyles;
