import GridContainer from 'components/GridContainer';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import downloadCertificationPdf from 'helpers/investor/downloadCertificationPdf';

import useInvestorCertification from 'hooks/data/investor/useInvestorCertification';
import CurrentCertificationResult from './CurrentCertificationResult';
import ExpiredCertificationResult from './ExpiredCertificationResult';

const useStyles = makeStyles(() => ({
  value: {
    color: '#656565',
    fontWeight: 'bold',
    height: 26,
    lineHeight: '26px',
  },
  label: {
    color: '#656565',
    fontSize: 12,
    display: 'flex',
    alignItems: 'flex-start',
    height: 26,
    lineHeight: '26px',
  },
  minHeight: {
    minHeight: 50,
  },
}));

type Props = {
  investorDetail: {
    _id: string;
    fullName: string;
  };
};

const InvestorCertification: React.FC<Props> = ({ investorDetail }) => {
  const classes = useStyles();
  const { certification, deleteCertification, resetCertification } =
    useInvestorCertification(investorDetail._id);

  const investorCertification = certification?.data ?? [];

  const currentCertifications = investorCertification.filter(
    ({ forceExpired }) => !forceExpired,
  );
  const expiredCertifications = investorCertification.filter(
    ({ forceExpired }) => forceExpired,
  );

  return (
    <Grid item xs={12}>
      <GridContainer className={classes.minHeight}>
        <Grid item xs={3}>
          <span className={classes.label}>Investor self-certification</span>
        </Grid>
        <Grid item xs={9}>
          {!investorCertification.length ? (
            'N/A'
          ) : (
            <Box display="flex" flexDirection="column" gridGap="8px">
              {!currentCertifications.length &&
                !!expiredCertifications.length && (
                  <span className={classes.value}>
                    Re-certification requested
                  </span>
                )}
              {currentCertifications.map((certification) => (
                <CurrentCertificationResult
                  key={certification._id}
                  certification={certification}
                  isResetting={resetCertification.isLoading}
                  onReset={() => resetCertification.mutate()}
                  onDownload={(certification) =>
                    downloadCertificationPdf(
                      investorDetail.fullName,
                      certification,
                    )
                  }
                />
              ))}
              {!!expiredCertifications.length && (
                <Typography component="i" variant="caption">
                  Previous results
                </Typography>
              )}
              {expiredCertifications.map((certification) => (
                <ExpiredCertificationResult
                  key={certification._id}
                  certification={certification}
                  isDeleting={deleteCertification.isLoading}
                  onDelete={(certificationId) =>
                    deleteCertification.mutate(certificationId)
                  }
                  onDownload={(certification) =>
                    downloadCertificationPdf(
                      investorDetail.fullName,
                      certification,
                    )
                  }
                />
              ))}
            </Box>
          )}
        </Grid>
      </GridContainer>
    </Grid>
  );
};

export default InvestorCertification;
