import FieldRow from 'components/FormElements/FieldRow';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import { dateToLabel } from 'further-ui/utils';

const SummaryStep = ({
  company,
  shareSplitDate,
  shareSplitFactor,
  currentSharePriceDisplay,
  newSharePriceDisplay,
  onSubmit,
  onBack,
  classes,
}) => {
  return (
    <GridContainer item md={8}>
      <Grid item xs={12}>
        <p>
          You are attempting to split the shares by a factor of{' '}
          <strong>{shareSplitFactor}</strong>. The new share price will be{' '}
          <strong>{newSharePriceDisplay}</strong>
        </p>
      </Grid>
      <FieldRow title="Company" centerTitle>
        {company.data?.tradingName}
      </FieldRow>
      <FieldRow title="Share split date" centerTitle>
        {dateToLabel(shareSplitDate)}
      </FieldRow>
      <FieldRow title="Current share price" centerTitle>
        {currentSharePriceDisplay}
      </FieldRow>
      <FieldRow title="Share split factor" centerTitle>
        {shareSplitFactor}
      </FieldRow>
      <FieldRow title="New share price" centerTitle>
        {newSharePriceDisplay}
      </FieldRow>
      <Grid item className={classes.buttonAlignEnd}>
        <Button
          onClick={onBack}
          variant="outlined"
          style={{ marginRight: '0.5rem' }}
        >
          Back
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </Grid>
    </GridContainer>
  );
};

export default SummaryStep;
