import { useQuery } from '@tanstack/react-query';

import { api } from 'lib/httpClient';
import { Api } from 'further-types/companies';
async function fetchGroupedCompanies({
  queryKey,
}: {
  queryKey: [string, Api.GetGroupedCompaniesQuery];
}) {
  const [, params] = queryKey;

  const { data } = await api.get(`investee/list/grouped`, { params });

  return data.data;
}

function useGroupedCompanies(params: Api.GetGroupedCompaniesQuery) {
  return useQuery<
    Api.GetGroupedCompaniesQuery,
    Error,
    Api.GetGroupedCompaniesResult,
    any
  >({
    queryKey: ['groupedCompanies', { ...params }],
    queryFn: fetchGroupedCompanies,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}

export default useGroupedCompanies;
