import downloadFile from 'utils/downloadFile';
import axios from 'axios';

export const getSumOfSharesHeldOnDate = (body, notification) => {
  return async () => {
    try {
      const response = await axios.post(`exit/sum-shares-held-on-date`, body);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      notification.error(error.response.data.responseMsg);
    }
  };
};

export const calculatePerformanceFee = (body, notification) => {
  return async () => {
    try {
      const response = await axios.post(`exit/calculate-performance-fee`, body);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      notification.error(error.response.data.responseMsg);
    }
  };
};

export const downloadExits = (exitId, notification) => {
  return async () => {
    try {
      const response = await axios.get(`exit/download/${exitId}`, {
        responseType: 'blob',
      });
      if (response.status === 200) {
        downloadFile(response.data, `Exit-${Date.now()}.xlsx`);
        return Promise.resolve(response);
      }
    } catch (error) {
      notification.error(
        error?.response?.data?.responseMsg ??
          'Error downloading exit transfer statement',
      );
      return false;
    }
  };
};
