import { FC } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { CheckCircle, PauseCircleFilled } from '@material-ui/icons';
import { useInvestorDisability } from 'hooks/data/investor/useInvestorDisability';

const useStyles = makeStyles(() => ({
  firmAdminToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
}));

interface Props {
  investorId: string;
  firmId: string;
  firmName?: string;
  emailsCurrentlyDisabled: boolean;
}

const SingleFirmEmailToggle: FC<Props> = ({
  investorId,
  firmId,
  firmName,
  emailsCurrentlyDisabled,
}) => {
  const classes = useStyles();
  const { mutate: updateInvestorDisability } = useInvestorDisability();

  return (
    <div className={classes.firmAdminToggle}>
      {emailsCurrentlyDisabled ? (
        <>
          <PauseCircleFilled color="secondary" style={{ fontSize: '1rem' }} />
          <span>{firmName ? `${firmName} - Disabled` : 'Disabled'}</span>
        </>
      ) : (
        <>
          <CheckCircle color="primary" style={{ fontSize: '1rem' }} />
          <span>{firmName ? `${firmName} - Enabled` : 'Enabled'}</span>
        </>
      )}
      <Button
        size="small"
        variant="outlined"
        color="primary"
        style={{ height: '26px' }}
        onClick={() =>
          updateInvestorDisability({
            investorId,
            firmId,
            disableAllEmails: !emailsCurrentlyDisabled,
          })
        }
      >
        {emailsCurrentlyDisabled ? 'Enable emails' : 'Disable emails'}
      </Button>
    </div>
  );
};

export default SingleFirmEmailToggle;
