import React from 'react';
import { nationality } from 'further-ui/labels';
import { Control, Controller, useFieldArray } from 'react-hook-form';

import AppCheckbox from 'components/FormElements/AppCheckBox';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import FieldRow from 'components/FormElements/FieldRow';
import { IconButton } from '@material-ui/core';
import { AddOutlined, Remove } from '@material-ui/icons';
import useStyles from '../styles';
import { InvestorFormValues } from 'helpers/investor/investorForm';

type Props = {
  control: Control<InvestorFormValues>;
};

const InvestorNationality: React.FC<Props> = ({ control }) => {
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'otherNationality',
  });

  return (
    <>
      <FieldRow title="Nationality" centerTitle>
        <Controller
          name="nationality"
          control={control}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <AppSelectBox
              {...rest}
              data={nationality}
              valueKey="Country"
              labelKey="Country"
              variant="outlined"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FieldRow>
      <FieldRow title="" centerTitle>
        {/* @ts-expect-error component needs to be converted to TS */}
        <AppCheckbox
          key="isOtherNationality"
          label="This is the investor’s only nationality"
          checked={!fields.length}
          onChange={(event) => {
            if (event.target.checked) {
              for (let i = fields.length - 1; i >= 0; i--) {
                remove(i);
              }
            } else {
              append({ country: '' });
            }
          }}
        />
      </FieldRow>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <FieldRow title="Other nationality" key={i} centerTitle>
            <div className={classes.otherNationalityContainer}>
              <Controller
                name={`otherNationality.${i}.country`}
                control={control}
                render={({
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <AppSelectBox
                    {...rest}
                    data={nationality}
                    valueKey="Country"
                    labelKey="Country"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <IconButton onClick={() => append({ country: '' })}>
                <AddOutlined />
              </IconButton>
              <IconButton onClick={() => remove(i)}>
                <Remove />
              </IconButton>
            </div>
          </FieldRow>
        </React.Fragment>
      ))}
    </>
  );
};
export default InvestorNationality;
