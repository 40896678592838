import GridContainer from 'components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { Button, makeStyles } from '@material-ui/core';
import {
  manuallyPassAppropriateness,
  updateInvestorQuestion,
} from '@redux/actions/Investor';
import { useDispatch } from 'react-redux';
import { useGetRole } from 'hooks/ui/useGetRole';
import { getCurrentAppropriatenessStatus } from 'helpers/investor/getCurrentAppropriatenessStatus';
import {
  signUpSourceLabel,
  getSignUpDetail,
} from 'helpers/investor/getSignUpSourceLabel';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { dateToLabel } from 'further-ui/utils';

const useStyles = makeStyles(() => ({
  label: {
    color: '#656565',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  reviewBtn: {
    height: 26,
    paddingTop: '8px !important',
  },
  reviewBtnlft: {
    marginLeft: 25,
  },
  value: {
    color: '#656565',
    fontWeight: 'bold',
  },
  middleContent: {
    transform: 'translateY(3px)',
    display: 'inline-block',
  },
  marginRight: {
    marginRight: '1rem',
  },
  minHeight: {
    minHeight: 50,
  },
}));

export default function InvestorAppropriateness({
  downloadQuestion,
  id,
  investorDetail,
  refetchInvestor,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleApiRequest = useApiRequestHandler();
  const { isSuperAdmin } = useGetRole();
  const { questionnaireData, answerList } = investorDetail;

  const appropriateness = questionnaireData?.length
    ? questionnaireData[0]
    : null;
  const hasAnswerList = answerList?.length > 0;

  const appropriatenessPassCount =
    answerList?.filter((answers) => answers.status === true).length || 0;
  const appropriatenessFailureCount =
    answerList?.length - appropriatenessPassCount;
  const showManuallyPassButton =
    isSuperAdmin &&
    !appropriateness?.isManuallyPassedByAdmin &&
    !appropriateness?.status;

  return (
    <>
      <Grid item xs={12} md={12}>
        <GridContainer className={classes.minHeight}>
          <Grid item xs={3}>
            <span className={classes.label}>Account created by</span>
          </Grid>
          <Grid item xs={9} className={classes.middleContent}>
            <span className={classes.value}>
              {signUpSourceLabel[investorDetail?.signUpSource]}
            </span>
            <span>{getSignUpDetail(investorDetail, isSuperAdmin)}</span>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12}>
        <GridContainer alignItems="center" className={classes.minHeight}>
          <Grid item xs={3}>
            <span className={classes.label}>Appropriateness assessment</span>
          </Grid>
          <Grid item xs={9}>
            <div className={classes.dFlex}>
              {hasAnswerList ? (
                <>
                  <span className={classes.marginRight}>
                    Passes: {appropriatenessPassCount}
                  </span>
                  <span>Failures: {appropriatenessFailureCount}</span>
                </>
              ) : (
                <span>Attempts: {appropriateness?.attempt || 0}</span>
              )}
            </div>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12}>
        <GridContainer className={classes.minHeight}>
          <Grid item xs={3}>
            <span className={classes.label}>
              Current appropriateness status
            </span>
          </Grid>
          <Grid item xs={9}>
            <span className={`${classes.value} ${classes.marginRight}`}>
              {getCurrentAppropriatenessStatus(appropriateness)}
            </span>
            <span>{dateToLabel(appropriateness?.updatedAt, null, true)}</span>

            {appropriateness?.attemptBlocked && (
              <Button
                className={`${classes.reviewBtn} ${classes.reviewBtnlft}`}
                size="small"
                variant="outlined"
                color="primary"
                onClick={async () => {
                  await handleApiRequest(async (showSuccess) => {
                    const response = await dispatch(updateInvestorQuestion(id));
                    showSuccess(response?.responseMsg);
                  });
                }}
              >
                Allow appropriateness
              </Button>
            )}
            {showManuallyPassButton && (
              <Button
                className={`${classes.reviewBtn} ${classes.reviewBtnlft}`}
                size="small"
                variant="outlined"
                color="primary"
                onClick={async () => {
                  await handleApiRequest(async (showSuccess) => {
                    const response = await dispatch(
                      manuallyPassAppropriateness(id),
                    );
                    showSuccess(response?.responseMsg);
                    refetchInvestor();
                  });
                }}
              >
                Manually pass appropriateness
              </Button>
            )}
            {questionnaireData?.length > 0 && (
              <Button
                className={`${classes.reviewBtn} ${classes.reviewBtnlft}`}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  downloadQuestion();
                }}
              >
                Download appropriateness results
              </Button>
            )}
          </Grid>
        </GridContainer>
      </Grid>
    </>
  );
}
