import { Avatar, makeStyles, IconButton } from '@material-ui/core';
import { dateToLabel } from 'further-ui/utils';
import { NotesApi } from 'further-types/notes';
import { Delete } from '@material-ui/icons';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { UseMutationResult } from '@tanstack/react-query';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    position: 'relative',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #C4C4C4',
    borderRadius: '2px',
    padding: '16px',
    width: '100%',
  },
  date: {
    fontSize: '12px',
  },
  avatar: {
    border: '3px solid white',
    boxShadow: '0px 4px 20px 0px #8689A540',
    height: '56px',
    width: '56px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '10px',
  },
  noteLink: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '56px',
      left: '26px',
      width: '3px',
      height: '100%',
      backgroundColor: '#E9E9E9',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  deleteIcon: {
    alignSelf: 'flex-start',
    width: '80px',
    display: 'flex',
    justifyContent: 'end',
  },
  content: {
    textAlign: 'justify',
    width: '100%',
  },
  icon: {
    padding: 0,
  },
}));

type NoteProps = {
  note: NotesApi.NoteResponse;
  link?: boolean;
  deleteNote: UseMutationResult<boolean, Error, string>;
};

const Note: React.FC<NoteProps> = ({ note, link, deleteNote }) => {
  const {
    content,
    createdByUser: { firstName, lastName, profilePic },
    createdAt,
    _id: id,
  } = note;
  const classes = useStyles();
  const deleteDisclosure = useDisclosure();

  return (
    <>
      <div className={`${classes.container} ${link ? classes.noteLink : ''}`}>
        <Avatar src={profilePic} className={classes.avatar} />
        <div className={classes.noteContainer}>
          <div className={classes.titleRow}>
            <span>
              {firstName} {lastName}
            </span>
            <span className={classes.date}>{dateToLabel(createdAt)}</span>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{content}</div>
            <div className={classes.deleteIcon}>
              <IconButton
                onClick={() => deleteDisclosure.stageAction({ id })}
                className={classes.icon}
              >
                <Delete htmlColor="#E8453C" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        onClose={deleteDisclosure.onClose}
        open={deleteDisclosure.isOpen}
        title="Warning"
        content="Are you sure you wish to delete this note?"
        btnLabels={{ confirm: 'Delete note', cancel: 'Go back' }}
        cancelBtnProps={{ variant: 'contained', color: 'primary' }}
        confirmBtnProps={{
          variant: 'contained',
          color: 'secondary',
        }}
        onConfirm={() => {
          deleteNote.mutate(id);
          deleteDisclosure.onClose();
        }}
      />
    </>
  );
};

export default Note;
