import React, { PropsWithChildren } from 'react';
import GridContainer from 'components/GridContainer';
import Grid, { GridItemsAlignment } from '@material-ui/core/Grid';
import { Typography, CircularProgress, Box, Button } from '@material-ui/core';
import useKyc from 'hooks/data/kyc/useKyc';
import downloadKycPdf from 'helpers/investor/downloadKycPdf';
import { AccountType } from 'further-types/investor';

import useStyles from './styles';
import KycResultsButton from './KycResultsButton';
import CertificateItem from './CertificateItem';
import { Api, KycProcessStatus, KycRiskRating } from 'further-types/kyc';
import { useGetRole } from 'hooks/ui/useGetRole';
import KycManualApprovalModal from './KycManualApprovalModal';
import { useDisclosure } from 'further-ui/hooks';
import AlertDialog from 'components/AlertDialog';
import { capitalize } from 'lodash';
import KycResult from './KycResult';
import PreviousResults from './PreviousResults';
import { Attachment } from '@material-ui/icons';

const Row: React.FC<
  PropsWithChildren<{ label: string; verticalAlign?: GridItemsAlignment }>
> = ({ children, label, verticalAlign = 'center' }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <GridContainer alignItems={verticalAlign}>
        <Grid item xs={3} className={classes.row}>
          <span className={classes.label}>{label}</span>
        </Grid>
        <Grid item xs={9}>
          {children}
        </Grid>
      </GridContainer>
    </Grid>
  );
};

type Props = {
  investorId: string;
  fullName?: string;
  accountType: AccountType;
  advisersList: Array<{ _id: string; fullName: string }>;
  identityVerificationCertificates?: Array<{
    adviserId: string;
    date: string;
    url: string;
  }>;
};

const extractFileName = (filePath: string) =>
  `${filePath.split('/').pop().split('_').slice(0, -1).join('_')}.${filePath
    .split('.')
    .pop()}`;

const KycResults: React.FC<Props> = ({
  investorId,
  fullName,
  identityVerificationCertificates,
  advisersList,
  accountType,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useGetRole();
  const manualApprovalModal = useDisclosure();
  const resetKycConfirmationModal = useDisclosure();

  const {
    kycDetails: { data: kycData, isFetching },
    expiredKyc: { data: expiredKycData, isFetching: isFetchingExpiredData },
    getKycImages,
    resetKyc,
    approveIdv,
    approveAml,
    manualKycProcess,
    saveAmlApprovalNotes,
    saveIdvApprovalNotes,
  } = useKyc(investorId);

  if (isFetching || isFetchingExpiredData) {
    return <CircularProgress size={24} />;
  }

  const isLegalEntity = accountType === AccountType.LegalEntity;
  const showResetKycButton =
    isSuperAdmin &&
    kycData?.idvProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;
  const showApproveIdvButton =
    isSuperAdmin &&
    kycData?.idvProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;
  const showApproveAmlButton =
    isSuperAdmin &&
    kycData?.amlProcessStatus === KycProcessStatus.Failed &&
    !isLegalEntity;

  const isPassedManually =
    kycData?.amlProcessStatus === KycProcessStatus.ManuallyApproved ||
    kycData?.idvProcessStatus === KycProcessStatus.ManuallyApproved;

  const showDownloadResultsButton = !!(
    kycData?.isKycStepCompleted && kycData?.hasYotiData
  );

  const handleResetKyc = () => {
    resetKyc();
    resetKycConfirmationModal.onClose();
  };

  const handleManualKycProcess = (data: Api.ManualKycProcessRequest) => {
    manualKycProcess(data);
    manualApprovalModal.onClose();
  };

  const handleDownloadNotes = () => {
    const blob = new Blob(
      [kycData?.manualApprovalNotes?.replace(/<[^>]*>/g, '')],
      {
        type: 'text/plain',
      },
    );

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'notes.txt';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  };

  const handleApproveIdv = (idvApprovalNotes: string) => {
    approveIdv({ idvApprovalNotes });
  };

  const handleApproveAml = (amlApprovalNotes: string) => {
    approveAml({ amlApprovalNotes });
  };

  const handleSaveIdvApprovalNotes = (idvApprovalNotes: string) => {
    saveIdvApprovalNotes({ idvApprovalNotes });
  };

  const handleSaveAmlApprovalNotes = (amlApprovalNotes: string) => {
    saveAmlApprovalNotes({ amlApprovalNotes });
  };

  const handleDownloadKycResults = async (kycDetails: typeof kycData) => {
    const images = await getKycImages();
    downloadKycPdf(kycDetails, images.data ?? {}, { fullName });
  };

  const sectionTitle = isLegalEntity ? 'KYB information' : 'KYC results';
  const manualRecordTitle = isLegalEntity
    ? 'Manual KYB record'
    : 'Manual KYC record';
  const manualApprovalButtonTitle = isLegalEntity
    ? isPassedManually
      ? 'Edit manual KYB'
      : 'Complete manual KYB'
    : isPassedManually
    ? 'Edit manual KYC'
    : 'Upload manual KYC';

  return (
    <>
      <GridContainer>
        <Grid item xs="auto">
          <Typography variant="h2" className={classes.heading}>
            {sectionTitle}
          </Typography>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="flex-end" gridGap="16px">
            {showResetKycButton && (
              <Button
                onClick={resetKycConfirmationModal.onOpen}
                variant="contained"
                color="primary"
                size="small"
              >
                Reset eKYC
              </Button>
            )}
            {isSuperAdmin && (
              <Button
                onClick={manualApprovalModal.onOpen}
                variant="contained"
                color="primary"
                size="small"
              >
                {manualApprovalButtonTitle}
              </Button>
            )}
          </Box>
        </Grid>
      </GridContainer>
      <GridContainer>
        {isLegalEntity ? (
          <Row label="KYB status" verticalAlign="flex-start">
            <Box display="flex" flexDirection="column" gridGap="8px">
              <KycResult
                approveButtonText="Admin approve IDV"
                showApproveButton={false}
                statusLabel={
                  kycData?.isKycProcessPassed ? 'Approved' : 'Not approved'
                }
                showDownloadResultsButton={false}
                showNotesTooltip={false}
              />
              <PreviousResults
                expiredKycData={expiredKycData}
                type="idv"
                onDownloadResults={handleDownloadKycResults}
              />
            </Box>
          </Row>
        ) : (
          <>
            <Row label="IDV result" verticalAlign="flex-start">
              <Box display="flex" flexDirection="column" gridGap="8px">
                <KycResult
                  approveButtonText="Admin approve IDV"
                  showApproveButton={showApproveIdvButton}
                  handleApprove={handleApproveIdv}
                  statusLabel={kycData?.idvStatusLabel ?? 'Not attempted'}
                  showDownloadResultsButton={showDownloadResultsButton}
                  handleDowonloadResults={() =>
                    handleDownloadKycResults(kycData)
                  }
                  approvalNotes={kycData?.idvApprovalNotes ?? ''}
                  showNotesTooltip={
                    kycData?.idvProcessStatus === KycProcessStatus.AdminApproved
                  }
                  handleSaveNotes={handleSaveIdvApprovalNotes}
                />
                <PreviousResults
                  expiredKycData={expiredKycData}
                  type="idv"
                  onDownloadResults={handleDownloadKycResults}
                />
              </Box>
            </Row>
            <Row label="AML result" verticalAlign="flex-start">
              <Box display="flex" flexDirection="column" gridGap="8px">
                <KycResult
                  approveButtonText="Admin approve AML"
                  showApproveButton={showApproveAmlButton}
                  handleApprove={handleApproveAml}
                  statusLabel={kycData?.amlStatusLabel ?? 'Not attempted'}
                  showDownloadResultsButton={false}
                  approvalNotes={kycData?.amlApprovalNotes ?? ''}
                  showNotesTooltip={
                    kycData?.amlProcessStatus === KycProcessStatus.AdminApproved
                  }
                  handleSaveNotes={handleSaveAmlApprovalNotes}
                />
                <PreviousResults expiredKycData={expiredKycData} type="aml" />
              </Box>
            </Row>

            <Row label="Adviser submitted documents">
              {identityVerificationCertificates?.length
                ? identityVerificationCertificates?.map((certificate, idx) => (
                    <CertificateItem
                      certificate={certificate}
                      advisersList={advisersList}
                      key={idx}
                    />
                  ))
                : '-'}
            </Row>
          </>
        )}
        <Row label="Risk rating">
          <span className={classes.value}>
            {capitalize(kycData?.riskRating ?? KycRiskRating.Low)}
          </span>
        </Row>
        {kycData?.manualApprovalFiles?.length ||
        kycData?.manualApprovalNotes?.length ? (
          <Row label={manualRecordTitle} verticalAlign="flex-start">
            <Box display="flex" flexDirection="column" gridGap="16px">
              <div>
                <KycResultsButton
                  visible={!!kycData?.manualApprovalNotes?.length}
                  onClick={handleDownloadNotes}
                  buttonText="Download notes"
                />
              </div>
              <Box display="flex" flexDirection="column" gridGap="8px">
                {kycData?.manualApprovalFiles?.map(({ fileName, preview }) => (
                  <Box display="flex" alignItems="center" gridGap="4px">
                    <Attachment className={classes.attachmentIcon} />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={preview}
                      className={classes.attachmentLink}
                    >
                      {extractFileName(fileName)}
                    </a>
                  </Box>
                ))}
              </Box>
            </Box>
          </Row>
        ) : null}
      </GridContainer>
      {manualApprovalModal.isOpen && (
        <KycManualApprovalModal
          onClose={manualApprovalModal.onClose}
          processManualKyc={handleManualKycProcess}
          isPassedManually={isPassedManually}
          kycData={kycData}
          isLegalEntity={isLegalEntity}
        />
      )}
      {resetKycConfirmationModal.isOpen && (
        <AlertDialog
          open
          title="Warning"
          content="Are you sure you wish to reset this investor's eKYC?"
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Reset eKYC',
          }}
          onClose={resetKycConfirmationModal.onClose}
          onConfirm={handleResetKyc}
        />
      )}
    </>
  );
};

export default KycResults;
