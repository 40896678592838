import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import { useNotification } from 'hooks/ui/useNotification';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { format } from 'date-fns';
import downloadFile from 'utils/downloadFile';

export function useFund(id?: string) {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const handleApiException = useApiExceptionHandler();

  const create = useMutation({
    useErrorBoundary: false,
    mutationFn: async (data) => {
      const { data: result } = await api.post<ApiResponse<unknown>>(
        `fund/create`,
        data,
      );

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Tranche added successfully');
      await queryClient.invalidateQueries({ queryKey: ['tranches'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const edit = useMutation({
    useErrorBoundary: false,
    mutationFn: async (data) => {
      if (!id) throw new Error('Tranche ID is required');

      const { data: result } = await api.put<ApiResponse<unknown>>(
        `fund/update/${id}`,
        data,
      );

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Tranche updated successfully');
      await queryClient.invalidateQueries({ queryKey: ['tranches'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const remove = useMutation({
    useErrorBoundary: false,
    mutationFn: async (trancheId: string) => {
      const { data: result } = await api.delete<ApiResponse<unknown>>(
        `fund/delete/${trancheId}`,
      );

      return result.data;
    },
    onSuccess: async () => {
      notification.success('Tranche deleted successfully');
      await queryClient.invalidateQueries({ queryKey: ['tranches'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const duplicate = useMutation({
    useErrorBoundary: false,
    mutationFn: async (trancheId: string) => {
      const { data: result } = await api.get<ApiResponse<unknown>>(
        `fund/copy-fund/${trancheId}`,
      );

      return result.data;
    },
    onSuccess: async () => {
      notification.success(
        'Tranche copied successfully. You may now edit the copied tranche',
      );
      await queryClient.invalidateQueries({ queryKey: ['tranches'] });
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
  });

  const get = useQuery({
    queryKey: ['tranches', id],
    queryFn: async () => {
      const { data: result } = await api.get<ApiResponse<unknown>>(
        `fund/list/${id}`,
      );
      return result.data;
    },
    onError: (error: Error) => {
      handleApiException(error);
    },
    enabled: !!id,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const exportFund = async (
    trancheId: string,
    trancheName: string,
    firmName: string,
  ) => {
    const response = await api.get(`fund/${trancheId}/export`, {
      responseType: 'blob',
    });

    if (
      response.status !== 200 ||
      !response.headers['content-type']?.startsWith(
        'application/vnd.openxmlformats',
      )
    ) {
      throw Error('Unexpected export response.');
    }

    //$firmname-$tranchename-$dateofdownload-$randomgen5digits
    downloadFile(
      response.data,
      `${firmName}-${trancheName}-${format(
        new Date(),
        'dd/MM/yyyy',
      )}-${Math.floor(Math.random() * 100000)}.xlsx`,
    );
  };

  return {
    create,
    edit,
    remove,
    duplicate,
    get,
    exportFund,
  };
}
