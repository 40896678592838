import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchAllocations({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(`upload-shareholding`, {
      params,
    });

    return data.data;
  } catch (error) {
    throw error;
  }
}

export function useAllocations({ params }) {
  return useQuery({
    queryKey: ['allocations', { ...params }],
    queryFn: fetchAllocations,
    keepPreviousData: true,
    staleTime: 10 * 60 * 1000,
  });
}
