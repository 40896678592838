import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/fees';
import useApiExceptionHandler from 'hooks/ui/useApiExceptionHandler';
import { useNotification } from 'hooks/ui/useNotification';

export function useGetOneOffFeesAndDiscounts(
  params: Api.GetOneOffFeesAndDiscountsRequest,
) {
  return useQuery({
    queryKey: ['one-off-fees-and-discounts', params],
    queryFn: async () => {
      const { data } = await api.get<
        ApiResponse<Api.GetOneOffFeesAndDiscountsResponse>
      >('/fee/one-off-fees-and-discounts', {
        params,
      });

      return data.data;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
}

export function useDeleteOneOffFeesAndDiscounts() {
  const handleError = useApiExceptionHandler();
  const queryClient = useQueryClient();
  const { success } = useNotification();

  return useMutation({
    mutationFn: async (oneOffFeesBatchId: string) => {
      await api.delete<ApiResponse<Api.DeleteOneOffFeesBatchResponse>>(
        `/fee/one-off-fees-batch/${oneOffFeesBatchId}`,
      );
    },
    onError: handleError,
    onSuccess: () => {
      success('These fees/discounts have been deleted');
      queryClient.invalidateQueries({
        queryKey: ['one-off-fees-and-discounts'],
      });
    },
  });
}
