import { api } from 'lib/httpClient';
import { useQuery } from '@tanstack/react-query';

async function fetchInvestorsCount({ queryKey }) {
  try {
    const [, { reportingId, countOnly }] = queryKey;
    const {
      data: { data },
    } = await api.get(`investor-reporting/investors-in-report/${reportingId}`, {
      params: { countOnly },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

function useGetInvestorsInReportAPI({ params }) {
  return useQuery({
    queryKey: ['investors-in-report', params],
    queryFn: fetchInvestorsCount,
    enabled: !!params.reportingId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useGetInvestorsInReport(params) {
  return useGetInvestorsInReportAPI(params);
}
