import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  editButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  createButton: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  disabledButton: {
    minWidth: '35px',
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.disabled,
      background: 'none',
      cursor: 'default',
    },
  },
  tableMt: {
    marginTop: 20,
    whiteSpace: 'break-spaces',
  },
  capital: {
    textTransform: 'capitalize',
  },
  dFlex: {
    display: 'flex',
  },
  marginRight: {
    marginRight: '10px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },
}));

export default useStyles;
