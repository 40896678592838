import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gridGap: '0.5rem',
  },
  tooltipIcon: {
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
  },
});

type Props = {
  control: Control<any>;
  error?: FieldError;
};

const FormAmountPaidInput: FC<Props> = ({ control, error }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Controller
        name="amountPaid"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumberFormat
            {...rest}
            inputRef={ref}
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter amount"
            allowNegative={true}
            allowLeadingZeros={false}
            thousandSeparator={true}
            prefix="£"
            error={!!error?.message}
            helperText={error?.message || ''}
            customInput={TextField}
            type="text"
          />
        )}
      />
      <Tooltip
        leaveDelay={250}
        interactive
        arrow
        title={
          'For refunds, please enter a negative amount in this field. Refunds cannot be processed to the cash balance.'
        }
      >
        <InfoOutlined className={classes.tooltipIcon} />
      </Tooltip>
    </div>
  );
};

export default FormAmountPaidInput;
