import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  arrow: {
    color: 'rgb(204,204,204)',
  },
  tooltip: {
    backgroundColor: 'white',
    border: '1px solid rgb(204,204,204)',
    fontSize: 15,
    fontWeight: '600',
    padding: '20px',
    color: '#333',
    boxShadow: '0 0 12px 0px rgba(0,0,0,0.15)',
  },
};
export const Tooltip = withStyles((theme) => styles)(MaterialTooltip);

export const WideTooltip = withStyles((theme) => ({
  ...styles,
  tooltip: {
    ...styles.tooltip,
    maxWidth: '650px',
  },
}))(MaterialTooltip);
