import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import qs from 'qs';

async function fetchInvestmentInflowSummary({ queryKey }) {
  const [, firmId, dateRangeKey] = queryKey;
  const queryParams = qs.stringify({ firmId });

  const url = firmId
    ? `dashboard/investments-inflow-summary/${dateRangeKey}?${queryParams}`
    : `dashboard/investments-inflow-summary/${dateRangeKey}`;

  const { data } = await api.get(url);

  return data.data;
}

function useInvestmentInflowSummary({ firmId, dateRangeKey }) {
  return useQuery({
    queryKey: ['investment-inflow-summary', firmId, dateRangeKey],
    queryFn: fetchInvestmentInflowSummary,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useInvestmentInflowSummary;
