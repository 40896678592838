import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { compact } from 'lodash';

import { useDispatch } from 'react-redux';

function stripUrl(data, id) {
  let value = data;

  if (id) {
    value.id = id;
  }

  value.whiteLabelConfig.colorBgLogo.key =
    value.whiteLabelConfig.colorBgLogo.url;
  value.whiteLabelConfig.whiteBgLogo.key =
    value.whiteLabelConfig.whiteBgLogo.url;

  // TODO: Clean this up and move it to a helper function
  if (value?.investmentFirmLogo?.includes('.com/')) {
    value.investmentFirmLogo = value.investmentFirmLogo.split('.com/')[1];
  }

  if (value?.firmLogo?.includes('.com/')) {
    value.firmLogo = value.firmLogo.split('.com/')[1];
  }

  if (value?.whiteLabelConfig?.colorBgLogo?.key?.includes('.com/')) {
    value.whiteLabelConfig.colorBgLogo.key =
      value.whiteLabelConfig.colorBgLogo.key.split('.com/')[1];
    value.whiteLabelConfig.colorBgLogo.url =
      value.whiteLabelConfig.colorBgLogo.url.split('.com/')[1];
  }

  if (value?.whiteLabelConfig?.whiteBgLogo?.key?.includes('.com/')) {
    value.whiteLabelConfig.whiteBgLogo.key =
      value.whiteLabelConfig.whiteBgLogo.key.split('.com/')[1];
    value.whiteLabelConfig.whiteBgLogo.url =
      value.whiteLabelConfig.whiteBgLogo.url.split('.com/')[1];
  }

  if (value?.firmLogo?.includes('.com/')) {
    value.firmLogo = value.firmLogo.split('.com/')[1];
  }

  return value;
}

async function fetchFirms({ queryKey }) {
  const [, params] = queryKey;

  try {
    const { data } = await api.get(`firm`, { params });

    return data.data;
  } catch (error) {
    throw error;
  }
}

async function fetchFirm({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const { data } = await api.get(`firm/${params.firmId}`);

    return data.data;
  } catch (error) {
    throw error;
  }
}

async function createFirmRequest(data) {
  let value = stripUrl(data);

  value.contactDetails.keyChecks = compact(value.contactDetails.keyChecks);

  try {
    const response = await api.post(`firm`, value);
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateFirmRequest(id, data) {
  let value = stripUrl(data, id);

  value.contactDetails.keyChecks = compact(value.contactDetails.keyChecks);

  try {
    const response = await api.put(`firm/${id}`, value);

    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}

async function createAccountManagerRequest(accountManagerData, firmId) {
  try {
    const response = await api.post(`/account-manager/create`, {
      accountManagerData,
      firmId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateAccountManagerRequest(data) {
  try {
    const response = await api.put(`/account-manager/update/${data.id}`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }

    throw new Error(error);
  }
}
async function deleteAccountManagerRequest(id) {
  try {
    const response = await api.delete(`/account-manager/${id}`);
    return response;
  } catch (error) {
    if (error.response) {
      throw error.response.data.responseMsg;
    }
    throw new Error(error);
  }
}

export function useFirm({ params }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const firm = useQuery({
    queryKey: ['firms', { params: { firmId: params.firmId } }],
    queryFn: fetchFirm,
    keepPreviousData: true,
    enabled: !!params?.firmId,
  });

  const firms = useQuery({
    queryKey: ['firms', { ...params }],
    queryFn: fetchFirms,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const createFirm = useMutation({
    mutationFn: ({ data }) => createFirmRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firms'],
      });
    },
  });

  const updateFirm = useMutation({
    mutationFn: (data) => updateFirmRequest(params?.firmId, data, dispatch),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['firms'],
      });
    },
  });
  const createAccountManager = useMutation({
    mutationFn: ({ accountManagerData, firmId }) =>
      createAccountManagerRequest(accountManagerData, firmId, dispatch),
  });
  const updateAccountManager = useMutation({
    mutationFn: (data) => updateAccountManagerRequest(data, dispatch),
  });
  const deleteAccountManager = useMutation({
    mutationFn: (id) => deleteAccountManagerRequest(id, dispatch),
  });

  return {
    firm,
    firms,
    createFirm,
    updateFirm,
    createAccountManager,
    updateAccountManager,
    deleteAccountManager,
  };
}
