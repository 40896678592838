import { useQuery } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchDashboard({ queryKey }) {
  const [, params] = queryKey;

  const { data } = await api.get(
    `dashboard/closed-tranche/${params.trancheId}`,
  );

  return data.data;
}

function useClosedTrancheDashboard({ params }) {
  return useQuery({
    queryKey: ['closedTrancheDashboard', params],
    queryFn: fetchDashboard,
    enabled: !!params?.trancheId,
    keepPreviousData: true,
    staleTime: 4 * 60 * 60 * 1000,
  });
}

export default useClosedTrancheDashboard;
