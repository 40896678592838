import React, { useState, useMemo } from 'react';
import GridContainer from 'components/GridContainer';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { isEmpty, sortBy } from 'lodash';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';
import { useGetRole } from 'hooks/ui/useGetRole';
import useCompanies from 'hooks/data/company/useCompanies';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useNotification } from 'hooks/ui/useNotification';
import FullPageSuccess from 'components/FullPageSuccess';
import { ADD_CUSTOM_UPDATE } from 'constants/routes';
import { useCustomUpdate } from 'hooks/data/customUpdate/useCustomUpdate';

import { getCompanyLabel } from 'further-ui/utils';
import CardHeader from 'components/CardHeader';
import AddCustomUpdateDetails from './AddDetails';
import Summary from './Summary';
import { DeliveryType } from 'further-types/custom-updates';

const useStyles = makeStyles((theme) => ({
  cardContentPadding: {
    padding: '2rem',
  },
  errorCk: {
    color: '#f44336',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  buttonLeftMargin: {
    marginLeft: 12,
  },
  divider: {
    margin: '30px 0',
  },
  error: {
    color: theme.palette.error.main,
    padding: '5px 0 0 14px',
  },
  attachmentLink: {
    fontSize: '12px',
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.text.rootColor,
    },
  },
}));

const AddCustomUpdate = () => {
  const { firmId } = useGetRole();
  const { data: companies } = useCompanies({ params: { firmId } });
  const { tranches } = useFunds({ fundStatus: [0, 1] });
  const steps = { AddDetails: 1, Summary: 2 };
  const [activeStep, setActiveStep] = useState(steps.AddDetails);
  const [showSuccess, setShowSuccess] = useState(false);
  const notification = useNotification();
  const breadcrumbs = [
    { label: 'Dashboard', link: '/' },
    {
      label: 'Create custom update',
      link: '/',
      isActive: true,
    },
  ];
  const companiesWithLabel = useMemo(
    () =>
      companies?.result?.map((company) => ({
        label: getCompanyLabel(company),
        _id: company._id,
        firmId: company?.firmId,
      })),
    [companies?.result],
  );

  const [data, setData] = useState({
    selectedFirmIds: [],
    selectedFirmNames: [],
    companyId: [],
    trancheId: [],
    subject: '',
    title: '',
    isCheckedAllCompany: false,
    isCheckedAllTranche: false,
    sendToAllUsers: false,
    sendToDirectInvestors: false,
    sendToAdvisedInvestors: false,
    sendToAdvisers: false,
    sendToOrganisations: false,
    deliveryToInvestors: DeliveryType.Email,
    emailBody: '',
    attachments: [],
    accountManager: {},
  });
  const [errorText, setErrorText] = useState({});
  const classes = useStyles();
  const {
    createCustomUpdate: { mutate, isLoading },
    getNumberOfRecipientsToSendUpdate,
  } = useCustomUpdate();

  const heading = 'Investor reporting: Create custom update';

  const mappedTranche = (tranches.data ?? []).map((tranche) => ({
    _id: tranche._id,
    label: `${tranche.firmId.firmName} ${tranche.fundName}`,
    firmId: tranche.firmId,
  }));
  const sortedTranche = sortBy(mappedTranche ?? [], (tranche) =>
    tranche.label.toLowerCase(),
  );
  const sortedCompany = companiesWithLabel
    ? sortBy(companiesWithLabel, (c) => c.label?.toLowerCase())
    : [];

  const validateFields = () => {
    const errorMessage = {};
    if (!data.subject) {
      errorMessage.subject = 'Please enter subject line.';
    }
    if (!data.title) {
      errorMessage.title = 'Please enter email title.';
    }
    if (!data.emailBody) {
      errorMessage.emailBody = 'Please enter email body.';
    }
    if (
      !data.companyId.length &&
      !data.trancheId.length &&
      !data.sendToAllUsers
    ) {
      errorMessage.companyId = 'Please select company.';
    }

    if (isEmpty(data.accountManager)) {
      errorMessage.accountManager = 'Please select a manager.';
    }
    if (!data.role) {
      errorMessage.role = 'Please enter manager role.';
    }

    if (
      !data.sendToDirectInvestors &&
      !data.sendToAdvisedInvestors &&
      !data.sendToAdvisers &&
      !data.sendToOrganisations
    ) {
      errorMessage.sendTo = 'Please select recipients';
    }

    setErrorText(errorMessage);
    return Object.keys(errorMessage).length;
  };

  const onClick = async () => {
    if (steps.AddDetails === activeStep) {
      if (!validateFields() && data.selectedFirmIds.length === 1) {
        const firmId = data.selectedFirmIds[0];
        const response = await getNumberOfRecipientsToSendUpdate.mutateAsync({
          ...data,
          firmId,
        });

        if (response?.status === 200) {
          setData({
            ...data,
            firmId,
            numberOfRecipients: response?.data?.data?.numberOfRecipients,
          });
          setActiveStep(steps.Summary);
        } else {
          notification.error(response.response.data.responseMsg);
        }
      }
    } else {
      mutate(data, {
        onSuccess: (response) => {
          if (response.status === 200) {
            setShowSuccess(true);
          } else {
            notification.error(response.response.data.responseMsg);
          }
        },
      });
    }
  };
  const isTrancheSelected = data.trancheId.length;
  const list = isTrancheSelected ? sortedTranche : sortedCompany;
  const selectedValues = isTrancheSelected ? data.trancheId : data.companyId;
  const selectedTranchesOrCompanies = selectedValues?.map(
    (id) => list.find((rec) => rec._id === id)?.label,
  );

  if (showSuccess) {
    return (
      <FullPageSuccess
        title="Your update has been successfully sent"
        additionalText="Your recipients will receive this update to the email address stored on their Further account, as well as their investor portal, depending on the settings you selected."
        proceedText="Send another update"
        proceedLink={ADD_CUSTOM_UPDATE}
      />
    );
  }

  return (
    <PageContainer heading={heading} breadcrumbs={breadcrumbs}>
      <CmtCard>
        <CmtCardContent className={classes.cardContentPadding}>
          <CardHeader
            title={
              activeStep === steps.AddDetails
                ? 'Custom update details'
                : 'Custom update summary'
            }
          />

          {steps.AddDetails === activeStep ? (
            <AddCustomUpdateDetails
              setData={setData}
              data={data}
              mappedTranche={mappedTranche}
              errorText={errorText}
              classes={classes}
              firmId={firmId}
            />
          ) : (
            <Summary
              data={data}
              classes={classes}
              selectedTranchesOrCompanies={selectedTranchesOrCompanies}
              isTrancheSelected={isTrancheSelected}
            />
          )}

          <GridContainer md={12}>
            <Grid item xs={12}>
              <div className={classes.buttonContainer}>
                {activeStep === steps.Summary ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(steps.AddDetails);
                    }}
                  >
                    Back
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  className={classes.buttonLeftMargin}
                  color="primary"
                  variant="contained"
                  disabled={
                    getNumberOfRecipientsToSendUpdate.isLoading || isLoading
                  }
                  onClick={onClick}
                >
                  {activeStep === steps.AddDetails
                    ? 'Proceed to Summary'
                    : 'Send update'}
                </Button>
              </div>
            </Grid>
          </GridContainer>
        </CmtCardContent>
      </CmtCard>
    </PageContainer>
  );
};
export default AddCustomUpdate;
