import Autocomplete from 'components/FormElements/AppAutocomplete';
import { useAdvisers } from 'hooks/data/adviser/useAdvisers';
import { useGetRole } from 'hooks/ui/useGetRole';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

const labelRenderer = (option) =>
  option ? `${option.fullName ?? ''} (${option.email})` : 'Direct';

const AdviserSelect = ({ adviser, ...rest }) => {
  const { firmId } = useGetRole();
  const { data, isFetching } = useAdvisers({ params: { firmId } });

  const sortedOptions = useMemo(
    () => sortBy(data?.adviser, ['fullName']),
    [data?.adviser],
  );

  return !isFetching ? (
    <Autocomplete
      id="adviserId"
      name="adviserId"
      options={sortedOptions}
      getOptionLabel={labelRenderer}
      filterSelectedOptions
      variant="outlined"
      placeholder="N/A - Direct subscription"
      value={adviser}
      {...rest}
    />
  ) : (
    <span>Loading...</span>
  );
};

export default AdviserSelect;
