import Table from 'components/Table';
import { numberToCurrencyString, renderMultiple } from 'further-ui/utils';
import useTopOrganisations from 'hooks/data/dashboards/useTopOrganisations';
import { Link } from 'react-router-dom';
import { createOrganisationViewRoute, ORGANISATION } from 'constants/routes';
import Skeleton from '@material-ui/lab/Skeleton';
import topOrganisationTableStyles from './styles';
import { numberToDisplayString } from 'utils/numbers';
import { Button } from '@material-ui/core';
import { InfoOutlined, Visibility } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';

const TopOrganisationTable = ({ firmId, type }) => {
  const { data: organisations, isFetching } = useTopOrganisations({
    params: { firmId, type },
  });

  const classes = topOrganisationTableStyles();
  const columns = [
    {
      label: 'Name',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          organisation.name
        ),
      sort: false,
    },
    {
      label: 'Subscription amount',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(organisation.totalInvestmentAmount)
        ),
      sort: false,
    },
    {
      label: 'Current value AUM',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(organisation.currentValueAum)
        ),
      sort: false,
    },
    {
      label: 'Total portfolio value',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToCurrencyString(organisation.totalPortfolioValue)
        ),
      sort: false,
    },
    {
      label: (
        <div className={classes.tooltipContainer}>
          Blended multiple{' '}
          <Tooltip title="This is the live multiple on investment across all subscriptions linked to this adviser. The multiple considers only paid-up funds and does not include unpaid but committed capital.">
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        </div>
      ),
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          renderMultiple(organisation.blendedMultiple)
        ),
      sort: false,
    },
    {
      label: 'Subscriptions',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToDisplayString(organisation.totalInvestments)
        ),
      sort: false,
    },
    {
      label: 'Clients',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          numberToDisplayString(organisation.investorCount)
        ),
      sort: false,
    },
    {
      label: '',
      render: (organisation) =>
        isFetching ? (
          <Skeleton animation="wave" variant="text" />
        ) : (
          <Link to={createOrganisationViewRoute(organisation._id)}>
            <Button
              title="View"
              color="primary"
              className={classes.actionButton}
            >
              <Visibility />
            </Button>
          </Link>
        ),
      sort: false,
    },
  ];

  return (
    <>
      <Link className={classes.link} to={ORGANISATION}>
        View all
      </Link>
      <Table
        columns={columns}
        tablebody={
          organisations?.length
            ? organisations
            : // Adding empty objects to show skeletons
              Array.from({ length: 10 }, () => ({}))
        }
        pagination={false}
        emptyMessage="No top organisations found"
        variant="nohover"
      />
    </>
  );
};

export default TopOrganisationTable;
