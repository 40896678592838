import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useDisclosure } from 'further-ui/hooks';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import PageContainer from 'components/PageContainer';

import { TAG_ADD, createTagEditRoute } from 'constants/routes';
import { Link } from 'react-router-dom';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useTags } from 'hooks/data/tag/useTags';

const useStyles = makeStyles((theme) => ({
  customButton: {
    minWidth: '35px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.white,
    },
  },
  dFlex: {
    display: 'flex',
  },
  addRoleBtn: {
    textAlign: 'right',
  },
  tableMt: {
    marginTop: 10,
  },
}));

const breadcrumbs = [
  { label: 'Dashboard', link: '/' },
  { label: 'Fund Types', isActive: true },
];

const Tag: FC = () => {
  const classes = useStyles();
  const [createPermission, deletePermission, updatePermission] =
    useGetPermissions(['create:tag', 'delete:tag', 'edit:tag']);

  const deleteTagDisclosure = useDisclosure<{ tagId: string }>();
  const { tags, deleteTag } = useTags();

  const columns = [
    {
      label: 'Fund Type',
      key: 'name',
    },
    {
      label: 'Actions',
      key: '',
      sort: false,
      render: (elm: { _id: string }) => (
        <div className={classes.dFlex}>
          {updatePermission && (
            <Link to={createTagEditRoute(elm._id)}>
              <Button
                title="Edit"
                color="primary"
                className={classes.customButton}
              >
                <Edit />
              </Button>
            </Link>
          )}
          {deletePermission && (
            <Button
              title="Delete"
              className={classes.customButton}
              onClick={() => handleDelete(elm._id)}
            >
              <Delete htmlColor="#E8453C" />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleDelete = (tagId: string) => {
    deleteTagDisclosure.stageAction({ tagId });
    deleteTagDisclosure.onOpen();
  };

  const handleConfirmDelete = () => {
    deleteTag.mutate(deleteTagDisclosure.actionProps.tagId);
    deleteTagDisclosure.onClose();
  };

  return (
    <PageContainer heading="Fund Types" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              {createPermission && (
                <div className={classes.addRoleBtn}>
                  <Link to={TAG_ADD}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="mb-4"
                    >
                      Add Fund Type
                    </Button>
                  </Link>
                </div>
              )}
              <div className={classes.tableMt}>
                <Table
                  columns={columns}
                  tablebody={tags.data ?? []}
                  loading={tags.isLoading}
                  variant="nohover"
                />
              </div>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <AlertDialog
          open={deleteTagDisclosure.isOpen}
          onClose={deleteTagDisclosure.onClose}
          onConfirm={handleConfirmDelete}
          title={`Are you sure you wish to delete this fund type?`}
          content={
            <p>
              This cannot be undone and all information relating to the fund
              type will be lost.
            </p>
          }
          btnLabels={{
            cancel: 'Go back',
            confirm: 'Delete fund type',
          }}
        />
      </GridContainer>
    </PageContainer>
  );
};

export default Tag;
