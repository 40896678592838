import * as React from 'react';
import {
  isAlphaNumericKey,
  isNavigationKey,
  getCellProperty,
  getCharFromKeyCode,
  keyCodes,
} from '@silevis/reactgrid';

class AbstracTextTemplate extends React.Component {
  formatValue(value) {
    return value;
  }

  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = parseFloat(text);

    return {
      ...uncertainCell,
      text,
      value,
      placeholder,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(cell, isInEditMode) {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    } ${className}`;
  }

  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      const cellText = cell.text || cell.placeholder || '';
      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cellText;
      return cell.renderer ? cell.renderer(textToDisplay) : textToDisplay;
    }

    return (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => {
          e.target.value = this.formatValue(e.target.value);
          return onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false,
          );
        }}
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            !(e.view.event.keyCode === keyCodes.ESCAPE),
          )
        }
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}

export default AbstracTextTemplate;
