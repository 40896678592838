import { useQuery, useMutation } from '@tanstack/react-query';
import { api } from 'lib/httpClient';

async function fetchAdvisers({ queryKey }) {
  const [, { params }] = queryKey;

  try {
    const {
      data: { data },
    } = await api.get(`adviser/list`, { params });
    return data;
  } catch (error) {
    throw error;
  }
}
const exportAdviser = async (params) => {
  const response = await api.post('adviser/export', params, {
    responseType: 'blob',
  });
  if (response.status !== 200) {
    throw Error('Unexpected export response.');
  }
  return response.data;
};

// TODO: refactor to use correct type - AdviserApi.AdviserListResponse
export function useAdvisers({ params }) {
  return useQuery({
    queryKey: ['advisers', { params }],
    queryFn: fetchAdvisers,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });
}

export function useAdvisersExport({ params }) {
  const { mutateAsync, isLoading: isExportLoading } = useMutation({
    mutationFn: () => exportAdviser(params),
  });
  return {
    exportAdviser: mutateAsync,
    isExportLoading,
  };
}
