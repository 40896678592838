import { useState, useEffect } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router';
import PageLoader from 'components/PageLoader';
import { Alert, AlertTitle } from '@material-ui/lab';
import PageContainer from 'components/PageContainer';
import AlertDialog from 'components/AlertDialog';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import {
  INVESTOR_REPORTING,
  INVESTOR_REPORTING_EDIT,
  DASHBOARD_ALL_FIRMS,
} from 'constants/routes';
import { useGetRole } from 'hooks/ui/useGetRole';
import { useInvestorReporting } from 'hooks/data/investorReporting/useInvestorReporting';
import { useDisclosure } from 'further-ui/hooks';
import InvestorReportingForm from './InvestorReportingForm';
import InvestorReportingFormContextProvider from 'contexts/InvestorReportingFormContext';

const AddUpdateInvestorReport = () => {
  const { isSuperAdmin, firmId } = useGetRole();
  const backDisclosure = useDisclosure();
  const history = useHistory();
  const { id: investorReportId } = useParams();
  const isUpdatePage = useRouteMatch(INVESTOR_REPORTING_EDIT)?.isExact;

  const [pageTitle, setPageTitle] = useState(
    'Investor reporting: Create update',
  );

  const breadcrumbs = [
    {
      label: 'Dashboard',
      onClick: () => backDisclosure.stageAction(DASHBOARD_ALL_FIRMS),
    },
    {
      label: 'Investor Reporting',
      onClick: () => backDisclosure.stageAction(INVESTOR_REPORTING),
    },
    {
      label: investorReportId
        ? 'Update periodic report'
        : 'Create periodic report',
      link: '/',
      isActive: true,
    },
  ];

  const { data: investorReportData, error: fetchError } =
    useInvestorReporting().fetchInvestorReport(investorReportId);

  useEffect(() => {
    if (!investorReportData) return;

    setPageTitle(
      `Investor reporting - ${
        investorReportData.investorReport?.firm?.firmName
      }: ${isUpdatePage ? 'Edit' : 'Create update'}`,
    );
  }, [investorReportData, isUpdatePage]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setPageTitle(
        `Investor reporting: ${isUpdatePage ? 'Edit' : 'Create update'}`,
      );
    }
  }, [investorReportId, isSuperAdmin, isUpdatePage]);

  const handleExitForm = () => {
    backDisclosure.stageAction(INVESTOR_REPORTING);
  };

  if (fetchError)
    return (
      <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
        <CmtCard>
          <CmtCardContent>
            <Alert severity="warning">
              <AlertTitle>Sorry</AlertTitle>
              This investor report could not be loaded. Please try again later.
            </Alert>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    );

  if (investorReportId && !investorReportData?.investorReport)
    return <PageLoader />;

  return (
    <PageContainer heading={pageTitle} breadcrumbs={breadcrumbs}>
      <CmtCard style={{ overflow: 'visible' }}>
        <CmtCardContent style={{ overflow: 'visible' }}>
          <InvestorReportingFormContextProvider
            investorReport={investorReportData?.investorReport ?? null}
            isSuperAdmin={isSuperAdmin}
            userFirmId={firmId}
          >
            <InvestorReportingForm onExitForm={handleExitForm} />
          </InvestorReportingFormContextProvider>
        </CmtCardContent>
      </CmtCard>
      <AlertDialog
        onClose={backDisclosure.onClose}
        open={backDisclosure.isOpen}
        title="Are you sure you wish to go back?"
        content="Any changes made to this investor update will be lost if you go back without saving."
        btnLabels={{
          confirm: 'Keep editing',
          cancel: 'Go back',
        }}
        cancelBtnProps={{
          variant: 'outlined',
          color: 'primary',
          onClick: () => {
            history.push(backDisclosure.actionProps);
            backDisclosure.onClose();
          },
        }}
        confirmBtnProps={{ color: 'primary' }}
        onConfirm={backDisclosure.onClose}
      />
    </PageContainer>
  );
};

export default AddUpdateInvestorReport;
