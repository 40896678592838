import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',
    justifyContent: 'flex-start',
  },
}));
