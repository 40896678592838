import React, { useEffect } from 'react';

import DropzoneComponent from 'components/Dropzone';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, makeStyles, Typography } from '@material-ui/core';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import TextField from 'components/FormElements/AppTextInput';
import AppPasswordInput from 'components/FormElements/AppPasswordTextField';
import AppSelectBox from 'components/FormElements/AppSelectBox';
import Grid from '@material-ui/core/Grid';
import GridContainer from 'components/GridContainer';
import PageContainer from 'components/PageContainer';

import {
  getUserProfile,
  updateUserProfile,
  addUser,
} from '@redux/actions/User';
import { userInformation } from '@redux/actions/Auth';
import { useGetRole } from 'hooks/ui/useGetRole';
import { USER } from 'constants/routes';
import { Link } from 'react-router-dom';
import { capitalize, compact, startCase } from 'lodash';
import { validateUserName } from 'further-ui/utils';
import useFileUpload from 'hooks/ui/useFileUpload';
import useApiRequestHandler from 'hooks/ui/useApiRequestHandler';
import { UploadType } from 'further-types/files';
import { FirmSelector } from 'components/FirmSelector';
import useRoles from 'hooks/data/role/useRoles';

const useStyles = makeStyles(() => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
}));

const AddUpdateUser = () => {
  const { id } = useParams();

  const breadcrumbs = [
    { label: 'Dashboard' },
    { label: 'User', link: '/user' },
    {
      label: id ? 'Update User' : 'Add User',
      link: '/',
      isActive: true,
    },
  ];
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const userRole = useGetRole();
  const handleApiRequest = useApiRequestHandler();
  const { uploadFile } = useFileUpload();
  const { user } = useSelector(({ auth }) => auth);
  const [value, setValue] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleId: '',
    firmId: null,
    profilePic: null,
    previewProfilePic: null,
  });
  const [errorText, setErrorText] = React.useState({});
  const [flag, setFlag] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { data: roleList } = useRoles();

  const validate = () => {
    const errors = {};
    let isError = false;
    if (!value.firstName) {
      errors.firstName = 'Please enter first name.';
      isError = true;
    } else {
      const isNameError = validateUserName(value?.firstName);
      if (isNameError) {
        errors.firstName = isNameError;
        isError = true;
      }
    }
    if (!value.lastName) {
      errors.lastName = 'Please enter last name.';
      isError = true;
    } else {
      const isNameError = validateUserName(value?.lastName);
      if (isNameError) {
        errors.lastName = isNameError;
        isError = true;
      }
    }
    if (!value.email) {
      errors.email = 'Please enter email address.';
      isError = true;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value.email)
    ) {
      errors.email = 'Invalid email address.';
      isError = true;
    }
    if (!value.password && !id) {
      errors.password = 'Please enter password.';
      isError = true;
    }

    if (
      value.password &&
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!£@$%]).{8,}$/i.test(
        value.password,
      )
    ) {
      errors.password =
        'Your password needs to be at least 8 characters, contain an uppercase letter, a lowercase letter, a number and one of the following symbols: ! @ £ $ %.';
      isError = true;
    }
    if (!value.roleId) {
      errors.roleId = 'Please select role type.';
      isError = true;
    }
    if (!value.firmId && flag) {
      errors.firmId = 'Please select firm.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };

  const onSubmit = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      if (value?.profilePic?.includes('.com/')) {
        value.profilePic = value.profilePic.split('.com/')[1];
      }
      await handleApiRequest(async (success) => {
        if (id) {
          value.id = id;
          const response = await dispatch(updateUserProfile(value));
          if (response.status === 200) {
            success(response.data.responseMsg);
            if (id === user?.user?._id) {
              dispatch(userInformation({ user: response?.data?.data }));
            }
          }
        } else {
          const response = await dispatch(addUser(value));
          success(response.data.responseMsg);
        }
      });

      history.push(USER);
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      await handleApiRequest(async () => {
        const response = await dispatch(getUserProfile(id));
        setValue({
          firstName: response?.firstName,
          lastName: response?.lastName,
          email: response?.email,
          roleId: response?.roleId?.auth0RoleId,
          firmId: response?.firmId?._id,
          profilePic: response?.profilePic,
          previewProfilePic: response?.profilePic,
        });
        if (response?.firmId) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      });
      setLoading(false);
    };

    if (id) {
      getProfile();
    } else {
      setLoading(false);
    }
  }, [dispatch, user]);

  const handleChange = (event) => {
    setValue({ ...value, roleId: event });
    let filter = roleList?.filter((role) => role.id === event);
    if (filter[0]?.name === 'firm-manager') {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleProfileImage = async (file) => {
    if (!file?.[0]?.path) return;

    await handleApiRequest(async () => {
      const { filePath } = await uploadFile(file[0], UploadType.UserImages);

      setValue({
        ...value,
        profilePic: filePath,
        previewProfilePic: file[0]?.preview,
      });
    }, "Something went wrong - the file couldn't be uploaded");
  };

  const roles = compact(
    roleList?.map((role) => {
      if (role.name === 'super-admin' && !userRole.isSuperAdmin)
        return undefined;

      return {
        name: capitalize(startCase(role.name)),
        value: role.id,
      };
    }),
  );

  return (
    <React.Fragment>
      <PageContainer
        heading={id ? 'Update User' : 'Add User'}
        breadcrumbs={breadcrumbs}
      >
        {loading ? (
          ''
        ) : (
          <CmtCard>
            <CmtCardContent>
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="First name"
                    value={value.firstName}
                    onChange={(event) => {
                      setValue({ ...value, firstName: event.target.value });
                    }}
                    error={errorText.firstName}
                    helperText={errorText.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label="Last name"
                    onChange={(event) => {
                      setValue({ ...value, lastName: event.target.value });
                    }}
                    value={value.lastName}
                    error={errorText.lastName}
                    helperText={errorText.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    disabled={id ? true : false}
                    fullWidth
                    variant="outlined"
                    label="Email Address"
                    onChange={(event) => {
                      setValue({ ...value, email: event.target.value });
                    }}
                    value={value.email}
                    error={errorText.email}
                    helperText={errorText.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppPasswordInput
                    name="password"
                    label="Password"
                    onChange={(event) =>
                      setValue({ ...value, password: event.target.value })
                    }
                    value={value.password}
                    error={errorText.password}
                    helperText={errorText.password}
                    labelWidth={80}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AppSelectBox
                    required
                    data={roles}
                    valueKey="value"
                    labelKey="name"
                    label="Role Type"
                    value={value.roleId}
                    variant="outlined"
                    onChange={(event) => handleChange(event.target.value)}
                    error={errorText.roleId}
                    helperText={errorText.roleId}
                  />
                </Grid>
                {flag ? (
                  <Grid item xs={12} sm={6}>
                    <FirmSelector
                      firmId={value.firmId}
                      onChange={(firmId) => setValue({ ...value, firmId })}
                      placeholder="Select a firm"
                      required
                      error={errorText.firmId}
                      helperText={errorText.firmId}
                    />
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} sm={6}>
                  <Typography component="h4" variant="inherit" required>
                    Profile Image
                  </Typography>

                  <DropzoneComponent
                    files={
                      value?.profilePic
                        ? [{ preview: value?.previewProfilePic }]
                        : []
                    }
                    onDropFile={(files) => handleProfileImage(files)}
                    onRemove={() =>
                      setValue({
                        ...value,
                        profilePic: null,
                        previewProfilePic: null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                  >
                    {id ? 'Update' : 'Save'}
                  </Button>
                  <Link to={USER}>
                    <Button
                      className={classes.marginLeftBtn}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Link>
                </Grid>
              </GridContainer>
            </CmtCardContent>
          </CmtCard>
        )}
      </PageContainer>
    </React.Fragment>
  );
};

export default AddUpdateUser;
