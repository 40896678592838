import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'further-types/api';
import { api } from 'lib/httpClient';
import { Api } from 'further-types/role';

const useRoles = () => {
  return useQuery<Api.RolesResponse>({
    queryKey: ['roles'],
    queryFn: async () => {
      const result = await api.get<ApiResponse<Api.RolesResponse>>('/role');
      return result?.data?.data ?? [];
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });
};

export default useRoles;
