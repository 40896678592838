import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { capitalize } from 'lodash';

import PageContainer from 'components/PageContainer';
import GridContainer from 'components/GridContainer';
import Table from 'components/Table';
import CmtCard from 'components/CmtCard';
import CmtCardContent from 'components/CmtCard/CmtCardContent';
import { numberToCurrencyString, dateToLabel } from 'further-ui/utils';
import { useDisclosure } from 'further-ui/hooks';
import { displayFeeAccountingType } from 'utils/displayFeeAccounting';
import TotalFeeSummary from './TotalFeeSummary';
import FeeFilter, { optionValueAll } from './Filters';

import {
  createInvestmentEditRoute,
  createInvestorViewRoute,
} from 'constants/routes';
import { type as feeConst } from 'constants/typeConstant';
import AlertDialog from 'components/AlertDialog';
import { useGetPermissions } from 'hooks/ui/useGetPermissions';
import { useGetRole } from 'hooks/ui/useGetRole';
import { usePagination } from 'hooks/ui/usePagination';
import CardHeader from 'components/CardHeader';
import { useFees } from 'hooks/data/investment/useFees';
import { useFunds } from 'hooks/data/fund/useFunds';
import { useDebounce } from 'usehooks-ts';
import downloadFile from 'utils/downloadFile';
import { useNotification } from 'hooks/ui/useNotification';
import useFilters from 'hooks/ui/useFilters';
import { FeeAction } from 'components/SVGIcons';
import FrozenInvestment from './FrozenInvestment';

const useStyles = makeStyles({
  flxCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textColor: {
    color: '#282525',
    marginBottom: '15px',
  },
  marginBottom: {
    marginBottom: '15px',
  },
  content: {
    fontFamily: 'MaisonNeue',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#7B7B7B',
  },
  sectionAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '42px',
    marginBottom: '40px',
  },
  dialog: {
    maxWidth: '624px!important',
  },
  gridCard: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  gridItem: {
    width: '100%',
  },
  notesList: {
    color: '#757575',
    fontSize: 14,
    margin: '-16px 0 20px 20px',
  },
  frozenInvestments: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
});

const getColumns = (canEditInvestment) => [
  {
    label: 'Investor name',
    key: 'fullName',
  },
  {
    label: 'Date',
    key: 'fee.date',
    render: (row) => dateToLabel(row?.fee?.date),
  },
  {
    label: 'Fee type',
    key: 'fee.feeType',
    render: (row) => capitalize(row?.fee?.feeType).replace('_', ' '),
  },
  { label: 'Fee name', key: 'fee.feeName', render: (row) => row?.fee?.feeName },
  {
    label: 'Fee (%)',
    key: 'fee.netFeePercentage',
    render: (row) => {
      return (
        <>
          {row?.fee?.netFeePercentage
            ? typeof row?.fee?.netFeePercentage === 'number'
              ? row?.fee?.netFeePercentage?.toFixed(2) + '%'
              : row?.fee?.netFeePercentage + '%'
            : ''}
        </>
      );
    },
  },
  {
    label: 'Fee £ (Ex VAT)',
    key: 'fee.feeExVAT',
    render: (row) => numberToCurrencyString(row?.fee?.feeExVAT),
  },
  {
    label: 'Fee £ (Inc VAT)',
    key: 'fee.feeIncVAT',
    render: (row) => numberToCurrencyString(row?.fee?.feeIncVAT),
  },
  {
    label: 'VAT',
    key: 'fee.vat',
    render: (row) => numberToCurrencyString(row?.fee?.vat),
  },
  {
    label: 'Fee accounting',
    key: 'fee.feeAccounting',
    render: (row) => displayFeeAccountingType(row?.fee?.feeAccounting),
  },
  {
    label: 'Exported to custodian?',
    key: 'fee.custodian',
    render: (row) => (row?.fee?.custodian ? 'Yes' : 'No'),
  },
  {
    label: 'Action',
    sort: false,
    render: (row) => (
      <div>
        {canEditInvestment && row.investmentId ? (
          <Link to={createInvestmentEditRoute(row?.investmentId)}>
            <Button title="View full subscription page for this fee">
              <FeeAction />
            </Button>
          </Link>
        ) : null}
        {!row?.investmentId ? (
          <Link to={createInvestorViewRoute(row?.investorId)}>
            <Button title="View investor page for this fee">
              <FeeAction />
            </Button>
          </Link>
        ) : null}
      </div>
    ),
  },
];

const getInitialFilters = () => {
  return {
    feeAccounting: feeConst.feeAccounting.chargedToInvestor,
    fullName: '',
    exportToCustodian: false,
    feeType: feeConst.feeExistence.past,
    funds: [],
    startDate: null,
    endDate: null,
  };
};

const hasFrozenFees = (fees) => {
  return !!(fees?.filter((fee) => fee?.fee?.isFrozen) ?? []).length;
};

const getInvestmentsWithFrozenFees = (fees) => {
  return Object.values(
    fees
      ?.filter((fee) => fee?.fee?.isFrozen)
      .reduce((investments, fee) => {
        return {
          ...investments,
          [fee.investmentId]: fee,
        };
      }, {}) ?? {},
  );
};

const FeeSummary = () => {
  const classes = useStyles();
  const { error } = useNotification();
  const { firmId } = useGetRole();
  const { tranches } = useFunds({ leanResponse: true });
  const [canEditInvestment] = useGetPermissions(['edit:investment']);
  const custodianDisclosure = useDisclosure();
  const frozenFeesDisclosure = useDisclosure();

  const [isExported, setIsExported] = useState(false);

  const {
    page,
    rowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    toFirstPage,
  } = usePagination({ id: 'fees-summary', order: 'fee.date', orderBy: 'desc' });

  const { filters, handleChangeFilters } = useFilters('fees-summary', {
    defaultFilters: getInitialFilters(),
    onFiltersChange: toFirstPage,
  });

  const debouncedFullName = useDebounce(filters.fullName, 300);

  const { fees, exportFees, isExportLoading } = useFees({
    params: {
      ...filters,
      exportToCustodian:
        filters.exportToCustodian === optionValueAll
          ? undefined
          : filters.exportToCustodian,
      feeAccounting:
        filters.feeAccounting === optionValueAll ? null : filters.feeAccounting,
      fullName: debouncedFullName,
      funds: filters.funds?.length ? filters.funds : undefined,
      firmId,
      page,
      perPage: rowsPerPage,
      order,
      orderBy,
    },
  });

  const handleExport = async (type) => {
    try {
      const data = await exportFees(type);

      if (data) {
        downloadFile(data, `Fee-Summary-${Date.now()}.xlsx`);
        if (type === feeConst.feeExport.custodian) {
          custodianDisclosure.onClose();
          frozenFeesDisclosure.onClose();
          setIsExported(!isExported);
        }
      }
    } catch (exportError) {
      if (exportError) {
        error(exportError.message);
        return;
      }
    }
  };

  const summaryTotal = fees.data?.summaryTotal ?? {
    initial: 0,
    recurring: 0,
    other: 0,
    total: 0,
    performance: 0,
  };

  const handleCustodianExport = () => {
    if (hasFrozenFees(fees.data?.fees)) {
      custodianDisclosure.onClose();
      frozenFeesDisclosure.onOpen();
    } else {
      handleExport(feeConst.feeExport.custodian);
    }
  };

  return (
    <>
      <PageContainer
        heading="Fee manager: Fees summary"
        breadcrumbs={[
          { label: 'Dashboard', link: '/' },
          { label: 'Fee Summary', isActive: true },
        ]}
      >
        <GridContainer>
          <Grid item xs={12}>
            <CmtCard>
              <CmtCardContent>
                <CardHeader title="Please note:" />
                <ul className={classes.notesList}>
                  <li>
                    To change any ongoing fees for a tranche, please navigate to
                    the Tranches section in the side menu.
                  </li>
                  <li>
                    To change any adviser commissions or adviser-specific fund
                    fees, please navigate to the Advisers section in the side
                    panel.
                  </li>
                </ul>
                <FeeFilter
                  filters={filters}
                  onFiltersChange={handleChangeFilters}
                  trancheListing={tranches.data}
                  setIsOpen={custodianDisclosure.onOpen}
                  onExportClick={handleExport}
                  isExportLoading={isExportLoading}
                />
                <TotalFeeSummary
                  classes={classes}
                  summaryTotal={summaryTotal}
                />
                <Table
                  columns={getColumns(canEditInvestment)}
                  tablebody={fees.data?.fees ?? []}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  pagination={true}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={fees.data?.count ?? 0}
                  loading={fees.isFetching}
                  variant="nohover"
                />
              </CmtCardContent>
            </CmtCard>
          </Grid>
        </GridContainer>
      </PageContainer>
      <AlertDialog
        open={custodianDisclosure.isOpen}
        onClose={custodianDisclosure.onClose}
        title={
          filters.feeType === feeConst.feeExistence.future ||
          filters.feeType === optionValueAll
            ? 'Exporting future fees to custodian'
            : 'Exporting to custodian'
        }
        content={
          filters.feeType === feeConst.feeExistence.future ||
          filters.feeType === optionValueAll ? (
            <>
              <p className={classes.content}>
                Please note this will download a CSV containing all fees in the
                current view. Accrued (uncharged) fees will not be included in
                this export.
              </p>
              <br />
              <p className={classes.content}>
                This current view includes fees that are scheduled to be charged
                in the future. Please confirm you intend for these to be
                included in the export before proceeding.
              </p>
            </>
          ) : (
            <p className={classes.content}>
              Please note this will download a CSV containing all fees in the
              current view that have been charged to investors. Accrued
              (uncharged) fees will not be included in this export.
            </p>
          )
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Proceed',
        }}
        confirmBtnProps={isExportLoading ? { disabled: true } : {}}
        onConfirm={handleCustodianExport}
      />
      <AlertDialog
        open={frozenFeesDisclosure.isOpen}
        onClose={frozenFeesDisclosure.onClose}
        title="Freeze-adjusted fees"
        content={
          <div className={classes.frozenInvestments}>
            <p className={classes.content}>
              Fees for the following subscriptions are freeze-adjusted. If you
              export these fees to your custodian, the subscription(s) cannot be
              unfrozen. Do you wish to continue?
            </p>
            <div>
              {getInvestmentsWithFrozenFees(fees.data?.fees).map(
                (investment) => (
                  <FrozenInvestment
                    key={investment.investmentId}
                    investment={investment}
                  />
                ),
              )}
            </div>
          </div>
        }
        btnLabels={{
          cancel: 'Go back',
          confirm: 'Proceed',
        }}
        confirmBtnProps={isExportLoading ? { disabled: true } : {}}
        onConfirm={() => handleExport(feeConst.feeExport.custodian)}
      />
    </>
  );
};

export default FeeSummary;
