import { Typography } from '@material-ui/core';
import CmtAvatar from 'components/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px 16px 20px 32px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 20,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 6,
    lineHeight: '13px',
    fontSize: '16px',
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: 800,
    letterSpacing: 0.25,
  },
  brandImg: {
    width: '120px',
  },
  brandImgBlock: {
    marginBottom: '30px',
  },
  userTitleInfo: {
    display: 'block',
    width: '100%',
  },
  userNavDrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
  },
  roundthumb: {
    filter: 'drop-shadow(0px 4px 20px rgba(134, 137, 165, 0.25))',
    border: '2px solid #fff',
  },
  '@media screen and (min-width: 1920px)': {
    root: {
      padding: '30px 40px 20px 40px',
    },
  },
}));

const SidebarHeader: React.FC = () => {
  const classes = useStyles();

  //@ts-expect-error
  const { user } = useSelector(({ auth }) => auth);

  return (
    <div className={classes.root}>
      <div className={classes.brandImgBlock}>
        <img src="images/logo.png" className={classes.brandImg} alt="profile" />
      </div>
      <CmtAvatar
        //@ts-expect-error
        src={user?.user?.profilePic}
        className={classes.roundthumb}
        alt="User Avatar"
      />
      <div className={classes.userInfo}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div className={classes.userTitleInfo}>
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              {user?.user.firstName && user?.user.lastName
                ? `${user.user.firstName} ${user.user.lastName}`
                : '-'}
            </Typography>
          </div>
          <div className={classes.userNavDrop}>
            <Typography className={classes.userSubTitle}>
              {user?.user?.email}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
