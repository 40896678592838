import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppLayout from 'layouts/AppLayout';
import defaultTheme from 'theme/defaultTheme';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const AppWrapper = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <AppLayout>{children}</AppLayout>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
