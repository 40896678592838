import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from 'components/CmtImage';

const Logo = () => {
  const logoUrl = '/images/logo.png';

  return (
    <Box className="pointer" ml={2}>
      {/* @ts-expect-error */}
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" style={{ maxWidth: '100px' }} />
        </NavLink>
      </Hidden>
      {/* @ts-expect-error */}
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" style={{ maxWidth: '100px' }} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
