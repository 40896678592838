import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import AppRadioButton from 'components/FormElements/AppRadioButton';
import { makeStyles } from '@material-ui/core/styles';
import FieldRow from 'components/FormElements/FieldRow';
import CkEditor from 'components/CkEditor';
import DropzoneComponent from 'components/Dropzone';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function TrancheHighlight({
  errorTextFundHighlight,
  fundHighlight,
  gallaryImage,
  handleCancel,
  handleClickFundHighlight,
  handleGalleryImage,
  id,
  previewGallery,
  setFundHighlight,
  setGallaryImage,
  setPreviewGallery,
  disabled = false,
}) {
  const classes = useStyles();

  const onRemove = (index) => {
    gallaryImage.splice(index, 1);
    previewGallery.splice(index, 1);
    setPreviewGallery([...previewGallery]);
    setGallaryImage([...gallaryImage]);
  };

  return (
    <GridContainer item xs={12} md={11}>
      <FieldRow
        title="Highlight text"
        tooltipText="This section is for writing up to three bullet points summarising the fund. Use the text field to input highlights. Pressing ‘Enter’ on the keyboard starts a new bullet point’s text."
      >
        <CkEditor
          name="fundDescription"
          onChange={(event, editor) => {
            const data = editor.getData();
            setFundHighlight({
              ...fundHighlight,
              fundDescription: data,
            });
          }}
          value={fundHighlight.fundDescription}
        />

        <p className={classes.errorCk}>
          {errorTextFundHighlight?.fundDescription
            ? errorTextFundHighlight?.fundDescription
            : ''}
        </p>
      </FieldRow>
      <FieldRow title="Gallery / Gallery link" centerTitle>
        <AppRadioButton
          key={'link'}
          name="isChooseMedia"
          label={'Gallery link'}
          value={'0'}
          checked={fundHighlight.isChooseMedia === '0'}
          onChange={() =>
            setFundHighlight({
              ...fundHighlight,
              isChooseMedia: '0',
            })
          }
          error={errorTextFundHighlight.isChooseMedia}
          helperText={errorTextFundHighlight.isChooseMedia}
        />
        <AppRadioButton
          key={'gallary'}
          name="isChooseMedia"
          label={'Gallery image'}
          value={'0'}
          checked={fundHighlight.isChooseMedia === '1'}
          onChange={() =>
            setFundHighlight({
              ...fundHighlight,
              isChooseMedia: '1',
            })
          }
        />
      </FieldRow>
      <Grid item xs={12}>
        {fundHighlight.isChooseMedia === '0' ? (
          <TextField
            name="link"
            placeholder="Enter gallery link"
            fullWidth
            onChange={(event) =>
              setFundHighlight({
                ...fundHighlight,
                link: event.target.value,
              })
            }
            value={fundHighlight.link}
            variant="outlined"
            error={errorTextFundHighlight.link}
            helperText={errorTextFundHighlight.link}
          />
        ) : (
          <DropzoneComponent
            onDropFile={(files) => handleGalleryImage(files)}
            onRemove={onRemove}
            maxFiles={10}
            files={gallaryImage?.length ? previewGallery : []}
            multiple
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            disabled={disabled}
            color="primary"
            onClick={handleClickFundHighlight}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
