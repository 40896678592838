import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from 'lib/httpClient';
import { ApiResponse } from 'further-types/api';
import { Api } from 'further-types/annual-tax-report';

type UpdateTaxYearData = Api.UpdateTaxYearRequest & {
  taxYearStart: number;
};

function useUpdateTaxYear() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UpdateTaxYearData) =>
      (
        await api.put<ApiResponse<Api.UpdateTaxYearResponse>>(
          `/annual-tax-report/${data.taxYearStart}`,
          data,
        )
      ).data,
    onSuccess: () => {
      queryClient.invalidateQueries(['tax-years']);
    },
  });
}

export default function useTaxYears(params: {
  page: number;
  rowsPerPage: number;
}) {
  const taxYears = useQuery({
    queryKey: ['tax-years', params],
    queryFn: async () =>
      (
        await api.get<ApiResponse<Api.GetTaxYearsResponse>>(
          '/annual-tax-report/tax-years',
          {
            params,
          },
        )
      ).data.data,
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  return { taxYears, updateTaxYear: useUpdateTaxYear() };
}
