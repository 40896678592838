import React from 'react';
import GridContainer from 'components/GridContainer';
import { Button, Grid } from '@material-ui/core';
import TextField from 'components/FormElements/AppTextInput';
import { makeStyles } from '@material-ui/core/styles';
import DropzoneComponent from 'components/Dropzone';
import FieldRow from 'components/FormElements/FieldRow';

const useStyles = makeStyles((theme) => ({
  marginLeftBtn: {
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Research({
  handleCancel,
  handleClickResearch,
  id,
  onChangeResearchFile,
  researchValue,
  setResearchValue,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <GridContainer item xs={12} md={11}>
      <FieldRow title="Research label name" centerTitle>
        <TextField
          name="researchLabel"
          placeholder="Enter the title of this page section (e.g. Expert view)"
          fullWidth
          onChange={(event) =>
            setResearchValue({
              ...researchValue,
              researchLabel: event.target.value,
            })
          }
          value={researchValue.researchLabel}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Research text">
        <TextField
          name="sampleText"
          placeholder="Please enter research text"
          fullWidth
          onChange={(event) =>
            setResearchValue({
              ...researchValue,
              sampleText: event.target.value,
            })
          }
          value={researchValue.sampleText}
          multiline
          minRows={6}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Research provided by" centerTitle>
        <TextField
          name="researchProvidedBy"
          placeholder="Enter research provider or reviewer name"
          fullWidth
          onChange={(event) =>
            setResearchValue({
              ...researchValue,
              researchProvidedBy: event.target.value,
            })
          }
          value={researchValue.researchProvidedBy}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow title="Score" centerTitle>
        <TextField
          name="score"
          placeholder="Enter score is applicable"
          fullWidth
          onChange={(event) =>
            setResearchValue({
              ...researchValue,
              score: event.target.value,
            })
          }
          value={researchValue.score}
          variant="outlined"
        />
      </FieldRow>
      <FieldRow
        title="Upload research file"
        tooltipText="This document will be available for download by prospective investors. Only upload a research file if you are happy for it to be available for distribution."
      >
        <DropzoneComponent
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
          }}
          files={
            researchValue?.researchFile
              ? [
                  {
                    preview: researchValue?.previewResearchFile,
                    fileName: researchValue?.researchFile,
                  },
                ]
              : []
          }
          onDropFile={(files) => onChangeResearchFile(files)}
          onRemove={() =>
            setResearchValue({
              ...researchValue,
              researchFile: null,
              previewResearchFile: null,
            })
          }
        />
      </FieldRow>

      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.marginLeftBtn}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleClickResearch}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </Grid>
    </GridContainer>
  );
}
