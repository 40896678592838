import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import InvestorSearch from 'components/InvestorSearch';
import { INVESTOR_ADD } from 'constants/routes';
import useStyles from './styles';
import { Tooltip } from 'components/Tooltip';
import { InfoOutlined } from '@material-ui/icons';

type Props = {
  recipientId: string | null;
  handleRecipientSelect: (investorId?: string) => void;
  firmId: string | null;
};

const InvestorSearchRow: FC<Props> = ({
  recipientId,
  handleRecipientSelect,
  firmId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      alignItems="center"
      className={classes.investorSearchRow}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        xs={12}
        sm
        className={classes.investorSearch}
      >
        <Grid item>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="label"
            htmlFor="investorSearch"
          >
            Select investor
          </Typography>
        </Grid>
        <Grid item xs sm md={6}>
          <InvestorSearch
            selectedInvestorId={recipientId}
            onInvestorSelect={handleRecipientSelect}
            firmId={firmId}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm="auto">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          gridGap={10}
        >
          <Tooltip
            title="If you click to add a new investor, you will be returned to this
            screen once the investor creation process is complete."
          >
            <InfoOutlined />
          </Tooltip>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gridGap={10}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                history.push(INVESTOR_ADD, {
                  returnTo: pathname,
                })
              }
            >
              Add new investor
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InvestorSearchRow;
